/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {css as cssClassName} from '@emotion/css';
import {css} from '@mui/material';
import {ReactComponent as IconSVGSuccess} from '@src/shared/assets/images/icons/icon_success_round.svg';
import {H5, Text} from 'components/Text';
import {useTranslate} from 'i18n/useTranslate';
import React, {FC} from 'react';
import {toast, ToastOptions, ToastPosition} from 'react-toastify';
import {paletteColors} from 'theme';
import {ValidationErrorType, UUID} from 'types';

import {ReactComponent as IconClose} from './icons/close.svg';
import {ReactComponent as IconError} from './icons/error.svg';
import {ReactComponent as IconWarn} from './icons/warning.svg';

const IconCloseBtn = () => {
  return (
    <div css={css`height: 24px; width: 24px; display: flex; justify-content: center; align-items: center;`}>
      <IconClose />
    </div>
  );
};

// error
const ErrorToastContent: FC<{ header?: string, text?: ValidationErrorType }> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={20}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('TOAST_ERROR_HEADER')}</H5>
      <Text lh={20} fz={12}>{text && t(text)}</Text>
    </>
  );
};
const customErrorStyles = cssClassName`
    border: 1px solid ${paletteColors.error[200]};
    .Toastify {
        &__progress-bar {
            background: ${paletteColors.error[200]};
        }
    }
`;
export const errorToast = (data: { header?: string, text: ValidationErrorType, id?: UUID }, options?: ToastOptions) => {
  toast.error(() => <ErrorToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customErrorStyles,
    icon: <IconError />,
    closeButton: <IconCloseBtn />,
    toastId: data.id ?? data.text,
    ...options,
  });
  toast.update(data.id ?? data.text, options);
};

const WarnToastContent: FC<{ header?: string, text?: string }> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={20}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('TOAST_WARNING_HEADER')}</H5>
      <Text lh={20} fz={12}>{text}</Text>
    </>
  );
};
const customWarnStyles = cssClassName`
    border: 1px solid ${paletteColors.warning[200]};
    .Toastify {
        &__progress-bar {
            background: ${paletteColors.warning[200]};
        }
    }
`;
export const warningToast = (data: { header?: string, text: string }) => {
  toast.warn(() => <WarnToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customWarnStyles,
    icon: <IconWarn />,
    closeButton: <IconCloseBtn />,
  });
};

const SuccessToastContent: FC<{ header?: string, text?: string }> = ({header, text}) => {
  const {t} = useTranslate('common');
  return (
    <>
      <H5
        lh={20}
        fz={14}
        weight={700}
        mb={4}>{header ?? t('SUCCESS_TOAST_HEADER')}</H5>
      <Text lh={20} fz={12}>{text}</Text>
    </>
  );
};
const customSuccessStyles = cssClassName`
    border: 1px solid ${paletteColors.success[200]};
    .Toastify {
        &__progress-bar {
            background: ${paletteColors.success[200]};
        }
    }
`;
export const successToast = (data: { header?: string, text: string, position?: ToastPosition }) => {
  toast.success(() => <SuccessToastContent {...data} />, {
    type: toast.TYPE.DEFAULT,
    className: customSuccessStyles,
    icon: <IconSVGSuccess />,
    closeButton: <IconCloseBtn />,
    autoClose: 10000,
    position: data.position,
  });
};
