export const generateRefText = ({
  refText,
  refMin,
  refMax,
}: {
  refText?: string | null
  refMin: number | null
  refMax: number | null
}) =>
  refText || ((refMin || refMin === 0) && (refMax || refMax === 0) ? `${refMin} - ${refMax}` : '');
