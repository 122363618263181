/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Stack,
  Box,
  SxProps,
  Theme,
  Typography,
  Avatar,
  Button,
} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {Tabs, Tab, useTabs} from '@src/components/TabPanel';
import {BoxPaddings, WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {OverviewCalendar} from '@src/pages/Overview/fragments/OverviewCalendar';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as BreakRelation} from '@src/shared/assets/icons/break.svg';
import {getFullName, getUserInitials} from '@src/shared/utils';
import {RootState} from '@src/store';
import {accountActions} from '@src/store/account/slice';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useLocation, useMatch, useNavigate, useParams} from 'react-router-dom';

import {PageHeader} from '../PageHeader/PageHeader';
import {RelationEvents} from '../RelationEvents';
import {BreakRelationModal} from '../RelationMain/fragments/BreakRelationModal';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentContainer: {
    mt: 36,
    flexDirection: 'initial',
  },
  rightSideFragmentsContainer: {
    gap: 24,
    minWidth: '352px',
  },
  avatar: (t) => ({
    width: 72,
    height: 72,
    marginRight: 16,
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  personCard: (t) => ({
    display: 'flex',
    gap: 12,
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
  }),
  personName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    pr: 15,
    pb: 12,
  },
  shortInfoContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  shortInfo: {
    pr: 36,
  },
  calendar: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    alignSelf: 'stretch',
    backgroundColor: t.colors.all.white,
    width: '100%',
    zIndex: 0,
  }),
  events: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    alignSelf: 'stretch',
    backgroundColor: t.colors.all.white,
    width: '100%',
  }),
  tab: {
    whiteSpace: 'unset',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    padding: '6px 20px 12px 20px',
  },
  content: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    backgroundColor: alpha(t.palette.common.white, 0),
    gap: 36,
    flexGrow: 1,
  }),
  tabs: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    padding: '6px 90px 12px 90px',
  },
};

const tabIndexes: Record<string, number> = {
  about: 1,
  contacts: 2,
  'health-policies': 3,
  'user-relations': 4,
  orders: 5,
  results: 6,
};

export const TabletLayout = () => {
  const {t, ready} = useTranslate('connections');
  const step = useSelector((state: RootState) => state.relations.step);
  const {id} = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isFinalModalOpened = step === 'success' || step === 'error';
  const routAbout = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT);
  const routContacts = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS);
  const routHealthPolicies = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_HEALTH_POLICIES);
  const routRelations = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RELATIONS);
  const routResults = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const relationProfile = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION);
  const {index, handleChange: tabChange} = useTabs(
    tabIndexes[location.pathname.split('/').at(-1) ?? 'about'],
  );
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAccesses = relation?.accesses?.length;
  const address = relation?.actualAddresses;
  const isNotPaddingsForOutlet = routResults || routContacts || routHealthPolicies;

  useEffect(() => {
    if (isFinalModalOpened) dispatch(accountActions.requestAccountProfileData());
  }, [dispatch, isFinalModalOpened, step]);

  useEffect(() => {
    if (relationProfile) {
      navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id}/about`);
    }
  }, []);

  if (!ready) return null;

  return (
    <>
      <PageHeader />
      <Stack gap={24} flexGrow={1}>
        <Stack gap={24}>
          <WBox sx={sx.personCard}>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Breadcrumbs>
                <Link lh={24} weight={500} to={ROUTERS_PATH.CONNECTIONS_RELATIONS}>
                  {t('RELATIONS')}
                </Link>
                <Text lh={24} weight={500}>
                  {getFullName(relation?.contact)}
                </Text>
              </Breadcrumbs>
              <Button
                variant="contained"
                color="primary"
                startIcon={<BreakRelation />}
                onClick={() => setIsModalOpen((prev) => !prev)}
                disableRipple
              >
                {t('BREAK_RELATION')}
              </Button>
            </Box>
            <Box sx={sx.shortInfoContainer}>
              <Avatar sx={sx.avatar} alt={''} src={relation?.avatarUrl}>
                <Typography variant="24_28_600">{getUserInitials(relation?.contact)}</Typography>
              </Avatar>
              <Stack>
                <Typography component="h3" variant="24_34_700" sx={sx.personName}>
                  {getFullName(relation?.contact)}
                </Typography>
                <Box>
                  {isAccesses
                    ? (
                      <Box>
                        <Typography variant="16_24_400" sx={sx.shortInfo}>
                          {t('GENDER')}:{' '}
                          {relation?.contact?.gender ? t(relation?.contact?.gender) : '\u2015'}
                        </Typography>
                        <Typography variant="16_24_400" sx={sx.shortInfo}>
                          {t('DOB')}: {relation?.contact?.birthDateS && '\u2015'}
                        </Typography>
                        <Box>
                          <Typography variant="16_24_400" sx={sx.shortInfo}>
                            {t('ADDRESS')}: {address?.main || '\u2015'}
                          </Typography>
                        </Box>
                      </Box>
                    )
                    : (
                      <Typography variant="16_24_400" sx={sx.shortInfo}>
                        {t('DOB')}: {relation?.contact?.birthDateS}
                      </Typography>
                    )}
                </Box>
              </Stack>
            </Box>
          </WBox>
          <Box display="flex" gap={24}>
            <Box sx={sx.events}>
              <RelationEvents
                description={`${relation?.contact?.firstName}${t('DOESNT_HAVE_ANY_EVENTS_YET')}`}
              />
            </Box>
            <Box sx={sx.calendar}>
              <OverviewCalendar />
            </Box>
          </Box>
        </Stack>
        <Stack sx={sx.content}>
          <Tabs
            value={index}
            onChange={tabChange}
            scrollButtons="auto"
            sx={{padding: '24px 24px 0 24px', '.MuiTabs-indicator': {height: '1.6px'}}}
            variant="fullWidth"
          >
            <Tab
              label={t('ABOUT')}
              value={1}
              disableRipple
              isActive={!!routAbout}
              sx={sx.tab}
              onClick={() => navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id}/about`)}
            />
            <Tab
              value={2}
              label={t('CONTACTS')}
              disableRipple
              isActive={!!routContacts}
              sx={sx.tab}
              onClick={() => navigate('contacts')}
            />
            <Tab
              value={3}
              label={t('HEALTH_POLICIES')}
              disableRipple
              isActive={!!routHealthPolicies}
              sx={{...sx.tab, minWidth: 110}}
              onClick={() => navigate('health-policies')}
            />
            <Tab
              value={4}
              label={t('RELATIONS')}
              disableRipple
              isActive={!!routRelations}
              sx={sx.tab}
              onClick={() => navigate('user-relations')}
            />
            <Tab
              value={5}
              label={t('RESULTS')}
              disableRipple
              isActive={!!routResults}
              sx={sx.tab}
              onClick={() => navigate('results')}
            />
          </Tabs>
          <BoxPaddings paddings={isNotPaddingsForOutlet ? '0px' : '24px'} noTop>
            <Outlet />
          </BoxPaddings>
        </Stack>
      </Stack>
      <BreakRelationModal open={isModalOpen} setIsModalOpen={setIsModalOpen} contactRelationId={id}/>
      {}
    </>
  );
};
