import {Stack, Typography, Box, SxProps, Avatar, Theme} from '@mui/material';
import {RelationElement} from '@src/api';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import Grid from '@src/components/Grid';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useColumnsNumber} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {createRelationPath, getUserInitials} from '@src/shared/utils';
import {useContactRelations} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

const initialContact: RelationElement = {
  contact: {
    birthDate: '',
    firstName: '',
    lastName: '',
    middleName: '',
    id: '',
  },
  avatarUrl: '',
  endDate: '',
  id: '',
  type: undefined,
};

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    padding: {
      xs: '12px 18px 18px 18px',
      sm: 24,
    },
    boxSizing: 'border-box',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 24,
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  contentContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  content: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 28),
    padding: {
      xs: 12,
      sm: '12px 18px 0px 18px',
      xxl: '12px 18px 12px 18px',
    },
    boxSizing: 'border-box',
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'start',
    height: {
      xs: 114,
      sm: 132,
    },
    maxWidth: '100%',
    minWidth: {
      xs: 282,
      md: 384,
      lg: 260,
      xl: 285,
      xxl: 342,
    },
    backgroundColor: alpha(t.palette.secondary.main, 7),
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 14),
      borderColor: alpha(t.palette.secondary.main, 28),
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor: alpha(t.palette.secondary.main, 28),
      borderColor: alpha(t.palette.secondary.main, 62),
      cursor: 'pointer',
    },
  }),
  avatar: (t) => ({
    width: {
      xs: 40,
      sm: 72,
    },
    height: {
      xs: 40,
      sm: 72,
    },
    mr: 16,
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  avatarText: {
    typography: {
      xs: '14_18_700',
      sm: '24_28_600',
    },
  },
  personBirthDay: {
    pt: {xs: 3, sm: 6},
    pb: {
      xs: 16,
      sm: 12,
    },
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  personType: {
    color: (t) => t.colors.alertColor,
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
    },
  },
  personName: {
    typography: {
      xs: '14_18_700',
      sm: '18_24_700',
    },
    maxHeight: 36,
    overflow: 'hidden',
  },
  gridContent: {
    overflowX: {
      xs: 'scroll',
      'scrollbar-width': 'none',
      '-ms-overflow-style': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    justifyContent: 'space-between',
  },
  titleContainer: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
    },
  },
  button: {
    fontWeight: 600,
  },
  contentBoxContainer: {
    display: 'flex',
    overflow: 'hidden',
    '& > :last-child': {
      marginTop: {xs: 0},
    },
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '10%',
  },
};

interface OverviewRelationsProps {
  relations: RelationElement[] | undefined
  isSidebarOpen?: boolean
  cardsCount: number
}

export const OverviewRelations = ({relations, isSidebarOpen, cardsCount}: OverviewRelationsProps) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();
  const {groupedRelations} = useContactRelations('');
  const {mobile: isMobile, tabletPortrait: isTabletPortrait, desktop: isDesktop, lg: isDesktopLg} = useMQuery();
  const isMoreThanTwoRelation = groupedRelations && groupedRelations?.length > 2;
  const breakpoints = {lg: 623};
  const defaultColumns = {lg: 6};
  const customColumns = {lg: 12};
  const {colsNumberLg, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  return (
    <Stack sx={sx.container} ref={containerRef}>
      <Box sx={sx.topContainer}>
        <Typography sx={sx.titleContainer}>{t('RELATIONS')}</Typography>
        {relations && relations.length > 0 && (
          <Button
            sx={sx.button}
            variant={isDesktop ? 'outlined' : 'text'}
            color='primary'
            size={isMobile || isTabletPortrait ? 'xs' : 'sm'}
            onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_RELATIONS)}
          >{t('VIEW_ALL')}</Button>
        )}
      </Box>
      <Grid container sx={sx.gridContent}>
        <Grid
          container
          direction='row'
          wrap='nowrap'
          width='100%'
          gap={12}
        >
          {groupedRelations?.slice(0, cardsCount).map((person = initialContact) => (
            <Grid
              direction='row'
              wrap='nowrap'
              width='100%'
              xs={12}
              sm={6}
              lg={colsNumberLg}
              xl={6}
              key={person?.id}
              sx={{
                display: 'grid',
                minWidth: {
                  xs: 282,
                  md: 384,
                  lg: isDesktopLg && isSidebarOpen && !isMoreThanTwoRelation ? 270 : 260,
                  xl: 285,
                  xxl: 342,
                },
                maxWidth: {
                  xs: 282,
                  sm: '100%',
                  md: isMoreThanTwoRelation ? '100%' : 'calc(50% - 12px)',
                  xl: isMoreThanTwoRelation ? '100%' : 360,
                },
                width: {
                  lg: (relations?.length === 1 && isDesktopLg && isSidebarOpen && window.innerWidth > 1419) ||
                    (relations?.length === 1 && isDesktopLg && !isSidebarOpen)
                    ? 'calc(50% - 18px)'
                    : 'inherit',
                  xl: 'inherit',
                },
              }}
            >
              <Stack sx={sx.content} onClick={() => person?.contact?.id && navigate(createRelationPath(isMobile, person.contact.id))}>
                <Box sx={sx.contentBoxContainer}>
                  <Avatar sx={sx.avatar} src={person?.avatarUrl} alt={''}>
                    <Typography sx={sx.avatarText}>
                      {getUserInitials(person?.contact)}
                    </Typography>
                  </Avatar>
                  <Box sx={sx.boxContainer}>
                    <EllipsisTextTooltip ellipsisText={person?.contact.firstName} sx={sx.personName} />
                    <EllipsisTextTooltip ellipsisText={person?.contact.lastName} sx={sx.personName} />
                    <EllipsisTextTooltip sx={sx.personBirthDay} ellipsisText={person?.contact?.birthDate ?? ''} variant={isMobile ? '12_18_500' : '14_20_500'} />
                    <EllipsisTextTooltip sx={sx.personType} ellipsisText={person?.types ? person.types.map((type: TKeys<'overview'>) => t(type)).join(', ') : ''} />
                  </Box>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Stack>
  );
};
