/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {Stack, Typography, Box, ListItemText} from '@mui/material';
import {RelationProfile} from '@src/api/relations';
import {Breadcrumbs, Link, Text} from '@src/components';
import Grid from '@src/components/Grid';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_ZIP_CODE_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {validateValue} from '@src/shared/utils/validateValue';
import {useAddressForm, useContactRelations, useRelation} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Nullable} from '@src/types/NullableModel';
import {Formik} from 'formik';
import {isEqual} from 'lodash-es';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button, InputControl, Option, SelectControl} from 'ui-kit';

import {makeMapOfAddressAccess} from '../../../helpers/functionHelpers';
import {breadCrumbsLink} from '../../helpers';
import {statesSelectOptions} from '../constants';
import {accessCreateEntities} from '../matrix';

import {StyledForm, sx} from './styles';
import {SaveDataContacts} from './types';
import {validationSchema} from './validationSchema';

export const RelationContactsEdit = () => {
  const {t, ready} = useTranslate('connections');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {edit} = useAddressForm();
  const {mobile} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const goToContacts = () => {
    id && navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_CONTACTS, {id}));
  };

  const accessCreate = makeMapOfAddressAccess({relation, accessCreateEntities});

  const saveData = ({
    state,
    city,
    postalCode,
    address1,
    address2,
  }: Nullable<SaveDataContacts>) => {
    const data = {
      state: state || '',
      city: city || '',
      postalCode: postalCode || '',
      address1: address1 || '',
      address2: address2 || '',
    };

    edit({relationId, data});
    if (relation) {
      const newRelation: RelationProfile = {
        ...relation,
        actualAddresses: {
          ...relation.actualAddresses,
          state,
          city,
          postalCode,
          main: address1,
          additional: address2,
        },
      };
      dispatch(relationsActions.setRelation({relation: newRelation}));
      goToContacts();
    }
  };

  const initialValue = {
    state: relation?.actualAddresses?.state || null,
    city: relation?.actualAddresses?.city || null,
    postalCode: relation?.actualAddresses?.postalCode || null,
    address1: relation?.actualAddresses?.main || null,
    address2: relation?.actualAddresses?.additional || null,
  };

  if (!ready) return null;

  return (
    <>
      {mobile && (
        <Breadcrumbs>
          <Link to={breadCrumbsLink(id)}>
            {getFullName(relation?.contact)}
          </Link>
          <Text>{t('CONTACTS')}</Text>
        </Breadcrumbs>
      )}
      <Stack sx={sx.container}>
        <Formik<typeof initialValue>
          enableReinitialize
          onSubmit={(values) => {
            if (!isEqual(initialValue, values)) {
              const normalizedValues = {
                ...values,
                city: normalizeString(values.city),
                address1: normalizeString(values.address1),
                address2: normalizeString(values.address2),
              };
              saveData(normalizedValues);
            }
          }}
          initialValues={initialValue}
          validationSchema={validationSchema(t)}
        >
          {({handleChange, values, errors, touched}) => (
            <StyledForm>
              <Stack sx={sx.content}>
                <Typography component="h4" sx={sx.title}>
                  {t('ADDRESS')}
                </Typography>
                <Grid container columnSpacing={48} rowSpacing={24}>
                  <Grid
                    xl={4}
                    lg={6}
                    md={4}
                    sm={6}
                    xs={12}>
                    <SelectControl
                      disabled={!accessCreate?.includes('contactAddress')}
                      placeholder={t('SELECT')}
                      name='state'
                      value={t(values.state) || ''}
                      error={touched?.state ? errors.state : ''}
                      onChange={handleChange}
                      label={t('STATE')}
                      renderValue={(value: any) => t(value as TKeys<'common'>)}
                    >
                      {statesSelectOptions.map(option => (
                        <Option key={option.name} value={option.name}>
                          <ListItemText sx={{typography: '14_18_500'}}>{t(option.name)}</ListItemText>
                        </Option>
                      ))}
                    </SelectControl>
                  </Grid>
                  <Grid
                    xl={4}
                    lg={6}
                    md={4}
                    sm={6}
                    xs={12}>
                    <InputControl
                      maxlength={MAX_CITY_LENGTH}
                      disabled={!accessCreate?.includes('contactAddress')}
                      name="city"
                      label={t('CITY')}
                      value={values.city || ''}
                      error={touched?.city ? errors.city : ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={6}
                    md={4}
                    sm={6}
                    xs={12}>
                    <InputControl
                      disabled={!accessCreate?.includes('contactAddress')}
                      name="postalCode"
                      label={t('ZIP_CODE')}
                      value={validateValue(values.postalCode || '')}
                      error={touched?.postalCode ? errors.postalCode : ''}
                      onChange={handleChange}
                      maxlength={MAX_ZIP_CODE_LENGTH}
                      simpleTextInput
                    />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={6}
                    md={4}
                    sm={6}
                    xs={12}>
                    <InputControl
                      maxlength={MAX_ADDRESS_LENGTH}
                      disabled={!accessCreate?.includes('contactAddress')}
                      name='address1'
                      label={t('ADDRESS_1')}
                      error={touched?.address1 ? errors.address1 : ''}
                      value={values.address1 || ''}
                      onChange={handleChange} />
                  </Grid>
                  <Grid
                    xl={4}
                    lg={6}
                    md={4}
                    sm={6}
                    xs={12}>
                    <InputControl
                      optional
                      maxlength={MAX_ADDRESS_LENGTH}
                      disabled={!accessCreate?.includes('contactAddress')}
                      name='address2'
                      label={t('ADDRESS_2')}
                      error={touched?.address2 ? errors.address2 : ''}
                      value={values.address2 || ''}
                      onChange={handleChange} />
                  </Grid>
                </Grid>
              </Stack>
              <Box sx={sx.buttonsContainer}>
                <Button
                  variant="outlined"
                  onClick={goToContacts}>
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(initialValue, values)}
                  type="submit"
                  variant="contained">
                  {t('SAVE')}
                </Button>
              </Box>
            </StyledForm>
          )}
        </Formik>
      </Stack>
    </>
  );
};
