import {Theme, styled, css as _, Interpolation} from '@mui/material';
import {PropsWithChildren} from 'react';

export const styles = {
  container: () => _`
    width: 100%;
    position: relative;
    cursor: pointer;
    `,

  iconContainer: _`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    pointer-events: none;
    `,
};

const inputStyles: Interpolation<{ theme?: Theme, error?: boolean }> = ({theme, error}) => _`
  width: 100%;
  height: 30px;
  border: none;
  background-color: transparent;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${error ? theme?.colors.all.error[200] ?? '' : theme?.colors.all.grey4 ?? ''};
  padding: 0 40px 1px 0;
  font-size: 14px;
  color: inherit;
  box-sizing: border-box;
  cursor: pointer;
  &::placeholder {
    color: ${theme?.palette.grey[500] ?? ''}
  }
  outline: none;
  &:focus {
    border-bottom-width: 2px;
    padding: 0;
  }
  &:read-only:focus {
    border-bottom-width: 2px;
    padding-bottom: 0;
  }
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset !important;
  }
`;

export const StyledInput = styled('input')(inputStyles);
StyledInput.defaultProps = {
  type: 'text',
};

export const Arrow = styled('div')`
    --size: 30px;
    visibility: hidden;
    position: absolute;
    background: inherit;
    width: calc(var(--size) / 2);
    height: calc(var(--size) / 2);
    &::before {
        position: absolute;
        width: var(--size);
        height: var(--size);
        background: inherit;
        top: -50%;
        left: -50%;
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }
    [data-popper-placement^=left] & {
        right: 0 !important;
    }
    [data-popper-placement^=top] & {
        bottom: 0 !important;
    }
`;
export const CalendarContainer = styled('div')<{twoColumns?: boolean}>(({theme, twoColumns}) => _`
    --width: 312px;
    color: white;
    background-color: ${theme.colors.all.grey2};
    width: 100%;
    max-width: ${twoColumns ? 'calc(var(--width) * 2)' : 'var(--width)'};
    min-height: 336px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .mobile-layout & {
        inset: initial !important;
        transform: none !important;
        top: 0 !important;
        margin: 0 auto !important;
        position: initial !important;
        max-width: var(--width);
    }
`);
interface CircleProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  currentDay: boolean
  selected: boolean
}
export const Circle = styled(({children, currentDay: _, ...restProps}: PropsWithChildren<CircleProps>) => {
  return (
    <button {...restProps}>
      {children}
    </button>
  );
})<{ currentDay: boolean, selected: boolean }>(({theme, currentDay, selected, disabled}) => _`
    position: absolute;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${selected ? theme.palette.secondary.main : currentDay ? theme.palette.grey[600] : 'transparent'};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;
    border: none;
    cursor: pointer;
    color: ${disabled ? (selected || currentDay) ? 'white' : theme.colors.all.grey4 : 'white'};
    &:disabled {
        &:hover {
            cursor: inherit
        }
        &:active {
            background-color: transparent;
            transition: none;
        }
    }
    &:active {
        background-color: ${theme.palette.secondary.main};
        transition: none;
    }
`);
Circle.defaultProps = {
  type: 'button',
};
export const DatesContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
`;
export const Cell = styled('div')`
    border: none;
    background-color: transparent;
    position: relative;
    width: calc(100% / 7);
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
        content: ' ';
        margin-top: 100%;
    }
`;
