/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from '@emotion/styled';
import {Stack, Box, SxProps, Theme, Typography, Avatar, Button} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {Dialog, DialogActions, DialogTitle} from '@src/components/Dialog';
import {Tabs, Tab, useTabs} from '@src/components/TabPanel';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {CalendarPicker} from '@src/pages/Overview/fragments/Calendar/CalendarPicker';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as BreakRelation} from '@src/shared/assets/icons/break.svg';
import {ReactComponent as Calendar} from '@src/shared/assets/icons/calendar.svg';
import {Arrow} from '@src/shared/assets/icons/components';
import {getFullName, getUserInitials} from '@src/shared/utils';
import {RootState} from '@src/store';
import {accountActions} from '@src/store/account/slice';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {BreakRelationModal} from '../RelationMain/fragments/BreakRelationModal';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentContainer: {
    mt: 36,
    flexDirection: 'initial',
  },
  rightSideFragmentsContainer: {
    gap: 24,
    minWidth: '352px',
  },
  avatar: (t) => ({
    width: 72,
    height: 72,
    marginRight: 16,
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  personName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    pr: 18,
    pb: 8,
  },
  personCard: (t) => ({
    display: 'flex',
    gap: 12,
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
  }),
  shortInfoContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  shortInfo: {
    pr: 36,
  },
  calendar: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    alignSelf: 'stretch',
    backgroundColor: 'white',
    width: '100%',
  }),
  events: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    alignSelf: 'stretch',
    backgroundColor: 'white',
    width: '100%',
  }),
  tab: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    padding: '6px 77px 12px 77px',
    '&:hover &': {
      color: 'red !important',
    },
  },
  middleFragment: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    gap: 36,
  }),
  content: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    backgroundColor: t.colors.all.white,
    padding: '18px 16px',
    marginBottom: 98,
  }),
  tabs: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
    padding: '6px 90px 12px 90px',
  },
  bottomButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  calendarIconWrapper: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary.main,
    padding: 9,
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
  }),
};

export const StyledTabs = styled(Tabs)(({theme}) => ({
  padding: 0,
  '.MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    '.Mui-selected': {
      color: 'unset !important',
    },
    '.MuiButtonBase-root': {
      padding: '12px 0',
      justifyContent: 'space-between',
      '&:hover': {
        color: `${theme.palette.grey[400]} !important`,
        borderBottomColor: `${theme.palette.grey[400]} !important`,
        '& svg g path': {
          fill: `${theme.palette.grey[400]} !important`,
        },
      },
    },
  },
  '.MuiTabs-indicator': {
    backgroundColor: 'unset',
  },
}));

export const MobileLayout = () => {
  const {t, ready} = useTranslate('connections');
  const step = useSelector((state: RootState) => state.relations.step);
  const {id} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFinalModalOpened = step === 'success' || step === 'error';
  const {index, handleChange: tabChange} = useTabs(1);
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const [isEventsModalOpen, setIsEventsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAccesses = relation?.accesses?.length;
  const address = relation?.actualAddresses;

  useEffect(() => {
    if (isFinalModalOpened) dispatch(accountActions.requestAccountProfileData());
  }, [dispatch, isFinalModalOpened, step]);

  const handleEventsModalCondition = () => {
    setIsEventsModalOpen((prev) => !prev);
  };

  useLayoutEffect(() => {
    window.scrollTo({top: 0});
    const gridContainer = document.querySelector('#main');
    gridContainer?.scrollTo({top: 0});
  }, []);

  if (!ready) return null;

  return (
    <>
      <Stack gap={24}>
        <Stack gap={24}>
          <WBox sx={sx.personCard}>
            <Breadcrumbs>
              <Link to={ROUTERS_PATH.CONNECTIONS_RELATIONS}>{t('RELATIONS')}</Link>
              <Text>{getFullName(relation?.contact)}</Text>
            </Breadcrumbs>
            <Box sx={sx.shortInfoContainer}>
              <Avatar sx={sx.avatar} alt={''} src={relation?.avatarUrl}>
                <Typography variant="24_28_600">{getUserInitials(relation?.contact)}</Typography>
              </Avatar>
              <Stack sx={{overflow: 'hidden'}}>
                <Typography component='h3' variant="18_24_700" sx={sx.personName}>
                  {getFullName(relation?.contact)}
                </Typography>
                <Box>
                  {
                    !isAccesses
                      ? (
                        <Stack>
                          <Typography variant="14_20_500" sx={sx.shortInfo}>
                            {t('GENDER')}: {relation?.contact?.gender ? t(relation?.contact?.gender) : '\u2015'}
                          </Typography>
                          <Typography variant="14_20_500" sx={sx.shortInfo}>
                            {t('DOB')}: {relation?.contact?.birthDateS && '\u2015'}
                          </Typography>
                          <Typography variant="14_20_500" sx={sx.shortInfo}>
                            {t('ADDRESS')}: {address?.main || '\u2015'}
                          </Typography>
                        </Stack>
                      )
                      : (
                        <Typography variant="14_20_500" sx={sx.shortInfo}>
                          {t('DOB')}: {relation?.contact?.birthDateS}
                        </Typography>
                      )
                  }
                </Box>
              </Stack>
            </Box>
          </WBox>
          <WBox sx={sx.middleFragment}>
            <Stack gap={24} justifyContent='flex-start'>
              <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant='22_26_500'>{t('APPOINTMENTS_AND_EVENTS')}</Typography>
                <Box sx={sx.calendarIconWrapper}>
                  <Calendar onClick={() => setIsEventsModalOpen(true)} />
                </Box>
              </Box>
              <Button variant='outlined' disabled sx={{width: '70%', padding: '9px 24px', maxWidth: 200, whiteSpace: 'nowrap'}}>{t('MAKE_AN_APPOINTMENT')}</Button>
            </Stack>
            <Typography variant='12_18_500'>{`${relation?.contact?.firstName}${t('DOESNT_HAVE_ANY_EVENTS_YET')}`}</Typography>
          </WBox>
        </Stack>
        <Stack sx={sx.content}>
          <StyledTabs
            value={index}
            onChange={tabChange}
            scrollButtons="auto"
            variant="fullWidth">
            <Tab
              value={1}
              label={t('ABOUT')}
              icon={<Arrow.Go.X24 fillRule='nonzero' />}
              iconPosition='end'
              disableRipple
              disableFocusRipple
              disableTouchRipple
              isActive={false}
              sx={sx.tab}
              onClick={() => navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id}/about`)}
            />
            <Tab
              value={2}
              label={t('CONTACTS')}
              icon={<Arrow.Go.X24 />}
              iconPosition='end'
              disableRipple
              isActive={false}
              sx={sx.tab}
              onClick={() => navigate('contacts')} />
            <Tab
              value={3}
              label={t('HEALTH_POLICIES')}
              icon={<Arrow.Go.X24 />}
              iconPosition='end'
              disableRipple
              isActive={false}
              sx={sx.tab}
              onClick={() => navigate('health-policies')} />
            <Tab
              value={4}
              label={t('RELATIONS')}
              icon={<Arrow.Go.X24 />}
              iconPosition='end'
              disableRipple
              isActive={false}
              sx={sx.tab}
              onClick={() => navigate('user-relations')} />
            <Tab
              value={5}
              label={t('RESULTS')}
              icon={<Arrow.Go.X24 />}
              iconPosition='end'
              disableRipple
              disableFocusRipple
              disableTouchRipple
              isActive={false}
              sx={sx.tab}
              onClick={() => navigate('results')} />
          </StyledTabs>
        </Stack>
        <WBox sx={{position: 'fixed', bottom: 0, right: 0, padding: '16px !important'}}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<BreakRelation />}
            onClick={() => setIsModalOpen((prev) => !prev)}
            disableRipple>{t('BREAK_RELATION')}</Button>
        </WBox>
        <Dialog open={isEventsModalOpen} size={'md'} onClose={handleEventsModalCondition}>
          <DialogTitle onClose={handleEventsModalCondition}>
            {t('SELECT_DATE')}
          </DialogTitle>
          <CalendarPicker
            style={{minWidth: '100%'}}
            attrs={undefined}
            onClose={() => { }}
          />
          <DialogActions sx={{flexDirection: 'initial !important'}}>
            <Button
              variant='outlined'
              color='secondary'
              sx={{width: '40% !important'}}
              disableRipple
              onClick={() => setIsEventsModalOpen(false)}>
              {t('CANCEL')}
            </Button>
            <Button
              variant='contained'
              color='secondary'
              sx={{width: '40% !important'}}
              disableRipple
              disabled>
              {t('APPLY')}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
      <BreakRelationModal open={isModalOpen} setIsModalOpen={setIsModalOpen} contactRelationId={id}/>
    </>
  );
};
