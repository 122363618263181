import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {HealthcareInsurancePolicy} from '@src/api';
import {HealthcareInsurancePolicyChangeRequest, InsuranceCarriersList, InsurancePlansList} from '@src/api/insurances';
import {PartialRecord, ValidationErrorType} from '@src/types';

type Fetching = 'common' | 'account' | 'dashboard' | 'profile';
export type RequestNPIOrgFlowSteps =
  | 'init'
  | 'open'
  | 'loading'
  | 'running'
  | 'success'
  | 'error';
export interface FavoritesState {
  healthcareInsurancePolicies: HealthcareInsurancePolicy[] | null
  insuranceCarriers: InsuranceCarriersList[] | null
  insurancePlans: InsurancePlansList[] | null
  step: RequestNPIOrgFlowSteps
  fetching: PartialRecord<Fetching, boolean> | null
  error?: ValidationErrorType | null
}
const initialState: FavoritesState = {
  healthcareInsurancePolicies: null,
  insuranceCarriers: null,
  insurancePlans: null,
  step: 'init',
  fetching: null,
};

export const insurance = createSlice({
  name: 'insurance',
  initialState,
  reducers: {
    requestInsurances () {},
    requestInsuranceCarriers (_state, _action: PayloadAction<{filter: string}>) {},
    requestInsurancePlans (_state, _action: PayloadAction<{insuranceCarrierId: string}>) {},
    createInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    editInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    deleteInsurance (_state, _payload: PayloadAction<{data: HealthcareInsurancePolicyChangeRequest}>) {},
    setInsurances (state, {payload}: PayloadAction<{healthcareInsurancePolicies: HealthcareInsurancePolicy[]}>) {
      state.healthcareInsurancePolicies = payload.healthcareInsurancePolicies;
    },
    setInsurancePlans (state, {payload}: PayloadAction<{insurancePlans: InsurancePlansList[]}>) {
      state.insurancePlans = payload.insurancePlans;
    },
    setInsuranceCarriers (state, {payload}: PayloadAction<{insuranceCarriers: InsuranceCarriersList[]}>) {
      state.insuranceCarriers = payload.insuranceCarriers;
    },
    setStep (
      state,
      {payload}: PayloadAction<{ step: RequestNPIOrgFlowSteps }>,
    ) {
      state.step = payload.step;
    },
    setFetching (state, {payload}: PayloadAction<FavoritesState['fetching']>) {
      if (!payload) {
        state.fetching = null;
      }
      state.fetching = {...state.fetching, ...payload};
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const insuranceReducer = insurance.reducer;
export const insuranceActions = insurance.actions;
export const insuranceStoreName = insurance.name;
