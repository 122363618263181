import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogContentWrapper: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    paddingBottom: 0,
  },
  dialogContentInfo: (t) => ({
    flexDirection: 'row',
    backgroundColor: alpha(t.palette.info.main, 10),
    maxWidth: 1008,
    padding: 9,
    gap: 6,
    alignItems: 'center',
  }),
  dialogContent: {
    gap: 36,
  },
  dialogActionsWrapper: {
    justifyContent: 'space-between !important',
    flexDirection: 'row !important',
  },
  infoBlockContainer: (t) => ({
    flexDirection: 'row',
    backgroundColor: alpha(t.palette.info.main, 10),
    maxWidth: 1008,
    padding: 12,
    gap: 12,
  }),
  informationIconWrapper: (t) => ({
    display: 'flex',
    alignItems: 'center',
    '& svg path': {
      fill: t.colors.all.info,
    },
  }),
  radioBths: (t) => ({
    [t.breakpoints.only('xs')]: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      height: 20,
    },
  }),
  dialogTitleContainer: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary._14,
  }),
  dateControl: (t) => ({
    ' .MuiInputBase-input': {
      color: t.palette.grey[700],
    },
  }),
  errorText: {
    mt: 3,
    ml: {xs: 0, sm: 96},
  },
};
