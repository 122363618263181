/* eslint-disable @typescript-eslint/no-explicit-any */
import {Divider, IconButton, Stack, styled, Typography, useTheme} from '@mui/material';
import {BoxWrapper, RowInformation} from '@src/components';
import {Icons} from '@src/shared/assets/icons';
import {DynamicResultWithAnalysesData} from '@src/store/results/helpers';
import {format} from 'date-fns';
import React, {FC, useRef} from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip, TooltipProps,
} from 'recharts';

interface RechartsProps {
  data: DynamicResultWithAnalysesData[]
  domain: { min: number, max: number }
}

const CustomWrapper = styled(BoxWrapper)(() => ({
  border: '1px solid #DEE9ED',
  filter: 'drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.15))',
  padding: '15px 24px',
}));

interface TtProps extends TooltipProps<string, string> {
  closeTooltip: (e: React.MouseEvent) => void
}
const CustomTooltip = (props: TtProps) => {
  const {active, payload, closeTooltip} = props;
  if (active && payload && payload.length > 0) {
    const data: DynamicResultWithAnalysesData = payload[0].payload;
    const {value, unit, refText, biomaterialSamplingDate} = data;
    return (
      <CustomWrapper>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" fontWeight="700" marginRight="8px">
            {format(new Date(biomaterialSamplingDate), 'd MMMM yyyy, hh:mm')}
          </Typography>
          <IconButton onClick={closeTooltip}>
            <Icons.Cross.X24 />
          </IconButton>
        </Stack>
        <Stack>
          <RowInformation value={value} name="Result" />
          <Divider />
          <RowInformation value={unit} name="Units" />
          <Divider />
          <RowInformation
            value={refText || '--'}
            name="Reference Range"
          />
          <Divider />
        </Stack>
      </CustomWrapper>
    );
  }

  return null;
};

const CustomizedDot: FC<any> = (props) => {
  const {cx, cy, value, payload, active} = props;

  if (value == null) return null;
  const referenceColor = payload?.isRefMark ? '#FAB41C' : undefined;
  const criticalColor = payload?.isCriticalRefMark ? '#EA3D5C' : undefined;
  const colorCircle = criticalColor || referenceColor || '#88999E';

  return (
    <svg
      x={cx - 12}
      y={cy - 20}
      width={40}
      height={40}
      fill={colorCircle}
      viewBox="0 0 1024 1024">
      <circle
        cx="300"
        cy="500"
        r="150"
        fill={active ? 'rgba(44, 150, 181,0.5)' : '#fff'} />
      <circle cx="300" cy="500" r="90" />
    </svg>
  );
};

export const Recharts: React.FC<RechartsProps> = ({data, domain}) => {
  const ref = useRef<any>();
  const theme = useTheme();
  const dataWithoutEmpty = data.filter((test) => test.value);
  const closeTooltip = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (ref.current) {
      ref.current.setState({
        isTooltipActive: false,
      });
    }
  };

  const criticalAreaColor = '#F4E3E6';
  const safeAreaColor = '#DFFFE4';
  const outZoneColor = '#FFD7D7';

  const tenPc = Math.ceil((domain.max - domain.min) / 100 * 10);
  return (
    <ResponsiveContainer width="100%" height={290}>
      <ComposedChart
        barCategoryGap="-1"
        data={dataWithoutEmpty}
        margin={{
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
        ref={ref}
      >
        <Bar
          dataKey="chart.zones.offset"
          stackId="a"
          fill={'none'}
          isAnimationActive={false} />
        <Bar
          dataKey="chart.zones.lowerOverCritical"
          stackId="a"
          fill={outZoneColor}
          isAnimationActive={false} />
        <Bar
          dataKey="chart.zones.lowerCritical"
          stackId="a"
          fill={criticalAreaColor}
          isAnimationActive={false} />
        <Bar
          dataKey="chart.zones.normal"
          stackId="a"
          fill={safeAreaColor}
          isAnimationActive={false} />
        <Bar
          dataKey="chart.zones.upperCritical"
          stackId="a"
          fill={criticalAreaColor}
          isAnimationActive={false} />
        <Bar
          dataKey="chart.zones.upperOverCritical"
          stackId="a"
          fill={outZoneColor}
          isAnimationActive={false} />
        <XAxis
          dataKey="shortDate"
          stroke={theme.palette.grey[400]}
          fontSize='14px'
          tickMargin={8}
          axisLine={false}
          tickSize={0} />
        <YAxis
          width={100}
          stroke={theme.palette.grey[400]}
          fontSize='14px'
          tickMargin={8}
          axisLine={false}
          tickSize={0}
          domain={[domain.min - tenPc, domain.max + tenPc]}
          allowDataOverflow={true} />
        <CartesianGrid />
        <Line
          isAnimationActive={false}
          dataKey="dot"
          stroke="#2C96B5"
          dot={<CustomizedDot />}
          fill="none"
          strokeWidth={2}
          connectNulls
          activeDot={<CustomizedDot active />}
        />
        <Tooltip
          isAnimationActive={false}
          content={<CustomTooltip closeTooltip={closeTooltip} />}
          trigger="hover"
          wrapperStyle={{pointerEvents: 'initial', width: '85%'}}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
