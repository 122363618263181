import {Theme} from '@emotion/react';
import styled, {Interpolation} from '@emotion/styled';
import {Box, Stack, css} from '@mui/material';
import {shouldNotForwardProps} from '@src/shared/utils/shouldNotForwardProps';
import ReactPhoneNumberInput from 'react-phone-number-input';
import {initialColors} from 'theme';

import {CONTROL_CONTAINER} from './InputControl';
import {PhoneInput} from './PhoneInput';
import {IBodyProps} from './types';

const inputStyles: Interpolation<{theme: Theme, error?: boolean}> = ({theme}) => `
  width: 100%;
  width: calc(100% - 20px);
  height: 30px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0 26px 1px 0;
  font-size: 14px;
  color: inherit;
  box-sizing: border-box;
  &::placeholder {
    color: ${theme.palette.grey[500]}
  }
  outline: none;
  &:focus {
    border-bottom-width: 2px;
    padding: 0;
  }
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }
`;
export const StyledPhoneInput = styled(PhoneInput)((props) => `input {${inputStyles(props) as string}}`);
export const StyledInput = styled.input(inputStyles as any);
export const StyledContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;
export const StyledBody = styled.div`
  position: relative;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`;
export const StyledIconContainer = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledPasswordEyeButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  &:hover {
    opacity: .7;
  }
`;
export const StyledInputTip = styled(Box)<{color?: string}>`
  line-height: 20px;
  height: 24px;
  position: absolute;
  .mobile-layout & {
    height: 24px;
  }
  .tablet-layout & {
    height: 24px;
  }
  color: ${({color}) => color ?? initialColors.grey4};
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledReactPhoneNumberInput = styled(ReactPhoneNumberInput)`
.PhoneInputCountry {
  display: none;
}
`;
export const Label = styled.label`
  font-size: 14px;
  font-weight: 700;
  margin: auto;
  width: auto!important;
`;

export const IconContainer = styled(Stack)(
  ({theme: _t}) => css`
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
`,
);

export const InputBody = styled(
  Box,
  shouldNotForwardProps(['noHover', 'border', 'variant']),
)<IBodyProps>(
  ({theme: t, border, variant, noHover, error}) => css`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  border-width: ${error ? '2px' : '1px'};
  border-style: solid;
  border-color: transparent;
  ${variant === 'contained' ? 'padding: 0 12px;' : ''}
  ${variant === 'contained' ? 'border-color' : 'border-bottom-color'}: ${
  border?.(t) ?? t.palette.grey[400]
};
  .${CONTROL_CONTAINER}:hover & {
    border-color: ${!error && !noHover && t.palette.grey[500]};
  }
  &:has(.Mui-focused), &>div:has(.PhoneInput--focus), &.focused {
    border-color: ${!error && t.palette.grey[500]} !important;
    border-width: ${!error && '2px'};
  }
  input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-box-shadow: none!important;
  }
`,
);

export const ControlContainer = styled(Box)<{ disabled?: boolean }>(
  ({theme: t, disabled}) => css`
    width: 100%;
    user-select: none;
    position: relative;
    ${
  disabled
    ? `pointer-events: none;
          * {
            color: ${t.palette.grey[400]};
          }
          input::placeholder {
            color: ${t.palette.grey[400]}!important;
          }`
    : ''
}
  `,
);
