import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialog: {
  },
  avatar: (t) => ({
    width: 42,
    height: 42,
    backgroundColor: t.palette.secondary._35,
    color: t.palette.common.white,
  }),
  bthCancel: {
    minWidth: 102,
    p: {
      xs: 0,
    },
  },
  bthCreatePatient: {
    minWidth: 168,
  },
  boxContainer: (t) => ({
    padding: 24,
    display: 'flex',
    gap: 12,
    [t.breakpoints.only('xs')]: {
      p: '24px 18px',
    },
  }),
  attentionInfoContainer: (t) => ({
    p: {xs: 9, sm: 12},
    display: 'flex',
    gap: {xs: 6, sm: 12},
    width: '100%',
    alignItems: {xs: 'flex-start', sm: 'center'},
    backgroundColor: alpha(t.colors.all.attention, 10),
    svg: {
      path: {
        fill: t.colors.all.attention,
      },
    },
    [t.breakpoints.only('xs')]: {
      '& > svg': {
        position: 'relative',
        top: -2,
      },
      svg: {
        height: 20,
      },
    },
  }),
  infoWrapper: (t) => ({
    gap: {xs: 12, sm: 24},
    overflow: 'auto',
    [t.breakpoints.up('sm')]: {
      mr: 24,
    },
    [t.breakpoints.between('sm', 'lg')]: {
      gap: 24,
      '&::-webkit-scrollbar': {
        display: 'block',
      },
      scrollbarWidth: 'auto',
      '-ms-overflow-style': 'auto',
    },
  }),
  dialogContent: {
    overflow: 'hidden',
    textAlign: 'initial !important',
    padding: '0 !important',
  },
  infoContainer: {
    p: {xs: '18px 18px 12px 18px', sm: '24px 24px 12px 24px'},
    flexDirection: 'row',
    gap: 12,
  },
  boxInfoContainer: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    width: {xs: 307, sm: 362},
    height: {xs: 242, sm: 262},
  }),
  dialogActions: (t) => ({
    justifyContent: {xs: 'center !important', sm: 'space-between !important'},
    margin: 0,
    padding: {xs: 18, sm: 24},
    flexDirection: {xs: 'column-reverse !important', sm: 'row !important'},
    borderTop: '1px solid',
    borderTopColor: t.palette.secondary._21 || '',
  }),
  dialogActionsFailure: (t) => ({
    justifyContent: 'end !important',
    margin: 0,
    padding: 24,
    borderTop: '1px solid',
    borderTopColor: t.palette.secondary._21 || '',
    [t.breakpoints.only('xs')]: {
      flexDirection: 'row !important',
      p: 18,
    },
  }),
  flexContainer: {
    p: {xs: '24px 18px 18px 18px', sm: 24},
    width: '100%',
  },
};
