/* eslint-disable @typescript-eslint/dot-notation */
import {Stack, Typography, debounce, Box, ListItemText} from '@mui/material';
import {Gender, Race, RelationType} from '@src/api';
import {RelationshipType, RelationErrorType, RequestRelationType} from '@src/api/relations';
import {Breadcrumbs, Link, RowInformation, Text} from '@src/components';
import {GoogleAutocomplete} from '@src/components/Google/Autocomplete/GoogleAutocomplete';
import Grid from '@src/components/Grid';
import {ListWrapper} from '@src/components/ListWrapper';
import {RadioButtons} from '@src/components/RadioButtons';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH} from '@src/pages/Connections/constants';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_INPUT_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {useSidebarClassObserver, useTranslationMap, useColumnsNumber} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useRecaptcha} from '@src/shared/hooks/useRecaptcha';
import {
  NO_VALUE,
  dateFormatted,
  dateToFormat,
  getFullName,
  getPrimaryInsurance,
} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {validateValue} from '@src/shared/utils/validateValue';
import {RootState} from '@src/store';
import {useAccountProfile} from '@src/store/account/hooks';
import {useInsurances} from '@src/store/insurance/hooks';
import {useCreateRelationContact} from '@src/store/relations/hooks';
import {Formik} from 'formik';
import {isEqual} from 'lodash-es';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {PhoneInputControl, Button, InputControl, Option, SelectControl} from 'ui-kit';

import {SendRequestSuccessModal} from '../SendRequestSuccessModal';
import {birthSexOptions, ethnicityOptions, genderOptions, raceOptions, sexualOrientationOptions} from '../fragments/RelationEditLayouts/constants';
import {SearchSelect} from '../helpers/SearchSelect';
import {checkIfPearsonYounger18} from '../helpers/checkIfPearsonYounger18';
import {EAddressComponentsTypes, getAddressComponentByType} from '../helpers/functionHelpers';

import {birthSexSelectOptions, relationshipTypesGuarantorSelectOptions, relationshipTypesSelectOptions, statesSelectOptions, touchedData} from './constants';
import {ErrorModal} from './fragments/ErrorModal';
import {FailureModal} from './fragments/FailureModal';
import {ParentRoleModal} from './fragments/ParentRoleModal';
import {SuccessModal} from './fragments/SuccessModal';
import {addressOnClear, getInitialValues, handleChangeRadioBth} from './helpers';
import {useInsuranceState, useValidationSchema} from './hooks';
import {StyledForm, sx} from './styles';
import {EWhichPrimaryInsuranceUse} from './types';

export const CreateNewPatient = () => {
  useRecaptcha();

  const {t, ready} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const [relationType, setRelationType] = useState<RequestRelationType | null>(null);
  const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
  const [availableOptions, setAvailableOptions] = useState(relationshipTypesGuarantorSelectOptions);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const {accountProfile} = useAccountProfile();

  const navigate = useNavigate();
  const location = useLocation();

  const {healthcareInsurancePolicies} = useInsurances();

  const validationSchema = useValidationSchema();

  const {mapValue} = useTranslationMap();

  const {createRelationContact, successCreatedContact, failureCreatedContact} = useCreateRelationContact();

  const {isSidebarOpen} = useSidebarClassObserver();

  const {selectedCarrier, setSelectedCarrier, insurancePlans, searchPrimaryText, setSearchPrimaryText} = useInsuranceState();

  const insurance = getPrimaryInsurance(healthcareInsurancePolicies);

  const insuranceCarriers = useSelector((state: RootState) => state.insurance.insuranceCarriers);
  const birthOfDateError = useSelector((state: RootState) => state.relations.birthOfDateError);
  const step = useSelector((state: RootState) => state.relations.step);

  const tabletGapForInsuranceBlock = 36;
  const isMobileGapForInsuranceBlock = isMobile ? 48 : 24;
  const gapForInsuranceBlockWith = isMobile ? 36 : tabletGapForInsuranceBlock;
  const gapForInsuranceBlockWithout = isMobile ? 24 : isMobileGapForInsuranceBlock;

  const accountProfileValidInsurance = accountProfile?.healthcareInsurancePolicies?.find(
    (insurance) =>
      (compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null) &&
      insurance.primary,
  );

  const handlePrimaryTextChange = (value: string) => setSearchPrimaryText(value);

  const debouncedPrimaryChangeHandler = useCallback(debounce(handlePrimaryTextChange, 300), []);

  const memoInsuranceCarriersOptions = useMemo(() => {
    return insuranceCarriers?.map((option) => option.name);
  }, [insuranceCarriers]);

  const setCarrier = (
    value: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  ) => {
    setSelectedCarrier(value);
    setFieldValue('insuranceCarrier', value);
    if (setFieldValue && value !== selectedCarrier) setFieldValue('insurancePlan', null);
  };

  const relationshipTypeError =
    birthOfDateError === RelationErrorType.LESS_EIGHTEEN_ERROR ||
    birthOfDateError === RelationErrorType.PARENT_AGE_ERROR ||
    birthOfDateError === RelationErrorType.BIRTH_DATE_ERROR ||
    birthOfDateError === RelationErrorType.GREATER_EIGHTEEN_ERROR;

  const birthDateError = relationshipTypeError ? t('INVALID_DATE_OF_BIRTH') : '';

  const initRelationshipType = () => {
    const queryParams = new URLSearchParams(location.search);
    const relationshipTypeFromQuery = queryParams.get('relationshipType');
    return relationshipTypeFromQuery as RequestRelationType | null;
  };

  const initialValue = getInitialValues({insurance, accountProfileValidInsurance, initRelationshipType: initRelationshipType()});

  // TODO [tech-debt]: Code bug (figure out why changes are not applied when moving constants to the constants.ts file)
  const breakpoints = {xxl: 2123, xl: 1476, lg: 1002, sm: 592, md: 434};
  const defaultColumns = {xxl: 4, xl: 4, lg: 6, sm: 6};
  const customColumns = {xxl: 4, xl: 6, lg: 12, sm: 12};

  const {colsNumberSm, containerRef} = useColumnsNumber({breakpoints, defaultColumns, customColumns});

  const handleChangeRelationType = (
    e: { target: { value: string } },
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
    handleSetGuarantorFields: (event: { target: { value: string }}) => void,
  ) => {
    setFieldValue('guarantorRelationshipType', e.target.value, true);
    handleSetGuarantorFields(e);
  };

  const handleClickBreadcrumbs = () => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION);

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    if (step === 'error') {
      setIsErrorModalOpen(true);
    }
  }, [step]);

  useEffect(() => {
    const isYoungerThan18 = checkIfPearsonYounger18(dateOfBirth);
    if (isYoungerThan18 === null || isYoungerThan18) {
      setAvailableOptions(relationshipTypesGuarantorSelectOptions);
    } else {
      setAvailableOptions(relationshipTypesGuarantorSelectOptions.filter(option => option.name !== RelationshipType.Self));
    }
  }, [dateOfBirth]);

  if (!ready) return null;

  return (
    <>
      <Formik<typeof initialValue>
        validateOnChange
        validateOnBlur
        enableReinitialize
        onSubmit={(values) => {
          if (!isEqual(initialValue, values)) {
            const normalizedValues = {
              ...values,
              firstName: normalizeString(values.firstName),
              lastName: normalizeString(values.lastName),
              middleName: normalizeString(values.middleName),
              city: normalizeString(values.city),
              address1: normalizeString(values.address1),
              address2: normalizeString(values.address2),
              guarantorAddress1: normalizeString(values.guarantorAddress1),
              guarantorAddress2: normalizeString(values.guarantorAddress2),
              guarantorCity: normalizeString(values.guarantorCity),
              guarantorFirstName: normalizeString(values.guarantorFirstName),
              guarantorLastName: normalizeString(values.guarantorLastName),
              guarantorMiddleName: normalizeString(values.guarantorMiddleName),
              guarantorRelationshipType: values.guarantorRelationshipType !== RelationType.SELF ? values.guarantorRelationshipType : null,
            };

            createRelationContact({
              ...normalizedValues,
              insurancePlanId:
                insurancePlans?.find((plan) => plan.name === values.insurancePlan)?.id || null,
            });
          }
        }}
        initialValues={initialValue}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          handleBlur,
          setFieldValue,
          setValues,
          resetForm,
          setTouched,
          setFieldTouched,
          handleSubmit,
        }) => {
          const addressChangeHandler: ((placeResult: google.maps.places.PlaceResult) => void) = (placeResult) => {
            const addressComponents = placeResult.address_components || [];

            const streetNumber = getAddressComponentByType([EAddressComponentsTypes.STREET_NUMBER], addressComponents);
            const locality = getAddressComponentByType([EAddressComponentsTypes.CITY], addressComponents);
            const sublocality = getAddressComponentByType([EAddressComponentsTypes.SUBLOCALITY], addressComponents);
            const neighborhood = getAddressComponentByType([EAddressComponentsTypes.NEIGHBORHOOD], addressComponents);
            const state = getAddressComponentByType([EAddressComponentsTypes.STATE], addressComponents, true) || '';

            let currentCity = locality || sublocality || neighborhood;

            const administrativeAreas = ['administrative_area_level_1', 'administrative_area_level_2'];
            const hasAdministrativeArea = addressComponents.some((component) =>
              administrativeAreas.some((type) => component.types.includes(type)),
            );

            if (!locality && hasAdministrativeArea) {
              currentCity = state;
            } else if (!currentCity) {
              currentCity = state;
            }
            setValues({
              ...values,
              address1: placeResult.formatted_address || placeResult.name || '',
              state: getAddressComponentByType([EAddressComponentsTypes.STATE], placeResult.address_components, true) || '',
              postalCode: getAddressComponentByType([EAddressComponentsTypes.POSTAL_CODE], placeResult.address_components),
              city: currentCity,
              location: {
                googlePlaceId: placeResult.place_id || '',
                latitude: placeResult.geometry?.location?.lat() || 0,
                longitude: placeResult.geometry?.location?.lng() || 0,
              },
              streetNumber: streetNumber as unknown as null,
            });
          };

          const handleSetGuarantorFields = (event: { target: { value: string }}) => {
            const selectedValue = event.target.value;

            if (selectedValue === RelationshipType.Self) {
              setFieldValue('guarantorFirstName', values.firstName || '', true);
              setFieldValue('guarantorLastName', values.lastName || '', true);
              setFieldValue('guarantorMiddleName', values.middleName || '', true);
              setFieldValue('guarantorBirthDate', values.birthDate || '', true);
              setFieldValue('guarantorBirthSex', values.birthSex || '', true);
              setFieldValue('guarantorState', values.state || '', true);
              setFieldValue('guarantorCity', values.city || '', true);
              setFieldValue('guarantorAddress1', values.address1 || '', true);
              setFieldValue('guarantorAddress2', values.address2 || '', true);
              setFieldValue('guarantorPostalCode', values.postalCode || '', true);
              setFieldValue('guarantorPhone', values.phone || '', true);
            } else {
              setFieldValue('guarantorFirstName', '', true);
              setFieldValue('guarantorLastName', '', true);
              setFieldValue('guarantorMiddleName', '', true);
              setFieldValue('guarantorBirthDate', '', true);
              setFieldValue('guarantorBirthSex', '', true);
              setFieldValue('guarantorState', '', true);
              setFieldValue('guarantorCity', '', true);
              setFieldValue('guarantorAddress1', '', true);
              setFieldValue('guarantorAddress2', '', true);
              setFieldValue('guarantorPostalCode', '', true);
              setFieldValue('guarantorPhone', '', true);
            }
          };

          const genderValues = mapValue(values.gender ?? '', 'gender');
          const raceValues = mapValue(values.race ?? '', 'race');

          const genderOptionsView = (name: Gender) => mapValue(name, 'gender');
          const raceOptionsView = (name: Race) => mapValue(name, 'race');

          const handleChangeZipCode = (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const validatedValue = validateValue(value);

            setFieldValue('guarantorPostalCode', validatedValue);

            const newEvent = {
              ...e,
              target: {
                ...e.target,
                value: validatedValue,
              },
            };

            handleChange(newEvent);
          };

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (values.birthDate) {
              const isYoungerThan18 = checkIfPearsonYounger18(values.birthDate);

              setDateOfBirth(values.birthDate);

              if (!isYoungerThan18) {
                setFieldValue('guarantorRelationshipType', '', true);
                handleSetGuarantorFields({target: {value: ''}});
              }
            }
          }, [values.birthDate, dateOfBirth]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (values.guarantorRelationshipType) {
              handleSetGuarantorFields(
                {target: {value: values.guarantorRelationshipType}},
              );
            }
          }, [values.guarantorRelationshipType]);

          return (
            <StyledForm>
              <WBox sx={sx.mainContainer}>
                <Stack sx={sx.contentContainer}>
                  {!isMobile && (
                    <Breadcrumbs>
                      <Link to={ROUTERS_PATH.CONNECTIONS_RELATIONS}>{t('RELATIONS')}</Link>
                      <Link to={ROUTERS_PATH.CONNECTIONS_ADD_RELATION}>{t('ADD_RELATION')}</Link>
                      <Text sx={sx.textColor}>{t('CREATE_A_NEW_PATIENT')}</Text>
                    </Breadcrumbs>
                  )}
                  {isMobile && (
                    <Stack sx={sx.breadCrumbsMobile} onClick={handleClickBreadcrumbs}>
                      <ArrowLeftIcon />
                      <Typography variant="14_18_600">{t('SEARCH')}</Typography>
                    </Stack>
                  )}
                  <Grid
                    ref={containerRef}
                    container
                    spacing={24}
                    columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                    <Grid
                      xl={4}
                      sm={colsNumberSm}
                      xs={12}>
                      <SelectControl
                        placeholder={t('SELECT')}
                        name="relationShipType"
                        value={values.relationShipType || ''}
                        error={touched?.relationShipType ? errors.relationShipType : ''}
                        onChange={handleChange}
                        label={t('RELATIONSHIP_TYPE')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {relationshipTypesSelectOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                  </Grid>
                  <Stack gap={isMobile ? 16 : 12}>
                    <Typography sx={sx.title} variant="18_24_500">{t('PATIENT_INFORMATION')}</Typography>
                    <Grid
                      container
                      spacing={24}
                      columnSpacing={{xs: 18, sm: 36, lg: 48}}
                    >
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          name="firstName"
                          label={t('FIRST_NAME')}
                          value={values.firstName || ''}
                          error={touched?.firstName ? errors.firstName : ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          name="lastName"
                          label={t('LAST_NAME')}
                          value={values.lastName || ''}
                          error={touched?.lastName ? errors.lastName : ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <InputControl
                          maxlength={MAX_INPUT_LENGTH}
                          optional
                          name="middleName"
                          label={t('MIDDLE_NAME')}
                          value={values.middleName || ''}
                          error={touched?.middleName ? errors.middleName : ''}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <DateControl
                          labelTop
                          placeholder='mm/dd/yyyy'
                          disabledFuture
                          name="birthDate"
                          value={dateToFormat('P', values.birthDate) || ''}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          label={t('DATE_OF_BIRTH')}
                          error={touched?.birthDate ? birthDateError || errors.birthDate : ''}
                        />
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <SelectControl
                          placeholder={t('SELECT')}
                          name="birthSex"
                          value={values.birthSex || ''}
                          error={touched?.birthSex ? errors.birthSex : ''}
                          onChange={handleChange}
                          label={t('BIRTH_SEX')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {birthSexOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <SelectControl
                          optional
                          placeholder={t('SELECT')}
                          name="gender"
                          value={genderValues || ''}
                          error={touched?.gender ? errors.gender : ''}
                          onChange={handleChange}
                          label={t('GENDER')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {genderOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{genderOptionsView(option.name)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <SelectControl
                          optional
                          placeholder={t('SELECT')}
                          name="race"
                          value={raceValues || ''}
                          error={touched?.race ? errors.race : ''}
                          onChange={handleChange}
                          label={t('RACE')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {raceOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{raceOptionsView(option.name)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <SelectControl
                          optional
                          placeholder={t('SELECT')}
                          name="ethnicity"
                          value={values.ethnicity || ''}
                          error={touched?.ethnicity ? errors.ethnicity : ''}
                          onChange={handleChange}
                          label={t('ETHNICITY')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {ethnicityOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <SelectControl
                          optional
                          placeholder={t('SELECT')}
                          name="sexualOrientation"
                          value={values.sexualOrientation || ''}
                          error={touched?.sexualOrientation ? errors.sexualOrientation : ''}
                          onChange={handleChange}
                          label={t('SEXUAL_ORIENTATION')}
                          renderValue={(value: any) => t(value as TKeys<'common'>)}
                        >
                          {sexualOrientationOptions.map(option => (
                            <Option key={option.name} value={option.name}>
                              <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                            </Option>
                          ))}
                        </SelectControl>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack sx={sx.contactsContainer}>
                    <Typography sx={sx.contacts} variant="18_24_500">
                      {t('CONTACTS')}
                    </Typography>
                    <Grid
                      container
                      spacing={24}
                      columnSpacing={{xs: 18, sm: 36, lg: 48}}
                    >
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>

                        <PhoneInputControl
                          name="phone"
                          optional={!checkIfPearsonYounger18(values?.birthDate)}
                          label={t('MOBILE_PHONE')}
                          placeholder={t('ENTER_A_PHONE')}
                          value={values.phone || ''}
                          error={touched.phone ? errors.phone : undefined}
                          onChange={handleChange}
                        />

                      </Grid>
                      <Grid
                        xl={4}
                        sm={colsNumberSm}
                        xs={12}>
                        <InputControl
                          optional
                          name="email"
                          label={t('EMAIL')}
                          placeholder={t('ENTER_AN_EMAIL_ADDRESS')}
                          value={values.email || ''}
                          error={touched?.email ? errors.email : ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack gap={isMobile ? 24 : 0}>
                    <Box sx={sx.boxContainer}>
                      <Typography variant="18_24_500">
                        {t('ADDRESS')}
                      </Typography>
                      <Typography sx={sx.optionalText} variant="12_16_500">
                        {t('OPTIONAL')}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={24}
                      columnSpacing={{xs: 18, sm: 36, lg: 48}}
                    >
                      <Grid
                        xl={8}
                        xs={12}
                        display='grid'
                        sx={sx.addressesContainer}
                      >
                        <GoogleAutocomplete
                          placeholder=''
                          value={values.address1}
                          label={t('ADDRESS_1')}
                          onChange={addressChangeHandler}
                          onClear={(e) => addressOnClear(e, setFieldValue)}
                          error={values.streetNumber !== null ? errors.streetNumber : undefined}
                        />
                        <InputControl
                          maxlength={MAX_ADDRESS_LENGTH}
                          disabled={!values.streetNumber}
                          name="address2"
                          label={t('ADDRESS_2')}
                          value={values.address2 || ''}
                          error={touched?.address2 && !values.address2 ? errors.address2 : ''}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Stack>

                  <Stack
                    sx={{
                      gap:
                        values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My
                          ? gapForInsuranceBlockWith
                          : gapForInsuranceBlockWithout,
                    }}
                  >
                    <Stack gap={isMobile ? 18 : 24}>
                      <Stack sx={sx.primatyInsuranceContainer}>
                        <Typography variant="18_24_500">{t('PRIMARY_INSURANCE')}</Typography>
                        <Typography
                          variant="12_16_500"
                          sx={sx.optionalText}
                        >
                          {t('OPTIONAL')}
                        </Typography>
                      </Stack>
                      {!!accountProfileValidInsurance && (
                        <RadioButtons
                          gapNumber={isMobile ? 12 : ''}
                          stylesSx={sx.radioBth}
                          height={isMobile ? 'initial' : ''}
                          inColumn={isMobile}
                          name="whichPrimaryInsuranceUse"
                          error={touched.whichPrimaryInsuranceUse && errors.whichPrimaryInsuranceUse}
                          value={values.whichPrimaryInsuranceUse}
                          handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleChangeRadioBth(e, setFieldValue);
                            handleChange(e);
                          }}
                          onTouched={handleBlur}
                          firstButtonData={{
                            value: EWhichPrimaryInsuranceUse.My,
                            label: t('USE_MY_INSURANCE_POLICY'),
                          }}
                          secondaryButtonData={{
                            value: EWhichPrimaryInsuranceUse.Patient,
                            label: t('USE_PATIENTS_INSURANCE_POLICY'),
                          }}
                        />
                      )}
                    </Stack>
                    {values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.My && (
                      <Stack sx={[...spreadSx(sx.content), {width: {lg: isSidebarOpen ? 706 : 844, xl: 844}}]}>
                        <Stack
                          sx={[
                            ...spreadSx(sx.insuranceWrapper),
                            {maxWidth: {sm: 'calc(50% - 18px)', lg: isSidebarOpen ? 335 : 404, xl: 404}},
                          ]}>
                          <ListWrapper
                            padding='12px'
                            noLast={{xs: 0}}
                          >
                            <RowInformation
                              name={t('CARRIER')}
                              value={accountProfileValidInsurance?.insurancePlan?.carrier?.name}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('PLAN')}
                              value={accountProfileValidInsurance?.insurancePlan?.name}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('POLICY_ID')}
                              value={accountProfileValidInsurance?.number}
                              noMargin
                              tooltip
                            />
                            <RowInformation
                              name={t('END_DATE')}
                              value={dateFormatted(accountProfileValidInsurance?.endDate)}
                              noMargin
                              tooltip
                            />
                          </ListWrapper>
                        </Stack>
                        <Stack sx={sx.guarantorWrapper}>
                          <Typography variant="18_24_500">{t('GUARANTOR')}</Typography>
                          <Stack sx={sx.guarantorContentWrapper}>
                            <Stack
                              sx={[
                                ...spreadSx(sx.guarantorListContent),
                                {maxWidth: {sm: 'calc(50% - 18px)', lg: isSidebarOpen ? 335 : 404, xl: 404}},
                              ]}>
                              <ListWrapper
                                padding='12px'
                                noLast={{xs: 0}}
                              >
                                <RowInformation
                                  tooltip
                                  value={t(accountProfileValidInsurance?.guarantor?.relationType)}
                                  name={t('RELATIONSHIP')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.firstName}
                                  name={t('FIRST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.lastName}
                                  name={t('LAST_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.middleName}
                                  name={t('MIDDLE_NAME')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={dateFormatted(accountProfileValidInsurance?.guarantor?.birthDate ?? NO_VALUE)}
                                  name={t('DATE_OF_BIRTH')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={t(accountProfileValidInsurance?.guarantor?.birthSex)}
                                  name={t('BIRTH_SEX')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.ssnTail}
                                  name={t('SSN_LAST_4')}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                            <Stack
                              sx={[
                                ...spreadSx(sx.guarantorListContent),
                                {maxWidth: {sm: 'calc(50% - 18px)', lg: isSidebarOpen ? 335 : 404, xl: 404}},
                              ]}
                            >
                              <ListWrapper padding='12px' noLast={{xs: 0}}>
                                <RowInformation
                                  tooltip
                                  value={t(accountProfileValidInsurance?.guarantor?.state)}
                                  name={t('STATE')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.city}
                                  name={t('CITY')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.main}
                                  name={t('ADDRESS_1')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.additional}
                                  name={t('ADDRESS_2')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={accountProfileValidInsurance?.guarantor?.postalCode}
                                  name={t('ZIP_CODE')}
                                  noMargin
                                />
                                <RowInformation
                                  tooltip
                                  value={formatPhoneNumber(accountProfileValidInsurance?.guarantor?.phone)}
                                  name={t('PHONE')}
                                  noMargin
                                />
                              </ListWrapper>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                    {values.whichPrimaryInsuranceUse === EWhichPrimaryInsuranceUse.Patient && (
                      <Stack
                        sx={[
                          ...spreadSx(sx.mainContainer),
                          {gap: {xs: 42, sm: 48, lg: 42}, p: {xs: 0}},
                        ]}>
                        <Stack gap={24}>
                          <Grid
                            container
                            spacing={24}
                            columnSpacing={{xs: 18, sm: 36, lg: 48}}
                          >
                            <Grid
                              xl={4}
                              sm={colsNumberSm}
                              xs={12}>
                              <SearchSelect
                                isLabelTop
                                placeholder={t('SELECT')}
                                label={t('INSURANCE_CARRIER')}
                                debouncedChangeHandler={debouncedPrimaryChangeHandler}
                                memoOptions={memoInsuranceCarriersOptions}
                                searchText={searchPrimaryText}
                                setSelectedCarrier={(value: string) =>
                                  setCarrier(value, setFieldValue)
                                }
                                error={
                                  touched?.insuranceCarrier && !values.insuranceCarrier
                                    ? errors.insuranceCarrier
                                    : ''
                                }
                              />
                            </Grid>
                            <Grid
                              xl={4}
                              sm={colsNumberSm}
                              xs={12}>
                              <SelectControl
                                sx={sx.plan}
                                placeholder={t('SELECT')}
                                disabled={!insurancePlans?.length}
                                name="insurancePlan"
                                value={t(values.insurancePlan) || ''}
                                error={
                                  touched?.insurancePlan && !values.insurancePlan
                                    ? errors.insurancePlan
                                    : ''
                                }
                                onChange={handleChange}
                                label={t('INSURANCE_PLAN')}
                                renderValue={(value: any) => t(value as TKeys<'common'>)}
                              >
                                {insurancePlans?.map(option => (
                                  <Option key={option.name} value={option.name}>
                                    <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                  </Option>
                                ))}
                              </SelectControl>
                            </Grid>
                            <Grid
                              xl={4}
                              sm={colsNumberSm}
                              xs={12}>
                              <InputControl
                                name="policyId"
                                label={t('POLICY_ID')}
                                value={t(values.policyId) || ''}
                                error={touched?.policyId && !values.policyId ? errors.policyId : ''}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid
                              xl={4}
                              sm={colsNumberSm}
                              xs={12}>
                              <DateControl
                                labelTop
                                placeholder='mm/dd/yyyy'
                                reverseYears
                                disabledPast
                                onlyFutureYears
                                name="endDate"
                                helperText={t('OPTIONAL')}
                                value={dateToFormat('P', values.endDate) || ''}
                                onChange={handleChange}
                                onTouched={setFieldTouched}
                                label={t('END_DATE')}
                                error={touched.endDate ? errors.endDate : ''}
                                optional
                              />
                            </Grid>
                          </Grid>
                        </Stack>
                        <Stack sx={sx.guarantorContainer}>
                          <Stack sx={sx.flexContainer}>
                            <Box sx={sx.guarantorTitleContainer}>
                              <Typography component="h4" variant="18_24_500">
                                {t('GUARANTOR')}
                              </Typography>
                            </Box>
                            <Grid
                              container
                              spacing={24}
                              columnSpacing={{xs: 18, sm: 36, lg: 48}}>
                              <Grid
                                xl={4}
                                sm={colsNumberSm}
                                xs={12}>
                                <SelectControl
                                  optional
                                  placeholder={t('SELECT')}
                                  name="guarantorRelationshipType"
                                  value={t(values.guarantorRelationshipType) || ''}
                                  error={
                                    touched?.guarantorRelationshipType &&
                                      !values.guarantorRelationshipType
                                      ? errors.guarantorRelationshipType
                                      : ''
                                  }
                                  onChange={(e) => {
                                    handleChangeRelationType(e, setFieldValue, handleSetGuarantorFields);
                                  }}
                                  label={t('RELATIONSHIP')}
                                  renderValue={(value: any) => t(value as TKeys<'common'>)}
                                >
                                  {availableOptions.map(option => (
                                    <Option key={option.name} value={option.name}>
                                      <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                    </Option>
                                  ))}
                                </SelectControl>
                              </Grid>
                            </Grid>
                          </Stack>
                          <Stack gap={24}>
                            <Stack sx={sx.content} flexGrow={1}>
                              <Grid
                                container
                                spacing={24}
                                columnSpacing={{xs: 18, sm: 36, lg: 48}}
                              >
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    name="guarantorFirstName"
                                    label={t('FIRST_NAME')}
                                    value={values.guarantorFirstName || ''}
                                    error={
                                      touched?.guarantorFirstName ? errors.guarantorFirstName : ''
                                    }
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    name="guarantorLastName"
                                    label={t('LAST_NAME')}
                                    value={values.guarantorLastName || ''}
                                    error={touched?.guarantorLastName ? errors.guarantorLastName : ''}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_INPUT_LENGTH}
                                    optional
                                    name="guarantorMiddleName"
                                    label={t('MIDDLE_NAME')}
                                    value={values.guarantorMiddleName || ''}
                                    error={
                                      touched?.guarantorMiddleName ? errors.guarantorMiddleName : ''
                                    }
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <DateControl
                                    labelTop
                                    placeholder='mm/dd/yyyy'
                                    name="guarantorBirthDate"
                                    disabledFuture
                                    value={dateToFormat('P', values.guarantorBirthDate) || ''}
                                    onChange={handleChange}
                                    onTouched={setFieldTouched}
                                    label={t('DATE_OF_BIRTH')}
                                    error={
                                      touched?.guarantorBirthDate && !values?.guarantorBirthDate
                                        ? errors.guarantorBirthDate
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <SelectControl
                                    placeholder={t('SELECT')}
                                    name="guarantorBirthSex"
                                    value={
                                      t(values.guarantorBirthSex || ('' as TKeys<'connections'>)) ||
                                      ''
                                    }
                                    error={
                                      touched?.guarantorBirthSex && !values.guarantorBirthSex
                                        ? errors.guarantorBirthSex
                                        : ''
                                    }
                                    onChange={handleChange}
                                    label={t('BIRTH_SEX')}
                                    renderValue={(value: any) => t(value as TKeys<'common'>)}
                                  >
                                    {birthSexSelectOptions.map(option => (
                                      <Option key={option.name} value={option.name}>
                                        <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                      </Option>
                                    ))}
                                  </SelectControl>
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    type='number'
                                    maxlength={MAX_SSN_LENGTH}
                                    optional
                                    name="guarantorSsnTail"
                                    label={t('SSN_LAST_4')}
                                    error={touched?.guarantorSsnTail ? errors.guarantorSsnTail : ''}
                                    value={values.guarantorSsnTail || ''}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                            <Stack sx={sx.content} flexGrow={1}>
                              <Grid
                                container
                                spacing={24}
                                columnSpacing={{xs: 18, sm: 36, lg: 48}}
                              >
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <SelectControl
                                    placeholder={t('SELECT')}
                                    name="guarantorState"
                                    value={t(values.guarantorState) || ''}
                                    error={
                                      touched?.guarantorState && !values.guarantorState
                                        ? errors.guarantorState
                                        : ''
                                    }
                                    onChange={handleChange}
                                    label={t('STATE')}
                                    renderValue={(value: any) => t(value as TKeys<'common'>)}
                                  >
                                    {statesSelectOptions.map(option => (
                                      <Option key={option.name} value={option.name}>
                                        <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                      </Option>
                                    ))}
                                  </SelectControl>
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_CITY_LENGTH}
                                    name="guarantorCity"
                                    label={t('CITY')}
                                    value={values.guarantorCity || ''}
                                    error={touched?.guarantorCity ? errors.guarantorCity : ''}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    name="guarantorPostalCode"
                                    label={t('ZIP_CODE')}
                                    value={values.guarantorPostalCode || ''}
                                    error={
                                      touched?.guarantorPostalCode ? errors.guarantorPostalCode : ''
                                    }
                                    onChange={handleChangeZipCode}
                                    maxlength={POSTAL_CODE_LENGTH}
                                    simpleTextInput
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_ADDRESS_LENGTH}
                                    name="guarantorAddress1"
                                    label={t('ADDRESS_1')}
                                    error={touched?.guarantorAddress1 ? errors.guarantorAddress1 : ''}
                                    value={values.guarantorAddress1 || ''}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <InputControl
                                    maxlength={MAX_ADDRESS_LENGTH}
                                    optional
                                    name="guarantorAddress2"
                                    label={t('ADDRESS_2')}
                                    error={touched?.guarantorAddress2 ? errors.guarantorAddress2 : ''}
                                    value={values.guarantorAddress2 || ''}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid
                                  xl={4}
                                  sm={colsNumberSm}
                                  xs={12}>
                                  <PhoneInputControl
                                    optional
                                    name="guarantorPhone"
                                    label={t('PHONE')}
                                    error={touched.guarantorPhone ? errors.guarantorPhone : undefined}
                                    placeholder={t('ENTER_A_PHONE')}
                                    value={values.guarantorPhone || ''}
                                    onChange={handleChange}
                                    sx={sx.phoneInput}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                <Stack sx={sx.buttonsWrapper}>
                  <Button
                    sx={sx.button}
                    variant="outlined"
                    color="primary"
                    disabled={step === 'loading'}
                    onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    sx={sx.button}
                    variant="contained"
                    color="primary"
                    disabled={step === 'loading' || isEqual(initialValue, values)}
                    onClick={() => {
                      setTouched(touchedData);
                      setRelationType(values.relationShipType || null);
                      handleSubmit();
                    }}
                  >
                    {t('SAVE')}
                  </Button>
                </Stack>
              </WBox>
              <ParentRoleModal/>
              {failureCreatedContact !== null && (
                <FailureModal
                  failureCreatedContact={failureCreatedContact}
                  relationType={relationType}
                  resetForm={resetForm}
                  setTouched={setTouched}
                />
              )}
              <SendRequestSuccessModal isFullWidthBth={false} />
            </StyledForm>
          );
        }
        }
      </Formik>
      {successCreatedContact !== null && (
        <SuccessModal
          fullName={getFullName({
            firstName: successCreatedContact?.['firstName'],
            lastName: successCreatedContact?.['lastName'],
            middleName: successCreatedContact?.['middleName'],
          })}
          email={successCreatedContact?.['email']}
          phone={successCreatedContact?.['phone']}
          successType={successCreatedContact?.['type']}
        />
      )}
      <ErrorModal open={isErrorModalOpen} close={handleCloseErrorModal}/>
    </>
  );
};
