import {BirthSex, RelationType, StateType} from '@src/api';
import {HealthcareInsurancePolicyChangeRequest} from '@src/api/insurances';
import {Nullable} from '@src/types/NullableModel';

export interface EditDataHealthPolicies {
  relationship: RelationType
  birthDate: string
  birthSex: BirthSex
  firstName: string
  lastName: string
  middleName: string
  ssnTail: string
  state: StateType
  city: string
  postalCode: string
  address1: string
  address2: string
  phone: string
  endDate: string
  id: string
  edit: ({relationId, data}: {
    relationId: string | undefined
    data: HealthcareInsurancePolicyChangeRequest
  }) => void
  relationId: string | undefined
}

export const saveData = ({
  relationship,
  birthDate,
  birthSex,
  firstName,
  lastName,
  middleName,
  ssnTail,
  state,
  city,
  postalCode,
  address1,
  address2,
  phone,
  endDate,
  id,
  edit,
  relationId,
}: Nullable<EditDataHealthPolicies>) => {
  const data = [
    {
      endDate: endDate
        ? {
          value: endDate || '',
        }
        : null,
      guarantor: {
        value: {
          address1: address1
            ? {
              value: address1,
            }
            : null,
          address2: address2
            ? {
              value: address2,
            }
            : null,
          birthDate: birthDate
            ? {
              value: birthDate,
            }
            : null,
          birthSex: birthSex
            ? {
              value: birthSex,
            }
            : null,
          city: city
            ? {
              value: city,
            }
            : null,
          firstName: firstName
            ? {
              value: firstName,
            }
            : null,
          lastName: lastName
            ? {
              value: lastName,
            }
            : null,
          middleName: middleName
            ? {
              value: middleName,
            }
            : null,
          phone: phone
            ? {
              value: phone,
            }
            : null,
          postalCode: postalCode
            ? {
              value: postalCode,
            }
            : null,
          relationType: (!relationship || relationship === RelationType.SELF)
            ? null
            : {
              value: relationship,
            },
          ssnTail: ssnTail
            ? {
              value: ssnTail,
            }
            : null,
          state: state
            ? {
              value: state,
            }
            : null,
        },
      },
      id: id || '',
    },
  ];
  if (edit && relationId) {
    edit({
      relationId,
      data: {
        deletedHealthcareInsurancePolicyIds: [],
        healthcareInsurancePoliciesCreate: [],
        healthcareInsurancePoliciesEdit: data || undefined,
      },
    });
  }
};
