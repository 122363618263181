import {Stack, SxProps, Typography} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';

const sx: Partial<Record<string, SxProps>> = {
  container: {
    padding: '26px 24px 36px 24px',
  },
  title: {
    fontSize: 24,
    lineHeight: '28px',
    pb: 29,
  },
  content: {
    fontSize: 12,
    lineHeight: '18px',
  },
};

export const RelationEvents = ({description}: {description?: string}) => {
  const {t} = useTranslate('connections');
  return (
    <Stack sx={sx.container}>
      <Typography
        sx={sx.title}
        paddingBottom={4}
        component='h3'
        variant='24_28_400'>{t('EVENTS')}</Typography>
      <Typography sx={sx.content} variant='12_18_500'>
        {
          description || t('YOU_DONT_HAVE_ANY_EVENTS_YET')
        }
      </Typography>
    </Stack>
  );
};
