import {Button, Stack, Typography} from '@mui/material';
import {TypographyOwnProps} from '@mui/material/Typography/Typography';
import {Icons} from '@src/shared/assets/icons';
import React from 'react';
import {useNavigate} from 'react-router-dom';

interface IHeaderPageProps {
  children?: string | JSX.Element
  itemAction?: JSX.Element
  withReturn?: boolean
  marginBottom?: string
  variant?: TypographyOwnProps['variant']
  height?: number | string
}

export const PageHeader: React.FC<IHeaderPageProps> = ({
  itemAction,
  children,
  withReturn,
  variant,
  height,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {withReturn && (
        <Button onClick={() => navigate(-1)}>
          <Icons.Arrow.Back.X24 /> Back
        </Button>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {typeof children === 'string'
          ? (
            <Typography
              sx={{
                typography: variant || {
                  xs: '24_32_700',
                  sm: '32_36_700',
                },
                height: (t) => height ?? t.pageTitleHeight,
              }}
            >
              {children}
            </Typography>
          )
          : (
            children
          )}
        {itemAction}
      </Stack>
    </>
  );
};
