import {Stack, styled, SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  breadcrumbs: (t) => ({
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    color: t.palette.grey[800],
    'a, a:visited': {
      color: t.palette.grey[800],
    },
  }),
  mainContainer: {
    gap: 24,
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    flexDirection: {
      md: 'column-reverse',
      lg: 'row',
    },
  },
  leftContainer: {
    gap: 24,
    padding: 24,
  },
  mobileDownloadButton: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 18,
    background: theme => theme.colors.all.white,
  },
};

export const ContentContainer = styled(Stack)<{ isSidebarOpen: boolean }>(({theme, isSidebarOpen}) => ({
  flex: 1,
  [theme.breakpoints.only('lg')]: {
    flexDirection: isSidebarOpen ? 'column-reverse' : 'row',
    '& > :first-child': {
      borderRight: !isSidebarOpen && 'unset',
      borderTop: isSidebarOpen && 'unset',
    },
  },
  [theme.breakpoints.up('xl')]: {
    flexDirection: 'row',
    '& > :first-child': {
      borderRight: 'unset',
    },
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse',
    '& > :first-child': {
      borderTop: 'unset',
    },
  },
  [theme.breakpoints.only('xs')]: {
    marginBottom: 78,
  },
}));
