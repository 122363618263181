import {SxProps, Theme, styled} from '@mui/material';
import {alpha} from '@src/theme/utils';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    border: '1px solid',
    borderColor: t.palette.secondary._14,
    padding: 24,
    gap: 40,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
      padding: 0,
      gap: 24,
    },
    [t.breakpoints.only('xs')]: {
      marginBottom: 24,
      width: 'auto',
      backgroundColor: t.palette.common.white,
    },
  }),
  content: {
    flexGrow: 1,
    gap: {xs: 24, lg: 36},
  },
  buttonsContainer: (t) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 12,
    [t.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      pt: 30,
    },
  }),
  button: (t) => ({
    [t.breakpoints.down('sm')]: {
      flex: 1,
    },
  }),
  title: {
    typography: {xs: '22_26_500', sm: '18_24_500'},
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  mainContainer: {
    gap: {xs: 48, sm: 36, lg: 42},
  },
  whiteBox: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: {xs: 48, sm: 36, lg: 60},
    justifyContent: 'space-between',
    padding: {xs: '18px 16px', sm: '0px 24px 24px 24px', lg: 24},
    flexGrow: 1,
    border: {
      sm: 'none',
      xs: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    },
  }),
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: {xs: 'space-between', sm: 'flex-end'},
    gap: 12,
  },
  insuranceTitle: {
    typography: {xs: '22_26_500', lg: '24_34_500'},
  },
  flexContainerGuarantor: {
    gap: {xs: 36, sm: 40, lg: 36},
  },
  boxContainer: {
    display: 'flex',
    gap: 24,
  },
};

export const StyledForm = styled(Form)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: 1,
  gap: 64,
  [theme.breakpoints.between('sm', 'lg')]: {
    gap: 36,
  },
  [theme.breakpoints.only('xs')]: {
    gap: 36,
    pb: 18,
  },
}));
