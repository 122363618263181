import {Button as MuiButton, ButtonProps, ButtonTypeMap} from '@mui/material';
import {FC, PropsWithChildren} from 'react';

export enum ButtonSize {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}

export const buttonWidthMap = {
  [ButtonSize.Xs]: 70,
  [ButtonSize.Sm]: 120,
  [ButtonSize.Md]: 160,
  [ButtonSize.Lg]: 200,
};

type IButtonProps = ButtonTypeMap<ButtonProps, 'button'>['props'] & {
  width?: ButtonSize
  component?: any
  target?: any
  to?: any
};

export const Button: FC<PropsWithChildren<IButtonProps>> = ({
  width,
  children,
  ...rest
}) => {
  return (
    <MuiButton
      {...rest}
      sx={width ? {minWidth: buttonWidthMap[width], ...rest.sx} : rest.sx}
    >
      {children}
    </MuiButton>
  );
};
