import {Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

// TODO Заменить на цвет из ui-kit (пока не добавлен)
export const sx = {
  container: {
    flexDirection: 'row',
    backgroundColor: '#E7F1F9',
    maxWidth: 990,
    padding: {
      xs: 9,
      sm: 12,
    },
    gap: {
      xs: 6,
      sm: 12,
    },
  },
  containerWithBorder: () => ({
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '2px solid transparent',
      animation: 'pulse 500ms ease-out infinite',
    },
    '@keyframes pulse': (t: Theme) => ({
      '0%, 100%': {
        borderColor: 'transparent',
      },
      '50%': {
        borderColor: alpha(t.palette.info.main, 30),
      },
    }),
  }),
};
