import {createSelector} from '@reduxjs/toolkit';
import {Result} from '@src/api';
import {UUID} from '@src/types';
import {RootState} from '@store';
import {useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {resultsActions, selectDynamicsData} from './slice';

const selectResults = createSelector(
  (state: RootState) => state.results.results,
  (results) => {
    return results?.allIDs.map((id) => results.byID[id] as Result);
  });
const dummy: any[] = [];
export const useResults = () => {
  const results = useSelector(selectResults);
  const isLoading = useSelector((state: RootState) => state.results.fetching);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resultsActions.requestResults());
  }, [dispatch]);
  return {
    // TODO
    isLoading: Boolean(isLoading?.results),
    data: results ?? dummy as Result[],
  };
};

export const useResultDetail = ({resultId, companyId}: {resultId: UUID | undefined, companyId: UUID | undefined}) => {
  const allDetails = useSelector((state: RootState) => state.results.resultDetails);
  const dispatch = useDispatch();
  const details = resultId ? allDetails?.byID[resultId] : null;
  useEffect(() => {
    if ((resultId && companyId)) {
      dispatch(resultsActions.requestResultDetails({companyId, resultId}));
    }
  }, [companyId, dispatch, resultId]);
  return {
    // TODO: investigate how it had been handled in frontend and add required code
    isLoading: false,
    isFetching: false,
    data: details,
  };
};

export const useEmailForm = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      submit: ({resultId, email}: {resultId?: UUID, email: string}) => {
        if (!resultId) {
          console.error('[useEmailForm] resultId is undefined');
        } else {
          dispatch(resultsActions.sendResultsByEmail({resultId, email}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useDynamicsData = () => {
  const dynamics = useSelector(selectDynamicsData);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [dynamics]);

  useEffect(() => {
    if (!dynamics.testResults.length) {
      setIsLoading(true);
      dispatch(resultsActions.requestDynamics());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  return {
    dynamics,
    isLoading,
  };
};
