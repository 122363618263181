import {Box, SxProps, Stack, Theme} from '@mui/material';
import Grid from '@src/components/Grid';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {OverviewProps} from '@src/types';
import {useNavigate} from 'react-router-dom';

import {Contacts} from './fragments/Contacts';
import {GeneralInfo} from './fragments/GeneralInfo';
import {OverviewEmptyFragment} from './fragments/OverviewEmptyFragment';
import {OverviewInsurance} from './fragments/OverviewInsurance';
import {OverviewLastResults} from './fragments/OverviewLastResults';
import {OverviewRelations} from './fragments/OverviewRelations';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    flexDirection: 'row',
    gap: 24,
    flexGrow: 1,
  },
  leftContainer: {
    gap: 24,
  },
  rightContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  upperSideFragments: {
    padding: '0',
    maxWidth: '348px',
    minWidth: '348px',
  },
  upperLeftSideFragments: {
    padding: '0',
    maxWidth: '332px',
    minWidth: '332px',
  },
  lowerSideFragments: {
    padding: '0',
    maxWidth: '348px',
    minWidth: '348px',
  },
  lowerLeftSideFragments: {
    padding: '0',
    maxWidth: '332px',
    minWidth: '332px',
    flexGrow: 1,
  },
};

interface IOverviewDesktopProps extends Omit<OverviewProps, 'isSidebarOpen'> {}

export const OverviewDesktop = ({accountProfile, fetching, data, isLoading, insurances}: IOverviewDesktopProps) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();

  if (fetching || isLoading) return null;
  return (
    <Stack sx={sx.mainContainer}>
      <Stack sx={sx.leftContainer}>
        <WBox sx={sx.upperLeftSideFragments}>
          <GeneralInfo contact={accountProfile?.contact} avatarUrl={accountProfile?.avatarUrl} />
        </WBox>
        <WBox sx={sx.lowerLeftSideFragments}>
          <Contacts profile={accountProfile} />
        </WBox>
      </Stack>
      <Box sx={sx.rightContainer}>
        <Grid container spacing={24}>
          <Grid lg={12} md={12}>
            <WBox noPaddings={true}>
              {accountProfile?.relations?.length
                ? (
                  <OverviewRelations relations={accountProfile?.relations} cardsCount={3} />
                )
                : (
                  <OverviewEmptyFragment
                    variant='14_18_500'
                    title={t('RELATIONS')}
                    button={t('ADD_RELATIONS')}
                    isDisabled={false}
                    onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                    description={t('YOU_DIDNT_ADD_ANY_RELATIONS_YET')}
                  />
                )}
            </WBox>
          </Grid>
          <Grid lg={12} md={12}>
            <WBox noPaddings={true}>
              {data?.length
                ? (
                  <OverviewLastResults results={data} />
                )
                : (
                  <OverviewEmptyFragment
                    title={t('LAST_RESULTS')}
                    description={t('YOU_DONT_HAVE_ANY_RESULTS_YET')}
                  />
                )}
            </WBox>
          </Grid>
          <Grid lg={12} md={12}>
            <WBox noPaddings={true}>
              {insurances.length
                ? (
                  <OverviewInsurance insurances={insurances} cardsCount={3} />
                )
                : (
                  <OverviewEmptyFragment
                    isDisabled={false}
                    title={t('INSURANCE')}
                    button={t('ADD_INSURANCE')}
                    onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                    description={t('YOU_DIDNT_ADD_ANY_INSURANCE_YET')}
                  />
                )}
            </WBox>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
