import {Box, ButtonBaseProps, Typography} from '@mui/material';
import {useClickOutside} from '@src/shared/hooks/useClickOutside';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Avatar} from 'components/Avatar';
import {IMenuItem, MenuList} from 'components/MenuList/MenuList';
import {FC, forwardRef, PropsWithChildren, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import {ProfileMenuButtonStyled} from './styles';

const sx = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
};
interface IProfileMenuProps {
  name: string
  src?: string | undefined
  menu: IMenuItem[]
  className?: string
}
export const ProfileMenuView: FC<PropsWithChildren<IProfileMenuProps>> = ({children, name, src, menu, className}) => {
  const [opened, setOpened] = useState(false);
  const {desktop} = useMQuery();
  const elRef = useRef(window.document.createElement('div'));
  const [container, setContainerEl] = useState<HTMLElement | null>(null);
  const tmtRef = useRef<number>();

  const handleClick = useCallback(() => {
    setOpened(s => !s);
  }, []);

  const handleMouseLeave = useCallback(() => {
    tmtRef.current = window.setTimeout(() => {
      setOpened(false);
    }, 1000);
  }, []);

  const handleMouseOver = useCallback(() => {
    window.clearTimeout(tmtRef.current);
  }, []);

  const btRef = useClickOutside(() => {
    window.setTimeout(() => {
      setOpened(false);
    });
  });

  useEffect(() => {
    container?.appendChild(elRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => { container?.removeChild(elRef.current); };
  }, [container]);

  return (
    <Box ref={setContainerEl} className={className} sx={sx.container}>
      {desktop &&
        <Typography variant='16_20_500'>{name ?? '...'}</Typography>
      }
      <ProfileMenuButton
        ref={(el) => { btRef.current = el; }}
        type={'button'}
        onClick={handleClick}
        onMouseLeave={handleMouseLeave}
        onMouseOver={handleMouseOver}
        src={src}
      >
        <>
          {children}
          {createPortal(
            <MenuList
              parentRef={btRef}
              open={opened}
              menu={menu}
              onClose={handleClick}
            />,
            elRef.current,
          )}
        </>
      </ProfileMenuButton>
    </Box>
  );
};
interface IProps extends ButtonBaseProps {
  src?: string
}
export const ProfileMenuButton = forwardRef<any, IProps>(({children, src, ...baseBtProps}, ref) => {
  return (
    <ProfileMenuButtonStyled
      ref={ref}
      disableRipple
      {...baseBtProps}
    >
      <Avatar src={src} useDefaultIcon size={42} />
      {children}
    </ProfileMenuButtonStyled>
  );
});
ProfileMenuButton.displayName = 'ProfileMenuButton';
