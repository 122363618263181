import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Access, AccessRequests} from '@src/api/accesses/models';
import {ValidationErrorType, UUID} from '@src/types';

export interface AccountState {
  accesses: Access | null
  accessesRequests: AccessRequests | null
  isFetching: boolean
  error?: ValidationErrorType | null
}
const initialState: AccountState = {
  accesses: null,
  isFetching: false,
  accessesRequests: null,
};

export const accesses = createSlice({
  name: 'accesses',
  initialState,
  reducers: {
    requestAccesses () {},
    requestAccessesRequests () {},
    approveAccessesRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    declineAccessesRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    deleteAccessesRequest (_state, _action: PayloadAction<{requestId: UUID}>) {},
    setAccesses (state, {payload}) {
      state.accesses = payload.accesses;
    },
    setAccessesRequests (state, {payload}: PayloadAction<{accessesRequests: AccessRequests}>) {
      state.accessesRequests = payload.accessesRequests;
    },
    setFetching (state, {payload}) {
      state.isFetching = payload;
    },
    setCommonError (state, {payload}: PayloadAction<ValidationErrorType | null>) {
      state.error = payload;
    },
  },
});

export const accessesReducer = accesses.reducer;
export const accessesActions = accesses.actions;
export const accessesStoreName = accesses.name;
