import {useLayoutEffect, useState} from 'react';

export const getRestTime = (timeInMs: number) => {
  const seconds = Math.floor((timeInMs - Date.now()) / 1000);
  if (seconds < 0) {
    return '';
  }
  const pad = (secs: number | string) => `0${secs}`.slice(-2);
  return `00:${pad(Math.floor(seconds / 60))}:${pad(seconds % 60)}`;
};

export const useCountDown = (timeInMs: number | undefined) => {
  const [time, setTime] = useState('...');

  useLayoutEffect(() => {
    if (timeInMs === undefined) {
      setTime(() => {
        return '...';
      });
      return;
    }
    setTime(getRestTime(timeInMs));
    const tmt = window.setInterval(() => {
      const resetTime = getRestTime(timeInMs);
      if (!resetTime) {
        window.clearInterval(tmt);
      }
      setTime(resetTime);
    }, 1000);
    return () => window.clearInterval(tmt);
  }, [timeInMs]);

  return time;
};
