import {Box, Stack, Typography} from '@mui/material';

import {sx} from './styles';
import {IAlertWithAnimatedBorderProps} from './types';

export const AlertWithAnimatedBorder = ({icon, text, isVisible, variant = '14_20_500'}: IAlertWithAnimatedBorderProps) => {
  return (
    <Stack sx={isVisible ? [sx.container, sx.containerWithBorder] : sx.container}>
      <Box>
        {icon}
      </Box>
      <Typography variant={variant}>{text}</Typography>
    </Stack>
  );
};
