import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  boxContainer: {
    padding: '24',
    flexWrap: 'unset',
  },
  patientNotFound: {
    color: (t) => t.palette.grey[500],
    paddingTop: 14,
  },
};
