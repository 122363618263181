import {Box, Typography, Stack} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as IconChevronRight} from '@src/shared/assets/icons/chevron-right.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Link} from 'react-router-dom';
import {Button} from 'ui-kit';

import {sx} from './styles';
import {CardProp} from './types';

export const Card = ({
  img,
  title,
  description,
  link,
  linkText,
}: CardProp) => {
  const {mobile} = useMQuery();

  const colorText = link ? '' : 'grey.400';

  return (
    <WBox sx={sx.card}>
      <Stack>
        <Typography variant='24_34_500' gap={12} color={colorText}>{title}</Typography>
        <Box sx={sx.content} color={colorText}>
          <Typography sx={sx.description}>{description}</Typography>
        </Box>
        <Box sx={sx.buttonContainer}>
          {link
            ? (
              <Link to={ROUTERS_PATH.BMI}>
                <Button
                  sx={sx.linkButton}
                  size='sm'
                  variant={'text'}
                  color='secondary'
                  endIcon={<IconChevronRight />}
                >
                  <Typography sx={sx.buttonText}>{linkText}</Typography>
                </Button>
              </Link>
            )
            : (
              <Button
                size='sm'
                variant={'outlined'}
                color='secondary'
                disabled={true}
              >
                <Typography variant='14_18_500'>Coming soon</Typography>
              </Button>

            )}
        </Box>
      </Stack>
      {!mobile && (
        <Box sx={sx.imgContainer}>
          {img}
        </Box>
      )}
    </WBox>
  );
};
