import {Race, Gender, BirthSex, Ethnicity, StateType, SexualOrientationMap} from '@src/api';
import {RelationType} from '@src/api/insurances';
import {RelationshipType} from '@src/api/relations';

export const birthSexOptions = Object.values(BirthSex).map((name) => ({name}));

export const raceOptions = Object.values(Race).map((name) => ({name}));

export const ethnicityOptions = Object.values(Ethnicity).map((name) => ({name}));

export const genderOptions = Object.values(Gender).map((name) => ({name}));

export const sexualOrientationOptions = Object.keys(SexualOrientationMap).map((name) => ({name}));

export const birthSexSelectOptions = Object.values(BirthSex).map((name) => ({name}));

export const statesSelectOptions = Object.values(StateType).map((name) => ({name}));

export const relationTypesSelectOptions = Object.values(RelationType).map((name) => ({name}));

export const relationshipTypeSelectOptions = Object.values(RelationshipType).map((name) => ({name}));
