/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
import {Stack, Typography} from '@mui/material';
import {RelationErrorType} from '@src/api/relations';
import {Tooltip} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as InformationIcon} from '@src/shared/assets/icons/info-without-background.svg';
import {ReactComponent as LoaderIcon} from '@src/shared/assets/icons/loader_sm.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {createRelationPath} from '@src/shared/utils';
import {useSendRelationRequest} from '@src/store/relations/hooks';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {checkIfPearsonYounger18} from '../../helpers/checkIfPearsonYounger18';

import {getRequestDateAndTime, getTooltipsList} from './helpers';
import {sx} from './styles';
import {IAddRelationTableActionProps} from './types';

export const AddRelationTableAction = ({
  id,
  relationError,
  relationshipType,
  newContactBirthDate,
  variant,
  isModal,
}: IAddRelationTableActionProps) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();
  const navigate = useNavigate();
  const {sendRelationRequest} = useSendRelationRequest();
  const tooltipsList = getTooltipsList({t, newContactBirthDate, checkIfPearsonYounger18});

  const relationshipTypeError =
    relationError?.['type'] === RelationErrorType.LESS_EIGHTEEN_ERROR ||
    relationError?.['type'] === RelationErrorType.PARENT_AGE_ERROR ||
    relationError?.['type'] === RelationErrorType.GREATER_EIGHTEEN_ERROR;

  const relationshipErrorTypes =
    relationError && (
      relationError['type'] !== RelationErrorType.LESS_EIGHTEEN_ERROR &&
      relationError['type'] !== RelationErrorType.BIRTH_DATE_ERROR &&
      relationError['type'] !== RelationErrorType.ACCOUNT_STATUS_ERROR
    );

  const isIncludeRelationshipType =
    relationshipErrorTypes && relationError['type'] !== RelationErrorType.RELATION_CONTRADICT_ERROR;

  const tooltipText =
    relationError &&
    (isIncludeRelationshipType
      ? `${relationError['type']}_${relationshipType || ''}`
      : relationError['type']);

  const errorsWithoutInfo = [RelationErrorType.RELATION_DUPLICATE_ERROR, RelationErrorType.RELATION_REQUEST_DUPLICATE_ERROR];

  const tooltipContent = tooltipsList[tooltipText as keyof typeof tooltipsList];

  const isRelationContradictError = relationError?.['type'] === RelationErrorType.RELATION_CONTRADICT_ERROR;
  const isRelationRequestDuplicateError = relationError?.['type'] === RelationErrorType.RELATION_REQUEST_DUPLICATE_ERROR;
  const isRelationDuplicateError = relationError?.['type'] === RelationErrorType.RELATION_DUPLICATE_ERROR;
  const isAccountStatusError = relationError?.['type'] === RelationErrorType.ACCOUNT_STATUS_ERROR;
  const isBirthDateError = relationError?.['type'] === RelationErrorType.BIRTH_DATE_ERROR;

  const hasRelevantRelationError = relationError && !errorsWithoutInfo.includes(relationError['type']);

  const hasSendRequestError = (isAccountStatusError || isBirthDateError) && isModal;

  const isMobileOrModal = isMobile || isModal;

  const buttonStyles = isModal ? sx.bthModal : sx.btn;

  const shouldShowContradictErrorInModal = isRelationContradictError && !relationshipTypeError && isModal && isMobile;
  const shouldShowContradictErrorOutsideModal = isRelationContradictError && !isModal;

  const alignItemsCondition = () => {
    if (shouldShowContradictErrorInModal) return 'baseline';
    if (shouldShowContradictErrorOutsideModal) return 'flex-start';
    return 'center';
  };

  const paddingTopCondition = (shouldShowContradictErrorOutsideModal) || (shouldShowContradictErrorInModal) ? 4 : 0;

  const handleSendRequest = () => {
    relationshipType && sendRelationRequest({
      data: {
        contactId: id,
        recaptcha: '',
        relationType: relationshipType,
      },
    });
  };

  return (
    <Stack sx={sx.container} gap={hasSendRequestError ? 6 : 10}>
      {hasRelevantRelationError && (
        <Tooltip title={tooltipContent} placement={isMobileOrModal ? 'bottom-start' : 'left'}>
          <Stack
            sx={(t) => ({
              flexDirection: 'unset',
              alignItems: alignItemsCondition(),
              pt: paddingTopCondition ? 4 : 0,
              '& svg path': {
                fill: hasSendRequestError ? t.palette.grey[400] : t.palette.grey[600],
              },
            })}
          >
            <InformationIcon />
          </Stack>
        </Tooltip>
      )}
      {isRelationDuplicateError && (
        <Button
          sx={isModal ? sx.viewPatientBthModal : sx.viewPatientBth}
          size="xs"
          variant={isMobileOrModal ? 'text' : 'outlined'}
          color="secondary"
          onClick={() => navigate(createRelationPath(isMobile, id))}
        >
          {t("VIEW_PATIENT'S_CHART")}
        </Button>
      )}
      {(isRelationContradictError || relationshipTypeError) && (
        <Stack alignItems='flex-start'>
          <Typography
            variant="14_18_500"
            sx={sx.alert}
          >
            {t(
              relationshipTypeError
                ? 'WRONG_RELATIONSHIP_TYPE'
                : 'RELATIONSHIP_TYPE_CONTRADICTS_AN_EXISTING',
            )}
          </Typography>
        </Stack>
      )}
      {isRelationRequestDuplicateError && (
        <Stack minHeight={isModal ? 42 : ''}>
          <Stack sx={isModal ? sx.duplicateErrorWeapperModal : sx.duplicateErrorWeapper}>
            <Typography variant="14_18_500">{t('PENDING_REQUEST')}</Typography>
            <LoaderIcon />
          </Stack>
          <Typography
            variant='12_16_500'
            sx={isModal ? sx.creationRequestDateModal : sx.creationRequestDate}
          >{getRequestDateAndTime(relationError)}
          </Typography>
        </Stack>
      )}
      {(isAccountStatusError ||
        isBirthDateError) && (
        <Button
          sx={isModal ? sx.sendRequestDisabledBthModal : sx.sendRequestDisabledBth}
          size={isMobileOrModal ? 'xs' : 'lg'}
          variant={isMobileOrModal ? 'text' : 'contained'}
          disabled
        >
          {t('SEND_REQUEST')}
        </Button>
      )}
      {!relationError && (
        variant === 'text'
          ? (
            <Button
              sx={buttonStyles}
              size={isMobileOrModal ? 'xs' : 'lg'}
              variant={isMobileOrModal ? 'text' : 'contained'}
              color={isMobileOrModal ? 'primary' : 'secondary'}
              onClick={handleSendRequest}
            >
              {t('SEND_REQUEST')}
            </Button>
          )
          : (
            <Button
              sx={buttonStyles}
              size={isMobileOrModal ? 'xs' : 'lg'}
              variant={isMobileOrModal ? 'text' : 'contained'}
              color={isMobileOrModal ? 'primary' : 'secondary'}
              onClick={handleSendRequest}
            >
              {t('SEND_REQUEST')}
            </Button>
          )
      )}
    </Stack>
  );
};
