import {
  FormControl,
  Typography,
  Input as MuiInput,
  InputAdornment,
  FormHelperText,
  SxProps,
  Theme,
} from '@mui/material';
import {ReactComponent as Cancel} from '@src/shared/assets/icons/close-sm.svg';
import {ChangeEvent, MouseEventHandler} from 'react';

interface BodyType {
  sx?: SxProps<Theme>
  value: string
  label?: string
  StartIcon?: any
  helperText?: string
  variant?: 'standard' | 'outlined' | 'filled' | undefined
  onClear?: () => void
  onChange: (
    event: React.FormEvent<EventTarget & (HTMLInputElement | HTMLTextAreaElement)>,
  ) => void
  error?: string
  placeholder?: string
  name?: string
  disabled?: boolean | (() => boolean)
  onTouched?: MouseEventHandler<HTMLDivElement> | undefined
  noClear?: boolean
  maxLength?: number
  validation?: (value: string) => string
}

export const Input = ({
  sx,
  value,
  label,
  StartIcon,
  onTouched,
  placeholder,
  helperText,
  variant = 'standard',
  onClear,
  error,
  onChange,
  disabled = false,
  name = 'input',
  noClear,
  maxLength,
  validation,
}: BodyType) => {
  const isDisabled = typeof disabled === 'function' ? disabled() : disabled;

  const handleChange = (
    event: React.FormEvent<EventTarget & (HTMLInputElement | HTMLTextAreaElement)>,
  ) => {
    const {name, value} = event.target as HTMLInputElement;
    const filteredValue = validation ? validation(value) : value;
    onChange({
      ...event,
      target: {
        ...event.target,
        value: filteredValue,
        name,
      },
    } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <FormControl
      fullWidth
      onClick={onTouched}
      sx={[
        (t) => ({
          m: 1,
          pointerEvents: isDisabled ? 'none' : 'unset',
          backgroundColor: isDisabled ? `${t.palette.grey[200]}` : 'initial',
          '&:hover': {backgroundColor: t.palette.grey[100]},
          '&:active': {backgroundColor: t.palette.grey[200]},
          ' .MuiInput-input': {textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'},
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant={variant}
    >
      <Typography
        sx={(t) => ({
          position: 'absolute',
          top: '-18px',
          fontSize: 12,
          lineHeight: '18px',
          color: `${t.colors.all.grey[400]} !important`,
        })}
      >
        <em>{helperText}</em>
      </Typography>
      <MuiInput
        name={name}
        id="standard-adornment-amount"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        inputProps={{maxLength}}
        sx={(t) => ({
          marginTop: '0px !important',
          color: t.colors.all.grey2,
          '&:before': {
            borderBottom:
              error && !isDisabled
                ? `1px solid ${t.colors.all.errorLegacy} !important`
                : `1px solid ${t.colors.all.grey[300]} !important`,
          },
          '&:after': {
            borderBottom:
              error && !isDisabled
                ? `1px solid ${t.colors.all.errorLegacy} !important`
                : `1px solid ${t.colors.all.grey[300]} !important`,
          },
        })}
        startAdornment={
          StartIcon
            ? (
              <InputAdornment position="start">
                <StartIcon />
              </InputAdornment>
            )
            : (
              <Typography
                variant="14_20_700"
                sx={(t) => ({whiteSpace: 'nowrap', paddingRight: 6, color: t.colors.all.grey1})}
              >
                {label}
              </Typography>
            )
        }
        endAdornment={
          !noClear && (
            <InputAdornment
              position="end"
              sx={{
                cursor: 'pointer',
                minWidth: 42,
                height: 42,
                maxHeight: 'unset',
                justifyContent: 'center',
              }}
              onClick={(event) => {
                event.stopPropagation();
                if (onClear) onClear();
              }}
            >
              {value && <Cancel />}
            </InputAdornment>
          )
        }
      />
      {!isDisabled && (
        <FormHelperText
          id="component-error-text"
          sx={(t) => ({
            color: t.colors.all.errorLegacy,
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '18px',
            position: 'absolute',
            bottom: '-21px',
            height: 18,
          })}
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
