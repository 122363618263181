import {Stack, SxProps} from '@mui/material';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Button} from 'ui-kit';

import {CalendarPicker} from './Calendar/CalendarPicker';

const sx: Partial<Record<string, SxProps>> = {
  container: {
    padding: {
      lg: 24,
    },
    pointerEvents: 'none',
    boxSizing: 'border-box',
    height: '100%',
    justifyContent: 'space-between',
  },
  button: {
    mt: 24,
    alignSelf: 'flex-start',
  },
};

export const OverviewCalendar = () => {
  const {t} = useTranslate('overview');
  const {mobile, tabletPortrait, lg: isDesktopLg, xl: isDesktopXl} = useMQuery();
  return (
    <Stack sx={sx.container}>
      <CalendarPicker
        style={{minHeight: isDesktopLg || isDesktopXl ? 354 : 342, minWidth: '100%', zIndex: 10}}
        attrs={undefined}
        onClose={() => { }}
      />
      {(!mobile || !tabletPortrait) && (
        <Button
          sx={sx.button}
          variant="outlined"
          color="secondary"
          disabled>
          {t('MAKE_AN_APPOINTMENT')}
        </Button>
      )}
    </Stack>
  );
};
