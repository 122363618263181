// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, css as _, styled} from '@mui/material';
import {Button, ButtonSize} from '@src/components/Button';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {DateTime} from 'luxon';
import {CSSProperties, forwardRef, useEffect, useMemo, useState} from 'react';

import {CalendarContainer} from '../styles';

import {Calendar} from './components/Calendar';

const insertByOrder = (
  r: [start?: DateTime | null, end?: DateTime | null] = [],
  date: DateTime,
  order: 0 | 1,
) => {
  let range = [...r];
  if (
    !range.length ||
    (range.length === 1 && range[0] == null) ||
    (!range[0] && !range[1])
  ) {
    range = [null, null];
    range.splice(order, 1, date);
    return range as TDateRange;
  }
  if (range.length === 1) {
    range.splice(order, 0, date);
  }
  if (range[0] && range[1]) {
    range[order] = date;
  }

  if (range[order] && !range[1 - order]) {
    range[1 - order] = date;
  } else {
    range[order] = date;
  }

  if (range[0] && range[1] && range[0] > range[1]) {
    return range.reverse() as TDateRange;
  }

  return range as TDateRange;
};

export type TDateRange = [start: DateTime | null, end: DateTime | null];
interface ICalendarPickerProps {
  mode?: 'range' | 'calendar'
  style: CSSProperties
  date?: DateTime
  arrow?: JSX.Element
  attrs: any
  onClose: () => void
  range?: TDateRange
  onChange?: (date: DateTime | [DateTime, DateTime]) => void
  disabledPast?: boolean
  onlyFutureYears?: boolean
  disabledFuture?: boolean
  reverseYears?: boolean
  startRangeWithCurrentMonth?: boolean
}

export const CalendarPicker = forwardRef<HTMLDivElement, ICalendarPickerProps>(
  (props, ref) => {
    const {mobile} = useMQuery();

    const {date, style, arrow, attrs, range, mode, onChange, onClose, disabledPast, onlyFutureYears, disabledFuture, reverseYears, startRangeWithCurrentMonth = false} = props;
    const [internalRange, setInternalRange] = useState(
      mode === 'range' ? props.range : undefined,
    );
    const isRangeMode = mode === 'range';
    const {t} = useTranslate('common');

    const initialStartMonth = useMemo(() => {
      if (isRangeMode) {
        if (range?.[0]?.isValid) {
          return range[0];
        } else if (range?.[1]?.isValid) {
          return range[1].minus({month: startRangeWithCurrentMonth ? 1 : 0});
        } else {
          DateTime.now().minus({month: startRangeWithCurrentMonth ? 1 : 0});
        }
      }

      return date ?? DateTime.now().minus({month: startRangeWithCurrentMonth ? 1 : 0});
    }, [date, isRangeMode, range, startRangeWithCurrentMonth]);

    const initialEndMonth = range?.[1]?.isValid
      ? range[1]
      : initialStartMonth.plus({month: 1});

    const [[start, end], setMonthRange] = useState<[DateTime, DateTime]>([
      initialStartMonth,
      initialEndMonth,
    ]);

    useEffect(() => {
      if (mode === 'range') {
        setInternalRange(range);
        let externalStartMonth;

        if (range?.[0]?.isValid) {
          externalStartMonth = range[0];
        } else {
          if (range?.[1]?.isValid) {
            externalStartMonth = range[1].minus({month: 1});
          } else {
            externalStartMonth = DateTime.now().minus({month: startRangeWithCurrentMonth ? 1 : 0});
          }
        }

        const externalEndMonth = range?.[1]?.isValid
          ? range[1]
          : externalStartMonth.plus({month: 1});
        setMonthRange([externalStartMonth, externalEndMonth]);
      }
    }, [range, mode, startRangeWithCurrentMonth]);

    useEffect(() => {
      setMonthRange((d) => {
        const [rangeStart, rangeEnd] = internalRange ?? [];
        if (rangeStart && rangeEnd && !rangeStart.hasSame(rangeEnd, 'month')) {
          return [rangeStart, rangeEnd];
        }
        return d;
      });
    }, [internalRange]);
    const handleChange = (d: DateTime, order: 0 | 1) => {
      if (isRangeMode) {
        setInternalRange((range) => {
          const [start, end] = range ?? [];
          if (start && end) {
            const r: TDateRange = [null, null];
            r.splice(order, 1, d);
            return r;
          }
          return insertByOrder(range, d, order);
        });
      } else {
        onChange?.(d);
        onClose();
      }
    };
    const handleChangeMonth = (d: DateTime, order: 0 | 1) => {
      setMonthRange((dt) => {
        const [start, end] = dt;
        const newRange = [...dt];
        newRange[order] = d;
        if (order === 0 && d > end) {
          newRange[1] = d.plus({month: 1});
        }
        if (order === 1 && d < start) {
          newRange[0] = d.minus({month: 1});
        }
        if (newRange[order].hasSame(newRange[1 - order], 'month')) {
          newRange[1 - order] = newRange[1 - order][order ? 'minus' : 'plus']({
            month: 1,
          });
        }
        return newRange as [DateTime, DateTime];
      });
    };

    const handleSubmit = () => {
      if (internalRange?.[0]?.isValid && internalRange?.[1]?.isValid) {
        onChange?.(internalRange as [DateTime, DateTime]);
        onClose();
      } else {
        console.error('[Range selector]. Range is invalid', internalRange);
      }
    };

    const calendar = (
      <CalendarContainer
        twoColumns={isRangeMode}
        style={style}
        sx={(t) => ({
          [t.breakpoints.down('sm')]: {
            width: 235,
            height: 330,
            minHeight: 330,
          },
        })}
        ref={ref}
        {...attrs}
      >
        {!mobile && arrow}
        <Calendar
          reverseYears={reverseYears}
          disabledPast={disabledPast}
          onlyFutureYears={onlyFutureYears}
          disabledFuture={disabledFuture}
          onChange={handleChange}
          date={date}
          range={internalRange}
          css={
            isRangeMode
              ? _`width: 50%; .mobile-layout & {width: 100%}; padding-bottom: 0`
              : undefined
          }
          order={0}
          mode={mode}
          monthDate={start}
          onChangeMonthDate={handleChangeMonth}
          isRangeMode={isRangeMode}
        />
        {isRangeMode && (
          <>
            <Calendar
              disabledFuture={disabledFuture}
              disabledPast={disabledPast}
              onlyFutureYears={onlyFutureYears}
              onChange={handleChange}
              date={date}
              range={internalRange}
              order={1}
              css={
                isRangeMode
                  ? _`width: 50%; .mobile-layout & {width: 100%; display: none}; padding-bottom: 0`
                  : undefined
              }
              mode={'range'}
              monthDate={end}
              onChangeMonthDate={handleChangeMonth}
            />
            <Box
              sx={(t) => ({
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                p: 16,
                [t.breakpoints.down('sm')]: {
                  pt: 5,
                },
              })}
            >
              <Button
                color={'secondary'}
                variant={'outlined'}
                size={'small'}
                css={(t) =>
                  _`border-color: white; color: white; &:hover {border-color: ${t.palette.grey[200]};}`
                }
                onClick={onClose}
                width={ButtonSize.Xs}
              >
                {t('CANCEL')}
              </Button>
              <Button
                size={'small'}
                color={'secondary'}
                variant={'contained'}
                css={(t) =>
                  _`background-color: white; color: ${t.palette.grey[800]}; &:hover {background-color: ${t.palette.grey[200]};}`
                }
                onClick={handleSubmit}
                width={ButtonSize.Xs}
              >
                {t('APPLY')}
              </Button>
            </Box>
          </>
        )}
      </CalendarContainer>
    );

    return mobile ? <MobileContainer>{calendar}</MobileContainer> : calendar;
  },
);
CalendarPicker.displayName = 'CalendarPicker';

export const MobileContainer = styled('div')`
  position: absolute;
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1301;
`;
