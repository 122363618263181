import {useTranslate} from '@src/i18n/useTranslate';
import {envs} from '@src/shared/constants/envs';
import {FC, useMemo} from 'react';

import {SupportMenuView} from './SupportMenu';
import {ISupportMenuItem} from './SupportMenu/SupportMenu';

export const useSupportItems = () => {
  const {t} = useTranslate('common');

  const menuItems: ISupportMenuItem[] = useMemo(() => {
    return [
      {
        label: t('USER_GUIDE'),
        to: envs.PATIENT_USER_GUIDE_URL,
      },
      {
        label: t('CONTACT_SUPPORT'),
        to: envs.PATIENT_CONTACT_SUPPORT_URL,
      },
    ];
  }, [t]);

  return {menuItems};
};

export const SupportMenuContainer: FC = () => {
  const {menuItems} = useSupportItems();
  return (
    <SupportMenuView
      menu={menuItems}
    />
  );
};
