import {Box, Stack, Theme} from '@mui/material';
import {Panel, Product, ResultDetail} from '@src/api';
import {
  Breadcrumbs,
  EmptySearch,
  ITableProps,
  Link,
  Text,
  RowInformation,
  Table,
  PageHeader,
} from '@src/components';
import Grid from '@src/components/Grid';
import {PulseLoader} from '@src/components/PulseLoader';
import {SendResult} from '@src/components/SendResult';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {envs} from '@src/shared/constants/envs';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {
  getAge,
  getFullName,
  dateFormatted,
} from '@src/shared/utils';
import {useEmailForm, useResultDetail} from '@src/store/results/hooks';
import {BoxBody, BoxHeader, WhiteBox, BoxPaddings} from 'components/WhiteBox/WhiteBox';
import React, {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {ExpandableTableRowContainer, UpdateCode} from './ExpandableTableRowContainer';
import {useHeader} from './useHeader';

const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    [t.breakpoints.down('lg')]: {
      gap: 24,
    },
    flexGrow: 1,
  }),
  buttonsContainer: (t: Theme) => ({
    [t.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
  }),
  boxPaddings: {
    padding: {
      xs: '18px 16px',
      md: '24px',
      lg: '24px 36px',
    },
  },
  wBoxContainer: {
    flexGrow: 1,
    pb: {lg: 24},
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxOrderDetails: {
    height: {lg: 226, xxl: 186},
    padding: {
      xs: '6px 12px 6px 16px',
      md: 24,
      lg: '18px 24px',
      xxl: 36,
    },
  },
  wBoxPatientDetails: {
    height: {lg: 226, xxl: 186},
    flexGrow: 1,
    padding: {
      xs: '6px 12px 6px 16px',
      md: 24,
      lg: '18px 24px',
      xxl: 36,
    },
  },
  headerSx: {
    mb: {xs: 24, lg: 36},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
  }),
  rowInformation: {
    mb: {lg: 12},
  },
  boxHeader: {
    height: {lg: 42, xxl: 0},
    mb: 24,
  },
};

export const getResultTitle = (orderDetails: ResultDetail['orderDetails']): string =>
  orderDetails.reported ? dateFormatted(orderDetails.reported) as string : '';

export const Details: React.FC = () => {
  const {t} = useTranslate('details');
  const [testCodes, setTestCodes] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const {mobile, tablet, tabletPortrait, desktop: isDesktop, lg: isDesktopLg, xxl: isDesktopXxl} = useMQuery();
  const [allCodeSelect, setAllCodeSelect] = useState<string[]>([]);
  const [selectHeaderCheckbox, setSelectHeaderCheckbox] = useState(false);
  const {isSidebarOpen} = useSidebarClassObserver();

  const {resultId, companyId} = useParams();
  const navigate = useNavigate();
  const {submit} = useEmailForm();

  const {
    data: result,
    isFetching: resultLoading,
    isLoading: loading,
  } = useResultDetail({resultId, companyId});

  useEffect(() => {
    const allTestCodesInPanels = result?.panels?.map((panel) =>
      panel.products?.flatMap((product) => product.tests.flatMap((test) => test.loinc)),
    );
    const allTestCodesProducts = result?.products?.map((product) =>
      product.tests.flatMap((test) => test.loinc),
    );

    const allTestCodes = [
      ...(allTestCodesInPanels ?? []),
      ...(allTestCodesProducts ?? []),
    ].flat() as string[];

    if (allTestCodes) {
      setAllCodeSelect(allTestCodes);
    }
  }, [result]);

  useEffect(() => {
    if (allCodeSelect.length > 0 && testCodes.length === allCodeSelect.length) {
      setSelectHeaderCheckbox(true);
      setSelectAll(true);
    } else {
      setSelectHeaderCheckbox(false);
      setSelectAll(false);
    }
  }, [testCodes, allCodeSelect]);

  const goToDynamics = () => {
    const params = encodeURI(JSON.stringify([...new Set(testCodes)]));
    navigate(`dynamics/${params}`);
  };

  const changeCodesForDynamics: UpdateCode = (item, checked, flag) => {
    if (typeof item === 'string') {
      if (checked) {
        setTestCodes((prev) => [...prev, item]);
      } else {
        const findCodeIndex = testCodes.indexOf(item);
        setTestCodes((prev) => {
          prev.splice(findCodeIndex, 1);
          return [...prev];
        });
      }
    } else {
      const tests: string[] = flag
        ? item.flat().map((t) => t.code)
        : (item as any).map((t: any) => t.code);

      if (checked) {
        setTestCodes((prev) => [...new Set([...prev, ...tests])]);
      } else {
        setTestCodes((prev) => {
          return prev.filter((t) => {
            const findIndex = tests.indexOf(t);
            if (findIndex >= 0) {
              tests.splice(findIndex, 1);
            }
            return findIndex < 0;
          });
        });
      }
    }
  };

  const handleSendResult = (email: string) => {
    submit({resultId, email});
  };

  const changeAllTests = (_: any, checked: any) => {
    if (checked) {
      setSelectAll(true);
      setTestCodes([...allCodeSelect]);
    } else {
      setSelectAll(false);
      setTestCodes([]);
    }
  };

  const {HEADER_LIST} = useHeader(changeAllTests, selectHeaderCheckbox);

  const desktopGridColumns = '285px auto 100px 160px 140px';
  const desktopLgGridColumns = '285px minmax(211px, 1fr) 100px 160px 140px';
  const desktopLgOpenSidebarGridColumns = '285px minmax(180px, 1fr) 100px 160px 140px';
  const tabletGridColumns = '28.79% 26.77% 5.55% 12.59% 15.65%';
  const mobileGridColumns = '50px 1fr';

  const gridTemplateColumns = useMemo(() => {
    if (tablet) {
      return tabletGridColumns;
    }

    if (mobile) {
      return mobileGridColumns;
    }

    if (isDesktopLg) {
      return !isSidebarOpen ? desktopLgGridColumns : desktopLgOpenSidebarGridColumns;
    }
    return desktopGridColumns;
  }, [tablet, mobile, isDesktopLg, isSidebarOpen]);

  if (!result || loading) {
    return (
      <Stack sx={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
        <PulseLoader loading={loading} />
      </Stack>
    );
  }

  const {orderDetails, patient, products, panels} = result;
  const {birthDate, birthSex, firstName, lastName} = patient;
  const {collected, number, provider, reported} = orderDetails;

  return (
    <Box sx={sx.mainContainer}>
      <Stack gap={24}>
        <Breadcrumbs>
          <Link to={ROUTERS_PATH.RESULTS}>{t('RESULTS')}</Link>
          <Text sx={sx.breadcrumbsText}>{getResultTitle(orderDetails)}</Text>
        </Breadcrumbs>
        <PageHeader itemAction={<SendResult onSubmit={handleSendResult} />}>
          {getResultTitle(orderDetails)}
        </PageHeader>
      </Stack>
      <Grid container spacing={{lg: '36px', xs: '24px'}}>
        <Grid xs={12} md={6}>
          <WhiteBox
            notApplySx
            collapsible={mobile || tabletPortrait}
            sx={sx.wBoxPatientDetails}
            id={'boxOne'}
            headerSx={sx.headerSx}
            headerMargin="24px"
            hasIconBox
            header={mobile || tabletPortrait || isDesktopXxl ? t('PATIENT_DETAILS') : ''}
          >
            {(!mobile && !tabletPortrait && !isDesktopXxl) && <BoxHeader sx={sx.boxHeader}>{t('PATIENT_DETAILS')}</BoxHeader>}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={isDesktop ? 18 : 0}>
                <Grid xs={12} xxl={6}>
                  <Grid>
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={firstName}
                      name={t('FIRST_NAME')} />
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={lastName}
                      name={t('LAST_NAME')} />
                  </Grid>
                </Grid>
                <Grid xs={12} xxl={6}>
                  <Grid>
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={birthDate ? `${dateFormatted(birthDate) || ''} (${getAge(birthDate)} ${t('YEARS')})` : null}
                      name={t('DATE_OF_BIRTH')}
                    />
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      noMargin={!mobile && !tabletPortrait}
                      tooltip
                      value={t(birthSex)}
                      name={t('BIRTH_SEX')} />
                  </Grid>
                </Grid>
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
        <Grid xs={12} md={6} display="flex">
          <WhiteBox
            notApplySx
            sx={sx.wBoxOrderDetails}
            collapsible={mobile || tabletPortrait}
            id={'boxTwo'}
            headerMargin="24px"
            headerSx={sx.headerSx}
            hasIconBox
            header={mobile || tabletPortrait || isDesktopXxl ? t('ORDER_DETAILS') : ''}
          >
            {(!mobile && !tabletPortrait && !isDesktopXxl) && <BoxHeader sx={sx.boxHeader}>{t('ORDER_DETAILS')}</BoxHeader>}
            <BoxBody>
              <Grid container rowSpacing={0} columnSpacing={isDesktop ? 18 : 0}>
                <Grid xs={12} xxl={6}>
                  <Grid>
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={number.toString()}
                      name={t('ACCESSION_SPECIMIEN')} />
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={dateFormatted(collected)}
                      name={t('COLLECTION_DATE')}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} xxl={6}>
                  <Grid>
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      sx={sx.rowInformation}
                      noMargin={isDesktop}
                      tooltip
                      value={dateFormatted(reported)}
                      name={t('REPORTED_DATE')}
                    />
                    <RowInformation
                      variant={isDesktop ? '16_20_700' : '14_18_700'}
                      valueTypographyVariant={isDesktop ? '16_20_500' : '14_18_500'}
                      noMargin={!mobile && !tabletPortrait}
                      tooltip
                      value={getFullName(provider)}
                      name={t('ORDERING_PROVIDER')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </BoxBody>
          </WhiteBox>
        </Grid>
      </Grid>
      <WhiteBox noPaddings sx={sx.wBoxContainer}>
        <BoxBody>
          <BoxPaddings sx={sx.boxPaddings}>
            <Box sx={sx.boxContainer}>
              <BoxHeader>{t('RESULTS')}</BoxHeader>
              {!mobile && !tabletPortrait && (
                <Box sx={sx.bthContainer}>
                  <Button
                    variant="text"
                    href={`${envs.BASE_API}/api/results/${resultId ?? ''}/fn/download?companyId=${companyId ?? ''
                    }`}
                    target="_blank"
                  >
                    {t('DOWNLOAD_RESULTS')}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={goToDynamics}
                    disabled={testCodes.length === 0}
                  >
                    {t('DYNAMICS_OF_RESULTS')}
                  </Button>
                </Box>
              )}
            </Box>
          </BoxPaddings>
          <Table
            paddingLeft={isDesktop ? '28px' : '18px'}
            header={!mobile && !tabletPortrait}
            keyIndicator="code"
            data={([...products, ...panels] as ITableProps<Product & Panel>['data']) || []}
            gridTemplateColumns={gridTemplateColumns}
            headerList={HEADER_LIST}
            rowComponent={{
              component: ExpandableTableRowContainer,
              props: {
                changeCodesForDynamics,
                testCodes,
                selectAll,
                selectRest: selectHeaderCheckbox,
                gridTemplateColumns,
              },
            }}
            loading={resultLoading}
            emptyData={<EmptySearch isLoading={loading} />}
            minHeight={1565}
            isModifiedScroll
          />
        </BoxBody>
        {(mobile || tabletPortrait) && (
          <BoxPaddings paddings="36px 16px 24px 16px">
            <Box sx={sx.buttonsContainer}>
              <Button
                fullWidth
                variant="contained"
                onClick={goToDynamics}
                disabled={testCodes.length === 0}
              >
                {t('DYNAMICS_OF_RESULTS')}
              </Button>
              <Button
                fullWidth
                variant="outlined"
                href={`${envs.BASE_API}/api/results/${resultId ?? ''}/fn/download?companyId=${companyId ?? ''
                }`}
                target="_blank"
              >
                {t('DOWNLOAD_RESULTS')}
              </Button>
            </Box>
          </BoxPaddings>
        )}
      </WhiteBox>
    </Box>
  );
};
