/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {css as _, Box, Button, Typography} from '@mui/material';
import {CalendarType} from '@src/components/form/DateControlDeprecated/DateInput';
import {DatesArea} from '@src/components/form/DateControlDeprecated/DateInput/CalendarPicker/components/DatesArea';
import {MonthsSelectorArea} from '@src/components/form/DateControlDeprecated/DateInput/CalendarPicker/components/MonthsSelectorArea';
import {YearSelectorArea} from '@src/components/form/DateControlDeprecated/DateInput/CalendarPicker/components/YearSelectorArea';
import {CalendarContainer} from '@src/components/form/DateControlDeprecated/DateInput/styles';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {TKeys, useTranslate} from 'i18n/useTranslate';
import {DateTime} from 'luxon';
import {CSSProperties, FC, forwardRef, useCallback, useEffect, useMemo, useState} from 'react';

import {YearMonthHeader} from './YearMonthHeader';
const insertByOrder = (r: [start?: DateTime | null, end?: DateTime | null] = [], date: DateTime, order: 0 | 1) => {
  let range = [...r];
  if (!range.length || (range.length === 1 && range[0] == null) || (!range[0] && !range[1])) {
    range = [null, null];
    range.splice(order, 1, date);
    return range as DateRange;
  }
  if (range.length === 1) {
    range.splice(order, 0, date);
  }
  if (range[0] && range[1]) {
    range[order] = date;
  }

  if (range[order] && !range[1 - order]) {
    range[1 - order] = date;
  } else {
    range[order] = date;
  }

  if (range[0] && range[1] && (range[0] > range[1])) {
    return range.reverse() as DateRange;
  }

  return range as DateRange;
};

export type DateRange = [start: DateTime | null, end: DateTime | null];
interface CalendarPickerProps {
  type?: CalendarType
  style: CSSProperties
  date?: DateTime
  arrow?: JSX.Element
  attrs: any
  onClose: () => void
  onBlur?: () => void
  range?: DateRange
  onChange?: (date: DateTime | [DateTime, DateTime]) => void
}
export const CalendarPicker = forwardRef<HTMLDivElement, CalendarPickerProps>((props, ref) => {
  const {mobile, tabletPortrait} = useMQuery();
  const {date, style, arrow, attrs, range, type, onChange, onClose} = props;
  const [internalRange, setInternalRange] = useState(type === 'range' ? props.range : undefined);
  const isRangeMode = type === 'range';
  const {t} = useTranslate('common');
  const initialStartMonth = isRangeMode
    ? range?.[0]?.isValid
      ? range[0]
      : range?.[1]?.isValid
        ? range[1].minus({month: 1})
        : DateTime.now()
    : date ?? DateTime.now();
  const initialEndMonth = range?.[1]?.isValid && (range[0]?.isValid && !range[0]?.hasSame(range[1], 'month'))
    ? range[1]
    : initialStartMonth.plus({month: 1});
  const [[start, end], setMonthRange] = useState<[DateTime, DateTime]>([initialStartMonth, initialEndMonth]);
  useEffect(() => {
    if (type === 'range') {
      setInternalRange(range);
      const externalStartMonth = range?.[0]?.isValid ? range[0] : range?.[1]?.isValid ? range[1].minus({month: 1}) : DateTime.now();
      const externalEndMonth = range?.[1]?.isValid && (range[0]?.isValid && !range[0]?.hasSame(range[1], 'month')) ? range[1] : externalStartMonth.plus({month: 1});
      setMonthRange([externalStartMonth, externalEndMonth]);
    }
  }, [range, type]);

  useEffect(() => {
    setMonthRange((d) => {
      const [rangeStart, rangeEnd] = internalRange ?? [];
      if (rangeStart && rangeEnd && !rangeStart.hasSame(rangeEnd, 'month')) {
        return [rangeStart, rangeEnd];
      }
      return d;
    });
  }, [internalRange]);
  const handleChange = (d: DateTime, order: 0 | 1) => {
    if (isRangeMode) {
      setInternalRange((range) => {
        const [start, end] = range ?? [];
        if (start && end) {
          const r: DateRange = [null, null];
          r.splice(order, 1, d);
          return r;
        }
        return insertByOrder(range, d, order);
      });
    } else {
      onChange?.(d);
      onClose();
    }
  };
  const handleChangeMonth = (d: DateTime, order: 0 | 1) => {
    setMonthRange((dt) => {
      const [start, end] = dt;
      const newRange = [...dt];
      newRange[order] = d;
      if (order === 0 && d > end) {
        newRange[1] = d.plus({month: 1});
      }
      if (order === 1 && d < start) {
        newRange[0] = d.minus({month: 1});
      }
      if (newRange[order].hasSame(newRange[1 - order], 'month')) {
        newRange[1 - order] = newRange[1 - order][order ? 'minus' : 'plus']({month: 1});
      }
      return newRange as [DateTime, DateTime];
    });
  };

  const handleSubmit = () => {
    if (internalRange?.[0]?.isValid && internalRange?.[1]?.isValid) {
      onChange?.(internalRange as [DateTime, DateTime]);
      onClose();
    } else {
      console.error('[Range selector]. Range is invalid', internalRange);
    }
  };

  const calendar = (
    <CalendarContainer
      twoColumns={isRangeMode}
      style={style}
      ref={ref}
      {...attrs}>
      {!mobile && arrow}
      <Calendar
        onChange={handleChange}
        date={date}
        range={internalRange}
        css={isRangeMode ? _`width: 50%; .mobile-layout & {width: 100%}; padding-bottom: 0` : undefined}
        order={0}
        mode={type}
        monthDate={start}
        onChangeMonthDate={handleChangeMonth}
      />
      {isRangeMode && (
        <>
          <Calendar
            onChange={handleChange}
            date={date}
            range={internalRange}
            order={1}
            css={isRangeMode ? _`width: 50%; .mobile-layout & {width: 100%}; padding-bottom: 0` : undefined}
            mode={'range'}
            monthDate={end}
            onChangeMonthDate={handleChangeMonth}
          />
          <div css={_`width: 100%; display: flex; justify-content: space-between; padding: 24px;`}>
            <Button
              color={'secondary'}
              variant={'outlined'}
              css={t => _`border-color: white; color: white; &:hover {border-color: ${t.palette.grey[200]};}`}
              onClick={onClose}
            >
              {t('CANCEL')}
            </Button>
            <Button
              color={'secondary'}
              variant={'contained'}
              css={t => _`background-color: white; color: ${t.palette.grey[800]}; &:hover {background-color: ${t.palette.grey[200]};}`}
              onClick={handleSubmit}
            >
              {t('SET_DATE')}
            </Button>
          </div>
        </>
      )}
      {(mobile || tabletPortrait) && (
        <div css={_`width: 100%; display: flex; flex-direction: column; justify-content: space-between; padding: 24px; gap: 10px;`}>
          <Button
            color={'secondary'}
            variant={'contained'}
            css={t => _`background-color: white; color: ${t.palette.grey[800]}; &:hover {background-color: ${t.palette.grey[200]};}`}
            onClick={handleSubmit}
          >
            {t('APPLY')}
          </Button>
          <Button
            color={'secondary'}
            variant={'outlined'}
            css={t => _`border-color: white; color: white; &:hover {border-color: ${t.palette.grey[200]};}`}
            onClick={onClose}
          >
            {t('CANCEL')}
          </Button></div>
      )}
    </CalendarContainer>
  );

  return mobile || tabletPortrait
    ? (
      <MobileContainer>
        {calendar}
      </MobileContainer>
    )
    : (
      calendar
    );
});
CalendarPicker.displayName = 'CalendarPicker';

const MobileContainer = styled.div`
  top: 10%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1301;
`;

const PaddingsContainer = styled('div')`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
interface CalendarProps {
  onChange?: (d: DateTime, order: 0 | 1) => void
  date?: DateTime
  range?: DateRange
  order?: 0 | 1
  className?: string
  mode?: 'range' | 'calendar'
  monthDate: DateTime
  onChangeMonthDate: (d: DateTime, order: 0 | 1) => void
}
const Calendar: FC<CalendarProps> = ({onChange, date, range, order = 0, className, mode, monthDate, onChangeMonthDate}) => {
  const [selectorType, setSelectorType] = useState<'day' | 'month' | 'year'>('day');
  const {t} = useTranslate('common');
  const handleClick = useCallback((d: DateTime) => {
    if (mode === 'range') {
      onChange?.(d, order);
    } else {
      window.setTimeout(() => {
        onChange?.(d, order);
      }, 100);
    }
  }, [mode, onChange, order]);
  const handleChangeDate = () => {
    setSelectorType((t) => {
      switch (t) {
        case 'day':
          return 'month';
        case 'month':
          return 'year';
        case 'year':
          return 'day';
      }
    });
  };
  const header = useMemo(() => {
    switch (selectorType) {
      case 'day':
        return (
          <Box sx={{display: 'flex'}}>
            <Typography sx={{display: 'flex', color: 'secondary.main'}} variant='14_20_500'>
              {monthDate.monthLong}
            </Typography>
            <Typography sx={{paddingLeft: 10}} variant='14_20_500'>{monthDate.day}</Typography>
            <Typography sx={{paddingLeft: 50, color: 'secondary.main'}} variant='14_20_500'>{monthDate.year}</Typography>
          </Box>
        );
      case 'month':
        return monthDate.year;
      case 'year':
        return `${t(monthDate.monthLong.toUpperCase() as TKeys<'common'>)} ${monthDate.year}`;
    }
  }, [monthDate.day, monthDate.monthLong, monthDate.year, selectorType, t]);
  const handleForward = () => {
    const date = monthDate.plus(selectorType === 'day' ? {month: 1} : {year: 1});
    onChangeMonthDate(date, order);
  };
  const handleBack = () => {
    if (selectorType === 'year') {
      setSelectorType('day');
      return;
    }
    const date = monthDate.minus(selectorType === 'day' ? {month: 1} : {year: 1});
    onChangeMonthDate(date, order);
  };

  return (
    <PaddingsContainer css={_`padding-bottom: ${selectorType === 'year' ? 10 : 0}px;`} className={className}>
      <YearMonthHeader
        backOnly={selectorType === 'year'}
        header={header}
        back={handleBack}
        forward={handleForward}
        onHeaderClick={handleChangeDate}
      />
      <div css={_`flex: 1 1 auto; position: relative; padding: 0px 10px`}>
        {selectorType === 'day' && (
          <DatesArea
            onChange={handleClick}
            month={monthDate}
            selected={mode === 'range' ? undefined : date}
            range={range}
          />
        )}
        {selectorType === 'month' && (
          <MonthsSelectorArea
            onChange={d => {
              onChangeMonthDate(d, order);
              setSelectorType('year');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
          />
        )}
        {selectorType === 'year' && (
          <YearSelectorArea
            onChange={d => {
              onChangeMonthDate(d, order);
              setSelectorType('day');
            }}
            date={monthDate}
            selected={mode === 'range' ? range?.[order] : date}
            range={[1900, DateTime.now().year]}
          />
        )}
      </div>
    </PaddingsContainer>
  );
};
