import {Box, Stack, SxProps, Typography} from '@mui/material';
import {Result} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers/const';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {OverviewTable} from './OverviewTable';

const sx: Partial<Record<string, SxProps>> = {
  container: {
    boxSizing: 'border-box',
  },
  topContainer: {
    alignItems: 'center',
    padding: {
      xs: '18px 16px',
      sm: 24,
    },
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  titleContainer: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
      xl: '24_34_500',
    },
  },
  button: {
    fontWeight: 600,
  },
};

export const OverviewLastResults = ({results}: { results: Result[] }) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();
  const {desktop: isDesktop, tabletPortrait: isTabletPortrait, mobile: isMobile} = useMQuery();

  const goToResult = () => {
    navigate(ROUTERS_PATH.RESULTS);
  };
  return (
    <Stack sx={sx.container}>
      <Box sx={sx.topContainer}>
        <Typography component="h3" sx={sx.titleContainer}>
          {t('LAST_RESULTS')}
        </Typography>
        <Button
          sx={sx.button}
          variant={isDesktop ? 'outlined' : 'text'}
          color="primary"
          size={isMobile || isTabletPortrait ? 'xs' : 'sm'}
          onClick={goToResult}>
          {t('VIEW_ALL')}
        </Button>
      </Box>
      <OverviewTable results={results} />
    </Stack>
  );
};
