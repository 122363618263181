import {MenuTypes, StorageFileCategory} from '@api';
import {DatePickerRange} from '@components';
import {
  Box,
  ListItemText,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {AnyAction} from '@reduxjs/toolkit';
import {DateRangeButton} from '@src/components/DateRangeButton';
import {TKeys} from '@src/i18n/useTranslate';
import {ReactComponent as RefreshIcon} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as SettingsIcon} from '@src/shared/assets/images/icons/icon_settings.svg';
import {INITIAL_DATES, INITIAL_FILTERS} from '@src/store/storage/constants';
import {storageActions} from '@src/store/storage/slice';
import {StorageFilters} from '@src/store/storage/types';
import {useAppDispatch, useAppSelector} from '@store';
import {ChangeEvent, FC, MouseEvent, useState} from 'react';
import {IconButton, Option, SearchControl, SelectControl} from 'ui-kit';

import {getCategoryOptions, getMenuTypesOptions} from '../../helpers';
import {useStorageTranslate} from '../../hooks';
import {FiltersDialog} from '../FiltersDialog';

import {FiltersContainer, sx} from './styles';
import {FiltersProps} from './types';

export const Filters: FC<FiltersProps> = ({isSidebarOpen, isScrolled}) => {
  const translate = useStorageTranslate();
  const theme = useTheme();
  const upXxl = useMediaQuery(theme.breakpoints.up('xxl'));
  const betweenLgXl = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const betweenSmLg = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const onlyXs = useMediaQuery(theme.breakpoints.only('xs'));

  const filters = useAppSelector((state) => state.storage.files.filters);
  const dispatch = useAppDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleToggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  const handleDispatchSetFilters = ({
    newState,
    updatedValue,
  }: {
    newState?: StorageFilters
    updatedValue?: { key: keyof StorageFilters, value: any }
  }) => {
    dispatch(storageActions.setFilters({newState, updatedValue}) as unknown as AnyAction);
  };

  const handleChangeCategory = (event: SelectChangeEvent<StorageFileCategory>) => {
    const value = event.target.value as StorageFileCategory;
    handleDispatchSetFilters({newState: {...filters, category: value}});
  };
  const handleChangeDate = (name: keyof StorageFilters) => (date: Date | null) => {
    handleDispatchSetFilters({
      updatedValue: {
        key: name,
        value: date,
      },
    });
  };
  const handleClearDate = () => {
    handleDispatchSetFilters({newState: {...filters, ...INITIAL_DATES}});
  };
  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    handleDispatchSetFilters({
      updatedValue: {
        key: 'phrase',
        value,
      },
    });
  };
  const handleChangeMenu = (_: MouseEvent<HTMLElement>, value: MenuTypes) => {
    handleDispatchSetFilters({updatedValue: {key: 'menu', value}});
  };

  const handleUpdateFilters = (newFilters: StorageFilters) => {
    handleDispatchSetFilters({newState: newFilters});
  };

  const handleClearAllFilters = () => {
    handleDispatchSetFilters({newState: INITIAL_FILTERS});
  };

  const showCategoryDate = !onlyXs && (betweenLgXl ? !isSidebarOpen : !betweenSmLg);

  return (
    <>
      <FiltersContainer isScrolled={isScrolled} isSidebarOpen={isSidebarOpen}>
        {showCategoryDate && (
          <Stack sx={sx.categoryDateContainer}>
            <Box sx={sx.categoryWrapper}>
              <SelectControl
                label={translate('CATEGORY')}
                placeholder={translate('SELECT')}
                compact={false}
                value={filters.category || ''}
                onChange={handleChangeCategory}
                renderValue={(val: string) => translate(val as TKeys<'storage'>)}
              >
                {getCategoryOptions(translate).map((option) => (
                  <Option key={option.label} value={option.value}>
                    <ListItemText primary={option.label} />
                  </Option>
                ))}
              </SelectControl>
            </Box>
            {!upXxl && (
              <DateRangeButton
                startDate={filters.from}
                endDate={filters.to}
                setStartDate={handleChangeDate('from')}
                setEndDate={handleChangeDate('to')}
                clearDate={handleClearDate}
              />
            )}
            {upXxl && (
              <Box sx={sx.dateRangeWrapper}>
                <DatePickerRange
                  sx={{mb: '0 !important'}}
                  isIconVisible={false}
                  variant="outlined"
                  label={translate('DATE_RANGE')}
                  startDate={filters.from}
                  endDate={filters.to}
                  setStartDate={handleChangeDate('from')}
                  setEndDate={handleChangeDate('to')}
                  clearDate={handleClearDate}
                  labelLeft={true}
                />
              </Box>
            )}
          </Stack>
        )}
        <Stack sx={sx.searchToggleContainer}>
          <Box sx={sx.searchWrapper}>
            <SearchControl
              placeholder={translate('SEARCH')}
              value={filters.phrase}
              onChange={handleChangeSearch}
            />
            {onlyXs && (
              <IconButton color={'secondary'} variant={'outlined'} onClick={handleToggleDialog}>
                <SettingsIcon />
              </IconButton>
            )}
          </Box>
          <ToggleButtonGroup
            color={'secondary'}
            size={'small'}
            value={filters.menu}
            exclusive
            onChange={handleChangeMenu}
          >
            {getMenuTypesOptions(translate).map((option) => (
              <ToggleButton sx={sx.toggleButton} key={option.value} value={option.value}>
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
        {!onlyXs &&
          (showCategoryDate
            ? (
              <IconButton strokeIcon color={'secondary-light'} onClick={handleClearAllFilters}>
                <RefreshIcon />
              </IconButton>
            )
            : (
              <IconButton color={'secondary'} variant={'outlined'} onClick={handleToggleDialog}>
                <SettingsIcon />
              </IconButton>
            ))}
      </FiltersContainer>
      <FiltersDialog
        filters={filters}
        onUpdateFilters={handleUpdateFilters}
        onClose={handleToggleDialog}
        isOpen={isDialogOpen}
      />
    </>
  );
};
