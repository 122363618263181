import {Box, Stack, Typography} from '@mui/material';
import {RelationProfile} from '@src/api/relations';
import Grid from '@src/components/Grid';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';

import {RenderEmails, RenderPhones, hasMainItems, hasOptionalItems} from '../../../helpers/RenderPhonesAndEmails';
import {isEmptyMainContainer} from '../../../helpers/checkingForEmptyContainer';

import {sx} from './styles';

export const RelationContactsWithoutAccess = ({relation}: { relation: RelationProfile | null }) => {
  const {t} = useTranslate('connections');
  const {mobile} = useMQuery();

  const isPhone = relation?.phones?.length;
  const isEmail = relation?.emails?.length;

  return (
    <>
      <Stack sx={sx.container}>
        <Stack sx={sx.upperBlockContainer}>
          <Box>
            <Grid container columnSpacing={36} gap={mobile ? 36 : 0}>
              <Grid
                gap={24}
                xs={12}
                lg={6}
                sm={6}
                md={6}
                xl={6}>
                <Stack>
                  <Typography component='h4' sx={sx.mainTitle}>{t('PHONE_LIST')}</Typography>
                  {
                    isPhone
                      ? (
                        <>
                          <Stack>
                            {hasMainItems(relation?.phones) && (
                              <Typography variant='18_24_500' pb={30}>{t('MAIN')}</Typography>
                            )}
                            <RenderPhones phones={relation?.phones} isMainPhone />
                          </Stack>
                          <Stack>
                            {
                              hasOptionalItems(relation?.phones) && (
                                <Typography variant='18_24_500' mt={isEmptyMainContainer({items: relation?.phones}) ? 36 : 0} pb={30}>{t('OPTIONAL')}</Typography>
                              )}
                            <RenderPhones phones={relation?.phones} isMainPhone={false} />
                          </Stack>
                        </>
                      )

                      : (
                        <Typography sx={sx.notSpecifiedText}>
                          {t('NOT_SPECIFIED')}
                        </Typography>
                      )
                  }
                </Stack>
              </Grid>
              <Grid
                xs={12}
                lg={6}
                sm={6}
                md={6}
                xl={6}>
                <Stack>
                  <Typography
                    component='h4'
                    sx={sx.mainTitle}
                    mb={36}>{t('EMAIL_LIST')}</Typography>
                  {
                    isEmail
                      ? (
                        <>
                          <Stack>
                            {hasMainItems(relation?.emails) && (
                              <Typography variant='18_24_500' pb={30}>{t('MAIN')}</Typography>
                            )}
                            <RenderEmails emails={relation?.emails} isMainEmail />
                          </Stack>
                          <Stack>
                            {
                              hasOptionalItems(relation?.emails) && (
                                <Typography variant='18_24_500' mt={isEmptyMainContainer({items: relation?.emails}) ? 36 : 0} pb={30}>{t('OPTIONAL')}</Typography>
                              )}
                            <RenderEmails emails={relation?.emails} isMainEmail={false} />
                          </Stack>
                        </>
                      )
                      : (
                        <Typography sx={sx.notSpecifiedText}>
                          {t('NOT_SPECIFIED')}
                        </Typography>
                      )
                  }
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};
