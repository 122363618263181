import {Box, Stack, Typography} from '@mui/material';
import {RelationProfile} from '@src/api/relations';
import {RowInformation} from '@src/components';
import Grid from '@src/components/Grid';
import {ListWrapper} from '@src/components/ListWrapper';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as InfoIcon} from '@src/shared/assets/icons/info-without-background.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getName} from '@src/shared/utils/getName';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {RenderEmails, RenderPhones, hasMainItems, hasOptionalItems} from '../../../helpers/RenderPhonesAndEmails';
import {isEmptyMainContainer} from '../../../helpers/checkingForEmptyContainer';
import {useHandleClickButton} from '../hooks';

import {sx} from './styles';

export const RelationContactsWithAccess = ({relation}: { relation: RelationProfile | null }) => {
  const {t} = useTranslate('connections');

  const {id} = useParams();
  const {mobile: isMobile} = useMQuery();
  const handleClickButton = useHandleClickButton(id);

  const isPhone = relation?.phones?.length;
  const isEmail = relation?.emails?.length;

  return (
    <>
      <Stack sx={sx.upperBlockContainer}>
        <Box sx={sx.listContainer}>
          <Grid container columnSpacing={24} gap={isMobile ? 36 : 0}>
            <Grid
              gap={24}
              xs={12}
              lg={6}
              sm={6}
              md={6}
              xl={6}>
              <Stack>
                <Typography
                  component='h4'
                  sx={sx.mainTitle}
                  mb={36}>{t('PHONE_LIST')}</Typography>
                {
                  isPhone
                    ? (
                      <>
                        <Stack>
                          {hasMainItems(relation?.phones) && (
                            <Typography variant='18_24_500' pb={30}>{t('MAIN')}</Typography>
                          )}
                          <RenderPhones phones={relation?.phones} isMainPhone />
                        </Stack>
                        <Stack>
                          {
                            hasOptionalItems(relation?.phones) && (
                              <Typography variant='18_24_500' mt={isEmptyMainContainer({items: relation?.phones}) ? 36 : 0} pb={30}>{t('OPTIONAL')}</Typography>
                            )}
                          <RenderPhones phones={relation?.phones} isMainPhone={false} />
                        </Stack>
                      </>
                    )

                    : (
                      <Typography sx={sx.notSpecifiedText} pb={12}>
                        {t('NOT_SPECIFIED')}
                      </Typography>
                    )
                }
              </Stack>
            </Grid>
            <Grid
              xs={12}
              lg={6}
              sm={6}
              md={6}
              xl={6}>
              <Stack>
                <Typography component='h4' sx={sx.mainTitle} mb={36}>
                  {t('EMAIL_LIST')}
                </Typography>
                {
                  isEmail
                    ? (
                      <>
                        <Stack>
                          {hasMainItems(relation?.emails) && (
                            <Typography variant='18_24_500' pb={30}>{t('MAIN')}</Typography>
                          )}
                          <RenderEmails emails={relation?.emails} isMainEmail />
                        </Stack>
                        <Stack>
                          {
                            hasOptionalItems(relation?.emails) && (
                              <Typography
                                variant='18_24_500'
                                mt={isEmptyMainContainer({items: relation?.emails}) ? 36 : 0}
                                pb={30}>
                                {t('OPTIONAL')}
                              </Typography>
                            )}
                          <RenderEmails emails={relation?.emails} isMainEmail={false} />
                        </Stack>
                      </>
                    )
                    : (
                      <Typography sx={sx.notSpecifiedText} pb={12}>
                        {t('NOT_SPECIFIED')}
                      </Typography>
                    )
                }

              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={sx.boxInfoContainer}>
          <InfoIcon />
          <Typography variant='14_18_500'>
            {t('YOU_CANT_EDIT_THIS_INFORMATION', {name: getName(relation?.contact, {short: true})})}
          </Typography>
        </Box>
      </Stack>
      <Stack sx={sx.loverBlockContainer}>
        <Stack sx={sx.addressContainer}>
          <Typography
            variant='24_28_400'
            component='h4'
            sx={sx.mainTitle}
            mb={24}>
            {t('ADDRESS')}
          </Typography>
          {
            isMobile && (
              <Button
                size='xs'
                variant='outlined'
                color='primary'
                onClick={handleClickButton}>
                {
                  relation?.actualAddresses?.main
                    ? t('EDIT')
                    : t('ADD')
                }
              </Button>
            )}
        </Stack>
        {
          relation?.actualAddresses?.main
            ? (
              <ListWrapper padding={isMobile ? '12px' : '16px'} noLast={{xl: 0, md: 36, lg: 0, xs: 0}}>
                <RowInformation
                  tooltip
                  value={t(relation?.actualAddresses?.state as TKeys<'common'>)}
                  noMargin
                  name={t('STATE')} />
                <RowInformation
                  tooltip
                  value={relation?.actualAddresses.city}
                  noMargin
                  name={t('CITY')} />
                <RowInformation
                  tooltip
                  value={relation?.actualAddresses.postalCode}
                  noMargin
                  name={t('ZIP_CODE')} />
                <RowInformation
                  tooltip
                  value={relation?.actualAddresses.main}
                  noMargin
                  name={t('ADDRESS_1')} />
                <RowInformation
                  tooltip
                  value={relation?.actualAddresses.additional}
                  noMargin
                  name={t('ADDRESS_2')} />
              </ListWrapper>
            )
            : (
              <Typography sx={sx.notSpecifiedText}>
                {t('NOT_SPECIFIED')}
              </Typography>
            )}
      </Stack>
    </>
  );
};
