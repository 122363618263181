import {Box, MenuItem, Typography} from '@mui/material';
import {MobileInteractionView} from '@src/components/MobileInteractionView';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as Selected} from '@src/shared/assets/icons/selected.svg';
import {FC, useEffect, useState} from 'react';
import {Button} from 'ui-kit';

import {sx} from '../styles';
import {IOption} from '../types';

import {IMobileSelectProps} from './types';

export const MobileSelect: FC<IMobileSelectProps> = ({
  label,
  onClose,
  onChangeMobile,
  options,
  value,
  name,
  onTouch,
  searchInput,
  onBack,
}) => {
  const {t} = useTranslate('common');
  const [tempSelected, setTempSelected] = useState(value);

  const handleSelectOption = (newValue: string) => {
    setTempSelected(newValue);
  };

  const handleApply = () => {
    onChangeMobile?.({name, value: tempSelected});
    onBack();
  };

  useEffect(() => {
    onTouch?.();
  }, [onTouch]);

  return (
    <MobileInteractionView
      isOpen
      title={label || ''}
      onClose={onClose}
      onBack={onBack}
    >
      {searchInput}
      <Box sx={sx.optionsContainer}>
        {options.map((option: IOption | any, index: number) => (
          <MenuItem
            value={option.name}
            key={option?.key ?? index}
            selected={false}
            placeholder="Select"
            sx={sx.selectItem}
            disableRipple
            disableTouchRipple
            onClick={() => handleSelectOption(option.name)}
          >
            {tempSelected === t(option.name as TKeys<'common'>) ||
              t(tempSelected as TKeys<'common'>) === t(option.name as TKeys<'common'>)
              ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="14_18_500">{t(option.name as TKeys<'common'>)}</Typography>
                  <Selected />
                </Box>
              )
              : (
                <Typography variant="14_18_500">{t(option.name as TKeys<'common'>)}</Typography>
              )}
          </MenuItem>
        ))}
      </Box>
      <Box sx={sx.buttonContainer}>
        <Button color={'secondary'} onClick={handleApply} fullWidth>
          Apply
        </Button>
      </Box>
    </MobileInteractionView>
  );
};
