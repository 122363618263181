// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks';
import Grid from '@src/components/Grid';
import {FC} from 'react';
import {DatePickerRange, SelectLabels} from '@src/components';
import {IFilterResults} from '@src/pages/Results/Results.constant';
import {Button} from 'ui-kit';
import {getPhysiciansProps} from '../types';

interface ResultsFilterDialogProps {
  selectItem: string
  filter: IFilterResults
  filterOpened: boolean
  setFilterOpened: (value: boolean) => void
  changeSelectPhysicians: (event: SelectChangeEvent<string>) => void
  getPhysicians: getPhysiciansProps[]
  changeDate: (value: string) => void
  onClearReportDate: () => void
  onClearCollectionDate: () => void
  onClear: () => void
  onApply: () => void
}

export const ResultsFilterDialog: FC<ResultsFilterDialogProps> = ({filterOpened, setFilterOpened, changeSelectPhysicians, getPhysicians, changeDate, onClearReportDate, onClearCollectionDate, onClear, onApply, filter, selectItem}) => {
  const {t} = useTranslate('results');
  const {desktop} = useMQuery();

  return (
    <Dialog open={filterOpened} size={'lg'} border={'0px'}>
      <DialogTitle onClose={() => setFilterOpened(false)}>{t('FILTERS')}</DialogTitle>
      <DialogContent
        css={_`&.MuiDialogContent-root {padding-bottom: 36px}; label, .MuiTextField-root, .MuiFormControlLabel-root > div {width: 100%; max-width: 100%;}`}
      >
        <Grid
          container={!desktop}
          spacing={24}
          rowSpacing={24}
        >
          <Grid xs={12} sm={6} pb={desktop ? 24 : 0}>
            {desktop && <Typography variant='14_18_700'>{t('PHYSICIAN')}</Typography>}
            <SelectLabels
              value={selectItem}
              placeholder={t('SELECT')}
              onChange={changeSelectPhysicians}
              options={getPhysicians || []}
              label={!desktop ? t('PHYSICIAN') : ''}
            />
          </Grid>
          <Grid xs={12} sm={6} pb={desktop ? 24 : 0}>
            {desktop && <Typography variant='14_18_700'>{t('REPORT_DATE')}</Typography>}
            <DatePickerRange
              label={!desktop ? t('REPORT_DATE') : ''}
              variant="outlined"
              startDate={filter.reportDateFrom}
              setStartDate={changeDate('reportDateFrom')}
              setEndDate={changeDate('reportDateTo')}
              endDate={filter.reportDateTo}
              css={_`width: 100%; margin-bottom: 0 !important`}
              clearDate={onClearReportDate}
              isLabelOutside={true}
              isIconVisible={false}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            {desktop && <Typography variant='14_18_700'>{t('COLLECTION_DATE')}</Typography>}
            <DatePickerRange
              label={!desktop ? t('COLLECTION_DATE') : ''}
              variant="outlined"
              startDate={filter.collectionDateFrom}
              setStartDate={changeDate('collectionDateFrom')}
              setEndDate={changeDate('collectionDateTo')}
              endDate={filter.collectionDateTo}
              sx={{backgroundColor: 'black'}}
              css={_`width: 100%; margin-bottom: 0 !important`}
              clearDate={onClearCollectionDate}
              isLabelOutside={false}
              isIconVisible={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between !important',
          marginTop: 0,
          '.tablet-layout &': {marginBottom: 36},
          '.mobile-layout &': {marginBottom: 48},
        }}
      >
        {desktop && (
          <Button
            sx={{minWidth: 0, p: 5}}
            variant="text"
            color="secondary"
            onClick={() => setFilterOpened(false)}>
            {t('CANCEL')}
          </Button>
        )}
        <Stack flexDirection={'row'} gap={24} width={!desktop ? '100%' : 'auto'}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => {
              setFilterOpened(false);
              onApply();
            }}
            sx={{order: desktop ? 2 : 1}}
          >
            {t('APPLY')}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{order: desktop ? 1 : 2}}
            onClick={onClear}>
            {t('CLEAR_ALL')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
