import {Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx = {
  mainContainer: (t: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 36,
    [t.breakpoints.down('lg')]: {
      gap: 24,
    },
    flexGrow: 1,
    boxSizing: 'border-box',
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    [t.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      border: 'unset',
    },
    [t.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      border: 'unset',
    },
  }),
  topContainer: (t: Theme) => ({
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 'initial',
    [t.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'initial',
      gap: 24,
    },
  }),
  buttonsContainer: (t: Theme) => ({
    [t.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
  }),
  wBoxContainer: {
    flexGrow: 1,
  },
  bthContainer: {
    display: 'flex',
    gap: 36,
  },
  wBoxOrderDetails: {
    padding: {xs: '6px 12px 6px 16px', md: 24},
  },
  wBoxPatientDetails: {
    flexGrow: 1,
    padding: {xs: '6px 12px 6px 16px', md: 24},
  },
  boxPaddings: {
    padding: {xs: '18px 16px', md: '24px'},
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerSx: {
    mb: {xs: 24, lg: 36},
  },
  breadcrumbsText: (t: Theme) => ({
    color: t.colors.breadcrumbsText,
  }),
};
