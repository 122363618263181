import {SxProps, Theme, styled, css as _} from '@mui/material';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: {xs: 24, sm: 36},
    flexGrow: 1,
  },
  whiteBox: (t) => ({
    p: {xs: '18px 16px', sm: 24},
    flexGrow: 1,
    border: {
      sm: 'none',
      xs: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    },
  }),
  container: {
    p: {lg: 24},
  },
  content: {
    gap: {xs: 24, sm: 36},
  },
  insuranceWrapper: {
    gap: 24,
  },
  guarantorWrapper: {
    gap: {xs: 16, sm: 18},
  },
  guarantorContentWrapper: {
    flexDirection: {xs: 'column', sm: 'row'},
    gap: {xs: 12, sm: 24, lg: 36},
  },
  noInsuranceDescription: (t) => ({
    typography: {xs: '12_18_500', lg: '14_18_500'},
    color: t.palette.grey[500],
  }),
  insuranceTitle: {
    typography: {xs: '22_26_500', sm: '24_34_500'},
  },
  contentWrapper: {
    flexGrow: 1,
    gap: {xs: 24, sm: 48, lg: 36},
  },
  addInsuranceButtonOnMobile: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: '16px !important',
  },
  emptyFragment: (t) => ({
    typography: {lg: '14_18_500'},
    color: t.palette.grey[500],
  }),
  noInsuranceContainer: {
    gap: {xs: 24, lg: 30},
  },
  icon: (t) => ({
    width: 42,
    height: 42,
    border: {
      sm: `1px solid ${t.palette.primary.main}`,
      xs: 'none',
    },
    backgroundColor: 'transparent',
    borderRadius: 0,
    color: t.palette.primary.main,
    svg: {
      width: 18,
      height: 18,
      path: {
        fill: 'currentColor',
      },
    },
    ':hover': {
      borderColor: t.palette.primary.light,
      svg: {
        path: {
          fill: t.palette.primary.light,
        },
      },
    },
    ':active': {
      borderColor: t.palette.primary.dark,
      svg: {
        path: {
          fill: t.palette.primary.dark,
        },
      },
    },
  }),
  dialogContent: {pb: 0},
  dialogActions: {flexDirection: 'row !important'},
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noInsurancePlansContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  addInsuranceBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'end',
    mt: {lg: 12},
  },
  listWrapperContainer: {
    flexGrow: 1,
    maxWidth: {lg: 'calc(50% - 18px)'},
  },
  iconBtnsWrapper: {
    flexDirection: 'row',
    gap: 18,
  },
  addInsuranceButtonOnisMobile: {
    mt: {lg: 12},
  },
};

export const AddIconWhite = styled(Add)(
  ({theme}) => _`
  path {
    fill: ${theme.colors.all.white};
  }
`,
);

export const AddIconPrimary = styled(Add)(
  ({theme}) => _`
  path {
    fill: ${theme.palette.primary.main};
  }
  &:hover {
    path {
      fill: ${theme.palette.primary.light};
    }
  }
  &:active {
    path {
      fill: ${theme.palette.primary.dark};
    }
  }
`,
);
