import {Stack} from '@mui/material';
import {PageHeader as PageTitle} from '@src/components';
import {WBox} from '@src/components/WhiteBox';
import {InsuranceDeleteDialog} from '@src/features/InsuranceDeleteDialog';
import {StepDialog} from '@src/features/InsuranceDeleteDialog/types';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {RootState} from '@src/store';
import {useInsurances, useRemoveInsurance} from '@src/store/insurance/hooks';
import {insuranceActions} from '@src/store/insurance/slice';
import {useTranslate} from 'i18n/useTranslate';
import {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button} from 'ui-kit';

import {InsuranceContent} from './InsuranceContent/InsuranceContent';
import {sx} from './styles';

export const Insurance = () => {
  const {t, ready} = useTranslate('insurance');
  const {mobile} = useMQuery();
  const {removeInsurance} = useRemoveInsurance();
  const {healthcareInsurancePolicies, activeHealthcareInsurancesPolicies: activeInsurance} = useInsurances();
  const [deleteInsuranceId, setDeleteInsuranceId] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const step = useSelector((state: RootState) => state.insurance.step);

  const setId = (id: string | undefined) => {
    if (id) {
      setDeleteInsuranceId(id);
      dispatch(insuranceActions.setStep({step: 'open'}));
    }
  };

  const onClose = () => {
    dispatch(insuranceActions.setStep({step: StepDialog.INIT}));
  };

  const onRemove = () => {
    if (deleteInsuranceId) {
      removeInsurance({
        data: {
          deletedHealthcareInsurancePolicyIds: [deleteInsuranceId],
          healthcareInsurancePoliciesCreate: [],
          healthcareInsurancePoliciesEdit: [],
        },
      });
    }
  };

  useScrollToTop();

  return (
    <Stack sx={[...spreadSx(sx.mainContainer), {mb: mobile && (healthcareInsurancePolicies?.length ?? 0) < 2 ? 60 : 0}]}>
      <Stack sx={sx.headerWrapper}>
        <PageTitle height={42}>{ready ? t('INSURANCE') : ''}</PageTitle>
        {!mobile && (activeInsurance?.length ?? 0) < 2 && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<Add />}
            onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
          >
            {t('ADD_INSURANCE')}
          </Button>
        )}
      </Stack>
      <InsuranceContent
        setId={setId}
        activeInsurance={activeInsurance}
      />
      {mobile && (activeInsurance?.length ?? 0) < 2 && (
        <WBox sx={sx.addInsuranceButtonOnMobile}>
          <Button
            color="secondary"
            onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
          >
            {t('ADD_INSURANCE')}
          </Button>
        </WBox>
      )}
      <InsuranceDeleteDialog
        onRemove={onRemove}
        step={step}
        onClose={onClose}
        translate='insurance'
      />
    </Stack>
  );
};
