import {useSelector, useDispatch} from 'react-redux';
import {RootState} from 'store';
import {useEffect, useMemo} from 'react';
import {insuranceActions} from './slice';
import {UUID} from '@src/types';
import {HealthcareInsurancePolicyChangeRequest} from '@src/api/insurances';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {sortInsurances} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';

export const useInsurances = () => {
  const healthcareInsurancePolicies = useSelector((state: RootState) => state.insurance.healthcareInsurancePolicies);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(insuranceActions.requestInsurances());
  }, [dispatch]);

  const sorted = sortInsurances(healthcareInsurancePolicies || []);
  const activeInsurances = sorted?.filter((insurance) => compareDatesWithToday(insurance.endDate, false, true) || insurance.endDate === null);

  return {
    fetching: null,
    healthcareInsurancePolicies: sorted,
    activeHealthcareInsurancesPolicies: activeInsurances,
  };
};

export const useInsuranceCarriers = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      getInsuranceCarriers: (filter: string) => {
        if (!filter) {
          console.error('[useInsuranceCarriers] filter is undefined');
        } else {
          dispatch(insuranceActions.requestInsuranceCarriers({filter}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useInsurancePlans = (insuranceCarrierId: UUID | undefined) => {
  const insurancePlans = useSelector((state: RootState) => state.insurance.insurancePlans);
  const dispatch = useDispatch();
  useEffect(() => {
    if (insuranceCarrierId) {
      dispatch(insuranceActions.requestInsurancePlans({insuranceCarrierId}));
    }
  }, [dispatch, insuranceCarrierId]);
  return {
    fetching: null,
    insurancePlans,
  };
};

export const useCreateInsurance = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      createInsurance: ({data}: {data: HealthcareInsurancePolicyChangeRequest}) => {
        if (!data) {
          console.error('[useCreateInsurance] data is undefined');
        } else {
          dispatch(insuranceActions.createInsurance({data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useEditInsurance = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      editInsurance: ({data}: {data: HealthcareInsurancePolicyChangeRequest}) => {
        if (!data) {
          console.error('[useCreateInsurance] data is undefined');
        } else {
          dispatch(insuranceActions.createInsurance({data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};

export const useRemoveInsurance = () => {
  const dispatch = useDispatch();
  const callbacks = useMemo(() => {
    return {
      removeInsurance: ({data}: {data: HealthcareInsurancePolicyChangeRequest}) => {
        if (!data) {
          console.error('[useCreateInsurance] data is undefined');
        } else {
          dispatch(insuranceActions.deleteInsurance({data}));
        }
      },
    };
  }, [dispatch]);
  return {
    ...callbacks,
  };
};
