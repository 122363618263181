import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  title: {
    mb: 12,
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    mt: {
      sm: 12,
      xs: 18,
    },
  },
  card: {
    padding: {
      xs: 18,
      sm: 24,
    },
    height: {
      xs: 228,
      sm: 242,
    },
    flexDirection: 'row',

  },
  imgContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
    ml: {
      xxl: 24,
      xl: 12,
    },
    width: {
      xxl: 246,
      sm: 164,
    },
    svg: {
      height: 'auto',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'start',
    flex: 1,
  },
  buttonText: {
    typography: {
      xxl: '16_20_600',
      xs: '14_18_600',
    },
  },
  description: {
    typography: {
      xs: '12_16_500',
      sm: '14_18_500',
      xxl: '16_20_500',
    },
  },
  linkButton: {
    pl: 0,
    pb: 0,
    position: 'relative',
    bottom: -6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bmiValue: {
    typography: '36_38_700',
  },
  weightTitle: {
    typography: '18_24_500',
  },
  date: {
    mt: 12,
    color: (theme) => theme.palette.grey[500],
    typography: '14_18_500',
  },
  chartTitle: {
    color: (theme) => theme.palette.grey[500],
  },
  chartContainer: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
    ml: {
      xxl: 24,
      xl: 12,
    },
    width: {
      sm: 246,
      xs: 102,
    },
    svg: {
      height: 'auto',
    },
  },
};
