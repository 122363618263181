import {Box, Typography} from '@mui/material';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC} from 'react';

import {listWrapperPadding} from '../styled';

import {sx} from './styles';
import {IRelationAboutWithoutAccess} from './types';

export const RelationAboutWithoutAccess: FC<IRelationAboutWithoutAccess> = ({relation}) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  return (
    <Box sx={sx.listContainer}>
      <Typography component='h4' sx={sx.title}>{t('PATIENT_INFORMATION')}</Typography>
      <ListWrapper padding={listWrapperPadding(isMobile)} noLast={{xs: 0}}>
        <RowInformation
          tooltip
          value={relation?.contact?.firstName}
          noMargin
          name={t('FIRST_NAME')} />
        <RowInformation
          tooltip
          value={relation?.contact?.lastName}
          noMargin
          name={t('LAST_NAME')} />
        <RowInformation
          tooltip
          value={relation?.contact?.middleName}
          noMargin
          name={t('MIDDLE_NAME')} />
        <RowInformation
          tooltip
          value={relation?.contact?.birthDateS}
          noMargin
          name={t('DATE_OF_BIRTH')} />
        <RowInformation
          tooltip
          value={t(relation?.contact?.birthSex)}
          noMargin
          name={t('BIRTH_SEX')} />
        <RowInformation
          tooltip
          value={relation?.contact?.ssnTail}
          noMargin
          name={t('SSN')} />
      </ListWrapper>
    </Box>
  );
};
