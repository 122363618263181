/** @jsxImportSource @emotion/react */
import {FC, forwardRef, PropsWithChildren, useEffect, useMemo, useRef} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Box, ButtonBase, css as _} from '@mui/material';
import styled from '@emotion/styled';
import {DateTime} from 'luxon';

interface YAProps {
  onChange: (d: DateTime) => void
  date: DateTime
  selected?: DateTime | null
  range: [number, number]
}
export const YearSelectorArea: FC<YAProps> = ({
  date,
  selected,
  onChange,
  range: [start, end],
}) => {
  const years = useMemo(() => {
    return [...Array(end - start + 1)];
  }, [start, end]);
  const selectedRef = useRef<null | HTMLButtonElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const parent: HTMLDivElement | null = selectedRef.current?.parentNode?.parentNode as HTMLDivElement;
    if (parent) {
      parent.scrollTop = (selectedRef.current?.offsetTop ?? 0);
    }
  }, []);

  return (
    <PerfectScrollbar
      css={_`position: absolute; top: 0; left: 0; width: 100%; height: 100%;`}
      options={{swipeEasing: true}}
      containerRef={(e) => {
        containerRef.current = e as HTMLDivElement;
      }}>
      <Box css={_`display: flex; flex-direction: column; align-items: center; gap: 10px;`}>
        {years.map((_, i) => {
          const s = selected?.year === (i + start);
          const scrollTo = selected ? s : date.year === (i + start);
          return (
            <DateButton
              key={i}
              active={s}
              onClick={() => {
                onChange(date.set({year: start + i}));
              }}
              ref={scrollTo ? selectedRef : undefined}
            >
              {start + i}
            </DateButton>
          );
        })}
      </Box>
    </PerfectScrollbar>
  );
};

interface MbProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean
}

export const BtComponent = forwardRef(({
  active: _,
  ...restProps
}: PropsWithChildren<MbProps>, ref) =>
  <ButtonBase {...restProps} ref={ref as any}/>);
BtComponent.displayName = 'DateButton';

export const DateButton = styled(BtComponent)<{ active?: boolean }>(({
  theme,
  active,
}) => _`
  height: 28px;
  min-width: 76px;
  padding: 0 5px;
  font-size: 16px;
  background-color: ${active ? theme.palette.secondary.main : 'transparent'};
  border-radius: 20px;
  color: ${active ? 'white' : theme.palette.grey[300]};
  &:hover {
    color: white;
    background-color: ${theme.palette.grey[600]};
  }
  &:focus {
    border: 1px solid ${theme.colors.all.focus};
  }
`);
