import {
  StorageFile,
} from '@api';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {storageApi} from '@src/api';
import {getError} from '@src/store/common/error-handlers';
import {errorHandler} from '@src/store/errorHandler';
import {notifyActions} from '@src/store/notifications/slice';

import {INITIAL_FILTERS} from './constants';
import {
  DeleteStorageFileProps,
  FetchStorageFilesProps,
  SetFiltersProps,
  StorageState,
} from './types';

const initialState: StorageState = {
  files: {
    hasNext: true,
    items: [],
    page: 0,
    filters: INITIAL_FILTERS,
  },
  isLoading: false,
};

export const fetchStorageFiles = createAsyncThunk(
  'storage/fetchStorageFiles',
  async ({isNext, ...payload}: FetchStorageFilesProps, {rejectWithValue}) => {
    try {
      return {data: await storageApi.getStorageFiles(payload), isNext};
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const updateStorageFile = createAsyncThunk(
  'storage/updateStorageFile',
  async (payload: StorageFile, {rejectWithValue}) => {
    try {
      const response = await storageApi.updateStorageFile(payload);
      return response;
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteStorageFile = createAsyncThunk(
  'storage/deleteStorageFile',
  async (payload: DeleteStorageFileProps, {rejectWithValue, dispatch}) => {
    try {
      await storageApi.deleteStorageFile(payload.id);
      dispatch(notifyActions.showNotifications([{id: payload.id, type: 'success', text: payload.successText}]));
      return payload.id;
    } catch (e) {
      const error = getError(e);
      errorHandler(error);
      return rejectWithValue(error);
    }
  },
);

export const storage = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setFilters: (state, {payload}: PayloadAction<SetFiltersProps>) => {
      if (payload.updatedValue) {
        state.files.filters = {...state.files.filters, [payload.updatedValue.key]: payload.updatedValue.value};
      }
      if (payload.newState) {
        state.files.filters = payload.newState;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStorageFiles.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchStorageFiles.fulfilled, (state, action) => {
      if (action.payload) {
        const {data, isNext} = action.payload;
        state.files = isNext
          ? {
            ...state.files,
            hasNext: data.hasNext,
            page: state.files.page + 1,
            items: [...state.files.items, ...data.items],
          }
          : {...data, filters: state.files.filters, page: 1};
      }
      state.isLoading = false;
    });
    builder.addCase(updateStorageFile.fulfilled, (state, {payload}) => {
      if (payload) {
        state.files.items = state.files.items.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        });
      }
    });
    builder.addCase(deleteStorageFile.fulfilled, (state, {payload}) => {
      if (payload) {
        state.files.items = state.files.items.filter(item => item.id !== payload);
      }
    });
  },
});

export const storageReducer = storage.reducer;
export const storageActions = storage.actions;
export const storageStoreName = storage.name;
