import {Stack, Typography, Box, SxProps, TypographyProps, Theme} from '@mui/material';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Button} from 'ui-kit';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    height: 124,
    padding: {
      xs: 18,
      md: 24,
    },
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    pb: {xs: 36, sm: 24, xxl: 24},
    alignItems: 'center',
  },
};

export const OverviewEmptyFragment = ({title, button, description, onClick, isDisabled = true, variant = '12_16_500'}: { title: string, button?: string, description: string, onClick?: () => void, isDisabled?: boolean, variant?: TypographyProps['variant'] }) => {
  const {mobile: isMobile} = useMQuery();

  return (
    <Stack sx={sx.container}>
      <Box sx={sx.topContainer}>
        <Typography component='h3' variant={isMobile ? '22_26_500' : '24_34_500'}>{title}</Typography>
        {button
          ? (
            <Button
              variant='outlined'
              color='secondary'
              size='sm'
              disabled={isDisabled}
              onClick={onClick}
              startIcon={<Add />}>{button}</Button>
          )
          : null}
      </Box>
      <Typography
        sx={{
          color: (t: Theme) => t.colors.alertColor,
        }}
        variant={variant}>{description}</Typography>
    </Stack>
  );
};
