import {Stack, styled, SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  toggleButton: {
    textTransform: 'unset',
    typography: '14_18_600',
    padding: '10px 16px',
    '&.Mui-selected': {
      border: '2px solid currentColor',
    },
  },
  categoryWrapper: {
    width: {xl: 370, lg: 336},
  },
  dateRangeWrapper: {
    width: 370,
  },
  categoryDateContainer: {
    flexDirection: 'row',
    gap: {
      xxl: 24,
      md: 12,
    },
  },
  searchWrapper: {
    flex: 1,
    display: 'flex',
    gap: 12,
  },
  searchToggleContainer: {
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: 24,
    flex: 1,
  },
};

export const FiltersContainer = styled(Stack)<{ isScrolled: boolean, isSidebarOpen: boolean }>(({theme, isScrolled, isSidebarOpen}) => ({
  flexDirection: 'row',
  gap: 24,
  background: theme.colors.background,
  padding: '24px 36px',
  borderBottom: '1px solid',
  borderColor: theme.colors.borderBlueLight,
  zIndex: 1,
  boxShadow: isScrolled ? '0px 4px 8px 0px #0000001A' : 'unset',
  transition: '0.2s',
  [theme.breakpoints.between('lg', 'xxl')]: {
    gap: 36,
  },
  [theme.breakpoints.only('lg')]: {
    gap: isSidebarOpen ? 24 : 36,
  },
  [theme.breakpoints.between('sm', 'lg')]: {
    padding: 24,
  },
  [theme.breakpoints.down('sm')]: isScrolled
    ? {
      position: 'fixed',
      top: theme.header.height.sm,
      left: 0,
      width: '100%',
      padding: 18,
      height: 78,
      overflow: 'hidden',
    }
    : {
      padding: 18,
    },
}));
