/* eslint-disable no-comments/disallowComments */
/** @jsxImportSource @emotion/react */
import {GlobalStyles, css} from '@mui/material';
import {SupportMenuContainer} from '@src/components/SupportMenu/SupportMenuContainer';
import {ReactComponent as IconSVGHamburger} from '@src/shared/assets/images/icons/icon_hamburger.svg';
import {ReactComponent as IconSVGTimesMenu} from '@src/shared/assets/images/icons/icon_times_thin.svg';
import {envs} from '@src/shared/constants/envs';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {ProfileMenuContainer} from 'features/profile-menu/ProfileMenuContainer';
import {SideMenuContainer} from 'features/side-menu/SideMenuContainer';
import {FC, useEffect, useState, useCallback, PropsWithChildren} from 'react';
import {Cookies} from 'react-cookie';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Link, Outlet} from 'react-router-dom';

import {
  PageBody,
  Header,
  Main,
  GridNavPanel,
  Content,
  GridContainer,
  StyledAppLogo,
  globalStyles,
  MenuLayout,
  IconButton,
  MenuBackdrop,
  FloatMenu,
  BurgerLogoContainer,
} from './styles';

const ACCESS_TOKEN_COOKIE_NAME = 'access_token';

export const MainLayout: FC = () => {
  const {mobile, tablet, desktop} = useMQuery();
  const [menuOpen, setMenuOpen] = useState(false);

  const [isLogin, setIsLogin] = useState(false);

  const checkLogin = () => {
    const accessTokenCookie = new Cookies().get(ACCESS_TOKEN_COOKIE_NAME);
    const url = envs.PASSPORT_WEB_URL;

    if (accessTokenCookie || !url) {
      setIsLogin(true);
      return;
    }

    const currentLocation = window.location.href;
    window.location.href = `${url}/auth/welcome?backUrl=${currentLocation}`;
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const toggleMenu = useCallback(() => {
    if (!menuOpen) {
      window.document.body.classList.add('sandwich-menu-open');
      window.document.body.scrollTop = 0;
      const selectedMenuItem = window.document
        .querySelector?.('.grid-nav-panel')
        ?.querySelector('ul > li.selected');
      selectedMenuItem?.scrollIntoView?.();
    } else {
      window.document.body.classList.remove('sandwich-menu-open');
    }
    setMenuOpen((m) => !m);
  }, [menuOpen]);

  const closeMenu = useCallback(() => {
    window.document.body.classList.remove('sandwich-menu-open');
    setMenuOpen(false);
  }, []);

  useEffect(() => {
    if (!mobile && !tablet) {
      window.document.body.classList.remove('sandwich-menu-open');
      setMenuOpen(false);
    }
  }, [mobile, tablet]);

  if (!isLogin) {
    return null;
  }

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <PageBody>
        <Header>
          <BurgerLogoContainer>
            {(tablet || mobile) && <HamburgerButton toggle={toggleMenu} open={menuOpen} />}
            <Link style={{lineHeight: 0}} to={{pathname: '/', search: window.location.search}}>
              <StyledAppLogo />
            </Link>
          </BurgerLogoContainer>
          <MenuLayout>
            <ProfileMenuContainer />
            {desktop && <SupportMenuContainer />}
          </MenuLayout>
        </Header>
        <Main>
          {mobile || tablet
            ? (
              <GridNavPanel onClick={closeMenu}>
                <FloatMenu>
                  <ScrollbarContainer>
                    <SideMenuContainer open={menuOpen} toggleMenu={toggleMenu} />
                  </ScrollbarContainer>
                </FloatMenu>
              </GridNavPanel>
            )
            : (
              <SideMenuContainer open={menuOpen} toggleMenu={toggleMenu} />
            )}
          <GridContainer id="main">
            <Content>
              <Outlet />
            </Content>
            <MenuBackdrop open={menuOpen && (mobile || tablet)} onClick={toggleMenu} />
          </GridContainer>
        </Main>
      </PageBody>
    </>
  );
};

const HamburgerButton = ({toggle, open}: { toggle: () => void, open: boolean }) => {
  return (
    <IconButton
      css={(theme) =>
        css`
          color: ${theme.colors.all.grey2};
        `
      }
      onClick={toggle}
    >
      {open ? <IconSVGTimesMenu /> : <IconSVGHamburger />}
    </IconButton>
  );
};

const ScrollbarContainer: FC<PropsWithChildren> = ({children}) => {
  const {mobile, tablet} = useMQuery();

  if (mobile || tablet) {
    return (
      <div
        css={css`
          width: 100%;
          height: 100%;
          overflow-y: auto;
        `}
      >
        {children}
      </div>
    );
  }
  return <PerfectScrollbar>{children}</PerfectScrollbar>;
};
