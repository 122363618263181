// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {TableRowProps, CircleStatus} from '@components';
import {Box, css, IconButton, styled, SxProps, Typography} from '@mui/material';
import {Result} from '@src/api';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as IconSVGArrowDown} from '@src/shared/assets/images/icons/icon_arrow_down.svg';
import {ReactComponent as IconSVGArrowUp} from '@src/shared/assets/images/icons/icon_arrow_up.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, DATE_FORMAT, getFullNameWithoutMiddleName} from '@src/shared/utils';
import {alpha} from '@src/theme/utils';
import {useTranslate} from 'i18n/useTranslate';
import React, {useState, useEffect, MouseEventHandler} from 'react';
import {useNavigate, useParams, useMatch} from 'react-router-dom';

import {EBackgroundFlagColor, EFlagColor, EFlagName} from './Results.constant';
interface IRowTableProps extends TableRowProps<Result> {
  onExpand?: (expanded: boolean) => void
  allExpanded?: boolean
  onlySemanticBlock?: boolean
  sx?: SxProps
}

export const TableRow: React.FC<IRowTableProps> = ({className, gridTemplateColumns, data, onExpand, allExpanded, onlySemanticBlock = true, sx}) => {
  const navigate = useNavigate();
  const relation = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULTS);
  const {mobile} = useMQuery();
  const {t} = useTranslate('results');
  const params = useParams();
  const {id, reported, collected, physician, flag, companyId} = data;
  const [expanded, setExpanded] = useState(false);
  const goToResult = () => {
    relation
      ? navigate(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${params?.id ?? ''}/${id}/${companyId}/results`)
      : navigate(`${id}/${companyId}`);
  };

  const handleExpand: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setExpanded(st => {
      const newVal = !st;
      onExpand?.(newVal);
      return !st;
    });
  };

  useEffect(() => {
    setExpanded(allExpanded ?? false);
  }, [allExpanded]);

  return (
    <ExpandableRowContainer
      sx={sx}
      className={className}
      onlySemanticBlock={onlySemanticBlock}
      critical={flag === 'CRITICAL' ? 1 : 0}>
      <MainRow
        onClick={goToResult}
        style={{gridTemplateColumns}}
      >
        <Cell gap={12}>
          {mobile && flag && (
            <Box width={24} display='flex' justifyContent='center'>
              <CircleStatus color={EFlagColor[flag]} />
            </Box>
          )}
          {dateToFormat(DATE_FORMAT, reported ?? '')}
        </Cell>
        {!mobile && (
          <>
            <Cell>{dateToFormat(DATE_FORMAT, collected || '')}</Cell>
            <Cell>{getFullNameWithoutMiddleName(physician) || ''}</Cell>
            <Cell>
              {flag
                ? (
                  <Typography
                    variant='14_20_500'
                    padding='4px 8px'
                    sx={{backgroundColor: EBackgroundFlagColor[flag], opacity: 0.7}}
                    color={EFlagColor[flag]}>{EFlagName[flag]}</Typography>
                )
                : null}
            </Cell>
          </>
        )}
        {mobile && (
          <>
            <Cell>{getFullNameWithoutMiddleName(physician) || ''}</Cell>
          </>
        )}
        {mobile && (
          <Cell css={css`justify-content: end`}>
            <IconButton css={css`margin-right: -10px;`} onClick={handleExpand}>
              {expanded ? <IconSVGArrowUp /> : <IconSVGArrowDown />}
            </IconButton>
          </Cell>
        )}
      </MainRow>
      {mobile && expanded && (
        <Box mb={'16px'}>
          <InternalRow style={{gridTemplateColumns}}>
            <Cell ml={36}><strong>{t('COLLECTED')}</strong></Cell>
            <Cell>{dateToFormat(DATE_FORMAT, collected || '')}</Cell>
          </InternalRow>
        </Box>
      )}
    </ExpandableRowContainer>
  );
};

export const MainRow = styled(Box)(() => `
  display: grid;
  align-content: center;
  min-height: 56px;
  box-sizing: border-box;
  column-gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  & > div {
    align-items: center;
  }
`);
export const InternalRow = styled(Box)`
  display: grid;
  column-gap: 20px;
  min-height: 30px;
  padding: 6px 0;
  box-sizing: border-box;
`;
export const ExpandableRowContainer = styled(Box)<{ critical: number, onlySemanticBlock?: boolean }>(({theme, critical, onlySemanticBlock}) => `
paddingX: {xs: '12px', sm: '24px', lg: '36px'};
padding-left: ${onlySemanticBlock ? '36px' : '24px'} !important;
box-sizing: border-box;
.mobile-layout & {
  padding-left: 8px !important;
}
cursor: initial;
border-top: ${critical ? `1px solid ${alpha(theme.palette.primary.main, 14)}` : 'unset'};
border-bottom: ${critical ? `1px solid ${alpha(theme.palette.primary.main, 14)} !important` : `1px solid ${alpha(theme.palette.secondary.main, 14)} !important`};
background-color: ${critical ? alpha(theme.palette.primary.main, 7) : 'initial'};
&:active {
  background-color: ${critical ? alpha(theme.palette.primary.main, 14) : 'initial'};
};
${theme.breakpoints.up('sm')} {
  &:hover {
    background-color: ${critical ? alpha(theme.palette.primary.main, 14) : alpha(theme.palette.secondary.main, 7)};
  };
  '&:active': {
    background-color: ${critical ? alpha(theme.palette.primary.main, 14) : alpha(theme.palette.secondary.main, 14)};
  };
}
`);

ExpandableRowContainer.defaultProps = {
  paddingY: 0,
};

export const Cell = styled(Box)`
align-items: flex-start;
display: flex;
`;
