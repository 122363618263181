import {DynamicsTest} from '@src/api';
import {insurancesApi} from '@src/api/insurances';
import {relationsApi} from '@src/api/relations';
import {deleteRelation} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {all, takeLeading, call, put, SagaReturnType, takeEvery, select} from 'redux-saga/effects';

import {errorHandler} from '../errorHandler';
import {transformData} from '../results/helpers';
import {SagaPayload, SagaReturn} from '../types';

import {relationsActions as actions, relationsActions} from './slice';

export function * relationsSaga () {
  yield all([
    takeLeading(actions.requestRelation, requestRelation),
    takeLeading(actions.requestDeleteRelation, requestDeleteRelation),
    takeLeading(actions.requestResultsById, requestResultsById),
    takeLeading(actions.requestResultDetails, requestResultDetails),
    takeLeading(actions.requestDynamics, requestDynamics),
    takeLeading(actions.editRelationAddressData, editRelationAddressData),
    takeLeading(actions.editRelationContactData, editRelationContactData),
    takeLeading(actions.editRelationHealthPoliciesData, editRelationHealthPoliciesData),
    takeLeading(actions.requestPrimaryInsuranceCarriers, requestPrimaryInsuranceCarriers),
    takeLeading(actions.requestSecondaryInsuranceCarriers, requestSecondaryInsuranceCarriers),
    takeLeading(actions.requestPrimaryInsurancePlans, requestPrimaryInsurancePlans),
    takeLeading(actions.requestSecondaryInsurancePlans, requestSecondaryInsurancePlans),
    takeLeading(actions.requestRelations, requestRelations),
    takeLeading(actions.requestRelationRequests, requestRelationRequests),
    takeLeading(actions.approveRelationRequest, approveRelationRequest),
    takeLeading(actions.declineRelationRequest, declineRelationRequest),
    takeEvery(actions.deleteRelationRequest, deleteRelationRequest),
    takeLeading(actions.searchRelationContacts, searchRelationContacts),
    takeLeading(actions.sendRelationRequest, sendRelationRequest),
    takeLeading(actions.createRelationContact, createRelationContact),
    takeLeading(actions.deleteInsurance, deleteInsurance),
    takeLeading(actions.setDynamics, transformDynamicData),
    takeLeading(actions.sendResultsByEmail, sendResultsByEmail),
  ]);
}

function * transformDynamicData (): SagaReturn {
  try {
    yield put(actions.setFetching({relation: true}));
    const dynamics = yield select(s => s.relations.dynamics);
    const data = dynamics?.allIDs.map((id: string | number) => dynamics.byID[id] as DynamicsTest);
    console.info('warn, transformData executed');
    const {testResults}: Awaited<ReturnType<typeof transformData>> = data ? yield call(transformData, data) : {testResults: []};
    yield put(actions.setTranformedData(testResults));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
};

export function * requestRelations (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({relation: true}));
    const relations: Awaited<ReturnType<typeof relationsApi.getRelations>> = yield call(relationsApi.getRelations);
    yield put(actions.setRelations({relations}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

export function * requestRelationRequests (): SagaReturnType<any> {
  try {
    yield put(actions.setFetching({relation: true}));
    const relationRequests: Awaited<ReturnType<typeof relationsApi.getRelationRequests>> = yield call(relationsApi.getRelationRequests);
    yield put(actions.setRelationRequests({relationRequests}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

export function * requestRelation ({payload}: SagaPayload<typeof actions.requestRelation>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const relation: Awaited<ReturnType<typeof relationsApi.getRelationById>> = yield call(
      relationsApi.getRelationById,
      {relationId: payload.relationId},
    );
    yield put(actions.setRelation({relation}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

export function * requestDeleteRelation ({
  payload,
}: SagaPayload<typeof actions.requestDeleteRelation>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const brokeRelationsStatus: Awaited<ReturnType<typeof deleteRelation>> = yield call(
      deleteRelation,
      {relations: payload.relations, types: payload.types, relationId: payload.relationId},
    );
    const relations: Awaited<ReturnType<typeof relationsApi.getRelations>> = yield call(relationsApi.getRelations);
    yield put(actions.setRelations({relations}));
    const isStatusError = Object.values(brokeRelationsStatus).find((status) => status !== 200);
    const step = isStatusError ? 'error' : 'success';
    yield put(actions.setBrokeRelationsStatus({brokeRelationsStatus}));
    yield put(actions.setStep({step}));
  } catch (e) {
    yield put(actions.setStep({step: 'error'}));
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

export function * requestResultsById ({payload}: SagaPayload<typeof actions.requestResultsById>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const results: Awaited<ReturnType<typeof relationsApi.getResultsById>> = yield call(
      relationsApi.getResultsById,
      {relationId: payload.relationId},
    );
    yield put(actions.setResults({results}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * requestResultDetails ({
  payload,
}: SagaPayload<typeof actions.requestResultDetails>): SagaReturn {
  try {
    yield put(actions.setFetching({relation: true}));
    const details: Awaited<ReturnType<typeof relationsApi.getResultDetails>> = yield call(
      relationsApi.getResultDetails,
      payload,
    );
    yield put(actions.setDetails(details));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * requestDynamics ({payload}: SagaPayload<typeof actions.requestResultsById>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const dynamics: Awaited<ReturnType<typeof relationsApi.getDynamics>> = yield call(
      relationsApi.getDynamics,
      payload,
    );
    yield put(actions.setDynamics(dynamics));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * editRelationAddressData ({
  payload,
}: SagaPayload<typeof actions.editRelationAddressData>): SagaReturn {
  try {
    yield put(actions.setSave(true));
    yield call(relationsApi.editRelationAddressData, {
      relationId: payload.relationId,
      data: payload.data,
    });
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setSave(false));
  }
}

function * editRelationContactData ({
  payload,
}: SagaPayload<typeof actions.editRelationContactData>): SagaReturn {
  try {
    yield put(actions.setSave(true));
    yield call(relationsApi.editRelationContactData, {
      relationId: payload.relationId,
      data: payload.data,
    });
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setSave(false));
  }
}

function * editRelationHealthPoliciesData ({
  payload,
}: SagaPayload<typeof actions.editRelationHealthPoliciesData>): SagaReturn {
  try {
    yield call(relationsApi.editRelationHealthPoliciesData, {
      relationId: payload.relationId,
      data: payload.data,
    });
    const relation: Awaited<ReturnType<typeof relationsApi.getRelationById>> = yield call(
      relationsApi.getRelationById,
      {relationId: payload.relationId},
    );
    yield put(actions.setRelation({relation}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * requestPrimaryInsuranceCarriers ({
  payload,
}: SagaPayload<typeof actions.requestPrimaryInsuranceCarriers>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const insuranceCarriers: Awaited<ReturnType<typeof insurancesApi.getInsuranceCarriersList>> =
      yield call(insurancesApi.getInsuranceCarriersList, {filter: payload.filter});
    yield put(actions.setPrimaryInsuranceCarriers({insuranceCarriers}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * requestSecondaryInsuranceCarriers ({
  payload,
}: SagaPayload<typeof actions.requestSecondaryInsuranceCarriers>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const insuranceCarriers: Awaited<ReturnType<typeof insurancesApi.getInsuranceCarriersList>> =
      yield call(insurancesApi.getInsuranceCarriersList, {filter: payload.filter});
    yield put(actions.setSecondaryInsuranceCarriers({insuranceCarriers}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * requestPrimaryInsurancePlans ({payload}: SagaPayload<typeof actions.requestPrimaryInsurancePlans>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const insurancePlans: Awaited<ReturnType<typeof insurancesApi.getInsurancePlansList>> =
      yield call(insurancesApi.getInsurancePlansList, {
        insuranceCarrierId: payload.insuranceCarrierId,
      });
    yield put(actions.setPrimaryInsurancePlans({insurancePlans}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * requestSecondaryInsurancePlans ({payload}: SagaPayload<typeof actions.requestSecondaryInsurancePlans>) {
  try {
    yield put(actions.setFetching({relation: true}));
    const insurancePlans: Awaited<ReturnType<typeof insurancesApi.getInsurancePlansList>> =
      yield call(insurancesApi.getInsurancePlansList, {
        insuranceCarrierId: payload.insuranceCarrierId,
      });
    yield put(actions.setSecondaryInsurancePlans({insurancePlans}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * approveRelationRequest ({payload}: SagaPayload<typeof actions.approveRelationRequest>): SagaReturn {
  try {
    yield call(relationsApi.approveRelationRequest, payload);
    const relations: Awaited<ReturnType<typeof relationsApi.getRelations>> = yield call(relationsApi.getRelations);
    yield put(actions.setRelations({relations}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * declineRelationRequest ({payload}: SagaPayload<typeof actions.declineRelationRequest>): SagaReturn {
  try {
    yield call(relationsApi.declineRelationRequest, payload);
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * deleteRelationRequest ({payload}: SagaPayload<typeof actions.deleteRelationRequest>): SagaReturn {
  try {
    yield call(relationsApi.deleteRelationRequest, payload);
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * searchRelationContacts ({
  payload,
}: SagaPayload<typeof actions.searchRelationContacts>): SagaReturn {
  try {
    yield put(actions.setFetching({relation: true}));
    const relationContacts: Awaited<ReturnType<typeof relationsApi.searchRelationContacts>> = yield call(relationsApi.searchRelationContacts, {
      data: payload.data,
    });
    yield put(actions.setRelationContacts({relationContacts: relationContacts.items || null}));
    yield put(actions.setRelationContactsHasNext({relationContactsHasNext: relationContacts.hasNext || null}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  } finally {
    yield put(actions.setFetching({relation: false}));
  }
}

function * sendRelationRequest ({
  payload,
}: SagaPayload<typeof actions.sendRelationRequest>): SagaReturn {
  try {
    yield call(relationsApi.sendRelationRequest, {data: payload.data});
    yield put(actions.setFailureCreatedContact({failureCreatedContact: null}));
    yield put(relationsActions.setStep({step: 'open'}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * createRelationContact ({
  payload,
}: SagaPayload<typeof actions.createRelationContact>): SagaReturn {
  try {
    yield put(actions.setStep({step: 'loading'}));
    const successCreatedContact: Awaited<ReturnType<typeof relationsApi.createRelationContact>> = yield call(relationsApi.createRelationContact, {data: payload.data});
    yield put(actions.setSuccessCreatedContact({successCreatedContact} as never));
    yield put(actions.setStep({step: 'init'}));
    yield put(actions.setBirthOfDateError({error: null}));
  } catch (e: any) {
    if (e?.status === 409) {
      yield put(actions.setStep({step: 'loading'}));
      const failureCreatedContact = e?.data;
      yield put(actions.setFailureCreatedContact({failureCreatedContact}));
      yield put(actions.setStep({step: 'init'}));
    } else {
      yield put(actions.setStep({step: 'error'}));
      errorHandler(e, actions.setCommonError);
    }
  }
}

function * deleteInsurance ({
  payload,
}: SagaPayload<typeof actions.deleteInsurance>): SagaReturn {
  try {
    yield put(actions.setInsuranceStep({step: 'loading'}));
    yield call(relationsApi.editRelationHealthPoliciesData, {data: payload.data, relationId: payload.relationId});
    const relation: Awaited<ReturnType<typeof relationsApi.getRelationById>> = yield call(
      relationsApi.getRelationById,
      {relationId: payload.relationId},
    );
    yield put(actions.setRelation({relation}));
    yield put(actions.setInsuranceStep({step: 'init'}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * sendResultsByEmail ({payload}: SagaPayload<typeof actions.sendResultsByEmail>): SagaReturn {
  try {
    yield call(relationsApi.sendResultsByEmail, {relationId: payload.relationId, resultId: payload.resultId, data: {email: payload.email}});
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
