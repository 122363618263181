// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Box, Stack, SxProps, Theme, Typography} from '@mui/material';
import {Product, Test, Panel} from '@src/api';
import {Dl} from '@src/components';
import {PopoverTooltip, usePopoverTooltip} from '@src/components/PopoverTooltip';
import {Checkbox, CheckboxControl} from '@src/components/form/CheckboxControl';
import {useTranslate} from '@src/i18n/useTranslate';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC, Fragment} from 'react';

import {getCriticalNameReference, getNameReference, getReference} from './Result.helpers';
import {Accordion, AccordionDetails} from './StyledAccordionSummary';

const stylesSx = {
  boxPaddings: {
    padding: '24px 24px 24px 81px',
  },
  checkboxPosition: {
    position: 'absolute',
    top: -9,
    left: -37,
  },
  boxContainer: {
    ml: {xs: 0, md: 33, lg: 0},
  },
  dlPosition: {
    position: 'relative',
  },
};

export type UpdateCode = (
  result: Array<Product | Panel> | string,
  checked: boolean,
  flag?: boolean,
) => void;

interface TestRowType {
  resultTests: Test[]
  className: string
  gridTemplateColumns: string
  padding?: number
  changeCodesForDynamics?: UpdateCode
  changeChildrenStatus: (i: number, s: boolean) => void
  childrenStatus: boolean[]
  sx?: SxProps
}

export const TableRow: FC<TestRowType> = ({
  resultTests,
  className,
  padding = 0,
  changeCodesForDynamics,
  changeChildrenStatus,
  childrenStatus,
  sx,
}) => {
  const {mobile, tabletPortrait, desktop: isDesktop, lg: isDesktopLg} = useMQuery();
  const {t} = useTranslate('details');
  const {onOpen: handleClick, open, ...forPopover} = usePopoverTooltip();
  const {isSidebarOpen} = useSidebarClassObserver();

  const handleChangeCheckbox = (checked: boolean, loinc: string, index: number) => {
    if (changeCodesForDynamics) {
      changeCodesForDynamics(loinc, checked);
    }
    changeChildrenStatus(index, checked);
  };

  return (
    <>
      {resultTests.map((result, index) => {
        const isCritical = result.criticalReference?.isOutOfRef;
        const isReference = result.reference?.isOutOfRef;
        const referenceColor = isReference ? '#FAB41C' : undefined;
        const criticalColor = isCritical ? '#EA3D5C' : undefined;
        const colorValue = criticalColor || referenceColor;
        const critical = isCritical ? '#FCF2F5' : 'initial';
        const colorInconsiderable = '#4b5563';

        const {
          name,
          value,
          comment,
          reference,
          criticalReference,
          qualityReference,
          code,
          loinc,
          units,
        } = result;

        const valueNode = (
          <>
            <div
              style={{color: colorValue, fontWeight: isCritical || isReference ? 700 : 'initial'}}
            >
              {value || '--'}
            </div>
            {qualityReference?.value
              ? (
                <div color={colorInconsiderable}>
                  {t('QUALITY_REFERENCE')}: {qualityReference.value}
                </div>
              )
              : null}
          </>
        );
        if (mobile || tabletPortrait) {
          return (
            <Fragment key={`${code}/tests/${index}`}>
              <Accordion
                sx={(t: Theme) => ({
                  borderBottom: `1px solid ${t.colors.borderLight}`,
                  [t.breakpoints.up('md')]: {
                    padding: `0 ${36 + padding}px`,
                  },
                })}
                key={`${code}/tests/${index}`}
              >
                <AccordionDetails>
                  <Box sx={stylesSx.boxPaddings}>
                    <Dl sx={stylesSx.dlPosition}>
                      <Checkbox
                        sx={stylesSx.checkboxPosition}
                        checked={childrenStatus[index]}
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        onChange={(_, checked) => handleChangeCheckbox(checked, loinc, index)}
                      />
                      <dt>
                        <Typography variant="12_16_700">{t('TEST')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{name}</Typography>
                      </dd>
                    </Dl>
                    <Dl sx={{flexDirection: !valueNode.props.children[1] ? 'row' : 'column'}}>
                      <dt>
                        <Typography variant="12_16_700">{t('RESULTS')}</Typography>
                      </dt>
                      <dd onClick={handleClick}>
                        <Typography variant="12_16_500">{valueNode}</Typography>
                      </dd>
                    </Dl>
                    <Dl>
                      <dt>
                        <Typography variant="12_16_700">{t('UNITS')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{units}</Typography>
                      </dd>
                    </Dl>
                    <Dl>
                      <dt>
                        <Typography variant="12_16_700">{t('COMMENTS')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{comment || t('NO_COMMENTS')}</Typography>
                      </dd>
                    </Dl>
                    <Dl sx={{flexDirection: !reference && !criticalReference ? 'row' : 'column', mb: '0px !important'}}>
                      <dt>
                        <Typography variant="12_16_700">{t('REFERENCE_INTERVAL')}</Typography>
                      </dt>
                      <dd>
                        <Typography variant="12_16_500">{getReference(result)}</Typography>
                        {reference
                          ? (
                            <Box>
                              <Typography variant="12_16_500" color={colorInconsiderable}>
                                {t('REFERENCE_RANGE')}: {getNameReference(reference, units)}
                              </Typography>
                            </Box>
                          )
                          : null}
                        {criticalReference
                          ? (
                            <Box>
                              <Typography variant="12_16_500" color={colorInconsiderable}>
                                {t('CRITICAL_REFERENCE')}:{' '}
                                {getCriticalNameReference(criticalReference, units)}
                              </Typography>
                            </Box>
                          )
                          : null}
                      </dd>
                    </Dl>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
                {valueNode}
                <br />
                {comment}
              </PopoverTooltip>
            </Fragment>
          );
        }
        return (
          <>
            <Box
              minWidth={{lg: 1033}}
              paddingX={{xs: 24, md: 24, lg: 66}}
              paddingRight={{lg: 36, xs: 36, md: 24}}
              paddingY={{lg: 12, xs: 8, md: 12}}
              sx={sx}
              key={`${code}/tests/${index}`}
              className={className}
              style={{
                gridTemplateColumns: (isDesktop && !isDesktopLg)
                  ? '250px auto 100px 160px 140px'
                  : (isDesktopLg && !isSidebarOpen)
                    ? '250px minmax(211px, 1fr) 100px 160px 140px'
                    : (isDesktopLg && isSidebarOpen)
                      ? '250px minmax(188px, 1fr) 100px 160px 140px'
                      : '28.79% 26.77% 5.55% 12.59% 15.65%',
                backgroundColor: critical,
              }}
            >
              <Box>
                <Box sx={stylesSx.boxContainer}>
                  <CheckboxControl
                    noMargin
                    sx={{padding: '0px !important'}}
                    checked={childrenStatus[index]}
                    color="secondary"
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(_, checked) => {
                      if (changeCodesForDynamics) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        changeCodesForDynamics(code, checked);
                      }
                      changeChildrenStatus(index, checked);
                    }}
                    label={name || '--'}
                  />
                </Box>
              </Box>
              <>
                <Stack sx={{gap: 6}} onClick={handleClick}>
                  {valueNode}
                </Stack>
                <Box>{units}</Box>
              </>
              {!mobile && !tabletPortrait && (
                <>
                  <Box>{comment || t('NO_COMMENTS')}</Box>
                  <Box>
                    <div>{getReference(result)}</div>
                    {reference
                      ? (
                        <>
                          <Box color={colorInconsiderable}>
                            {t('REFERENCE_RANGE')}:
                          </Box>
                          <Box color={colorInconsiderable}>
                            {getNameReference(reference, units)}
                          </Box>
                        </>
                      )
                      : null}
                    {criticalReference
                      ? (
                        <>
                          <Box color={colorInconsiderable}>
                            {t('CRITICAL_REFERENCE')}:
                          </Box>
                          <Box color={colorInconsiderable}>
                            {getCriticalNameReference(criticalReference, units)}
                          </Box>
                        </>
                      )
                      : null}
                  </Box>
                </>
              )}
            </Box>
            <PopoverTooltip {...forPopover} open={open && Boolean(comment)}>
              {valueNode}
              <br />
              {comment}
            </PopoverTooltip>
          </>
        );
      })}
    </>
  );
};
