export enum StepDialog {
  OPEN = 'open',
  LOADING = 'loading',
  INIT = 'init',
};

export interface InsuranceDeleteDialogProps {
  onRemove: VoidFunction
  step: string
  onClose: VoidFunction
  translate: string
};
