import {IconButton, IconButtonProps, SxProps, Theme} from '@mui/material';
import {ReactComponent as IconSVGSettings} from '@src/shared/assets/images/icons/icon_settings.svg';

const buttonSx: SxProps<Theme> = {
  border: '1px solid',
  borderColor: theme => theme.colors.secondary,
  borderRadius: 0,
};

export const SettingsButton = (props: IconButtonProps) => {
  return (
    <IconButton
      {...props}
      sx={{...buttonSx, ...props.sx}}
      color={'secondary'}
      size={'large'}
    >
      <IconSVGSettings />
    </IconButton>
  );
};
