import {Reference, Test} from '@src/api';

export const getNameReference = (reference: Reference, units: string | null) => {
  const minValue = reference?.minValue;
  const maxValue = reference?.maxValue;
  const minValueIsNull = minValue == null;
  const maxValueIsNull = maxValue == null;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && !maxValueIsNull) return `${minValue}-${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (minValueIsNull && !maxValueIsNull) return `<${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && maxValueIsNull) return `>${minValue}`;
};

export const getCriticalNameReference = (reference: any, units: any) => {
  const minValue = reference?.minValue;
  const maxValue = reference?.maxValue;
  const minValueIsNull = minValue === null;
  const maxValueIsNull = maxValue === null;

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && !maxValueIsNull) return `<${minValue}; >${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (minValueIsNull && !maxValueIsNull) return `>${maxValue}`;
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (!minValueIsNull && maxValueIsNull) return `<${minValue}`;
};

export const getReference = (result: Test) =>
  result.reference?.minValue !== null && result.reference?.maxValue !== null
    ? getNameReference(result.reference, result.units)
    : getCriticalNameReference(result.criticalReference, result.units);
