import {
  FetchStorageFileThumbnailsBody,
  StorageFile,
  StorageFileData,
  StorageFileDetails, StorageFileThumbnail,
} from '@src/api';
import {apiClient} from '@src/client/ApiClient';

import {FetchStorageFilesRequestParams, FetchStorageFilesResponse} from './models';

const STORAGE_URL = '/api/storage';

export const getStorageFiles = async (params: FetchStorageFilesRequestParams) => {
  const response = await apiClient.get<FetchStorageFilesResponse>(`${STORAGE_URL}/search`, {
    params,
  });
  return response.data;
};

export const updateStorageFile = async ({id, ...file}: StorageFile) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(file));

  const response = await apiClient.post<StorageFile>(`${STORAGE_URL}/update/${id}`, formData);
  return response.data;
};

export const deleteStorageFile = async (id: StorageFile['id']) => {
  const response = await apiClient.post(`${STORAGE_URL}/delete/${id}`);
  return response.status;
};

export const getStorageFileData = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileData>(`${STORAGE_URL}/${id}`);
  return response.data;
};

export const getStorageFileDetails = async (id: StorageFile['id']) => {
  const response = await apiClient.get<StorageFileDetails>(`${STORAGE_URL}/${id}/details`);
  return response.data;
};

export const getStorageFileThumbnails = async (data: FetchStorageFileThumbnailsBody) => {
  const response = await apiClient.post<StorageFileThumbnail[]>(`${STORAGE_URL}/fn/get-all-thumbnails`, data);
  return response.data;
};

export * as storageApi from './';
export * from './models';
