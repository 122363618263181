import {GuarantorCreateRequest} from '@src/api/relations';
import {ChangeEvent} from 'react';

import {EWhichPrimaryInsuranceUse, IInitialValueProps} from './types';

export const getInitialValues = ({
  insurance,
  accountProfileValidInsurance,
  initRelationshipType,
}: IInitialValueProps) => ({
  relationShipType: initRelationshipType || null,
  firstName: null,
  lastName: null,
  middleName: null,
  birthDate: null,
  birthSex: null,
  gender: null,
  race: null,
  ethnicity: null,
  sexualOrientation: null,
  phone: null,
  email: null,
  whichPrimaryInsuranceUse: EWhichPrimaryInsuranceUse.Patient,
  primary: insurance?.primary || null,
  insuranceCarrier: null,
  insurancePlan: null,
  policyId: null,
  endDate: null,
  insuranceId: insurance?.id || null,
  guarantorRelationshipType: null,
  guarantorFirstName: null,
  guarantorLastName: null,
  guarantorMiddleName: null,
  guarantorBirthDate: null,
  guarantorBirthSex: null,
  guarantorPhone: null,
  guarantorState: null,
  guarantorCity: null,
  guarantorAddress1: null,
  guarantorAddress2: null,
  guarantorSsnTail: null,
  guarantorPostalCode: null,
  guarantor: (insurance?.guarantor as unknown as GuarantorCreateRequest) || null,
  myInsurance: accountProfileValidInsurance || null,
  address1: '',
  address2: '',
  state: '',
  city: '',
  postalCode: '',
  location: {
    googlePlaceId: '',
    latitude: 0,
    longitude: 0,
  },
  telephoneNumber: '',
  faxNumber: '',
  invoicesReceiving: false,
  insuranceBillType: false,
  patientBillType: false,
  clientBillType: false,
  streetNumber: null,
});

export const resetGuarantorFieldsValue = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
) => {
  setFieldValue('guarantorRelationshipType', null);
  setFieldValue('guarantorFirstName', null);
  setFieldValue('guarantorLastName', null);
  setFieldValue('guarantorMiddleName', null);
  setFieldValue('guarantorBirthDate', null);
  setFieldValue('guarantorBirthSex', null);
  setFieldValue('guarantorPhone', null);
  setFieldValue('guarantorState', null);
  setFieldValue('guarantorCity', null);
  setFieldValue('guarantorAddress1', null);
  setFieldValue('guarantorAddress2', null);
  setFieldValue('guarantorSsnTail', null);
  setFieldValue('guarantorPostalCode', null);
};

export const handleChangeRadioBth = (
  e: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
) => {
  if (e.currentTarget.value === EWhichPrimaryInsuranceUse.My) {
    resetGuarantorFieldsValue(setFieldValue);
  }
};

export const addressOnClear = (
  e: ChangeEvent<HTMLInputElement>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
) => {
  if (!e.target?.value) {
    setFieldValue('address1', null, true);
    setFieldValue('address2', null, false);
    setFieldValue('state', null, false);
    setFieldValue('postalCode', null, false);
    setFieldValue('city', null, false);
    setFieldValue('location', null, false);
    setFieldValue('streetNumber', null, true);
  };
};
