export const RESET_FORM_DATA = {
  relationShipType: null,
  firstName: null,
  lastName: null,
  middleName: null,
  birthDate: null,
  birthSex: null,
  gender: null,
  race: null,
  ethnicity: null,
  sexualOrientation: null,
  phone: null,
  email: null,
  whichPrimaryInsuranceUse: 'patients',
  owner: null,
  insuranceCarrier: null,
  insurancePlan: null,
  policyId: null,
  endDate: null,
};
