import {Stack, Typography, debounce, Box} from '@mui/material';
import {InsurancePlansList, RelationType, StateType, BirthSex} from '@src/api/insurances';
import {PageHeader as PageTitle} from '@src/components';
import Grid from '@src/components/Grid';
import {SelectLabels} from '@src/components/Select/Select';
import {WBox} from '@src/components/WhiteBox';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH} from '@src/pages/Connections/constants';
import {Input} from '@src/pages/Connections/fragments/Relations/helpers/Input';
import {SearchSelect} from '@src/pages/Connections/fragments/Relations/helpers/SearchSelect';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as Add} from '@src/shared/assets/icons/plus.svg';
import {useDebounce} from '@src/shared/hooks/useDebounce';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat} from '@src/shared/utils';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {phoneNotRequiredUpdateValidator} from '@src/shared/utils/phoneNotRequiredUpdateValidator';
import {validateValue} from '@src/shared/utils/validateValue';
import {postalCodeValidator, ssnTailValidator} from '@src/shared/utils/validators';
import {RootState} from '@src/store';
import {useAccountProfile} from '@src/store/account/hooks';
import {
  useCreateInsurance,
  useInsuranceCarriers,
  useInsurancePlans,
  useInsurances,
} from '@src/store/insurance/hooks';
import {Nullable} from '@src/types/NullableModel';
import {Formik, FormikProps, FormikTouched} from 'formik';
import {isEqual} from 'lodash-es';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PhoneInputControl, Button} from 'ui-kit';
import * as Yup from 'yup';

import {
  birthSexSelectOptions,
  relationTypesSelectOptions,
  statesSelectOptions,
} from '../constants';

import {initialValue, touchedData} from './constants';
import {StyledForm, sx} from './styles';

export interface SaveDataHealthPolicies {
  insuranceCarrier: string | null
  insurancePlan: string | null
  insurancePolicyID: string | null
  insuranceEndDate: string | null
  relationship: RelationType
  birthDate: string
  birthSex: BirthSex
  firstName: string
  lastName: string
  middleName: string
  ssnTail: string
  state: StateType
  city: string
  postalCode: string
  address1: string
  address2: string
  phone: string
  policyId: string
  endDate: string
  insurancePlanId: string
}

export const AddInsurance = () => {
  const {t} = useTranslate('insurance');
  const {mobile, desktop} = useMQuery();
  const {accountProfile} = useAccountProfile();
  const {healthcareInsurancePolicies, activeHealthcareInsurancesPolicies: activeInsurance} = useInsurances();
  const {createInsurance} = useCreateInsurance();
  const navigate = useNavigate();
  const {getInsuranceCarriers} = useInsuranceCarriers();
  const [searchPrimaryText, setSearchPrimaryText] = useState<string>('');
  const [insurancePlans, setInsurancePlans] = useState<InsurancePlansList[] | null>([]);
  const [insuranceCarrierId, setInsuranceCarrierId] = useState('');
  const [insuranceCarrierError, setInsuranceCarrierError] = useState('');
  const [selectedCarrier, setSelectedCarrier] = useState<string>('');
  const insuranceCarriers = useSelector((state: RootState) => state.insurance.insuranceCarriers);
  const isPrimaryInsurance =
    activeInsurance?.length && !!activeInsurance.find((insurance) => insurance.primary);
  const handlePrimaryTextChange = (value: string) => {
    setSearchPrimaryText(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPrimaryChangeHandler = useCallback(debounce(handlePrimaryTextChange, 300), []);
  const {insurancePlans: plans} = useInsurancePlans(insuranceCarrierId);
  const memoInsuranceCarriersOptions = useMemo(() => {
    return insuranceCarriers?.map((option) => option.name);
  }, [insuranceCarriers]);

  const debouncedSearchPrimaryText = useDebounce(searchPrimaryText, 300);

  const setCarrier = (value: string, setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    setSelectedCarrier(value);
    handlePrimaryTextChange(value);
    setInsuranceCarrierError('');
    if (setFieldValue && value !== selectedCarrier) setFieldValue('insurancePlan', null);
  };

  useEffect(() => {
    if (selectedCarrier) {
      setInsurancePlans(plans);
    }
    // NOTE: ignoring the update of selectedCarrier
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  useEffect(() => {
    const selectedInsuranceCarrier = insuranceCarriers?.find(
      (carrier) => carrier.name === selectedCarrier,
    );
    if (selectedInsuranceCarrier) setInsuranceCarrierId(selectedInsuranceCarrier?.id);
  }, [selectedCarrier, insuranceCarriers]);

  useEffect(() => {
    if (debouncedSearchPrimaryText) {
      getInsuranceCarriers(debouncedSearchPrimaryText);
      setInsurancePlans(null);
      setInsuranceCarrierId('');
    }
  }, [getInsuranceCarriers, debouncedSearchPrimaryText]);

  const saveData = ({
    relationship,
    birthDate,
    birthSex,
    firstName,
    lastName,
    middleName,
    ssnTail,
    state,
    city,
    postalCode,
    address1,
    address2,
    phone,
    endDate,
    insurancePlanId,
    policyId,
  }: Nullable<SaveDataHealthPolicies>) => {
    const data = [
      {
        endDate: {
          value: endDate || '',
        },
        guarantor: {
          address1: address1 || '',
          address2: {
            value: address2 || '',
          },
          birthDate: birthDate || '',
          birthSex: birthSex || null,
          city: city || '',
          firstName: firstName || '',
          lastName: lastName || '',
          middleName: {
            value: middleName || '',
          },
          phone: {
            value: phone || '',
          },
          postalCode: postalCode || '',
          relationType: relationship === RelationType.SELF ? null : relationship,
          ssnTail: {
            value: ssnTail || '',
          },
          state: state || null,
        },
        insurancePlanId: insurancePlanId || '',
        number: policyId || '',
        primary: !isPrimaryInsurance,
      },
    ];
    createInsurance({
      data: {
        deletedHealthcareInsurancePolicyIds: [],
        healthcareInsurancePoliciesCreate: data || undefined,
        healthcareInsurancePoliciesEdit: [],
      },
    });
  };

  const setGuarantorFieldsBySelf = (setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    setFieldValue('firstName', accountProfile?.contact?.firstName);
    setFieldValue('lastName', accountProfile?.contact?.lastName);
    setFieldValue('middleName', accountProfile?.contact?.middleName);
    setFieldValue('birthDate', accountProfile?.contact?.birthDate);
    setFieldValue('birthSex', accountProfile?.contact?.birthSex);
    setFieldValue('ssnTail', accountProfile?.contact?.ssnTail);
    setFieldValue('state', accountProfile?.actualAddresses?.state);
    setFieldValue('city', accountProfile?.actualAddresses?.city);
    setFieldValue('address1', accountProfile?.actualAddresses?.main);
    setFieldValue('address2', accountProfile?.actualAddresses?.additional);
    setFieldValue('postalCode', accountProfile?.actualAddresses?.postalCode);
    setFieldValue('phone', accountProfile?.phones ? accountProfile?.phones[0].phone : '');
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('PLEASE_ENTER_FIRST_NAME')).nullable(),
    lastName: Yup.string().required(t('PLEASE_ENTER_LAST_NAME')).nullable(),
    birthDate: Yup.string().required(t('PLEASE_ENTER_A_BIRTH_DATE')).nullable(),
    birthSex: Yup.string().required(t('PLEASE_ENTER_BIRTH_SEX')).nullable(),
    state: Yup.string().required(t('PLEASE_ENTER_A_STATE')).nullable(),
    city: Yup.string().required(t('PLEASE_ENTER_A_CITY')).nullable(),
    address1: Yup.string().required(t('PLEASE_ENTER_AN_ADDRESS')).nullable(),
    endDate: Yup.string().nullable()
      .test('is-insurancePlan', t('DATE_MUST_BE_GREATER_THAN_TODAY'), function (value) {
        if (value) {
          return !!compareDatesWithToday(value, false, true);
        }
        return true;
      }),
    phone: phoneNotRequiredUpdateValidator(t),
    postalCode: postalCodeValidator(t),
    insurancePlan: Yup.string()
      .nullable()
      .required(t('PLEASE_SELECT_INSURANCE_PLAN'))
      .test('is-insurancePlan', t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE'), function (value) {
        return !healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            } else {
              return insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            }
          },
        );
      }),
    policyId: Yup.string()
      .nullable()
      .required(t('PLEASE_ENTER_A_POLICY_ID'))
      .test('is-policyId', t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE'), function (value) {
        return !healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            } else {
              return insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            }
          },
        );
      }),
    ssnTail: ssnTailValidator(t),
  });

  const handleCancelClick = (resetForm: FormikProps<typeof initialValue>['resetForm']) => {
    navigate(ROUTERS_PATH.INSURANCE);
    resetForm();
  };

  const handleFormSubmit = (
    handleSubmit: FormikProps<typeof initialValue>['handleSubmit'],
    setTouched: (touched: FormikTouched<typeof initialValue>, shouldValidate?: boolean) => void,
    selectedCarrier: string,
    setInsuranceCarrierError: (error: string) => void,
  ) => {
    if (!selectedCarrier) setInsuranceCarrierError(t('PLEASE_SELECT_INSURANCE_CARRIER'));
    handleSubmit();
    setTouched(touchedData);
  };

  return (
    <Formik<typeof initialValue>
      enableReinitialize
      onSubmit={(values) => {
        if (!isEqual(initialValue, values)) {
          saveData({
            ...values,
            insurancePlanId:
              insurancePlans?.find((plan) => plan.name === values.insurancePlan)?.id || null,
          });
          navigate(ROUTERS_PATH.INSURANCE);
        }
      }}
      initialValues={initialValue}
      validationSchema={validationSchema}
    >
      {({
        handleChange,
        values,
        setFieldValue,
        errors,
        handleBlur,
        touched,
        setFieldTouched,
        resetForm,
        setTouched,
        handleSubmit,
      }) => {
        return (
          <StyledForm>
            {!mobile && (
              <Stack sx={sx.mainTitleWrapper}>
                <PageTitle>{t('INSURANCE')}</PageTitle>
                <Button
                  disabled
                  variant="outlined"
                  color="secondary"
                  startIcon={<Add />}>
                  {t('ADD_INSURANCE')}
                </Button>
              </Stack>
            )}
            <WBox sx={sx.whiteBox}>
              <Stack sx={sx.mainContainer}>
                <Stack gap={24}>
                  <Typography variant={mobile ? '22_26_500' : '24_34_500'}>
                    {isPrimaryInsurance ? t('SECONDARY_INSURANCE') : t('PRIMARY_INSURANCE')}
                  </Typography>
                  <Grid container spacing={mobile ? 36 : 40} columnSpacing={desktop ? 48 : 36}>
                    <Grid
                      xl={4}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SearchSelect
                        placeholder={t('SELECT')}
                        label={t('CARRIER')}
                        debouncedChangeHandler={debouncedPrimaryChangeHandler}
                        memoOptions={memoInsuranceCarriersOptions}
                        searchText={searchPrimaryText}
                        value={selectedCarrier}
                        onSearchChange={handlePrimaryTextChange}
                        setSelectedCarrier={(value: string) => setCarrier(value, setFieldValue)}
                        error={insuranceCarrierError}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectLabels
                        disabled={!insurancePlans?.length}
                        name="insurancePlan"
                        value={values.insurancePlan || ''}
                        contentPaddingLeft="50px"
                        onChange={handleChange}
                        onChangeMobile={(selected) => handleChange({target: selected})}
                        onTouched={setFieldTouched}
                        error={
                          (touched?.insurancePlan && !values.insurancePlan) ||
                            errors.insurancePlan === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')
                            ? errors.insurancePlan
                            : ''
                        }
                        options={insurancePlans || []}
                        placeholder={t('SELECT')}
                        label={t('PLAN')}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      md={4}
                      sm={6}
                      xs={12}>
                      <Input
                        name="policyId"
                        label={t('POLICY_ID')}
                        placeholder={t('ENTER_AN_POLICY_ID')}
                        value={values.policyId || ''}
                        onTouched={handleBlur}
                        error={
                          (touched?.policyId && !values.policyId) ||
                            errors.policyId === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')
                            ? errors.policyId
                            : ''
                        }
                        onClear={() => setFieldValue('policyId', '')}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      md={4}
                      sm={6}
                      xs={12}>
                      <DateControl
                        reverseYears
                        disabledPast
                        onlyFutureYears
                        name="endDate"
                        helperText={t('OPTIONAL')}
                        value={dateToFormat('P', values.endDate) || ''}
                        onTouched={setFieldTouched}
                        onChange={handleChange}
                        label={t('END_DATE')}
                        error={touched.endDate ? errors.endDate : ''}
                        optional
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Stack gap={{lg: 36, md: 40, sm: 40, xs: 36}}>
                  <Stack flexGrow={1} gap={{lg: 36, md: 24, sm: 24, xs: 24}}>
                    <Box sx={{display: 'flex', gap: 24}}>
                      <Typography component="h4" variant="18_24_500" sx={sx.title}>
                        {t('GUARANTOR')}
                      </Typography>
                    </Box>
                    <Grid container spacing={40}>
                      <Grid
                        xl={4}
                        md={4}
                        sm={6}
                        xs={12}>
                        <SelectLabels
                          name="relationship"
                          placeholder={t('SELECT')}
                          helperText={t('OPTIONAL')}
                          value={t(values.relationship) || ''}
                          contentPaddingLeft="102px"
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === RelationType.SELF) {
                              setGuarantorFieldsBySelf(setFieldValue);
                            }
                          }}
                          onChangeMobile={(selected) => {
                            handleChange({target: selected});
                            if (selected.value === RelationType.SELF) {
                              setGuarantorFieldsBySelf(setFieldValue);
                            }
                          }}
                          onTouched={setFieldTouched}
                          error={
                            touched?.relationship && !values.relationship ? errors.relationship : ''
                          }
                          options={relationTypesSelectOptions || []}
                          label={t('RELATIONSHIP')}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack gap={40}>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid container spacing={mobile ? 36 : 40} columnSpacing={desktop ? 48 : 36}>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="firstName"
                            label={t('FIRST_NAME')}
                            placeholder={t('ENTER_FIRST_NAME')}
                            value={values.firstName || ''}
                            error={touched?.firstName ? errors.firstName : ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('firstName', '')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="lastName"
                            label={t('LAST_NAME')}
                            value={values.lastName || ''}
                            placeholder={t('ENTER_LAST_NAME')}
                            error={touched?.lastName ? errors.lastName : ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('lastName', '')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="middleName"
                            label={t('MIDDLE_NAME')}
                            placeholder={t('ENTER_MIDDLE_NAME')}
                            value={values.middleName || ''}
                            helperText={t('OPTIONAL')}
                            error={touched?.middleName ? errors.middleName : ''}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('middleName', '')}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <DateControl
                            name="birthDate"
                            disabledFuture
                            value={dateToFormat('P', values.birthDate) || ''}
                            onChange={handleChange}
                            onTouched={setFieldTouched}
                            label={t('DATE_OF_BIRTH')}
                            hideTip={false}
                            error={touched?.birthDate && !values?.birthDate ? errors.birthDate : ''}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <SelectLabels
                            name="birthSex"
                            placeholder={t('SELECT')}
                            value={t(values.birthSex || ('' as TKeys<'insurance'>)) || ''}
                            contentPaddingLeft="80px"
                            onChange={handleChange}
                            onChangeMobile={(selected) => handleChange({target: selected})}
                            onTouched={setFieldTouched}
                            error={touched?.birthSex && !values.birthSex ? errors.birthSex : ''}
                            options={birthSexSelectOptions || []}
                            label={t('BIRTH_SEX')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="ssnTail"
                            label={t('SSN_LAST_4')}
                            placeholder={t('ENTER_A_SSN')}
                            helperText={t('OPTIONAL')}
                            error={touched?.ssnTail ? errors.ssnTail : ''}
                            value={values.ssnTail || ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('ssnTail', '')}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack sx={sx.content} flexGrow={1}>
                      <Grid container spacing={mobile ? 36 : 40} columnSpacing={desktop ? 48 : 36}>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <SelectLabels
                            name="state"
                            value={t(values.state) || ''}
                            placeholder={t('SELECT')}
                            contentPaddingLeft="55px"
                            onChange={handleChange}
                            onChangeMobile={(selected) => handleChange({target: selected})}
                            onTouched={setFieldTouched}
                            error={touched?.state && !values.state ? errors.state : ''}
                            options={statesSelectOptions || []}
                            label={t('STATE')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="city"
                            label={t('CITY')}
                            value={values.city || ''}
                            placeholder={t('ENTER_A_CITY')}
                            error={touched?.city ? errors.city : ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('city', '')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="postalCode"
                            label={t('ZIP_CODE')}
                            placeholder={t('ENTER_A_POSTAL_CODE')}
                            value={values.postalCode || ''}
                            error={touched?.postalCode ? errors.postalCode : ''}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('postalCode', '')}
                            onChange={handleChange}
                            validation={(value) => validateValue(value)}
                            maxLength={POSTAL_CODE_LENGTH}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="address1"
                            label={t('ADDRESS_1')}
                            placeholder={t('ENTER_AN_ADDRESS')}
                            error={touched?.address1 ? errors.address1 : ''}
                            value={values.address1 || ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('address1', '')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <Input
                            name="address2"
                            label={t('ADDRESS_2')}
                            error={touched?.address2 ? errors.address2 : ''}
                            placeholder={t('ENTER_AN_ADDRESS')}
                            helperText={t('OPTIONAL')}
                            value={values.address2 || ''}
                            onChange={handleChange}
                            onTouched={handleBlur}
                            onClear={() => setFieldValue('address2', '')}
                          />
                        </Grid>
                        <Grid
                          xl={4}
                          md={4}
                          sm={6}
                          xs={12}>
                          <PhoneInputControl
                            optional
                            name="phone"
                            label={t('PHONE')}
                            error={touched?.phone ? errors.phone : ''}
                            placeholder={t('ENTER_A_PHONE')}
                            value={values.phone || ''}
                            onChange={handleChange}
                            sx={sx.phoneInput}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack sx={sx.buttonsWrapper}>
                <Button
                  sx={sx.button}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleCancelClick(resetForm)}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  disabled={isEqual(initialValue, values)}
                  sx={sx.button}
                  variant="contained"
                  color="primary"
                  onClick={() => handleFormSubmit(
                    handleSubmit,
                    setTouched,
                    selectedCarrier,
                    setInsuranceCarrierError,
                  )}
                >
                  {t('SAVE')}
                </Button>
              </Stack>
            </WBox>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
