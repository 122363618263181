import {FC, useCallback, useEffect} from 'react';
import {useGoogleAutocomplete} from '@src/components/Google/Autocomplete/useGoogleAutocomplete';
import {AutocompleteInputView} from '@src/components/Google/Autocomplete/AutocompleteInputView';

export const GoogleAutocomplete: FC<{
  onChange?: (
    placeResult: google.maps.places.PlaceResult,
    autocomplete: google.maps.places.Autocomplete
  ) => void
  placeholder?: string
  value?: string
  label?: string
  error?: string
  onClear?: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({onChange, placeholder, error, value, label, onClear}) => {
  const {ref} = useGoogleAutocomplete({
    onChange,
  });

  const onFocusHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'hidden';
      main.style.overflowY = 'hidden';
    };
  }, []);

  const onBlurHandler = useCallback(() => {
    const main = document.querySelector('#main') as HTMLElement;
    if (main) {
      document.body.style.overflowY = 'auto';
      main.style.overflowY = 'auto';
    };
  }, []);

  useEffect(() => {
    const inputEl = ref.current;
    if (inputEl) {
      inputEl.addEventListener('focus', onFocusHandler);
      inputEl.addEventListener('blur', onBlurHandler);
    }

    return () => {
      if (inputEl) {
        inputEl.removeEventListener('focus', onFocusHandler);
        inputEl.removeEventListener('blur', onBlurHandler);
      };
    };
  }, [ref, onFocusHandler, onBlurHandler]);

  return (
    <AutocompleteInputView
      value={value}
      label={label}
      ref={ref}
      error={error}
      placeholder={placeholder}
      onChange={onClear} />
  );
};
