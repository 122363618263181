import {EmptySearch} from '@components';
import {Box, Typography} from '@mui/material';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

import {generateRefText} from '../Dynamics.helper';
import {Recharts} from '../Recharts';

import {IGraphicsProps} from './types';

export const Graphics: FC<IGraphicsProps> = ({data, isLoading}) => {
  const {t} = useTranslate('details');

  if (!data?.length) {
    return <EmptySearch isLoading={isLoading}/>;
  }

  return (
    <>
      {data?.map(({resultLaboratoryAnalyses, refMin, refMax, refText, code, testName, domain}) => {
        const key: string = code ?? testName;
        return (
          resultLaboratoryAnalyses.some((a: any) => a.dot)
            ? (
              <Box key={`${key}graphics`} mb={{sm: '24px', lg: '36px'}}>
                <Typography fontSize={18} fontWeight={500} pb={4}>
                  {testName}
                </Typography>
                <Typography
                  color="grey.400"
                  fontSize={14}
                  fontWeight={500}
                  marginBottom="16px">
                  {t('REFERENCE_RANGE')}: {generateRefText({refMax, refMin, refText})}
                </Typography>
                <Recharts data={resultLaboratoryAnalyses} domain={domain} />
              </Box>
            )
            : <></>
        );
      }) || null}
    </>
  );
};
