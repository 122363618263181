/** @jsxImportSource @emotion/react */
import {FC} from 'react';
import {DateTime, Info} from 'luxon';
import {TKeys, useTranslate} from 'i18n/useTranslate';
import {Box, css as _} from '@mui/material';
import {DateButton} from './YearSelectorArea';

export const MonthsSelectorArea: FC<{ onChange: (d: DateTime) => void, date: DateTime, selected?: DateTime | null }> = ({
  date,
  selected,
  onChange,
}) => {
  const {t} = useTranslate('common');
  return (
    <Box css={_`display: flex; flex-wrap: wrap; width: 100%; row-gap: 36px;`}>
      {Info.months('long').map(m => {
        return (
          <DateButton
            key={m}
            css={_`width: calc(100% / 3)`}
            active={selected?.monthLong === m && date.year === selected?.year}
            onClick={() => {
              onChange(date.set({month: Info.months('long').indexOf(m) + 1}));
            }}
          >
            {t(m as TKeys<'common'>)}
          </DateButton>
        );
      })}
    </Box>
  );
};
