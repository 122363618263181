import {Stack, Typography} from '@mui/material';
import {NO_VALUE} from '@src/shared/utils';

import {EllipsisTextTooltip} from '../EllipsisTextTooltip';

import {IRowInformationProps} from './types';

export const RowInformation = ({
  value,
  name,
  sx,
  tooltip,
  noMargin,
  noWrap,
  spaceBetween,
  variant = '14_18_700',
  valueTypographyVariant = '14_18_500',
  containerSx,
}: IRowInformationProps) => (
  <Stack
    direction="row"
    marginBottom={noMargin ? 0 : '6px'}
    whiteSpace={!noWrap ? 'nowrap' : 'unset'}
    flexWrap={noWrap ? 'wrap' : 'unset'}
    sx={containerSx}
  >
    <Typography
      variant={variant}
      marginRight={spaceBetween || '12px'}
      whiteSpace="nowrap"
      marginBottom={noMargin ? 0 : '6px'}
      sx={sx}
    >
      {name}
    </Typography>
    {tooltip
      ? (
        <EllipsisTextTooltip ellipsisText={value || NO_VALUE} variant={valueTypographyVariant} sx={sx} />
      )
      : (
        <Typography
          variant={valueTypographyVariant}
          sx={sx}>
          {value || NO_VALUE}
        </Typography>
      )}
  </Stack>
);
