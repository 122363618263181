import {BirthSex, RelationTypeEnum} from '@src/api';
import {DateTime} from 'luxon';
import * as Yup from 'yup';

import {IValidationSchemaParams} from './types';

export const getValidationSchema = ({
  t,
  relationshipTypes,
  relationType,
  birthDate,
  birthSex,
  ageOfMajority,
}: IValidationSchemaParams) => {
  return Yup.object({
    newBirthDate: Yup.string().when('birthDate', {
      is: () => relationType && !relationshipTypes.includes(relationType) && !birthDate,
      then: Yup.string()
        .required(t('PLEASE_ENTER_DATE_OF_BIRTH'))
        .nullable()
        .test(
          'is-newBirthDate',
          t('YOU_SHOULD_BE_OVER_18_YO'),
          (value) => (value ? DateTime.fromISO(value) <= DateTime.now().minus({years: ageOfMajority}) : false),
        ),
      otherwise: Yup.string().ensure().nullable(),
    }),
    newBirthSex: Yup.string().when('birthSex', {
      is: () => relationType === RelationTypeEnum.Chd && birthSex === BirthSex.Unknown,
      then: Yup.string().nullable().required(),
      otherwise: Yup.string().ensure().nullable(),
    }),
  });
};
