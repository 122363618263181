import {ErrorsProps} from './types';

export const getInsuranceCarrierError = ({touched, values, errors}: ErrorsProps) => {
  if (touched?.insuranceCarrier && !values.insuranceCarrier) {
    return errors.insuranceCarrier;
  }
  return '';
};

export const getInsurancePlanError = ({touched, values, errors, t}: ErrorsProps) => {
  if (touched?.insurancePlan && !values.insurancePlan) {
    return errors.insurancePlan;
  }
  if (t && errors.insurancePlan === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')) {
    return errors.insurancePlan;
  }
  return '';
};

export const getPolicyIdError = ({touched, values, errors, t}: ErrorsProps) => {
  if (touched?.policyId && errors.policyId) {
    return errors.policyId;
  }
  if (touched?.policyId && !values.policyId) {
    return errors.policyId;
  }
  if (t && errors.policyId === t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE')) {
    return errors.policyId;
  }
  return '';
};
