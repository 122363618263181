import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: {
      lg: 36,
      md: 36,
      sm: 36,
      xs: 48,
    },
  },
  whiteBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: {
      lg: 48,
      md: 44,
      sm: 44,
      xs: 24,
    },
    justifyContent: 'space-between',
    padding: {
      lg: 36,
      sm: 36,
      md: 24,
      xs: '18px 16px',
    },
    flexGrow: 1,
    '.mobile-layout &': {
      padding: '18px 16px!important',
    },
  },
  mainTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsWrapper: (t) => ({
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 12,
    [t.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  }),
  button: (t) => ({
    [t.breakpoints.down('sm')]: {
      flex: 1,
    },
    width: '130px',
  }),
  phoneInput: (t) => ({
    mt: {
      xs: -24,
      sm: -19,
    },
    [t.breakpoints.down('sm')]: {
      mb: 24,
    },
  }),
};

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 36,
  flexGrow: 1,
  '.tablet-layout &': {
    gap: 36,
  },
  '.mobile-layout &': {
    gap: 48,
  },
}));
