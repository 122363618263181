import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    padding: 0,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
      marginBottom: 24,
      backgroundColor: t.palette.common.white,
    },
  }),
  upperBlockContainer: (t) => ({
    flexGrow: 1,
    padding: '24px 24px 24px 24px',
    [t.breakpoints.between('sm', 'lg')]: {
      paddingTop: 0,
      paddingBottom: 36,
      width: 'auto',
    },
  }),
  mainTitle: {
    mb: 36,
    typography: {xs: '22_26_500', sm: '24_34_500'},
  },
  notSpecifiedText: (t) => ({
    pb: 12,
    color: t.palette.grey[500],
    typography: '14_18_500',
  }),
};
