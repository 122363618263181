import {Box, SxProps, Stack} from '@mui/material';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useResults} from '@src/store/results/hooks';
import {OverviewMobileProps} from '@src/types';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAccountProfile} from 'store/account/hooks';

import {Contacts} from './fragments/Contacts';
import {GeneralInfo} from './fragments/GeneralInfo';
import {OverviewEmptyFragment} from './fragments/OverviewEmptyFragment';
import {OverviewInsurance} from './fragments/OverviewInsurance';
import {OverviewLastResults} from './fragments/OverviewLastResults';
import {OverviewRelations} from './fragments/OverviewRelations';

const sx: Partial<Record<string, SxProps>> = {
  upperContainer: {
    display: 'flex',
    mb: {xs: 12},
    overflow: 'hidden',
    '&:last-child': {
      mb: 0,
    },
    '&:nth-child(2)': {
      overflow: {xs: 'visible', md: 'hidden'},
    },
  },
  upperSideFragments: {
    padding: '0',
    maxWidth: '100%',
  },
};

export const OverviewMobile: FC<OverviewMobileProps> = ({insurances}) => {
  const {t} = useTranslate('overview');
  const navigate = useNavigate();
  const {accountProfile, fetching} = useAccountProfile();
  const {data, isLoading} = useResults();
  const {mobile: isMobile} = useMQuery();

  if (fetching || isLoading) return null;

  return (
    <Stack>
      <Box sx={sx.upperContainer}>
        <WBox sx={sx.upperSideFragments} noPaddings>
          <GeneralInfo contact={accountProfile?.contact} avatarUrl={accountProfile?.avatarUrl} />
          <Contacts profile={accountProfile} />
        </WBox>
      </Box>

      <Box sx={sx.upperContainer}>
        <WBox noPaddings={true}>
          {accountProfile?.relations?.length
            ? (
              <OverviewRelations relations={accountProfile?.relations} cardsCount={2} />
            )
            : (
              <OverviewEmptyFragment
                title={t('RELATIONS')}
                button={t('ADD_RELATIONS')}
                isDisabled={false}
                onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ADD_RELATION)}
                description={t('YOU_DIDNT_ADD_ANY_RELATIONS_YET')}
                variant={!isMobile ? '14_18_500' : '12_16_500'}
              />
            )}
        </WBox>
      </Box>

      <Box sx={sx.upperContainer}>
        <WBox noPaddings={true}>
          {data?.length
            ? (
              <OverviewLastResults results={data} />
            )
            : (
              <OverviewEmptyFragment
                title={t('LAST_RESULTS')}
                description={t('YOU_DONT_HAVE_ANY_RESULTS_YET')}
                variant={!isMobile ? '14_18_500' : '12_16_500'}
              />
            )
          }
        </WBox>
      </Box>

      <Box sx={sx.upperContainer}>
        <WBox noPaddings={true}>
          {insurances.length
            ? (
              <OverviewInsurance insurances={insurances} cardsCount={2} />
            )
            : (
              <OverviewEmptyFragment
                isDisabled={false}
                title={t('INSURANCE')}
                button={t('ADD_INSURANCE')}
                onClick={() => navigate(ROUTERS_PATH.ADD_INSURANCE)}
                description={t('YOU_DIDNT_ADD_ANY_INSURANCE_YET')}
                variant={!isMobile ? '14_18_500' : '12_16_500'}
              />
            )}
        </WBox>
      </Box>
    </Stack>
  );
};
