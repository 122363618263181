import {Stack, Box} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {breadCrumbsLink, isShowButtonEdit} from '../helpers';

import {RelationAboutWithAccess} from './RelationAboutWithAccess/RelationAboutWithAccess';
import {RelationAboutWithoutAccess} from './RelationAboutWithoutAccess/RelationAboutWithoutAccess';
import {sx} from './styles';

export const RelationAbout = () => {
  const {t, ready} = useTranslate('connections');

  const navigate = useNavigate();
  const {id} = useParams();
  const {mobile: isMobile} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const isAccesses = relation?.accesses?.length;

  const handleClickEditButton = () => {
    if (id) {
      navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT_EDIT, {id}));
    }
  };

  if (!ready) return null;

  return (
    <>
      {
        isMobile && (
          <Breadcrumbs>
            <Link to={breadCrumbsLink(id)}>{getFullName(relation?.contact)}</Link>
            <Text>{t('ABOUT')}</Text>
          </Breadcrumbs>
        )
      }
      <Stack sx={sx.container}>
        {isAccesses
          ? <RelationAboutWithAccess relation={relation}/>
          : <RelationAboutWithoutAccess relation={relation} />
        }
        {
          isShowButtonEdit({isMobile, relation}) && (
            <Box sx={sx.editBthContainer}>
              <Button
                sx={sx.buttonEdit}
                variant='outlined'
                color='primary'
                onClick={handleClickEditButton}
              >
                {t('EDIT')}
              </Button>
            </Box>
          )}
      </Stack>
    </>
  );
};
