import {BirthSex, RelationTypeEnum} from '@src/api';
import {RequestRelationType} from '@src/api/relations';
import {Dispatch, SetStateAction} from 'react';

export const openModal = ({
  values,
  birthDate,
  birthSex,
  setIsModalOpened,
}: {
  values: Record<string, any>
  birthDate?: string
  birthSex?: string
  setIsModalOpened: Dispatch<SetStateAction<boolean>>
}) => {
  const relationshipTypes = [RelationTypeEnum.Spo, RelationTypeEnum.Husb, RelationTypeEnum.Wife];
  if (!values.relationShipType) return false;
  if (
    (!relationshipTypes.includes(values.relationShipType) && !birthDate) ||
    (values.relationShipType === RelationTypeEnum.Chd && birthSex === BirthSex.Unknown)
  ) {
    setIsModalOpened(true);
    return true;
  }
  return false;
};

export const mapRequestRelationTypeToRelationTypeEnum = (type: RequestRelationType | null): RelationTypeEnum | null => {
  switch (type) {
    case RequestRelationType.CHD:
      return RelationTypeEnum.Chd;
    case RequestRelationType.DEP:
      return RelationTypeEnum.Dep;
    case RequestRelationType.FCH:
      return RelationTypeEnum.Fch;
    case RequestRelationType.FTH:
      return RelationTypeEnum.Fth;
    case RequestRelationType.GCH:
      return RelationTypeEnum.Gch;
    case RequestRelationType.GRP:
      return RelationTypeEnum.Grp;
    case RequestRelationType.HUSB:
      return RelationTypeEnum.Husb;
    case RequestRelationType.MTH:
      return RelationTypeEnum.Mth;
    case RequestRelationType.SPO:
      return RelationTypeEnum.Spo;
    case RequestRelationType.WIFE:
      return RelationTypeEnum.Wife;
    default:
      return null;
  }
};
