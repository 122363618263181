// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {Text} from '@components';
import {css} from '@mui/material';
import {InputControl} from '@src/components/form/InputControl';
import {useTranslate} from '@src/i18n/useTranslate';
import React, {FC, useState} from 'react';
import {Button} from 'ui-kit';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAlert,
} from '../Dialog';

import {ISendResultProps} from './types';

export const SendResult: FC<ISendResultProps> = ({onSubmit}) => {
  const {t} = useTranslate('details');

  const [email, setEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const toggleSendResultWindow = () => {
    setIsOpen((prev) => !prev);
    setIsError(false);
  };

  const handleCancelSuccessModal = () => {
    setIsSuccess(false);
    setEmail('');
  };

  const handleChangeMail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);

  const toggleConfirm = () => {
    if (isOpen) {
      const reg = /\S+@\S+\.\S+/;
      if (reg.test(email)) {
        onSubmit(email);
        setIsSuccess(true);
        setIsError(false);
        toggleSendResultWindow();
        return;
      }
      setIsError(true);
    }
  };

  return (
    <>
      <Button
        onClick={toggleSendResultWindow}
        color="secondary"
        variant="contained"
        size="lg">
        {t('SEND')}
      </Button>
      <Dialog
        open={isOpen}
        title="Send Results"
        onClose={toggleSendResultWindow}
        sx={{backgroundColor: 'rgb(55 65 81 / 50%)'}}
        size="xs"
      >
        <DialogTitle onClose={toggleSendResultWindow}>{t('SEND_RESULTS')}</DialogTitle>
        <DialogContent>
          <InputControl
            placeholder="example@domain.com"
            label={t('BY_EMAIL')}
            css={(t) => css`
                margin-bottom: -20px;
                position: relative;
                label {
                  position: absolute;
                  top: 3px;
                  z-index: 100;
                  font-weight: 700;
                }
                div {
                  padding-left: 65px;
                  border-bottom-color: ${t.palette.grey[300]};
                }
              `}
            error={isError ? t('INCORRECT_EMAIL') : ''}
            onChange={handleChangeMail}
            value={email}
          />
        </DialogContent>
        <DialogActions
          sx={{justifyContent: 'space-between !important', flexDirection: 'row !important'}}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={toggleSendResultWindow}
            sx={{flexGrow: 'inherit !important'}}
          >
            {t('CANCEL')}
          </Button>
          <Button
            sx={{flexGrow: 'inherit !important'}}
            variant="contained"
            color="secondary"
            onClick={toggleConfirm}
          >
            {t('SUBMIT')}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogAlert
        type={'success'}
        onClose={handleCancelSuccessModal}
        open={isSuccess}
        title={t('SUCCESS')}
      >
        <Text
          sx={{
            whiteSpace: 'pre',
          }}
        >
          {t('RESULT_HAS_BEEN_SUCCESSFULLY_SENT', {email})}
        </Text>
      </DialogAlert>
    </>
  );
};
