import {SxProps, Theme} from '@mui/material';
import {PartialRecord} from '@src/types';

export const sx: PartialRecord<string, SxProps<Theme>> = {
  dialog: {
    '.MuiPaper-root': {
      overflow: 'visible',
      maxWidth: 'calc(100vw - 80px)',
      margin: {sm: '60px 0', xs: '60px 0 120px 0'},
      background: 'unset',
      borderBottom: 'unset',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '-40px',
    display: {
      xs: 'none',
      sm: 'block',
    },
  },
  image: {
    maxWidth: '100%',
  },
  loader: {
    height: '80vh',
    width: {lg: '40vw', sm: '90vw'},
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme => theme.colors.all.white,
  },
  mobileCloseButton: {
    display: {
      xs: 'block',
      sm: 'none',
    },
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    background: theme => theme.colors.all.white,
    p: 18,
  },
};
