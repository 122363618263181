
import {Box} from '@mui/material';
import {PropsWithChildren} from 'react';

import {IListWrapperProps} from './types';

export const ListWrapper = ({children, padding = '22px', noLast, spaceBetween, stylesSx}: PropsWithChildren<IListWrapperProps>) => (
  <Box
    sx={{
      '> div': {
        paddingBottom: padding,
        '> :first-child': {marginRight: spaceBetween || '12px'},
      },
      '> :last-child': {paddingBottom: noLast || padding},
      ...stylesSx,
    }}>
    {children}
  </Box>
);
