import {apiClient} from 'client/ApiClient';
import {HealthcareInsurancePolicyChangeRequest, InsuranceCarriersList, InsurancePlansList} from './models';
import {HealthcareInsurancePolicy} from '../account';

export const getInsurances = async () => {
  const response = await apiClient.get<HealthcareInsurancePolicy[]>('/api/account-insurances');
  return response.data;
};

export const getInsuranceCarriersList = async ({filter}: {filter: string}) => {
  const response = await apiClient.get<InsuranceCarriersList[]>('/api/insurance-carriers', {params: {filter}});
  return response.data;
};

export const getInsurancePlansList = async ({insuranceCarrierId}: {insuranceCarrierId: string}) => {
  const response = await apiClient.get<InsurancePlansList[]>('/api/insurance-plans', {params: {insuranceCarrierId}});
  return response.data;
};

export const createInsurance = async ({data}: {data: HealthcareInsurancePolicyChangeRequest}) => {
  const response = await apiClient.post('/api/account-insurances/health-policies-change', data);
  return response.data;
};

export * as insurancesApi from './';
export * from './models';
