/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {Stack, Typography, Box, ListItemText} from '@mui/material';
import {Gender, Race, SexualOrientationMap} from '@src/api';
import {RelationProfile} from '@src/api/relations';
import {Breadcrumbs, Link, Text} from '@src/components';
import Grid from '@src/components/Grid';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {makeMapOfAccess} from '@src/pages/Connections/fragments/Relations/helpers/functionHelpers';
import {ROUTERS_PATH} from '@src/routers';
import {MAX_INPUT_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {useTranslationMap} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, getFullName} from '@src/shared/utils';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {useContactForm, useContactRelations, useRelation} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Nullable} from '@src/types/NullableModel';
import {Formik} from 'formik';
import {isEqual} from 'lodash-es';
import {useDispatch} from 'react-redux';
import {generatePath, useNavigate, useParams} from 'react-router-dom';
import {Button, InputControl, Option, SelectControl} from 'ui-kit';

import {birthSexOptions, ethnicityOptions, genderOptions, sexualOrientationOptions, raceOptions} from '../constants';
import {matrix} from '../matrix';

import {fieldAccesses} from './constants';
import {isDisabledField} from './helpers';
import {StyledForm, sx} from './styles';
import {ISaveData} from './types';
import {validationSchema} from './validationSchema';

export const RelationAboutEdit = () => {
  const {t, ready} = useTranslate('connections');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {id} = useParams();
  const {edit} = useContactForm();
  const {mobile} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const goToAbout = () => {
    id && navigate(generatePath(ROUTERS_PATH.CONNECTIONS_RELATION_ABOUT, {id}));
  };

  const accesses = makeMapOfAccess({fieldAccesses, relation, matrix, entity: 'contact'});

  const {mapValue} = useTranslationMap();

  const saveData = ({
    birthDate,
    birthSex,
    ethnicity,
    firstName,
    lastName,
    middleName,
    gender,
    race,
    sexualOrientation,
    ssnTail,
  }: Nullable<ISaveData>) => {
    const data = {
      birthDate:
        birthDate === relation?.contact?.birthDateS
          ? null
          : {
            value: birthDate || '',
          },
      birthSex:
        t(birthSex as TKeys<'connections'>) === t(relation?.contact?.birthSex)
          ? null
          : {
            value: birthSex || '',
          },
      ethnicity:
        t(ethnicity as TKeys<'connections'>) === t(relation?.contact?.ethnicity)
          ? null
          : {
            value: ethnicity || '',
          },
      firstName:
        firstName === relation?.contact?.firstName
          ? null
          : {
            value: firstName || '',
          },
      gender:
        t(gender) === t(relation?.contact?.gender)
          ? null
          : {
            value: gender || '',
          },
      lastName:
        lastName === relation?.contact?.lastName
          ? null
          : {
            value: lastName || '',
          },
      middleName:
        middleName === relation?.contact?.middleName
          ? null
          : {
            value: middleName || '',
          },
      race:
        t(race) === t(relation?.contact?.race)
          ? null
          : {
            value: race || '',
          },
      sexualOrientation:
          t(sexualOrientation) === t(relation?.contact?.sexualOrientation)
            ? null
            : {value: SexualOrientationMap[sexualOrientation as keyof typeof SexualOrientationMap] || ''},
      ssnTail:
        ssnTail === relation?.contact?.ssnTail
          ? null
          : {
            value: ssnTail || '',
          },
    };
    edit({relationId, data});
    if (relation) {
      const newRelation: RelationProfile = {
        ...relation,
        contact: {
          ...relation.contact,
          birthDate,
          birthSex,
          ethnicity,
          firstName,
          lastName,
          middleName,
          gender,
          race,
          sexualOrientation,
          ssnTail,
        },
      };
      dispatch(relationsActions.setRelation({relation: newRelation}));
      goToAbout();
    }
  };

  const initialValue = {
    birthDate: relation?.contact.birthDateS || null,
    birthSex: relation?.contact.birthSex || null,
    ethnicity: relation?.contact.ethnicity || null,
    firstName: relation?.contact.firstName || null,
    lastName: relation?.contact.lastName || null,
    middleName: relation?.contact.middleName || null,
    gender: relation?.contact.gender || null,
    race: relation?.contact.race || null,
    sexualOrientation: relation?.contact.sexualOrientation || null,
    ssnTail: relation?.contact.ssnTail || null,
  };

  const create = (baseValue: string | null | undefined, changedValue: string | null | undefined) => {
    if (!baseValue && changedValue) {
      return true;
    }
    return false;
  };

  const update = (baseValue: string | null | undefined, changedValue: string | null | undefined) => {
    if (baseValue && changedValue) {
      return true;
    }
    return false;
  };

  const delete_ = (baseValue: string | null | undefined, changedValue: string | null | undefined) => {
    if (baseValue && !changedValue) {
      return true;
    }
    return false;
  };

  const validateField = (operations: string, baseValue: string | null | undefined, changedValue: string | null | undefined) => {
    if (isEqual(baseValue, changedValue)) return true;

    let valid = false;
    if (operations.includes('C')) {
      valid = create(baseValue, changedValue);
    }
    if (valid) return valid;
    if (operations.includes('U')) {
      valid = update(baseValue, changedValue);
    }
    if (valid) return valid;
    if (operations.includes('D')) {
      valid = delete_(baseValue, changedValue);
    }
    return valid;
  };

  if (!ready) return null;

  return (
    <>
      {mobile && (
        <Breadcrumbs>
          <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`}>
            {getFullName(relation?.contact)}
          </Link>
          <Text>{t('ABOUT')}</Text>
        </Breadcrumbs>
      )}
      <Stack sx={sx.container}>
        <Formik<typeof initialValue>
          enableReinitialize
          onSubmit={(values) => {
            if (!isEqual(initialValue, values)) {
              const normalizedValues = {
                ...values,
                firstName: normalizeString(values.firstName),
                lastName: normalizeString(values.lastName),
                middleName: normalizeString(values.middleName),
              };

              saveData(normalizedValues);
            }
          }}
          initialValues={initialValue}
          validationSchema={validationSchema({t, accesses, relation, validateField})}
        >
          {({handleChange, values, errors, touched}) => {
            const genderValues = mapValue(values.gender ?? '', 'gender');
            const raceValues = mapValue(values.race ?? '', 'race');
            const sexualOrientationValues = mapValue(values.sexualOrientation ?? '', 'sexualOrientation');

            const genderOptionsView = (name: Gender) => mapValue(name, 'gender');
            const raceOptionsView = (name: Race) => mapValue(name, 'race');

            return (
              <StyledForm>
                <Stack sx={sx.content}>
                  <Typography component="h4" sx={sx.title}>
                    {t('PATIENT_INFORMATION')}
                  </Typography>
                  <Grid container columnSpacing={48} rowSpacing={30}>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <InputControl
                        maxlength={MAX_INPUT_LENGTH}
                        name="firstName"
                        label={t('FIRST_NAME')}
                        value={values.firstName || ''}
                        error={touched?.firstName ? errors.firstName : ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <InputControl
                        maxlength={MAX_INPUT_LENGTH}
                        name="lastName"
                        label={t('LAST_NAME')}
                        value={values.lastName || ''}
                        error={touched?.lastName ? errors.lastName : ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <InputControl
                        maxlength={MAX_INPUT_LENGTH}
                        optional
                        name="middleName"
                        label={t('MIDDLE_NAME')}
                        value={values.middleName || ''}
                        error={touched?.middleName ? errors.middleName : ''}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <DateControl
                        labelTop
                        disabled={isDisabledField(accesses, 'birthDate')}
                        disabledFuture
                        placeholder='mm/dd/yyyy'
                        name="birthDate"
                        value={dateToFormat('P', values.birthDate) || ''}
                        onChange={handleChange}
                        label={t('DATE_OF_BIRTH')}
                        error={touched?.birthDate ? errors.birthDate : ''}
                      />
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        disabled={isDisabledField(accesses, 'birthSex')}
                        placeholder={t('SELECT')}
                        name="birthSex"
                        value={t(values.birthSex as TKeys<'connections'>)}
                        onChange={handleChange}
                        error={touched?.birthSex ? errors.birthSex : ''}
                        label={t('BIRTH_SEX')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {birthSexOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={{typography: '14_18_500'}}>{t(option.name)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        optional
                        placeholder={t('SELECT')}
                        name="race"
                        value={raceValues || ''}
                        error={touched?.race ? errors.race : ''}
                        onChange={handleChange}
                        label={t('RACE')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {raceOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={{typography: '14_18_500'}}>{raceOptionsView(option.name)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        optional
                        placeholder={t('SELECT')}
                        name="ethnicity"
                        value={values.ethnicity || ''}
                        error={touched?.ethnicity ? errors.ethnicity : ''}
                        onChange={handleChange}
                        label={t('ETHNICITY')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {ethnicityOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={{typography: '14_18_500'}}>{t(option.name as TKeys<'common'>)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        optional
                        placeholder={t('SELECT')}
                        name="gender"
                        value={genderValues || ''}
                        error={touched?.gender ? errors.gender : ''}
                        onChange={handleChange}
                        label={t('GENDER')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {genderOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={{typography: '14_18_500'}}>{genderOptionsView(option.name)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <SelectControl
                        optional
                        placeholder={t('SELECT')}
                        name="sexualOrientation"
                        value={sexualOrientationValues || ''}
                        error={touched?.sexualOrientation ? errors.sexualOrientation : ''}
                        onChange={handleChange}
                        label={t('SEXUAL_ORIENTATION')}
                        renderValue={(value: any) => t(value as TKeys<'common'>)}
                      >
                        {sexualOrientationOptions.map(option => (
                          <Option key={option.name} value={option.name}>
                            <ListItemText sx={{typography: '14_18_500'}}>{t(option.name as TKeys<'common'>)}</ListItemText>
                          </Option>
                        ))}
                      </SelectControl>
                    </Grid>
                    <Grid
                      xl={4}
                      lg={6}
                      md={4}
                      sm={6}
                      xs={12}>
                      <InputControl
                        type='number'
                        maxlength={MAX_SSN_LENGTH}
                        optional
                        name="ssnTail"
                        label={t('SSN_LAST_4')}
                        error={touched?.ssnTail ? errors.ssnTail : ''}
                        value={values.ssnTail || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Box sx={sx.buttonsContainer}>
                  <Button
                    color='primary'
                    variant="outlined"
                    onClick={goToAbout}>
                    {t('CANCEL')}
                  </Button>
                  <Button
                    disabled={isEqual(initialValue, values)}
                    color='primary'
                    type="submit"
                    variant="contained">
                    {t('SAVE')}
                  </Button>
                </Box>

              </StyledForm>
            );
          }}
        </Formik>
      </Stack>
    </>
  );
};
