import {Stack} from '@mui/material';
import {PageHeader as PageTitle} from '@src/components';
import {Tabs, Tab, useTabs} from '@src/components/TabPanel';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {SyntheticEvent, useEffect} from 'react';
import {useMatch, useNavigate} from 'react-router-dom';

import {sx} from './styles';

export const PageHeader = () => {
  const {t} = useTranslate('connections');
  const {mobile} = useMQuery();
  const navigate = useNavigate();
  const {index, handleChange: tabChange} = useTabs(1);
  const relationsPage = useMatch(ROUTERS_PATH.CONNECTIONS_RELATIONS);
  const accessesPage = useMatch(ROUTERS_PATH.CONNECTIONS_ACCESSES);

  useEffect(() => {
    const newEvent = new Event('click') as unknown as SyntheticEvent;

    if (relationsPage && index !== 1) {
      tabChange(newEvent, 1);
    }

    if (accessesPage && index !== 2) {
      tabChange(newEvent, 2);
    }
  }, [index, tabChange, relationsPage, accessesPage]);

  return (
    <Stack
      sx={sx.tabsContainer}
    >
      {!mobile && <PageTitle>{index === 1 ? t('RELATIONS') : t('ACCESSES')}</PageTitle>}
      <Tabs
        value={index}
        onChange={tabChange}
        sx={{marginLeft: mobile ? 0 : 40, '.MuiTabs-indicator': {height: '1.6px'}, mt: 6}}
        variant='fullWidth'
      >
        <Tab
          label="Relations"
          value={1}
          isActive={index === 1}
          sx={sx.tabs}
          onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_RELATIONS)}
        />

        <Tab
          value={2}
          label="Accesses"
          isActive={index === 2}
          sx={sx.tabs}
          onClick={() => navigate(ROUTERS_PATH.CONNECTIONS_ACCESSES)}
        />
      </Tabs>
    </Stack>
  );
};
