import {Table as TableView, EmptySearch} from '@components';
import s from '@src/pages/Details/Result.module.scss';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import React from 'react';

import {TableRow, useHeader} from './TableComponents';

interface TableProps {
  start: Date | null
  end: Date | null
  data: DynamicTestTransformed[]
  className?: string
  isModifiedScroll?: boolean
  onlySemanticBlock?: boolean
  isLoading?: boolean
}

export const Table: React.FC<TableProps> = ({data, start, end, className, isModifiedScroll, onlySemanticBlock, isLoading}) => {
  const {HEADER_LIST, gridTemplateColumns} = useHeader(data || [], start, end);
  const {mobile, tablet} = useMQuery();
  return (
    <TableView
      onlySemanticBlock={onlySemanticBlock}
      paddingLeft='40px'
      header={!mobile && !tablet}
      className={className}
      classNames={{
        wrapper: s.wrapper,
        body: s.body,
        header: s.header,
        row: s.row_table_dynamics,
      }}
      isModifiedScroll={isModifiedScroll}
      keyIndicator="code"
      data={data || []}
      gridTemplateColumns={gridTemplateColumns}
      headerList={HEADER_LIST}
      rowComponent={{component: TableRow as any, props: {headers: HEADER_LIST, start, end}}}
      loading={false}
      emptyData={<EmptySearch isLoading={isLoading} />}
    />
  );
};
