import {all, takeLeading, call, put} from 'redux-saga/effects';
import {insuranceActions as actions, insuranceActions} from './slice';
import {SagaPayload, SagaReturn} from '../types';
import {insurancesApi} from '@src/api/insurances';
import {errorHandler} from '../errorHandler';

export function * insuranceSaga () {
  yield all([
    takeLeading(actions.requestInsuranceCarriers, requestInsuranceCarriers),
    takeLeading(actions.requestInsurancePlans, requestInsurancePlans),
    takeLeading(actions.createInsurance, createInsurance),
    takeLeading(actions.editInsurance, editInsurance),
    takeLeading(actions.deleteInsurance, deleteInsurance),
    takeLeading(actions.requestInsurances, requestInsurances),
  ]);
}

function * requestInsurances () {
  try {
    const healthcareInsurancePolicies: Awaited<ReturnType<typeof insurancesApi.getInsurances>> =
      yield call(insurancesApi.getInsurances);
    yield put(actions.setInsurances({healthcareInsurancePolicies}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * requestInsuranceCarriers ({
  payload,
}: SagaPayload<typeof actions.requestInsuranceCarriers>) {
  try {
    const insuranceCarriers: Awaited<ReturnType<typeof insurancesApi.getInsuranceCarriersList>> =
      yield call(insurancesApi.getInsuranceCarriersList, {filter: payload.filter});
    yield put(actions.setInsuranceCarriers({insuranceCarriers}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * requestInsurancePlans ({payload}: SagaPayload<typeof actions.requestInsurancePlans>) {
  try {
    const insurancePlans: Awaited<ReturnType<typeof insurancesApi.getInsurancePlansList>> =
      yield call(insurancesApi.getInsurancePlansList, {
        insuranceCarrierId: payload.insuranceCarrierId,
      });
    yield put(actions.setInsurancePlans({insurancePlans}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * createInsurance ({
  payload,
}: SagaPayload<typeof actions.createInsurance>): SagaReturn {
  try {
    yield call(insurancesApi.createInsurance, {data: payload.data});
    const healthcareInsurancePolicies: Awaited<ReturnType<typeof insurancesApi.getInsurances>> =
    yield call(insurancesApi.getInsurances);
    yield put(actions.setInsurances({healthcareInsurancePolicies}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * editInsurance ({
  payload,
}: SagaPayload<typeof actions.createInsurance>): SagaReturn {
  try {
    yield call(insurancesApi.createInsurance, {data: payload.data});
    const healthcareInsurancePolicies: Awaited<ReturnType<typeof insurancesApi.getInsurances>> =
    yield call(insurancesApi.getInsurances);
    yield put(actions.setInsurances({healthcareInsurancePolicies}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}

function * deleteInsurance ({
  payload,
}: SagaPayload<typeof actions.createInsurance>): SagaReturn {
  try {
    yield put(insuranceActions.setStep({step: 'loading'}));
    yield call(insurancesApi.createInsurance, {data: payload.data});
    const healthcareInsurancePolicies: Awaited<ReturnType<typeof insurancesApi.getInsurances>> =
    yield call(insurancesApi.getInsurances);
    yield put(actions.setInsurances({healthcareInsurancePolicies}));
    yield put(insuranceActions.setStep({step: 'init'}));
  } catch (e) {
    errorHandler(e, actions.setCommonError);
  }
}
