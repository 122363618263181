import {Stack} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {EmptyFragment} from '@src/components/EmptyFragment';
import {WhiteBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useParams} from 'react-router-dom';
import {ResultsTab} from './ResultsTab/ResultsTab';

export const RelationResults = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);
  const isAccesses = !!relation?.accesses?.length;
  const {mobile} = useMQuery();

  return (
    <>
      {mobile && (
        <Breadcrumbs>
          <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`}>{getFullName(relation?.contact)}</Link>
          <Text>{t('RELATIONS')}</Text>
        </Breadcrumbs>
      )}
      <Stack flexGrow={1} gap={24}>
        {
          isAccesses
            ? <ResultsTab />
            : (
              <WhiteBox sx={{flexGrow: 1, padding: 24}}>
                <EmptyFragment
                  title={t('RESULTS')}
                  description={t('YOU_DONT_HAVE_ACCESS_TO_THIS_DATA')}
                  button={t('REQUEST_ACCESS')}
                  isButtonIcon={false}
                  noPaddings
                />
              </WhiteBox>
            )
        }
      </Stack>
    </>
  );
};
