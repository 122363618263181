import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  dialogContent: {
    p: {lg: '36px 24px 24px 24px !important'},
  },
  dialogActions: {
    flexDirection: 'row!important',
  },
};
