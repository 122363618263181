import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {toast} from 'react-toastify';
import {globalStateResetAction} from 'store/common/actions';
import {UUID} from 'types';

import {NormalizedState, createNormalizedState, updateNormalizedState, deleteByIdFromNormalizedState} from './../common/normalized';
export interface Notification {
  type: 'error' | 'success'
  id: string
  text: string
}
export interface NotificationsState {
  notifications: NormalizedState<Notification> | null
  dismissalQueue: UUID[] | null
}
export const initialState: NotificationsState = {
  notifications: null,
  dismissalQueue: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showErrors (_state, _action: PayloadAction<string[] | string>) {},
    showNotifications (state, {payload}: PayloadAction<Notification[]>) {
      if (!state.notifications) {
        state.notifications = createNormalizedState([]);
      }
      updateNormalizedState(state.notifications, payload);
      if (!state.dismissalQueue) {
        return;
      }
      for (const {id} of payload) {
        if (state.dismissalQueue.includes(id)) {
          state.dismissalQueue.splice(state.dismissalQueue.indexOf(id), 1);
        }
      }
    },
    dismissNotifications () {},

    _removeNotifications (state, {payload}: PayloadAction<UUID[]>) {
      if (state.notifications) { deleteByIdFromNormalizedState(state.notifications, payload); }
    },
    _setDismissalQueue (state, {payload}: PayloadAction<UUID[] | null>) {
      state.dismissalQueue = payload;
    },
  },
  extraReducers (builder) {
    builder.addCase(globalStateResetAction, () => {
      toast.dismiss();
      return initialState;
    });
  },
});

export const notifyStateName = slice.name;
export const notifyReducer = slice.reducer;
export const notifyActions = slice.actions;
