import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  IconButton,
  styled,
  Theme,
} from '@mui/material';
import {ReactComponent as ArrowGoIcon} from '@src/shared/assets/icons/24x24/arrow-go.svg';
import {useSidebarClassObserver} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';

import {iconBtnSx} from './styles';

const IconContainer = () => {
  return (
    <IconButton sx={iconBtnSx}>
      <ArrowGoIcon />
    </IconButton>
  );
};

export const AccordionSummary = (props: AccordionSummaryProps) => {
  const {lg: isDesktopLg} = useMQuery();
  const {isSidebarOpen} = useSidebarClassObserver();
  return (
    <MuiAccordionSummary
      sx={(t: Theme) => ({
        flexDirection: 'row-reverse',
        minHeight: '48px',
        paddingLeft: '20px !important',
        [t.breakpoints.down('md')]: {
          pl: '11px !important',
        },
        [t.breakpoints.up('lg')]: {
          pl: '29px !important',
          minWidth: isDesktopLg && isSidebarOpen ? '1034px' : 'initial',
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
          display: 'flex',
          alignItems: 'center',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
        '&.Mui-expanded': {
          minHeight: '48px',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg) translateX(6px) translateY(4px)',
        },
      })}
      expandIcon={<IconContainer />}
    >
      {props.children}
    </MuiAccordionSummary>
  );
};

export const Accordion = styled(MuiAccordion)(() => ({
  '&:before': {
    background: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));
Accordion.defaultProps = {
  elevation: 0,
};

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));
