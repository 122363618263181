import styled from '@emotion/styled';
import {ButtonBase} from '@mui/material';

export const ProfileMenuButtonStyled = styled(ButtonBase)({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 36,
  position: 'relative',
  justifyContent: 'end',
  '&:hover, &:active': {
    '.sandwich-menu-open &': {
      backgroundColor: 'transparent',
    },
  },
  '.tablet-layout.sandwich-menu-open &, .mobile-layout.sandwich-menu-open &': {
    color: 'white',
  },
  '.tablet-layout.sandwich-menu-open & svg path, .mobile-layout.sandwich-menu-open & svg path': {
    fill: 'white',
  },
});
