/** @jsxImportSource @emotion/react */
import {css, SxProps} from '@mui/material';
import {ReactComponent as IconSVGWarning} from '@src/shared/assets/images/icons/icon_error_round.svg';
import {ReactComponent as IconSVGInfo} from '@src/shared/assets/images/icons/icon_info.svg';
import {ReactComponent as IconSVGSuccess} from '@src/shared/assets/images/icons/icon_success.svg';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {Text} from 'components/Text';
import React, {FC, ReactNode} from 'react';
const localSx: Record<string, SxProps> = {
  commonStyles: {
    display: 'inline-flex',
    textAlign: 'left',
  },
};

interface Props {
  children: ReactNode
  type: 'warning' | 'success' | 'info' | 'error'
  className?: string
  sx?: SxProps
}
export const NotificationText: FC<Props> = ({type, children, className, sx}) => {
  if (type === 'warning') {
    return (
      <Text
        className={className}
        sx={[localSx.commonStyles, ...spreadSx(sx)]}
        weight={500}
        fz={16}
        lh={22}
      ><IconSVGWarning css={css`min-width: 24px;`} />{children}</Text>
    );
  }
  if (type === 'error') {
    return (
      <Text
        className={className}
        sx={[localSx.commonStyles, ...spreadSx(sx)]}
        weight={500}
        fz={16}
        lh={22}
      ><IconSVGWarning css={css`min-width: 24px;`} />{children}</Text>
    );
  }
  if (type === 'info') {
    return (
      <Text
        className={className}
        sx={[localSx.commonStyles, ...spreadSx(sx)]}
        weight={500}
        fz={16}
        lh={22}
      ><IconSVGInfo css={css`min-width: 20px;`} />{children}</Text>
    );
  }
  return (
    <Text
      className={className}
      sx={[localSx.commonStyles, ...spreadSx(sx)]}
      weight={500}
      fz={16}
      lh={22}
    ><IconSVGSuccess css={css`min-width: 20px;`} />{children}</Text>
  );
};
export const ErrorText: FC<{ children: ReactNode, className?: string }> = ({children, className}) =>
  <NotificationText type={'warning'} className={className}>{children}</NotificationText>;
