import {Typography} from '@mui/material';

export const status = (isConfirm?: boolean | null) => {
  if (typeof isConfirm !== 'boolean') return false;
  return (
    <Typography variant='14_18_500' sx={{padding: '3px 6px', backgroundColor: isConfirm ? '#E6F8F7' : '#FDECEF', color: isConfirm ? '#05B7AC' : '#EA3D5C'}}>
      {
        isConfirm
          ? 'Confirmed'
          : 'Unconfirmed'
      }
    </Typography>
  );
};
