import {Button, Stack, SxProps, Theme, Typography, Avatar, Box} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {EmptyFragment} from '@src/components/EmptyFragment';
import Grid from '@src/components/Grid';
import {WBox} from '@src/components/WhiteBox';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName, getUserInitials} from '@src/shared/utils';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {alpha} from '@src/theme/utils';
import {useParams} from 'react-router-dom';

import {groupRelationsByType} from '../helpers/functionHelpers';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  contentContainer: (t) => ({
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    padding: {xs: '6px 18px 12px 18px', sm: '12px 12px 12px 24px'},
    boxSizing: 'border-box',
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'start',
  }),
  container: (t) => ({
    flexGrow: 1,
    border: '1px solid',
    borderColor: alpha(t.palette.secondary.main, 14),
    padding: 24,
    height: 'auto',
    minWidth: 0,
    [t.breakpoints.between('sm', 'md')]: {
      padding: 0,
      border: 'none',
    },
    [t.breakpoints.down('sm')]: {
      width: 'auto',
      backgroundColor: t.palette.common.white,
      minHeight: 'calc(100vh - 190px)',
    },
  }),
  content: {
    display: 'flex',
    paddingTop: '12px',
    width: '100%',
  },
  avatar: (t) => ({
    width: {xs: 40, sm: 72},
    height: {xs: 40, sm: 72},
    marginRight: {xs: 12, sm: 16},
    backgroundColor: alpha(t.palette.secondary.main, 21),
    color: t.palette.common.white,
  }),
  personBirthDay: {
    minHeight: {xs: 38, sm: 50},
    paddingTop: 6,
    paddingBottom: {xs: 12, sm: 24},
    typography: {xs: '12_16_500', sm: '14_20_500'},
  },
  personType: {
    color: 'grey.400',
    minHeight: 24,
    maxWidth: '230px',
  },
  personName: {
    mt: 2,
    typography: {xs: '14_18_700', sm: '18_24_700'},
    pr: 15,
  },
  title: {
    mb: 24,
  },
  mainContainer: {
    flexDirection: 'unset',
    width: '100%',
    flexGrow: 1,
    '.mobile-layout &': {
      flexGrow: 'unset',
      gap: 34,
      flexDirection: 'column',
      minHeight: 'calc(100vh - 307px)',
    },
  },
  avatarTypography: {
    typography: {xs: '14_18_700', sm: '24_28_600'},
  },
  widthLimitContainer: {
    minWidth: '10%',
  },
  mobileBthContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};

export const RelationRelations = () => {
  const {t} = useTranslate('connections');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const {relation, fetching} = useRelation(relationId);
  const {mobile} = useMQuery();
  const isAccesses = relation?.accesses?.length;
  const relations = groupRelationsByType(relation?.relations);
  const userName = getFullName(relation?.contact);

  if (!fetching) return null;
  if (!relations.length && !isAccesses) {
    return (
      <Stack sx={sx.mainContainer}>
        {mobile && (
          <Breadcrumbs>
            <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`}>
              {getFullName(relation?.contact)}
            </Link>
            <Text>{t('RELATIONS')}</Text>
          </Breadcrumbs>
        )}
        <Stack sx={sx.container}>
          <EmptyFragment
            title={t('PATIENT_RELATIONS')}
            description={`${userName} ${t('DOESNT_HAVE_ANY_EVENTS_YET')}`}
            noPaddings
          />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={sx.mainContainer}>
      {mobile && (
        <Breadcrumbs>
          <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`}>
            {getFullName(relation?.contact)}
          </Link>
          <Text>{t('RELATIONS')}</Text>
        </Breadcrumbs>
      )}
      <Stack sx={sx.container}>
        {isAccesses
          ? (
            <>
              <Typography component="h4" variant="24_28_400" sx={sx.title}>
                {t('PATIENT_RELATIONS')}
              </Typography>
              <Grid container columnSpacing={24} spacing={24}>
                {relations?.map((person) => (
                  <Grid
                    xs={12}
                    md={6}
                    sm={6}
                    lg={6}
                    xl={4}
                    key={person?.id}
                  >
                    <Stack sx={sx.contentContainer}>
                      <Box sx={sx.content}>
                        <Avatar sx={sx.avatar} src={person?.avatarUrl}>
                          <Typography sx={sx.avatarTypography}>
                            {getUserInitials(person?.contact)}
                          </Typography>
                        </Avatar>
                        <Stack sx={sx.widthLimitContainer}>
                          <EllipsisTextTooltip
                            ellipsisText={getFullName(person?.contact)}
                            sx={sx.personName}
                          />
                          <EllipsisTextTooltip
                            ellipsisText={person?.contact?.birthDate ?? ''}
                            sx={sx.personBirthDay}
                          />
                          <EllipsisTextTooltip
                            ellipsisText={person?.types?.map((type) => t(type as TKeys<'connections'>)).join(', ') ?? ''}
                            variant="14_20_500"
                            sx={sx.personType}
                          />
                        </Stack>
                      </Box>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </>
          )
          : (
            <>
              <EmptyFragment
                title={t('PATIENT_RELATIONS')}
                description={t('YOU_DONT_HAVE_ACCESS_TO_THIS_DATA')}
                button={!mobile && t('REQUEST_ACCESS')}
                isButtonIcon={false}
                noPaddings
              />
              {mobile && (
                <WBox sx={sx.mobileBthContainer}>
                  <Button fullWidth variant="outlined" disabled>
                    {t('REQUEST_ACCESS')}
                  </Button>
                </WBox>
              )}
            </>
          )}
      </Stack>
    </Stack>
  );
};
