/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-invalid-void-type */
import {Stack, Typography, Box, ListItemText} from '@mui/material';
import {RelationType, StateType} from '@src/api';
import {Breadcrumbs, Link, ListWrapper, RowInformation, Text, WBox} from '@src/components';
import Grid from '@src/components/Grid';
import {DateControl} from '@src/components/form/DateControl';
import {TKeys, useTranslate} from '@src/i18n/useTranslate';
import {POSTAL_CODE_LENGTH, touchedData} from '@src/pages/Connections/constants';
import {MAX_ADDRESS_LENGTH, MAX_CITY_LENGTH, MAX_INPUT_LENGTH, MAX_SSN_LENGTH} from '@src/shared/constants/formFields';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {dateToFormat, getFullName} from '@src/shared/utils';
import {normalizeString} from '@src/shared/utils/normalizeString';
import {validateValue} from '@src/shared/utils/validateValue';
import {
  useContactRelations,
  useHealthPoliciesForm,
  useRelation,
} from '@src/store/relations/hooks';
import {relationsActions} from '@src/store/relations/slice';
import {Formik, FormikHelpers} from 'formik';
import {isEqual} from 'lodash-es';
import {useEffect, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, InputControl, Option, PhoneInputControl, SelectControl} from 'ui-kit';

import {getOnlyActiveInsurances, makeMapOfAccess, sortInsurances} from '../../../helpers/functionHelpers';
import {setGuarantorFieldsBySelf} from '../../../helpers/setGuarantorFieldsBySelf';
import {breadCrumbsLink, getHealthPoliciesRoute} from '../../helpers';
import {birthSexSelectOptions, relationshipTypeSelectOptions, statesSelectOptions} from '../constants';
import {matrix} from '../matrix';

import {fieldAccesses} from './constants';
import {StyledForm, sx} from './styles';
import {saveData} from './utils/saveData';
import {useValidationSchema} from './utils/useValidationSchema';

// eslint-disable-next-line

export const RelationHealthPoliciesEdit = () => {
  const {t, ready} = useTranslate('connections');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {edit} = useHealthPoliciesForm();
  const {mobile: isMobile, desktop: isDesktop} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const primary = sessionStorage.getItem('primary');
  const sortedInsurance = sortInsurances(relation?.healthcareInsurancePolicies);
  const insurance = sortedInsurance?.find((insurance) => insurance.primary === JSON.parse(primary || ''));
  const isValidateGuarantorEdit = insurance?.guarantor?.firstName;
  const isPrimaryInsurance = insurance?.primary;

  const onlyActiveInsurances = useMemo(() => getOnlyActiveInsurances(relation?.healthcareInsurancePolicies), [relation?.healthcareInsurancePolicies]);
  const accesses = makeMapOfAccess({fieldAccesses, relation, matrix, entity: 'guarantor'});

  const initialValue = {
    relationship: insurance?.guarantor?.relationType || null,
    birthDate: insurance?.guarantor?.birthDate || null,
    birthSex: insurance?.guarantor?.birthSex || null,
    firstName: insurance?.guarantor?.firstName || null,
    lastName: insurance?.guarantor?.lastName || null,
    middleName: insurance?.guarantor?.middleName || null,
    ssnTail: insurance?.guarantor?.ssnTail || null,
    state: insurance?.guarantor?.state ? StateType[insurance?.guarantor?.state] : null,
    city: insurance?.guarantor?.city || null,
    postalCode: insurance?.guarantor?.postalCode || null,
    address1: insurance?.guarantor?.main || null,
    address2: insurance?.guarantor?.additional || null,
    phone: insurance?.guarantor?.phone || null,
    endDate: insurance?.endDate || null,
    id: insurance?.id || null,
  };

  const columnSpacingValue = isDesktop ? 48 : 36;
  const spacingValue = isMobile ? 36 : 24;

  const handleClickBtnCancel = (resetForm: FormikHelpers<typeof initialValue>['resetForm']) => {
    navigate(getHealthPoliciesRoute(id));
    resetForm();
  };

  const handleClickBtnSave = (
    setTouched: FormikHelpers<typeof initialValue>['setTouched'],
    handleSubmit: VoidFunction,
  ) => {
    setTouched(touchedData);
    handleSubmit();
  };

  useEffect(() => {
    if (onlyActiveInsurances?.length === 2) {
      dispatch(relationsActions.setInsuranceStep({step: 'twoInsurances'}));
    }
    if (onlyActiveInsurances?.length === 1 && onlyActiveInsurances[0].primary) {
      dispatch(relationsActions.setInsuranceStep({step: 'onlyPrimaryInsurance'}));
    }
    if (onlyActiveInsurances?.length === 1 && !onlyActiveInsurances[0].primary) {
      dispatch(relationsActions.setInsuranceStep({step: 'onlySecondaryInsurance'}));
    }
    if (!onlyActiveInsurances?.length) {
      dispatch(relationsActions.setInsuranceStep({step: 'noInsurances'}));
    }
  }, [dispatch, onlyActiveInsurances]);

  const canEditOrCreate = (fieldName: string) => {
    if (accesses) {
      return !accesses[fieldName]?.includes('C') &&
        !accesses[fieldName]?.includes('D') &&
        !accesses[fieldName]?.includes('U');
    } else return false;
  };

  const validationSchema = useValidationSchema({accesses, insurance, isValidateGuarantorEdit: !!isValidateGuarantorEdit});

  if (!ready) return null;

  return (
    <>
      {isMobile && (
        <Breadcrumbs>
          <Link to={breadCrumbsLink(id)}>
            {getFullName(relation?.contact)}
          </Link>
          <Link to={getHealthPoliciesRoute(id)}>
            {t('HEALTH_POLICIES')}
          </Link>
          <Text>{isPrimaryInsurance ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}</Text>
        </Breadcrumbs>
      )}
      <Formik<typeof initialValue>
        enableReinitialize
        onSubmit={(values) => {
          if (!isEqual(initialValue, values)) {
            const normalizedValues = {
              ...values,
              firstName: normalizeString(values.firstName),
              lastName: normalizeString(values.lastName),
              middleName: normalizeString(values.middleName),
              city: normalizeString(values.city),
              address1: normalizeString(values.address1),
              address2: normalizeString(values.address2),
              relationType: values.relationship !== RelationType.SELF ? values.relationship : null,
            };

            saveData({...normalizedValues, edit, relationId});
            navigate(getHealthPoliciesRoute(id));
          }
        }}
        initialValues={initialValue}
        validationSchema={validationSchema}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          resetForm,
          setTouched,
        }) => {
          return (
            <StyledForm>
              <WBox sx={sx.whiteBox}>
                <Stack sx={sx.mainContainer}>
                  <Stack gap={18}>
                    <Typography sx={sx.insuranceTitle}>
                      {isPrimaryInsurance ? t('PRIMARY_INSURANCE') : t('SECONDARY_INSURANCE')}
                    </Typography>
                    <ListWrapper padding='12px' noLast={{xs: 0}}>
                      <RowInformation
                        name={t('INSURANCE_CARRIER')}
                        value={insurance?.insurancePlan?.carrier?.name}
                        noMargin
                        tooltip
                      />
                      <RowInformation
                        name={t('INSURANCE_PLAN')}
                        value={insurance?.insurancePlan?.name}
                        noMargin
                        tooltip
                      />
                      <RowInformation
                        tooltip
                        name={t('POLICY_ID')}
                        value={insurance?.number}
                        noMargin/>
                      <Grid container spacing={24} columnSpacing={48}>
                        <Grid
                          xl={4}
                          lg={6}
                          md={4}
                          sm={6}
                          xs={12}>
                          <DateControl
                            labelTop
                            placeholder='mm/dd/yyyy'
                            reverseYears
                            disabledPast
                            onlyFutureYears
                            name="endDate"
                            helperText={t('OPTIONAL')}
                            value={dateToFormat('P', values.endDate) || ''}
                            onTouched={setFieldTouched}
                            onChange={handleChange}
                            label={t('END_DATE')}
                            error={touched.endDate ? errors.endDate : ''}
                            optional
                          />
                        </Grid>
                      </Grid>
                    </ListWrapper>
                  </Stack>
                  <Stack sx={sx.flexContainerGuarantor}>
                    <Stack flexGrow={1} gap={16}>
                      <Box sx={sx.boxContainer}>
                        <Typography component="h4" sx={sx.title}>
                          {t('GUARANTOR')}
                        </Typography>
                      </Box>
                      <Grid container spacing={spacingValue} columnSpacing={columnSpacingValue}>
                        <Grid
                          xl={4}
                          lg={6}
                          md={4}
                          sm={6}
                          xs={12}>
                          <SelectControl
                            disabled={canEditOrCreate('relationType')}
                            optional
                            placeholder={t('SELECT')}
                            name="relationship"
                            value={values.relationship || ''}
                            error={touched?.relationship && !values.relationship ? errors.relationship : ''}
                            onChange={(e) => {
                              handleChange(e);
                              if (e.target.value === RelationType.SELF) {
                                setGuarantorFieldsBySelf(setFieldValue, relation);
                              }
                            }}

                            label={t('RELATIONSHIP')}
                            renderValue={(value: any) => t(value as TKeys<'common'>)}
                          >
                            {relationshipTypeSelectOptions.map(option => (
                              <Option key={option.name} value={option.name}>
                                <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                              </Option>
                            ))}
                          </SelectControl>
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack gap={40}>
                      <Stack sx={sx.content} flexGrow={1}>
                        <Grid container spacing={spacingValue} columnSpacing={columnSpacingValue}>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              disabled={canEditOrCreate('firstName')}
                              name="firstName"
                              label={t('FIRST_NAME')}
                              value={values.firstName || ''}
                              error={touched?.firstName ? errors.firstName : ''}
                              onChange={handleChange}
                              maxlength={MAX_INPUT_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              disabled={canEditOrCreate('lastName')}
                              name="lastName"
                              label={t('LAST_NAME')}
                              value={values.lastName || ''}
                              error={touched?.lastName ? errors.lastName : ''}
                              onChange={handleChange}
                              maxlength={MAX_INPUT_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              optional
                              disabled={canEditOrCreate('middleName')}
                              name="middleName"
                              label={t('MIDDLE_NAME')}
                              value={values.middleName || ''}
                              error={touched?.middleName ? errors.middleName : ''}
                              onChange={handleChange}
                              maxlength={MAX_INPUT_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <DateControl
                              labelTop
                              placeholder='mm/dd/yyyy'
                              disabled={() => canEditOrCreate('birthDate')}
                              name="birthDate"
                              disabledFuture
                              value={dateToFormat('P', values.birthDate) || ''}
                              onChange={handleChange}
                              onTouched={setFieldTouched}
                              label={t('DATE_OF_BIRTH')}
                              hideTip={false}
                              error={touched?.birthDate && !values?.birthDate ? errors.birthDate : ''}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <SelectControl
                              disabled={canEditOrCreate('birthSex')}
                              placeholder={t('SELECT')}
                              name="birthSex"
                              value={t(values.birthSex || ('' as TKeys<'connections'>)) || ''}
                              error={touched?.birthSex && !values.birthSex ? errors.birthSex : ''}
                              onChange={handleChange}
                              label={t('BIRTH_SEX')}
                              renderValue={(value: any) => t(value as TKeys<'common'>)}
                            >
                              {birthSexSelectOptions.map(option => (
                                <Option key={option.name} value={option.name}>
                                  <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                </Option>
                              ))}
                            </SelectControl>
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              type='number'
                              optional
                              disabled={canEditOrCreate('ssnTail')}
                              name="ssnTail"
                              label={t('SSN_LAST_4')}
                              error={touched?.ssnTail ? errors.ssnTail : ''}
                              value={values.ssnTail || ''}
                              onChange={handleChange}
                              maxlength={MAX_SSN_LENGTH}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack sx={sx.content} flexGrow={1}>
                        <Grid container spacing={spacingValue} columnSpacing={columnSpacingValue}>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <SelectControl
                              disabled={canEditOrCreate('state')}
                              placeholder={t('SELECT')}
                              name="state"
                              value={t(values.state) || ''}
                              error={touched?.state && !values.state ? errors.state : ''}
                              onChange={handleChange}
                              label={t('STATE')}
                              renderValue={(value: any) => t(value as TKeys<'common'>)}
                            >
                              {statesSelectOptions.map(option => (
                                <Option key={option.name} value={option.name}>
                                  <ListItemText sx={sx.ListItemText}>{t(option.name as TKeys<'common'>)}</ListItemText>
                                </Option>
                              ))}
                            </SelectControl>
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              disabled={canEditOrCreate('city')}
                              name="city"
                              label={t('CITY')}
                              value={values.city || ''}
                              error={touched?.city ? errors.city : ''}
                              onChange={handleChange}
                              maxlength={MAX_CITY_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              simpleTextInput
                              disabled={canEditOrCreate('postalCode')}
                              name="postalCode"
                              label={t('POSTAL_CODE')}
                              value={validateValue(values.postalCode || '')}
                              error={touched?.postalCode ? errors.postalCode : ''}
                              onChange={handleChange}
                              maxlength={POSTAL_CODE_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              disabled={canEditOrCreate('address1')}
                              name="address1"
                              label={t('ADDRESS_1')}
                              error={touched?.address1 ? errors.address1 : ''}
                              value={values.address1 || ''}
                              onChange={handleChange}
                              maxlength={MAX_ADDRESS_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <InputControl
                              optional
                              disabled={canEditOrCreate('address2')}
                              name="address2"
                              label={t('ADDRESS_2')}
                              error={touched?.address2 ? errors.address2 : ''}
                              value={values.address2 || ''}
                              onChange={handleChange}
                              maxlength={MAX_ADDRESS_LENGTH}
                            />
                          </Grid>
                          <Grid
                            xl={4}
                            lg={6}
                            md={4}
                            sm={6}
                            xs={12}>
                            <PhoneInputControl
                              disabled={canEditOrCreate('phone')}
                              optional
                              name="phone"
                              label={t('PHONE')}
                              error={touched?.phone ? errors.phone : ''}
                              placeholder={t('ENTER_A_PHONE')}
                              value={values.phone || ''}
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack sx={sx.buttonsWrapper}>
                  <Button
                    sx={sx.button}
                    variant="outlined"
                    color="primary"
                    onClick={() => handleClickBtnCancel(resetForm)}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button
                    disabled={isEqual(initialValue, values)}
                    sx={sx.button}
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickBtnSave(setTouched, handleSubmit)}
                  >
                    {t('SAVE')}
                  </Button>
                </Stack>
              </WBox>
            </StyledForm>
          );
        }}
      </Formik>
    </>
  );
};
