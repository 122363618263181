import {BirthSex, RelationTypeEnum} from '@src/api';
import {Dispatch, SetStateAction} from 'react';

export const openModal = ({
  values,
  birthDate,
  birthSex,
  setIsModalOpened,
}: {
  values: Record<string, any>
  birthDate: string | undefined
  birthSex: string | undefined
  setIsModalOpened: Dispatch<SetStateAction<boolean>>
}) => {
  const relationshipTypes = [RelationTypeEnum.Spo, RelationTypeEnum.Husb, RelationTypeEnum.Wife];
  if (!values.relationType) return false;
  if (
    (!relationshipTypes.includes(values.relationType) && !birthDate) ||
    (values.relationType === RelationTypeEnum.Chd && birthSex === BirthSex.Unknown)
  ) {
    setIsModalOpened(true);
    return true;
  }
  return false;
};
