import {StorageThumbnailType} from '@api';
import {Dialog} from '@components';
import {Box} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {FC} from 'react';
import {ReactComponent as CrossIcon} from 'shared/assets/icons/close.svg';
import {Button, IconButton} from 'ui-kit';

import {useFileFirstThumbnail} from '../../hooks';

import {sx} from './styles';
import {FileViewDialogProps} from './types';

export const FileViewDialog: FC<FileViewDialogProps> = ({id, isOpen, onClose}) => {
  const translate = useStorageTranslate();
  const {fileThumbnail, isLoading} = useFileFirstThumbnail({
    id: isOpen ? id : undefined,
    type: StorageThumbnailType.LARGE,
  });
  return (
    <Dialog
      open={isOpen}
      size={'auto'}
      scroll={'body'}
      sx={sx.dialog}
      onClose={onClose}
    >
      <IconButton
        strokeIcon
        color={'white'}
        variant={'text'}
        sx={sx.closeButton}
        onClick={onClose}>
        <CrossIcon />
      </IconButton>
      {isLoading && (
        <Box sx={sx.loader}>
          <PulseLoader loading/>
        </Box>
      )}
      {!isLoading && fileThumbnail && (
        <Box
          component={'img'}
          src={`data:${fileThumbnail.data.contentType};base64, ${fileThumbnail.data.data}`}
          alt={'Full view image'}
          sx={sx.image}
        />
      )}
      <Box sx={sx.mobileCloseButton}>
        <Button color={'secondary'} fullWidth onClick={onClose}>{translate('CLOSE')}</Button>
      </Box>
    </Dialog>
  );
};
