import {StorageBiomarker} from '@api';
import {Box, Typography} from '@mui/material';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {useMQuery} from '@src/shared/hooks';
import {Table} from '@src/shared/ui/Table';
import {createColumnHelper} from '@tanstack/react-table';
import {FC, useMemo} from 'react';

import {sx} from './styles';
import {BiomarkersTableProps} from './types';

export const BiomarkersTable: FC<BiomarkersTableProps> = ({biomarkers}) => {
  const t = useStorageTranslate();
  const columnHelper = createColumnHelper<StorageBiomarker>();
  const {tabletPortrait: isTablePortrait, mobile: isMobile} = useMQuery();

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        columnHelper.accessor('biomarker', {
          id: 'biomarker',
          header: t('BIOMARKERS'),
          cell: (props) => (
            <Typography variant={'14_18_500'} sx={sx.biomarker}>
              {props.getValue()}
            </Typography>
          ),
        }),
        columnHelper.accessor('value', {
          id: 'result',
          header: t('RESULT'),
          maxSize: 160,
          cell: (props) => <div>{`${props.getValue()} ${props.row.original.unit}`}</div>,
        }),
      ];
    }
    return [
      columnHelper.accessor('biomarker', {
        id: 'biomarker',
        header: t('BIOMARKERS'),
        cell: (props) => (
          <Typography variant={'14_18_500'} sx={sx.biomarker}>
            {props.getValue()}
          </Typography>
        ),
      }),
      columnHelper.accessor('value', {
        id: 'result',
        header: t('RESULT'),
        maxSize: isTablePortrait ? 100 : 160,
      }),
      columnHelper.accessor('unit', {
        id: 'units',
        header: t('UNITS'),
        maxSize: isTablePortrait ? 80 : 100,
      }),
    ];
  }, [columnHelper, isTablePortrait]);

  if (!biomarkers.length) {
    return null;
  }

  return (
    <Box sx={sx.tableWrapper}>
      <Table columns={columns} data={biomarkers} />
    </Box>
  );
};
