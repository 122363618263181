import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    padding: 24,
    gap: 40,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
      padding: 0,
      gap: 24,
    },
    [t.breakpoints.only('xs')]: {
      marginBottom: 24,
      width: 'auto',
      backgroundColor: t.palette.common.white,
    },
  }),
  content: {
    flexGrow: 1,
    gap: {xs: 24, lg: 16},
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: 12,
    mt: {lg: 60},
  },
  title: {
    typography: {xs: '18_24_500', lg: '24_34_500'},
  },
};

export const StyledForm = styled(Form)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: 1,
  [theme.breakpoints.between('sm', 'lg')]: {
    gap: 36,
  },
  [theme.breakpoints.only('xs')]: {
    gap: 48,
  },
}));
