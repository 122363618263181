// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {SelectChangeEvent, css, useTheme, Button, Box, Stack} from '@mui/material';
import {
  DatePickerRange,
  FilterWrapper,
  Link,
  Text,
  PageHeader,
  OnChangeSelectType,
  SelectOption,
  Breadcrumbs,
} from '@src/components';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@src/components/Dialog';
import Grid from '@src/components/Grid';
import {SelectLabels} from '@src/components/Select/Select';
import {WhiteBox, BoxBody, BoxPaddings} from '@src/components/WhiteBox';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {Graphics, Table} from '@src/pages/Dynamics/Board';
import {dataWithoutEmptyValue} from '@src/pages/Dynamics/Dynamic.helpers';
import {TIME_PERIOD_OPTIONS} from '@src/pages/Dynamics/Dynamics.constant';
import {RadioButton} from '@src/pages/Dynamics/Dynamics.styled';
import {dateInInterval} from '@src/pages/Results/Results.helpers';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as GraphIcon} from '@src/shared/assets/icons/graph.svg';
import {ReactComponent as Refresh} from '@src/shared/assets/icons/refresh.svg';
import {ReactComponent as TableIcon} from '@src/shared/assets/icons/table.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useDynamicsData, useRelation, useRelationDetails} from '@src/store/relations/hooks';
import {DynamicTestTransformed} from '@src/store/results/helpers';
import {alpha} from '@src/theme/utils';
import {addWeeks, addMonths, addYears} from 'date-fns';
import {useTranslate} from 'i18n/useTranslate';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import MultipleSelectCheckmarks from '../../helpers/SelectWithCheckBox';
import {getResultTitle} from '../ResultDetails/ResultDetails';

import {Stripe, sx} from './styles';
import {IFilter, IOptions} from './types';

const initialFilter: (testCodes?: string | null) => IFilter = (testCodes) => {
  return {
    end: null,
    start: null,
    testCodes: testCodes ? JSON.parse(decodeURI(testCodes)) : [],
  };
};

const initialDates: Pick<IFilter, 'start' | 'end'> = {
  end: null,
  start: null,
};

export const ResultDynamics: FC = () => {
  const {id, tests, companyId, resultId} = useParams();
  const {relationId} = useContactRelations(id);
  const parsedTestCodes: string[] = useMemo(() => tests ? JSON.parse(tests) : [], [tests]);

  const [timePeriod, setTimePeriod] = useState(TIME_PERIOD_OPTIONS[4].name);
  const {t} = useTranslate('details');
  const initial = initialFilter(tests);
  const [filter, setFilter] = useState(initial);
  const [isGraphics, setIsGraphics] = useState(true);
  const [testsOptions, setTestOptions] = useState<IOptions[]>([]);
  const [dates, setDates] = useState<Pick<IFilter, 'start' | 'end'>>(initialDates);
  const {mobile, tablet, desktop} = useMQuery();
  const theme = useTheme();
  const [selectedTests, setSelectedTests] = useState<SelectOption<string> | Array<SelectOption<string>>>([]);
  const [filterOpened, setFilterOpened] = useState(false);

  const {result} = useRelationDetails({relationId, resultId});
  const resultTitle = result?.orderDetails ? getResultTitle(result.orderDetails) : '...';
  const changeTimePeriod = (event: SelectChangeEvent<unknown>) => {
    const {value} = event.target;
    setTimePeriod(value as string);

    switch (value) {
      case 'One week':
        return setDates((prev) => ({
          ...prev,
          start: addWeeks(new Date(), -1),
          end: new Date(),
        }));
      case 'One month':
        return setDates((prev) => ({
          ...prev,
          start: addMonths(new Date(), -1),
          end: new Date(),
        }));
      case 'Six month':
        return setDates((prev) => ({
          ...prev,
          start: addMonths(new Date(), -6),
          end: new Date(),
        }));
      case 'One year':
        return setDates((prev) => ({
          ...prev,
          start: addYears(new Date(), -1),
          end: new Date(),
        }));
      default:
        break;
    }
  };

  const changeDisableVariantDynamics = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!(e.target instanceof HTMLElement)) return;
    const {textContent} = e.target;
    setIsGraphics(textContent === 'Graph');
  };

  const {relation} = useRelation(relationId);
  const {dynamics} = useDynamicsData(relationId);
  const [dynamicsWithTests, setDynamicsWithTests] = useState<DynamicTestTransformed[]>([]);

  useEffect(() => {
    if (!dynamics.testResults.length) {
      return;
    }
    const optionsT: IOptions[] = dynamics.testResults
      ? dynamics.testResults?.map((item) => ({
        name: item.testName as string,
        value: item.code,
      }))
      : [];
    setTestOptions(optionsT || []);

    const result = dataWithoutEmptyValue(dynamics.testResults, parsedTestCodes);
    setDynamicsWithTests(result);
  }, [dynamics, parsedTestCodes]);

  const refToDynamicsWithTests = useRef(dynamicsWithTests);
  refToDynamicsWithTests.current = dynamicsWithTests;
  useEffect(() => {
    const {start, end} = filter;
    if (!end || !start) {
      return;
    }
    const dataFil = refToDynamicsWithTests.current?.map((dataItem) => ({
      ...dataItem,
      resultLaboratoryAnalyses: dataItem?.resultLaboratoryAnalyses?.filter(
        (column) =>
          dateInInterval(
            start,
            end,
            column.biomaterialSamplingDate,
          ) &&
          column.value !== null &&
          column.value !== undefined,
      ),
    })) ?? [];
    setDynamicsWithTests(dataFil.filter((it) => it.resultLaboratoryAnalyses.length > 0));
  }, [filter]);

  const changeTestCodes: OnChangeSelectType<SelectOption<string>> = (event: any) => {
    const data = event?.target?.value;
    setSelectedTests(data ?? []);
    if (Array.isArray(data)) {
      return setFilter((prev) => ({
        ...prev,
        testCodes: data.map((v) => v.value),
      }));
    }
  };

  const doFiltration = (item: DynamicTestTransformed) => {
    return testCodes.length > 0 ? testCodes.includes((item.code) || '') : false;
  };

  const {testCodes, start, end} = filter;

  const setTestWithFilter = () => {
    if (!dynamics.testResults.length) {
      return;
    }
    const filtered1 = dynamics.testResults?.filter((item) => doFiltration(item));
    const filtered2 = dataWithoutEmptyValue(filtered1, testCodes);
    setDynamicsWithTests(filtered2);
  };

  const onApply = () => {
    setTestWithFilter();
    setFilter((prev) => ({...prev, ...dates}));
  };

  const onClear = () => {
    setFilter(initialFilter(tests));
    setDates(initialDates);
    setTimePeriod(TIME_PERIOD_OPTIONS[4].value);
    setFilter(initial);
    setSelectedTests([]);
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setDates((prev) => ({...prev, [name]: date}));
    setTimePeriod('custom');
  };

  const clearDate = () => {
    setDates(initialDates);
  };

  useEffect(() => {
    if (desktop) onApply();
  }, [filter]);

  if (!dynamics) return null;

  return (
    <Box sx={sx.mainContainer}>
      <Stack sx={sx.container}>
        <Stack sx={sx.topContainer}>
          <Breadcrumbs>
            {mobile && <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}`}>{getFullName(relation?.contact)}</Link>}
            <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/results`}>{t('RESULTS')}</Link>
            <Link to={`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/${id ?? ''}/${resultId ?? ''}/${companyId ?? ''}/results`}>{resultTitle}</Link>
            <Text>{t('DYNAMICS_OF_RESULTS')}</Text>
          </Breadcrumbs>
          {desktop && (
            <Stripe>
              <RadioButton
                active={!isGraphics}
                endIcon={<TableIcon fill={isGraphics ? theme.palette.secondary.main : theme.colors.all.white} />}
                onClick={changeDisableVariantDynamics}
              >
                {t('TABLE')}
              </RadioButton>
              <RadioButton
                active={isGraphics}
                endIcon={<GraphIcon fill={isGraphics ? theme.colors.all.white : theme.palette.secondary.main} />}
                onClick={changeDisableVariantDynamics}
              >
                {t('GRAPH')}
              </RadioButton>
            </Stripe>
          )
          }
        </Stack>
        <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
          {(mobile || tablet) && <PageHeader>{t('DYNAMICS_OF_RESULTS')}</PageHeader>}
          {(tablet || mobile) && (
            <div css={css`display: flex; gap: 40px`}>
              {tablet && (
                <div css={css`display: flex;`}>
                  <RadioButton
                    active={!isGraphics}
                    size={'large'}
                    endIcon={<TableIcon fill={isGraphics ? theme.palette.secondary.main : theme.colors.all.white} />}
                    onClick={changeDisableVariantDynamics}
                  >
                    {t('TABLE')}
                  </RadioButton>
                  <RadioButton
                    size={'large'}
                    active={isGraphics}
                    endIcon={<GraphIcon fill={isGraphics ? theme.colors.all.white : theme.palette.secondary.main} />}
                    onClick={changeDisableVariantDynamics}
                  >
                    {t('GRAPH')}
                  </RadioButton>
                </div>
              )}
              {(mobile || tablet) && <SettingsButton onClick={() => setFilterOpened(true)} />}
            </div>
          )}
        </div>
        {mobile && (
          <div css={css`display: flex; width: 100%`}>
            <RadioButton
              active={!isGraphics}
              size={'small'}
              fullWidth
              endIcon={<TableIcon fill={isGraphics ? theme.palette.secondary.main : theme.colors.all.white} />}
              onClick={changeDisableVariantDynamics}
            >
              {t('TABLE')}
            </RadioButton>
            <RadioButton
              size={'small'}
              fullWidth
              active={isGraphics}
              endIcon={<GraphIcon fill={isGraphics ? theme.colors.all.white : theme.palette.secondary.main} />}
              onClick={changeDisableVariantDynamics}
            >
              {t('GRAPH')}
            </RadioButton>
          </div>
        )}
      </Stack>
      <WhiteBox noPaddings noBorder>
        <BoxBody>
          {(!mobile && !tablet) && (
            <>
              <BoxPaddings paddings='24px' noTop>
                <FilterWrapper
                  onApply={onApply}
                  onClear={onClear}
                  css={css`label .date_picker {width: 100%}`}>
                  <Grid container spacing={36} flexGrow={1}>
                    <Grid xs={4} lg={4} xl={4}>
                      <DatePickerRange
                        variant='outlined'
                        label={t('DATE')}
                        startDate={dates.start}
                        endDate={dates.end}
                        startAdornmentName='Date'
                        setStartDate={changeDate('start')}
                        setEndDate={changeDate('end')}
                        clearDate={clearDate}
                        isLabelOutside={true}
                      />
                    </Grid>
                    <Grid xs={4} lg={4} xl={4}>
                      <SelectLabels
                        value={timePeriod ?? 'Select'}
                        onChange={changeTimePeriod}
                        options={TIME_PERIOD_OPTIONS}
                        contentPaddingLeft='103px'
                        label={t('TIME_PERIOD')} />
                    </Grid>
                    <Grid xs={4} lg={4} xl={4}>
                      <MultipleSelectCheckmarks
                        contentPaddingLeft='55px'
                        placeholder={t('SELECT')}
                        value={selectedTests}
                        options={testsOptions}
                        onChange={changeTestCodes}
                        label={t('TESTS')} />
                    </Grid>
                  </Grid>
                </FilterWrapper>
              </BoxPaddings>
            </>
          )}
          <div css={css`margin-top: -1px;`}>
            {isGraphics
              ? (
                <BoxPaddings>
                  <Graphics data={dynamicsWithTests} />
                </BoxPaddings>
              )
              : (
                <Table
                  start={start}
                  end={end}
                  onlySemanticBlock={false}
                  data={dynamicsWithTests} />
              )}
          </div>
        </BoxBody>
      </WhiteBox>
      <Dialog open={filterOpened} size={'md'}>
        <DialogTitle onClose={() => setFilterOpened(false)}>
          {t('FILTERS')}
        </DialogTitle>
        <DialogContent css={css`label, .MuiTextField-root, .MuiFormControlLabel-root > div {width: 100%; max-width: 100%;}`}>
          <Grid container spacing={'24px'}>
            <Grid xs={12} sm={6}>
              <DatePickerRange
                variant='outlined'
                label={t('DATE')}
                startDate={dates.start}
                endDate={dates.end}
                startAdornmentName='Date'
                setStartDate={changeDate('start')}
                setEndDate={changeDate('end')}
                clearDate={clearDate}
                isLabelOutside={false}
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <SelectLabels
                value={timePeriod ?? 'Select'}
                onChange={changeTimePeriod}
                options={TIME_PERIOD_OPTIONS}
                contentPaddingLeft='103px'
                label={t('TIME_PERIOD')} />
            </Grid>
            <Grid
              xs={12}
              sm={6}>
              <MultipleSelectCheckmarks
                placeholder={t('SELECT')}
                value={selectedTests}
                options={testsOptions}
                onChange={changeTestCodes}
                label={t('TESTS')} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            sx={(t) => ({
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '9px',
              backgroundColor: alpha(t.palette.secondary.main, 7),
              minWidth: 'unset',
              '&:hover': {backgroundColor: alpha(t.palette.secondary.main, 7), 'svg path': {fill: t.palette.secondary.light}},
              '&:active': {'svg path': {fill: 'blue.500'}},
            })}
            color='secondary'
            disableRipple
            disableFocusRipple
            onClick={onClear} ><Refresh /></Button>
          <Button
            variant='contained'
            color='primary'
            disableRipple
            sx={{width: '35%'}}
            onClick={() => { setFilterOpened(false); onApply(); }}>
            {t('APPLY')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
