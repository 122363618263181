import {Box, Stack, Typography} from '@mui/material';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {TKeys} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as EyeIcon} from '@src/shared/assets/icons/18x18/eye.svg';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as FavoriteFilledIcon} from '@src/shared/assets/icons/24x24/favorites-filled.svg';
import {ReactComponent as FavoriteIcon} from '@src/shared/assets/icons/24x24/favorites.svg';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-grey.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks';
import {formatSize} from '@src/shared/utils';
import {deleteStorageFile, updateStorageFile} from '@src/store/storage/slice';
import {useAppDispatch} from '@store';
import {format} from 'date-fns';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, IconButton} from 'ui-kit';

import {useStorageTranslate} from '../../hooks';

import {sx} from './styles';
import {DocumentCardProps} from './types';

export const DocumentCard: FC<DocumentCardProps> = ({file}) => {
  const {name, date, size, favorite, category, type, id} = file;
  const dispatch = useAppDispatch();
  const translate = useStorageTranslate();
  const navigate = useNavigate();
  const {mobile: isMobile} = useMQuery();

  const handleToggleFavorite = () => {
    void dispatch(updateStorageFile({...file, favorite: !favorite}));
  };

  const handleDeleteFile = () => {
    void dispatch(deleteStorageFile({id, successText: translate('DELETE_SUCCESS')}));
  };

  const goToDocumentView = () => {
    navigate(`${ROUTERS_PATH.STORAGE}/${id}`);
  };

  return (
    <Stack sx={sx.container}>
      <Stack sx={sx.mainInfo}>
        <Stack flexDirection={'row'} justifyContent={'space-between'} gap={12}>
          <Box>
            <EllipsisTextTooltip
              sx={sx.name}
              variant={isMobile ? '16_20_700' : '20_24_700'}
              heightCheck
              ellipsisText={name}
            />
          </Box>
          <IconButton color={'secondary-light'} onClick={handleToggleFavorite}>
            {favorite ? <FavoriteFilledIcon /> : <FavoriteIcon />}
          </IconButton>
        </Stack>
        <Stack gap={6}>
          <Stack flexDirection={'row'} gap={12}>
            <Typography variant={'14_18_700'}>{translate('CATEGORY')}</Typography>
            <Typography variant={'14_18_500'}>{translate(category as TKeys<'storage'>)}</Typography>
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'space-between'}>
            <Stack flexDirection={'row'} gap={24}>
              <Stack flexDirection={'row'} gap={12}>
                <Typography variant={'14_18_700'}>{translate('SIZE')}</Typography>
                <Typography variant={'14_18_500'}>{formatSize(size)}</Typography>
              </Stack>
              <Stack flexDirection={'row'} gap={12}>
                <Typography variant={'14_18_700'}>{translate('TYPE')}</Typography>
                <Typography variant={'14_18_500'}>{type}</Typography>
              </Stack>
            </Stack>
            <Typography variant={'14_18_500'} color={(theme) => theme.colors.personTypeColor}>
              {format(new Date(date), 'dd/MM/yyyy')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack flexDirection={'row'} justifyContent={'space-between'} sx={sx.buttonsContainer}>
        <Stack
          flexDirection={'row'}
          gap={{
            xs: 6,
            sm: 18,
          }}
        >
          {isMobile && (
            <>
              <IconButton
                color={'secondary'}
                variant={'text'}
                sx={sx.iconButton}
                onClick={goToDocumentView}
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                color={'secondary'}
                variant={'text'}
                sx={sx.iconButton}
                strokeIcon>
                <EditIcon />
              </IconButton>
              <IconButton color={'secondary'} variant={'text'} sx={sx.iconButton}>
                <ShareIcon />
              </IconButton>
            </>
          )}
          {!isMobile && (
            <>
              <Button
                color={'secondary'}
                startIcon={<EyeIcon />}
                variant={'text'}
                size={'sm'}
                sx={sx.actionButton}
                onClick={goToDocumentView}
              >
                {translate('VIEW')}
              </Button>
              <Button
                color={'secondary'}
                startIcon={<EditIcon />}
                strokeIcon
                variant={'text'}
                size={'sm'}
                sx={sx.actionButton}
              >
                {translate('EDIT')}
              </Button>
              <Button
                color={'secondary'}
                startIcon={<ShareIcon />}
                variant={'text'}
                size={'sm'}
                sx={sx.actionButton}
              >
                {translate('SHARE')}
              </Button>
            </>
          )}
        </Stack>
        {isMobile && (
          <IconButton variant={'text'} sx={sx.iconButton} onClick={handleDeleteFile}>
            <TrashIcon />
          </IconButton>
        )}
        {!isMobile && (
          <Button
            startIcon={<TrashIcon />}
            variant={'text'}
            size={'sm'}
            sx={sx.actionButton}
            onClick={handleDeleteFile}
          >
            {translate('DELETE')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
