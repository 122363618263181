import styled from '@emotion/styled';
import {ReactComponent as ProfileIcon} from '@src/shared/assets/images/icons/icon_profile.svg';
import {alpha} from '@src/theme/utils';
import {memo} from 'react';

const AvatarLayout = styled.div<{ useDefaultIcon?: boolean, src?: string, size?: number, square?: boolean }>`
  --size: ${({size}) => size ?? 34}px;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  border-radius: ${({square}) => square ? 0 : '50%'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
  background: ${({src, theme, useDefaultIcon}) => src ? `url(${src}) center center / cover no-repeat ${theme.colors.all.secondaryAvatar}` : (!useDefaultIcon && alpha(theme.palette.secondary.main, 21))};
`;

interface AvatarProps {
  src?: string
  className?: string
  size?: number
  square?: boolean
  text?: string
  children?: React.ReactNode
  useDefaultIcon?: boolean
};
export const Avatar = memo(({src, className, size, square, text, useDefaultIcon = false, children}: AvatarProps) => {
  return (
    <AvatarLayout
      role={'img'}
      useDefaultIcon={useDefaultIcon}
      className={className}
      src={src}
      square={square}
      size={size}>
      {!src && !text && (useDefaultIcon && <ProfileIcon />)}
      {(!src && text) ? getFirstWordsLettersFrom(text) : ''}
      {!src && children}
    </AvatarLayout>
  );
});
Avatar.displayName = 'Avatar';

export const getFirstWordsLettersFrom = (txt: string, amount = 2) => {
  return txt
    .trim()
    .split(' ')
    .filter(Boolean)
    .slice(0, amount)
    .map((w) => w.substring(0, 1))
    .join('')
    .toUpperCase();
};
