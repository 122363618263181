import {ROUTERS_PATH} from '@src/routers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {Outlet, useMatch} from 'react-router-dom';

import {TabletLayout} from '../RelationLayouts/TabletLayout';

import {RelationDesktopLayout} from './fragments/RelationDesktopLayout';

export const RelationMain = () => {
  const details = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DETAILS);
  const dynamics = useMatch(ROUTERS_PATH.CONNECTIONS_RELATION_RESULT_DYNAMICS);
  const dynamicsOrDetails = details || dynamics;
  const {mobile, tablet} = useMQuery();
  if (mobile || (tablet && dynamicsOrDetails)) {
    return <Outlet />;
  }

  if (tablet) return <TabletLayout/>;

  return <RelationDesktopLayout />;
};
