import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  title: {
    mb: 24,
    typography: {xs: '18_24_500', lg: '24_34_500'},
  },
  listContainer: {
    mb: {xs: 36, lg: 0},
  },
};
