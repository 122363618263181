export const initialValue = {
  insuranceCarrier: null,
  insurancePlan: null,
  insurancePolicyID: null,
  insuranceEndDate: null,
  relationship: null,
  birthDate: null,
  birthSex: null,
  firstName: null,
  lastName: null,
  middleName: null,
  ssnTail: null,
  state: null,
  city: null,
  postalCode: null,
  address1: null,
  address2: null,
  phone: null,
  endDate: null,
  policyId: null,
};
