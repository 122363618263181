import {RelationProfile} from '@src/api/relations';
import {useTranslate} from '@src/i18n/useTranslate';
import {compareDatesWithToday} from '@src/shared/utils/compareDatesWithToday';
import {phoneNotRequiredUpdateValidator} from '@src/shared/utils/phoneNotRequiredUpdateValidator';
import {customRequiredValidation, postalCodeValidator, ssnTailValidator} from '@src/shared/utils/validators';
import * as Yup from 'yup';

export const useValidationSchema = ({relation}: { relation: RelationProfile | null }) => {
  const {t} = useTranslate('connections');

  return Yup.object().shape({
    firstName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    lastName: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    birthDate: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    birthSex: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    state: Yup.string().required(t('REQUIRED_FIELD')).nullable(),
    city: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    address1: Yup.string().required(t('REQUIRED_FIELD')).nullable().test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t)),
    endDate: Yup.string().nullable()
      .test('is-insurancePlan', t('DATE_MUST_BE_GREATER_THAN_TODAY'), function (value) {
        if (value) {
          return !!compareDatesWithToday(value, false, true);
        } else return true;
      }),
    phone: phoneNotRequiredUpdateValidator(t),
    postalCode: postalCodeValidator(t),
    ssnTail: ssnTailValidator(t),
    insuranceCarrier: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD')),
    insurancePlan: Yup.string()
      .nullable()
      .required(t('REQUIRED_FIELD'))
      .test('is-insurancePlan', t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE'), function (value) {
        return !relation?.healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            } else {
              return insurance.insurancePlan?.name === value &&
                insurance.number === this.parent.policyId;
            }
          },
        );
      }),
    policyId: Yup.string()
      .required(t('REQUIRED_FIELD'))
      .nullable()
      .test('custom-validation', t('REQUIRED_FIELD'), customRequiredValidation(t))
      .test('is-policyId', t('INSURANCE_PLAN_AND_POLICY_ID_MUST_BE_UNIQUE'), function (value) {
        return !relation?.healthcareInsurancePolicies?.find(
          (insurance) => {
            if (insurance.endDate) {
              return compareDatesWithToday(insurance.endDate, false, true) &&
                insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            } else {
              return insurance.insurancePlan?.name === this.parent.insurancePlan &&
                insurance.number === value;
            }
          },
        );
      }),
  });
};
