import {useTranslate} from '@src/i18n/useTranslate';
import {TFunction} from 'i18next';
import {parsePhoneNumber} from 'react-phone-number-input';
import * as yup from 'yup';

import {postalCodeValidation} from './postalCodeValidation';

export const phoneValidator = (
  t: ReturnType<typeof useTranslate<'common'>>['t'],
  accountPhone: string,
) =>
  yup
    .string()
    .nullable()
    .required(t('PLEASE_ENTER_A_PHONE'))
    .test('is-phone', t('INCORRECT_PHONE_NUMBER'), function (value) {
      if (accountPhone === value?.split(' ').join('')) {
        return false;
      }
      return true;
    })
    .test('validate-phone', t('PLEASE_ENTER_A_VALID_PHONE'), (value) => {
      const phoneNumber = parsePhoneNumber(value ?? '', 'US');
      return phoneNumber?.isValid() ?? false;
    });

export const postalCodeValidator = (
  t: ReturnType<typeof useTranslate<'common'>>['t'],
) =>
  yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test('is-valid-postal-code', t('ENTER_A_VALID_ZIP_CODE'),
      (value) => postalCodeValidation(value),
    );

export const phoneNotRequiredValidator = (
  t: ReturnType<typeof useTranslate<'common'>>['t'],
  accountPhone?: string,
) =>
  yup.string()
    .nullable()
    .notRequired()
    .test('custom-phone-validation', t('INCORRECT_PHONE_NUMBER'), function (value) {
      if (!value || value.trim() === '') {
        return true;
      }
      if (accountPhone && accountPhone === value.split(' ').join('')) {
        return false;
      }
      const phoneNumber = parsePhoneNumber(value ?? '', 'US');
      return phoneNumber?.isValid() ?? false;
    });
export const ssnTailValidator = (
  t: ReturnType<typeof useTranslate<'common'>>['t'],
) =>
  yup.string()
    .nullable()
    .matches(/^\d{4}$/, t('MIN_SSN_LENGTH'));

export const customRequiredValidation = (t: TFunction) => {
  return function (this: yup.TestContext, value?: string | null) {
    const isNotEmpty = value !== null && value !== undefined && value.trim() !== '';
    return isNotEmpty || this.createError({message: t('REQUIRED_FIELD')});
  };
};
