/** @jsxImportSource @emotion/react */
import {EmotionJSX} from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import {Box, ButtonBase, css as _, IconButton} from '@mui/material';
import {ReactComponent as IconSVGArrowLeft} from '@src/shared/assets/images/icons/icon_arrow_left.svg';
import {IconContainer} from 'components/IconContainer';
import {useTranslate} from 'i18n/useTranslate';
import {FC} from 'react';

interface YMSelectorProps {
  backOnly?: boolean
  header: string | number | EmotionJSX.Element
  className?: string
  back?: () => void
  forward?: () => void
  onHeaderClick?: () => void
}

export const YearMonthHeader: FC<YMSelectorProps> = ({
  header,
  className,
  back,
  forward,
  onHeaderClick,
  backOnly,
}) => {
  const {t} = useTranslate('common');
  return (
    <SelectorContainer className={className}>
      <Box css={_`align-items: center; display: flex;`}>
        {!backOnly && <ButtonSelector onClick={onHeaderClick}>{header}</ButtonSelector>}
      </Box>
      <Box sx={{display: 'flex'}}>
        <Box>
          {backOnly
            ? (
              <IconButton onClick={back} css={_`border-radius: 20px;`}>
                <div css={_`display: flex; align-items: center; gap: 5px;`}>
                  <IconContainer>
                    <IconSVGArrowLeft css={_`path {stroke: white}`} />
                  </IconContainer>
                  <span css={_`font-size: 16px; color: white`}>{t('BACK')}</span>
                </div>
              </IconButton>
            )
            : (
              <IconButton onClick={back}>
                <IconContainer>
                  <IconSVGArrowLeft css={_`path {stroke: #19A1B7}`} />
                </IconContainer>
              </IconButton>
            )}
        </Box>
        <Box>
          {!backOnly && (
            <IconButton onClick={forward}>
              <IconContainer>
                <IconSVGArrowLeft css={_`transform: rotate(180deg); path {stroke: #19A1B7}`} />
              </IconContainer>
            </IconButton>
          )}
        </Box>
      </Box>
    </SelectorContainer>
  );
};

const SelectorContainer = styled.div(() => _`
  display: flex;
  background-color: #374151;
  padding: 5px 5px;
  justify-content: space-between;
  margin-bottom: 20px;
`);

const ButtonSelector = styled(ButtonBase)(({theme}) => _`
  padding: 5px 10px;
  border-radius: 20px;
  &:focus {
    border: 1px solid ${theme.colors.all.focus};
  }
`);
