// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, ButtonBase, SelectChangeEvent, Stack, Typography} from '@mui/material';
import {Switch} from '@src/components/form/SwitchControl';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {initialFilter, initiialSort, useHeader} from '@src/pages/Results/Results.constant';
import {dateInInterval, sortByDate} from '@src/pages/Results/Results.helpers';
import {TableRow} from '@src/pages/Results/TableRow';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useRelationResults} from '@src/store/relations/hooks';
import {Result} from 'api';
import {
  EmptySearch,
  Table,
  ITableScrollUp,
  Text,
  PageHeader,
} from 'components';
import {BoxBody, WhiteBox} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import {ResultsFilterDialog} from './components/ResultsFilterDialog';
import {ResultsFilterTable} from './components/ResultsFilterTable';
import {ResultsTable} from './components/ResultsTable';
import {sx} from './styled';

export const ResultsTab = () => {
  const {t} = useTranslate('results');
  const {id} = useParams();
  const {relationId} = useContactRelations(id);
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState(initiialSort);
  const {results: data, fetching: isLoading} = useRelationResults(relationId);
  const [result, setResult] = useState<Result[]>();
  const {mobile, tablet, desktop, lg} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const refTable = useRef<ITableScrollUp>();
  const [allExpanded, setAllExpanded] = useState(false);
  const [selectItem, setSelectItem] = useState('');
  const [reportedPosition, setReportedPosition] = useState(true);
  const [collectedPosition, setCollectedPosition] = useState(true);

  useEffect(() => {
    if (data && data.length > 0) setResult(data);
  }, [data]);

  useEffect(() => {
    if (result) {
      const arrayForSort = [...result];
      setResult(arrayForSort.sort((a, b) => sortByDate(a, b, sort)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const getPhysicians = [
    ...new Map(data?.map((item) => [item?.physician?.id, item.physician])).values(),
  ]
    .map((item) => ({value: item?.id || '', name: getFullName(item), key: item?.id || ''}))
    .filter((item) => item.name && item.value);

  const onApply = useCallback(() => {
    const {physician, reportDateFrom, reportDateTo, collectionDateFrom, collectionDateTo} =
      filter;

    const doFiltration = (resultItem: Result) => {
      const filterByPhysician = physician ? resultItem.physician?.id === physician.value : true;

      const filterByRepoterDate =
        reportDateFrom || reportDateTo
          ? dateInInterval(reportDateFrom, reportDateTo, resultItem.reported)
          : true;

      const filterByCollectionDate =
        collectionDateFrom || collectionDateTo
          ? dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected)
          : true;
      return filterByPhysician && filterByRepoterDate && filterByCollectionDate;
    };
    if (data) setResult(data?.filter((item) => doFiltration(item)));
    refTable.current?.scrollUp();
  }, [data, filter]);

  const onClear = () => {
    setSelectItem('');
    setFilter(initialFilter);
    if (data) setResult(data);
  };

  const onClearReportDate = () => {
    setFilter((prev) => {
      return {
        ...prev,
        reportDateFrom: null,
        reportDateTo: null,
      };
    });
  };

  const onClearCollectionDate = () => {
    setFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const changeSelectPhysicians = (event: SelectChangeEvent<string>) => {
    setSelectItem(event.target.value);
    const data = getPhysicians.find((item) => item.name === event.target.value);
    if (data && event) {
      const item = event?.target?.value;
      setFilter((prev) => ({
        ...prev,
        physician: item ? {value: data?.value, name: data?.name} : null,
      }));
    }
  };

  const filterResultByDate = (columnName: string) => () => {
    setSort((prev) => ({...prev, sortBy: columnName, sortDesc: !prev.sortDesc}));
    if (columnName === 'reported') setReportedPosition((prev) => !prev);
    if (columnName === 'collected') setCollectedPosition((prev) => !prev);
  };

  const {HEADER_LIST} = useHeader(filterResultByDate, reportedPosition, collectedPosition);

  const handleExpandAll = () => {
    setAllExpanded((prev) => {
      return !prev;
    });
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setFilter((prev) => ({...prev, [name]: date}));
  };

  const handleOpenFilterClick = () => {
    setFilterOpened(true);
  };

  useEffect(() => {
    if (desktop) onApply();
  }, [desktop, filter, onApply]);

  if (isLoading) {
    return <EmptySearch isLoading={isLoading} />;
  }

  if (data?.length === 0) {
    return (
      <Stack>
        <PageHeader>{t('RESULTS')}</PageHeader>
        <Typography
          variant='14_18_500'
          sx={sx.patientNotFound}
        >
          {t('NO_RESULTS_WERE_FOUND')}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {mobile && (
        <div css={_`display: flex; justify-content: space-between; align-items: center`}>
          <PageHeader>{t('RESULTS')}</PageHeader>
          {mobile && <SettingsButton onClick={handleOpenFilterClick} />}
        </div>
      )}
      {tablet && (
        <div css={_`display: flex; justify-content: space-between; align-items: center`}>
          <Typography component="h4" variant="24_28_400" sx={{pl: 24}}>
            {t('RESULTS')}
          </Typography>
          {tablet && (
            <SettingsButton
              sx={{marginRight: 12, boxSizing: 'initial'}}
              onClick={handleOpenFilterClick}
            />
          )}
        </div>
      )}
      <WhiteBox noPaddings>
        {desktop && (
          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{p: 18}}>
            <Typography component="h4" variant="24_34_500" >
              {t('RESULTS')}
            </Typography>
            {lg && <SettingsButton onClick={handleOpenFilterClick} />}
          </Stack>
        )}
        <BoxBody>
          {mobile && (
            <ButtonBase
              onClick={handleExpandAll}
              css={_`
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      height: 76px;
                      align-items: center;
                      padding: 0 24px;
                      `}
            >
              <Text>{t('EXPAND_ALL')}</Text>
              <Switch css={_`margin-right: -12px;`} readOnly checked={allExpanded} />
            </ButtonBase>
          )}
          {(!lg && !(mobile || tablet)) && (
            <ResultsFilterTable
              filter={filter}
              selectItem={selectItem}
              changeSelectPhysicians={changeSelectPhysicians}
              getPhysicians={getPhysicians}
              changeDate={changeDate}
              onClearReportDate={onClearReportDate}
              onClearCollectionDate={onClearCollectionDate}
              onClear={onClear}
              onApply={onApply}
            />
          )
          }
          {(mobile || tablet) && (
            <Table
              css={_`margin-top: -1px`}
              classNames={{header: 'table-header'}}
              ref={refTable}
              gridTemplateColumns={mobile ? '4fr 3fr 1fr' : '1fr 1fr 1fr 0.5fr'}
              data={result || []}
              headerList={HEADER_LIST}
              loading={isLoading ?? false}
              rowComponent={{component: TableRow, props: {allExpanded, onlySemanticBlock: false}}}
              onlySemanticBlock={false}
              emptyData={<EmptySearch isLoading={isLoading} />}
            />
          )}
          {!mobile && !tablet &&
            (
              <ResultsTable
                result={result}
                isLoading={isLoading}
              />
            )}
        </BoxBody>
      </WhiteBox>
      <ResultsFilterDialog
        filter={filter}
        selectItem={selectItem}
        filterOpened={filterOpened}
        setFilterOpened={setFilterOpened}
        changeSelectPhysicians={changeSelectPhysicians}
        getPhysicians={getPhysicians}
        changeDate={changeDate}
        onClearReportDate={onClearReportDate}
        onClearCollectionDate={onClearCollectionDate}
        onClear={onClear}
        onApply={onApply}
      />
    </>
  );
};
