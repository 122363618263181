import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: (t) => ({
    p: 0,
    flexGrow: 1,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      border: 'none',
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
      mb: 24,
      backgroundColor: t.palette.common.white,
    },
  }),
  buttonsContainer: {
    mt: {lg: 24},
    display: 'flex',
    justifyContent: 'end',
    pb: 24,
    pr: 24,
  },
};
