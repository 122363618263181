import {postalCodeValidator} from '@src/shared/utils/validators';
import {TFunction} from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (t: TFunction) => Yup.object({
  state: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD')),
  city: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'notEmpty',
      t('REQUIRED_FIELD'),
      function (value) {
        return value !== null && value !== undefined && value.trim() !== '';
      },
    ),
  postalCode: postalCodeValidator(t),
  address1: Yup.string()
    .nullable()
    .required(t('REQUIRED_FIELD'))
    .test(
      'notEmpty',
      t('REQUIRED_FIELD'),
      function (value) {
        return value !== null && value !== undefined && value.trim() !== '';
      },
    ),
  address2: Yup.string()
    .nullable(),
});
