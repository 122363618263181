import {Link, PageHeader as PageTitle, RowInformation, WBox} from '@components';
import {Box, Stack} from '@mui/material';
import {TKeys} from '@src/i18n/useTranslate';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {BiomarkersTable} from '@src/pages/StorageDocument/components/BiomarkersTable';
import {FileView} from '@src/pages/StorageDocument/components/FileView';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as DownloadIcon} from '@src/shared/assets/icons/download.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {DateFormat, dateFormatted, formatSize} from '@src/shared/utils';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {useFileData} from './hooks';
import {ContentContainer, sx} from './styles';

export const StorageDocument = () => {
  const translate = useStorageTranslate();
  const {mobile: isMobile} = useMQuery();
  const {id} = useParams();
  const {isSidebarOpen} = useSidebarClassObserver();
  const {handleDownloadFile, fileDetails, handleToggleFavorite} = useFileData(id);

  const downloadFileHandler = () => {
    void handleDownloadFile();
  };

  return (
    <Stack sx={sx.mainContainer}>
      <Stack sx={sx.breadcrumbs}>
        <ArrowLeftIcon />
        <Link to={ROUTERS_PATH.STORAGE}>{translate('BACK_TO_STORAGE')}</Link>
      </Stack>
      <PageTitle
        height={'auto'}
        itemAction={ !isMobile
          ? (
            <Button
              color={'secondary'}
              variant={'outlined'}
              startIcon={<DownloadIcon />}
              onClick={downloadFileHandler}
            >
              {translate('DOWNLOAD_DOCUMENT')}
            </Button>
          )
          : undefined}
      >
        {fileDetails?.name}
      </PageTitle>
      <ContentContainer isSidebarOpen={isSidebarOpen}>
        <WBox sx={sx.leftContainer}>
          <BiomarkersTable biomarkers={fileDetails?.biomarkers || []} />
          <Stack gap={12}>
            <RowInformation
              variant={'16_20_700'}
              valueTypographyVariant={'16_20_500'}
              noMargin
              value={translate(fileDetails?.category as TKeys<'storage'>)}
              name={translate('CATEGORY')}
            />
            <RowInformation
              variant={'16_20_700'}
              valueTypographyVariant={'16_20_500'}
              noMargin
              value={dateFormatted(fileDetails?.date, DateFormat.DATE_SHORT)}
              name={translate('DATE')}
            />
            <RowInformation
              variant={'16_20_700'}
              valueTypographyVariant={'16_20_500'}
              noMargin
              value={fileDetails?.type}
              name={translate('DOCUMENT_TYPE')}
            />
            <RowInformation
              variant={'16_20_700'}
              valueTypographyVariant={'16_20_500'}
              noMargin
              value={formatSize(fileDetails?.size || 0)}
              name={translate('DOCUMENT_SIZE')}
            />
            <RowInformation
              variant={'16_20_700'}
              valueTypographyVariant={'16_20_500'}
              noMargin
              noWrap
              value={fileDetails?.description}
              name={translate('DESCRIPTION')}
            />
          </Stack>
        </WBox>
        <FileView details={fileDetails} isSidebarOpen={isSidebarOpen} onToggleFavorite={handleToggleFavorite}/>
      </ContentContainer>
      {isMobile && (
        <Box sx={sx.mobileDownloadButton}>
          <Button
            color={'secondary'}
            onClick={downloadFileHandler}
            fullWidth
          >
            {translate('DOWNLOAD_DOCUMENT')}
          </Button>
        </Box>
      )}
    </Stack>
  );
};
