import {Palette, createTheme} from '@mui/material';
import {ButtonSize, buttonWidthMap} from '@src/components/Button/Button';
import {ReactComponent as IconSVGCheckboxChecked} from '@src/shared/assets/images/icons/icon_checkbox_checked.svg';
import {ReactComponent as IconSVGCheckbox} from '@src/shared/assets/images/icons/icon_checkbox_unchecked.svg';

import {ThemeColors} from './types';
import {alpha} from './utils';
import {initialColors, paletteColors} from './vivicaUiKitColors';

export const customColors: ThemeColors = {
  bodyColor: initialColors.secondary5,
  defaultTextColor: initialColors.grey2,
  personTypeColor: initialColors.grey8,
  alertColor: initialColors.grey9,
  defaultBackground: alpha(initialColors.secondary, 7),
  defaultGreyColor: initialColors.grey3,
  sliderBgColor: initialColors.primary,
  breadcrumbsText: initialColors.grey10,
  primary: initialColors.primary,
  secondary: initialColors.secondary,
  secondaryLight: initialColors.secondaryLight,
  secondary5: initialColors.secondary5,
  secondary10: initialColors.secondary10,
  dividerColor: initialColors.grey11,
  button: {
    primary: {
      default: {
        background: initialColors.primary,
        color: initialColors.white,
      },
      hover: {
        background: initialColors.primaryDark,
      },
      active: {
        background: initialColors.primaryLight,
      },
      disabled: {
        background: initialColors.grey5,
      },
    },
    secondary: {
      default: {
        background: initialColors.secondary,
        color: initialColors.white,
      },
      hover: {
        background: initialColors.secondaryDark,
      },
      active: {
        background: initialColors.secondaryLight,
      },
      disabled: {
        background: initialColors.grey5,
      },
    },
    outlined: {
      default: {
        background: initialColors.white,
        color: initialColors.primary,
        border: initialColors.primary,
      },
      hover: {
        color: initialColors.primaryDark,
        border: initialColors.primaryDark,
      },
      active: {
        color: initialColors.primaryLight,
        border: initialColors.primaryLight,
      },
      disabled: {
        color: initialColors.grey5,
        border: initialColors.grey5,
      },
    },
    outlinedSecondary: {
      default: {
        background: initialColors.transparent,
        color: initialColors.secondary,
        border: initialColors.secondary,
      },
      hover: {
        color: initialColors.secondaryDark,
        border: initialColors.secondaryDark,
      },
      active: {
        color: initialColors.secondaryLight,
        border: initialColors.secondaryLight,
      },
      disabled: {
        color: initialColors.grey5,
        border: initialColors.grey5,
      },
    },
    outlinedPrimary: {
      default: {
        background: initialColors.transparent,
        color: initialColors.primary,
        border: initialColors.primary,
      },
      hover: {
        color: initialColors.primaryDark,
        border: initialColors.primaryDark,
      },
      active: {
        color: initialColors.primaryLight,
        border: initialColors.primaryLight,
      },
      disabled: {
        color: initialColors.grey5,
        border: initialColors.grey5,
      },
    },
    white: {
      default: {
        background: initialColors.white,
        color: initialColors.grey2,
        border: initialColors.transparent,
      },
      hover: {
        background: initialColors.grey6,
      },
      active: {
        background: initialColors.grey4,
      },
      disabled: {
        color: initialColors.grey5,
        border: initialColors.grey5,
      },
    },
    transparent: {
      default: {
        background: initialColors.transparent,
        color: initialColors.secondary,
        border: initialColors.transparent,
      },
      hover: {
        color: alpha(initialColors.secondary, 70),
      },
      active: {
        color: alpha(initialColors.secondary, 30),
      },
      disabled: {
        color: initialColors.grey5,
      },
    },
    red: {
      default: {
        background: initialColors.transparent,
        color: initialColors.primary,
        border: initialColors.transparent,
      },
      hover: {
        color: initialColors.primaryLight,
      },
      active: {
        color: initialColors.primaryLight,
      },
      disabled: {
        color: initialColors.grey5,
      },
    },
  },
  link: {
    color: initialColors.secondary,
    hover: initialColors.secondaryDark,
    active: initialColors.secondaryLight,
  },
  background: initialColors.white,
  background2: initialColors.secondary5,
  borderLight: initialColors.secondary10,
  borderBlueLight: initialColors.blueLight,
  grayBackground: initialColors.grayBackground,
  blueCardBorder: initialColors.blueCardBorder,
  secondary400: initialColors.secondary400,
  activeGrayBackground: initialColors.activeGrayBackground,
  all: {...initialColors, ...paletteColors},
} as const;

export const muiTheme = createTheme({
  spacing: 1,
  shape: {
    borderRadius: 0,
  },
  header: {
    height: {
      sm: 66,
      md: 84,
      lg: 60,
    },
  },
  pageTitleHeight: 42,
  typography: {
    fontFamily: 'Raleway, RalewayLocal, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '36px',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
    },
    body1: {
      fontSize: '20px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '18px',
      fontWeight: 400,
    },
    '10_14_500': {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: 500,
    },
    '12_16_500': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    '12_16_600': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 600,
    },
    '12_16_700': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 700,
    },
    '12_18_400': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    '12_18_500': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500,
    },
    '12_18_700': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 700,
    },
    '12_20_500': {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '12_20_600': {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    '12_24_500': {
      fontSize: '12px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    '14_16_500': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    '14_18_400': {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 400,
    },
    '14_18_500': {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 500,
    },
    '14_18_600': {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 600,
    },
    '14_18_700': {
      fontSize: '14px',
      lineHeight: '18px',
      fontWeight: 700,
    },
    '14_20_400': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 400,
    },
    '14_20_500': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '14_22_500': {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 500,
    },
    '14_24_500': {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    '14_20_700': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 700,
    },
    '16_20_500': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    '16_20_600': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
    },
    '16_20_700': {
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 700,
    },
    '16_24_500': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    '16_24_700': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    '16_24_400': {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    '18_24_500': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    '18_24_600': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
    },
    '18_24_700': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    '18_28_500': {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    '20_24_700': {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 700,
    },
    '22_26_400': {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 400,
    },
    '22_26_500': {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 500,
    },
    '22_26_700': {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 700,
    },
    '22_26_600': {
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: 600,
    },
    '22_34_500': {
      fontSize: '22px',
      lineHeight: '34px',
      fontWeight: 500,
    },
    '24_28_400': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 400,
    },
    '24_28_500': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    '24_28_600': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    '24_32_700': {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    '24_34_500': {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 500,
    },
    '24_34_700': {
      fontSize: '24px',
      lineHeight: '34px',
      fontWeight: 700,
    },
    '32_36_600': {
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 600,
    },
    '32_36_700': {
      fontSize: '32px',
      lineHeight: '36px',
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          textTransform: 'initial',
          boxShadow: 'none',
          transition: 'none',
          minWidth: buttonWidthMap[ButtonSize.Sm],
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          fontWeight: 500,
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
          '.MuiButton-startIcon, .MuiButton-endIcon': {
            width: 24,
            height: 24,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          '.MuiButton-endIcon': {
            marginLeft: 6,
          },
          '.MuiButton-startIcon': {
            marginRight: 6,
          },
          [theme.breakpoints.down('lg')]: {
            '&.MuiButton-text': {
              minWidth: 'auto',
            },
          },
          ...(ownerState.variant === 'text' && {
            '&:hover': {
              background: 'transparent',
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
              'svg path': {
                fill: theme.palette.grey[300],
              },
            },
            'svg path': {
              fill: (theme.palette[ownerState.color as keyof Palette] as any)?.main,
            },
            '@media (hover: hover)': {
              '&:hover': {
                color: (theme.palette[ownerState.color as keyof Palette] as any)?.light,
                path: {
                  fill: (theme.palette[ownerState.color as keyof Palette] as any)?.light,
                },
              },
            },
            '&:active': {
              color: (theme.palette[ownerState.color as keyof Palette] as any)?.dark,
              path: {
                fill: (theme.palette[ownerState.color as keyof Palette] as any)?.dark,
              },
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            color: (theme.palette[ownerState.color as keyof Palette] as any)?.main,
            borderColor: (theme.palette[ownerState.color as keyof Palette] as any)?.main,
            'svg path': {
              fill: (theme.palette[ownerState.color as keyof Palette] as any)?.main,
            },
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
              borderColor: theme.palette.grey[300],
              'svg path': {
                fill: theme.palette.grey[300],
              },
            },
            '@media (hover: hover)': {
              '&:hover': {
                color: (theme.palette[ownerState.color as keyof Palette] as any)?.light,
                borderColor: (theme.palette[ownerState.color as keyof Palette] as any)?.light,
                'svg path': {
                  fill: (theme.palette[ownerState.color as keyof Palette] as any)?.light,
                },
                background: 'transparent',
              },
            },
            '&:active': {
              color: (theme.palette[ownerState.color as keyof Palette] as any)?.dark,
              borderColor: (theme.palette[ownerState.color as keyof Palette] as any)?.dark,
              'svg path': {
                fill: (theme.palette[ownerState.color as keyof Palette] as any)?.dark,
              },
            },
            ...(ownerState.color === 'white' && {
              color: 'white',
              '@media (hover: hover)': {
                '&:hover': {
                  color: theme.palette.grey['100'],
                  borderColor: theme.palette.grey['100'],
                  'svg path': {
                    fill: theme.palette.grey['100'],
                  },
                },
              },
              '&:active': {
                color: theme.palette.grey['200'],
                borderColor: theme.palette.grey['200'],
                'svg path': {
                  fill: theme.palette.grey['200'],
                },
              },
              'svg path': {
                fill: 'white',
              },
            }),
          }),
          ...(ownerState.variant === 'contained' && {
            '&.Mui-disabled': {
              color: theme.palette.grey[300],
              'svg path': {
                fill: theme.palette.grey[300],
              },
              background: theme.palette.grey[200],
            },
            ...(ownerState.color === 'primary' && {
              '@media (hover: hover)': {
                '&:hover': {
                  background: theme.palette.primary.light,
                  boxShadow: 'none',
                },
              },
              '&:active': {
                background: theme.palette.primary.dark,
              },
            }),
            ...(ownerState.color === 'secondary' && {
              '@media (hover: hover)': {
                '&:hover': {
                  background: theme.palette.secondary.light,
                  boxShadow: 'none',
                },
              },
              '&:active': {
                background: theme.palette.secondary.dark,
              },
            }),
            ...(ownerState.color === 'white' && {
              color: theme.palette.grey[700],
              '@media (hover: hover)': {
                '&:hover': {
                  background: theme.palette.grey[100],
                },
              },
              '&:active': {
                background: theme.palette.grey[200],
              },
              'svg path': {
                fill: theme.palette.grey[700],
              },
            }),
          }),
        }),
        sizeSmall: {
          height: 32,
        },
        sizeLarge: {
          height: 54,
        },
        sizeMedium: {
          height: 42,
          padding: '6px 12px',
        },
        startIcon: {
          marginLeft: 0,
        },
      },
      defaultProps: {
        variant: 'contained',
        disableRipple: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          sx: {
            backgroundColor: 'white',
            boxShadow: 'none',
            borderRadius: 0,
            '.mobile-layout &': {marginLeft: '24px', marginRight: '24px'},
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        style: {fontFamily: 'inherit'},
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            background: 'none',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: () => ({
          boxSizing: 'border-box',
          backgroundColor: '#fff',
          borderRadius: 0,
          color: paletteColors.grey['500'],
          '&:hover': {
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            backgroundColor: '#fff',
          },
          '&.Mui-disabled': {
            background: 'none',
          },
          '& > div': {
            padding: '6px 0 6px 0',
          },
          '& > div > div ': {
            color: '#abb3bd',
          },
          '& .MuiInputBase-root ': {
            height: 56,
          },
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ownerState}) => ({
          color: paletteColors.grey[800],
          margin: 0,
          ...(ownerState.labelPlacement === 'top' && {alignItems: 'flex-start'}),
        }),
        label: () => ({
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: () => ({
          fontSize: '14px',
          color: paletteColors.grey['500'],
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {margin: '0 2px 0 0'},
        listbox: () => ({
          padding: 0,
          border: '1px solid #DEE9ED',
          maxHeight: '200px',
          overflow: 'auto',
          '& > li': {
            fontSize: '14px',
            fontWeight: 400,
            '&.MuiAutocomplete-option': {
              paddingTop: 0,
              paddingBottom: 0,
            },
            '&.MuiAutocomplete-option[aria-selected="true"]': {
              background: 'none',
            },
            '&.MuiAutocomplete-option[aria-selected="true"].Mui-focused,&.MuiAutocomplete-option:hover':
            {
              color: initialColors.secondary,
              background: '#EEF5F8 ',
            },
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <IconSVGCheckbox />,
        checkedIcon: <IconSVGCheckboxChecked />,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'none',
          },
        },
      },
    },
  },
  colors: {...customColors},
});
