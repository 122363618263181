import {ROUTERS_PATH} from '@src/routers';
import {MouseEventHandler} from 'react';
import {generatePath, useNavigate} from 'react-router-dom';

export const useHandleClickButton = (id?: string): MouseEventHandler<HTMLButtonElement> => {
  const navigate = useNavigate();
  return () => {
    if (id) {
      navigate(generatePath(`${ROUTERS_PATH.CONNECTIONS_RELATIONS}/relation/:id/edit/contacts`, {id}));
    }
  };
};
