import {StorageThumbnailType} from '@api';
import {Box, Stack} from '@mui/material';
import {PulseLoader} from '@src/components/PulseLoader';
import {useStorageTranslate} from '@src/pages/Storage/hooks';
import {useFileFirstThumbnail} from '@src/pages/StorageDocument/hooks';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as EditIcon} from '@src/shared/assets/icons/edit-grey.svg';
import {ReactComponent as LikeFilledIcon} from '@src/shared/assets/icons/like_filled.svg';
import {ReactComponent as LikeIcon} from '@src/shared/assets/icons/like_outed.svg';
import {ReactComponent as TrashIcon} from '@src/shared/assets/icons/trash.svg';
import {useMQuery} from '@src/shared/hooks';
import {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, IconButton} from 'ui-kit';

import {DeleteDialog} from '../DeleteDialog';
import {FileViewDialog} from '../FileViewDialog';

import {RightContainer, sx} from './styles';
import {FileViewProps} from './types';

export const FileView: FC<FileViewProps> = ({details, isSidebarOpen, onToggleFavorite}) => {
  const translate = useStorageTranslate();

  const {mobile: isMobile} = useMQuery();
  const navigate = useNavigate();
  const {fileThumbnail, isLoading} = useFileFirstThumbnail({
    id: details?.id,
    type: StorageThumbnailType.MEDIUM,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleDialogOpen = () => setIsDialogOpen(true);
  const handleDialogClose = () => setIsDialogOpen(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const handleNavigateToList = () => {
    navigate(ROUTERS_PATH.STORAGE);
  };

  return (
    <RightContainer isSidebarOpen={isSidebarOpen}>
      {isMobile && (
        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <IconButton color={'secondary'} variant={'text'} onClick={onToggleFavorite}>
            {details?.favorite ? <LikeFilledIcon /> : <LikeIcon />}
          </IconButton>
          <Stack flexDirection={'row'}>
            <IconButton variant={'text'} onClick={handleDeleteDialogOpen}>
              <TrashIcon />
            </IconButton>
            <IconButton variant={'text'} color={'secondary'} sx={sx.mobileShareButton}>
              <ShareIcon />
            </IconButton>
            <IconButton variant={'text'} strokeIcon color={'secondary'}>
              <EditIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}
      {!isMobile && (
        <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
          <Button
            variant={'text'}
            color={'secondary'}
            startIcon={details?.favorite ? <LikeFilledIcon /> : <LikeIcon />}
            sx={sx.actionButton}
            onClick={onToggleFavorite}
          >
            {details?.favorite ? translate('DELETE_FROM_FAVORITES') : translate('ADD_TO_FAVORITES')}
          </Button>
          <Stack flexDirection={'row'} gap={18}>
            <Button
              variant={'text'}
              startIcon={<TrashIcon />}
              sx={sx.actionButton}
              onClick={handleDeleteDialogOpen}
            >
              {translate('DELETE')}
            </Button>
            <Button
              variant={'text'}
              color={'secondary'}
              startIcon={<ShareIcon />}
              sx={sx.actionButton}
            >
              {translate('SHARE')}
            </Button>
            <Button
              variant={'text'}
              color={'secondary'}
              startIcon={<EditIcon />}
              strokeIcon
              sx={sx.actionButton}
            >
              {translate('EDIT')}
            </Button>
          </Stack>
        </Stack>
      )}
      {isLoading && (
        <Box sx={sx.loader}>
          <PulseLoader loading />
        </Box>
      )}
      {!isLoading && fileThumbnail && (
        <Box sx={sx.imageContainer} onClick={handleDialogOpen}>
          <Box
            sx={sx.image}
            component={'img'}
            src={`data:${fileThumbnail.data.contentType};base64, ${fileThumbnail.data.data}`}
            alt={details?.name}
          />
        </Box>
      )}
      <FileViewDialog id={details?.id} isOpen={isDialogOpen} onClose={handleDialogClose} />
      {details && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          id={details.id}
          name={details.name}
          onClose={handleDeleteDialogClose}
          afterDeleteAction={handleNavigateToList}
        />
      )}
    </RightContainer>
  );
};
