import {Tooltip as MuiTooltip, tooltipClasses, TooltipProps, styled} from '@mui/material';
import {useRef, useState} from 'react';

export const Tooltip = styled(
  ({className, title, disabled, ...props}: TooltipProps & { disabled?: boolean, arrowMarginTop?: string}) => {
    const [open, setOpen] = useState(false);
    const tmtCloseRef = useRef(0);
    const tmtOpenRef = useRef(0);
    const handleClose = () => {
      clearTimeout(tmtOpenRef.current);
      tmtCloseRef.current = window.setTimeout(() => {
        setOpen(false);
      }, 500);
    };
    const handleOpen = (_: React.MouseEvent, timeout?: boolean) => {
      if (!disabled) {
        clearTimeout(tmtCloseRef.current);
        tmtOpenRef.current = window.setTimeout(
          () => {
            setOpen(true);
          },
          timeout ?? true ? 500 : 0,
        );
      }
    };

    return (
      <MuiTooltip
        onMouseEnter={handleOpen}
        onClick={(e) => {
          handleOpen(e, false);
        }}
        onMouseLeave={handleClose}
        open={open}
        onClose={handleClose}
        title={disabled ? '' : title}
        TransitionProps={disabled ? {timeout: 0} : undefined}
        {...props}
        classes={{popper: className}}
      />
    );
  },
)(({theme: th, placement, arrowMarginTop}) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: th.palette.grey[800],
    padding: '10px 20px',
    fontSize: '12px',
    lineHeight: '20px',
    marginTop: placement === 'bottom-start' ? '8px !important' : arrowMarginTop || 'initial',
    marginLeft: placement === 'bottom-start' ? '-20px !important' : 'initial',
  },
  [`.${'MuiTooltip-arrow'}`]: {
    color: th.palette.grey[800],
    marginLeft: placement === 'bottom-start' ? '11px !important' : 'initial',
  },
}));

Tooltip.defaultProps = {
  placement: 'left',
  arrow: true,
  enterDelay: 200,
  disableInteractive: true,
};
