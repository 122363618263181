import {Nullable} from '@src/types/NullableModel';

export interface InsuranceCarriersList {
  id: string
  name: string
};

export interface InsurancePlansList {
  id: string
  name: string
};

export interface HealthcareInsurancePolicyChangeRequest {
  deletedHealthcareInsurancePolicyIds?: string[]
  healthcareInsurancePoliciesCreate?: Nullable<HealthcareInsurancePolicyCreateRequest[]>
  healthcareInsurancePoliciesEdit?: Nullable<HealthcareInsurancePolicyEditRequest[]>
}

export interface HealthcareInsurancePolicyCreateRequest {
  endDate?: NillableLocalDate
  guarantor: Nullable<GuarantorCreateRequest>
  insurancePlanId: string
  number: string
  primary: boolean
}

export interface HealthcareInsurancePolicyEditRequest {
  endDate: NillableString | null
  guarantor?: NillableGuarantorEditRequest
  id: string
}

export interface NillableLocalDate {
  value?: string
}

export interface GuarantorCreateRequest {
  address1: string
  address2?: NillableString
  birthDate: string
  birthSex: BirthSex
  city: string
  firstName: string
  lastName: string
  middleName?: NillableString
  phone?: NillablePhoneWrapper
  postalCode: string
  relationType: RelationType
  ssnTail?: NillableString
  state: StateType
}

export interface GuarantorEditRequest {
  address1?: NillableString
  address2?: NillableString
  birthDate?: NillableLocalDate
  birthSex?: NillableBirthSex
  city?: NillableString
  firstName?: NillableString
  lastName?: NillableString
  middleName?: NillableString
  phone?: NillablePhoneWrapper
  postalCode?: NillableString
  relationType?: NillableRelationType
  ssnTail?: NillableString
  state?: NillableStateType
}

export interface NillableBirthSex {
  value?: BirthSex
}

export interface NillablePhoneWrapper {
  value?: string | null
}

export interface NillableStateType {
  value?: StateType | null
}

export interface NillableRelationType {
  value?: RelationType | null
}

export interface NillableGuarantorEditRequest {
  value?: Nullable<GuarantorEditRequest>
}

export interface NillableString {
  value?: string | null
}

export enum BirthSex {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN',
}

export enum RelationType {
  SELF = 'SELF',
  CHD = 'CHD',
  DEP = 'DEP',
  EMC = 'EMC',
  FCH = 'FCH',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  OTH = 'OTH',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
}

export enum StateType {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  ZZ = 'ZZ',
}
