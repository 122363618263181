import {SxProps, Theme} from '@mui/material';
import {alpha} from '@src/theme/utils';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  selectItem: (t) => ({
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 7),
    },
    '&.Mui-selected': {
      backgroundColor: `${t.palette.grey[100]} !important`,
      '&:hover': {
        backgroundColor: `${t.palette.grey[100]} !important`,
      },
    },
  }),
  label: {
    color: 'grey.700',
    transform: 'translate(14px, 55%)',
    width: 'auto !important',
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 700,
    '&.Mui-focused': {
      color: 'grey.700',
    },
  },
  optionsContainer: {
    flexGrow: 1,
    overflowY: 'scroll',
    mb: 78,
  },
  buttonContainer: {
    position: 'fixed',
    bottom: 0,
    padding: 18,
    width: '100%',
  },
};
