import {Box} from '@mui/material';
import {ExpandedState, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import React, {FC, MouseEvent, useEffect, useRef, useState} from 'react';

import {TableBody} from './TableBody/TableBody';
import {TableHead} from './TableHead/TableHead';
import {StyledTable} from './styles';
import {ITableProps} from './types';

export const Table: FC<ITableProps> = ({
  data,
  columns,
  onRowClick,
  isError,
  isPaddingFirstColumn,
  isPaddingLastColumn,
  heightColumns = '52px',
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [isHorizontalScrolled, setIsHorizontalScrolled] = useState(false);
  const [isVerticallyScrolled, setIsVerticallyScrolled] = useState(false);

  const [expanded, setExpanded] = React.useState<ExpandedState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.tests,
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      sorting: [],
      expanded: true,
    },
  },
  );

  const handleOnRowClick = (
    event: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>,
    value: string,
  ) => {
    event.stopPropagation();
    onRowClick?.(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const scrollLeft = tableContainerRef.current.scrollLeft;
        const scrollTop = tableContainerRef.current.scrollTop;
        setIsHorizontalScrolled(scrollLeft > 0);
        setIsVerticallyScrolled(scrollTop > 0);
      }
    };

    const container = tableContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    table.toggleAllRowsExpanded(true);
  }, [table]);

  return (
    <Box ref={tableContainerRef} sx={{height: '100%', overflow: 'auto'}}>
      <StyledTable>
        <TableHead
          table={table}
          isHorizontalScrolled={isHorizontalScrolled}
          isVerticallyScrolled={isVerticallyScrolled}
          isPaddingFirstColumn={isPaddingFirstColumn}
          isPaddingLastColumn={isPaddingLastColumn}
        />
        <TableBody
          table={table}
          isHorizontalScrolled={isHorizontalScrolled}
          handleOnRowClick={handleOnRowClick}
          isPointer={!!onRowClick}
          isError={isError}
          height={heightColumns}
        />
      </StyledTable>
    </Box >
  );
};
