import {Stack, Box, Typography, SxProps, Theme, SelectChangeEvent} from '@mui/material';
import { AppointmentFull, AppointmentType } from '@src/api';
import { Button, DatePickerRange, SelectLabels } from '@src/components';
import { DialogActions, DialogContent, DialogTitle } from '@src/components/Dialog';
import { RadioButtons } from '@src/components/RadioButtons';
import {useTranslate} from '@src/i18n/useTranslate';
import { ReactComponent as AcceptIcon } from '@src/shared/assets/icons/accept_sm-secondary.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {DateFormat, NO_VALUE, dateFormatted} from '@src/shared/utils';
import {getPlace} from '@src/shared/utils/getPlace';
import {hasSame} from '@src/shared/utils/hasSame';
import {spreadSx} from '@src/shared/utils/spreadSx';
import {useProfessionalSlots, useRescheduleAppointment} from '@src/store/appointments/hooks';
import { RescheduleAppointmentFlowSteps } from '@src/store/appointments/slice';
import {alpha} from '@src/theme/utils';
import {DateTime} from 'luxon';
import {FC, useEffect, useState} from 'react';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: (t) => ({
    backgroundColor: t.colors.all.white,
    margin: {lg: 0, md: 0, sm: 0, xs: '-24px -16px 0 -16px'},
    flexGrow: 1,
    height: {lg: '100%', md: '100%', sm: '100%', xs: 'calc(100vh - 60px)'},
  }),
  variantsContainer: {
    flexDirection: 'row',
    gap: {lg: 8, md: 8, sm: 8, xs: 4},
  },
  variants: (t) => ({
    width: '100%',
    height: 60,
    backgroundColor: alpha(t.palette.secondary.main, 7),
    gap: 4,
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: alpha(t.palette.secondary.main, 14),
      cursor: 'pointer',
    },
  }),
  dialogActions: (t) => ({
    flexDirection: 'row!important',
    justifyContent: 'space-between!important',
    alignItems: 'end',
    borderTop: `1px solid ${alpha(t.palette.secondary.main, 14)}`,
    margin: 0,
    padding: 24,
  }),
  textAreaDescriptionWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    pt: {lg: 0, sm: 0, md: 0, xs: 2},
  },
  textAreaWrapper: {
    gap: {lg: 8, sm: 8, md: 8, xs: 0},
    mt: 24,
  },
  textAreaTitleWrapper: {
    flexDirection: 'row',
    gap: 12,
    alignItems: 'baseline',
    pb: {lg: 0, sm: 0, md: 0, xs: 8},
  },
  optional: (t) => ({
    color: t.colors.all.grey[400],
    fontStyle: 'italic',
  }),
  dialogContent: {
    padding: {lg: 24, sm: 24, md: 24, xs: '18px 24px 12px 24px!important'},
    overflow: 'scroll',
  },
  selectSectionContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 24,
  },
  slotsWrapper: {
    flexDirection: 'row',
    gap: 12,
    flexWrap: 'wrap',
  },
  slot: (t) => ({
    border: `2px solid ${t.colors.all.grey[400]}`,
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
    width: '100%',
    maxWidth: 98,
    textAlign: 'right',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  slotDateWrapper: {
    flexDirection: 'row',
    gap: 12,
  },
  slotToday: (t) => ({
    color: t.colors.all.grey[500],
    textAlign: 'left',
    ml: 4,
  }),
  dialogContentWrapper: {
    gap: {xs: 34, sm: 24, md: 24, lg: 24},
  },
  dateRangeWrapper: {
    height: 40,
    position: 'relative',
  },
};

interface TFilter {
  end: Date | null
  start: Date | null
}

const initialDates: Pick<TFilter, 'start' | 'end'> = {
  start: DateTime.now().toJSDate(),
  end: DateTime.now().plus({weeks: 2}).toJSDate(),
};

export const RescheduleAppointment: FC<{
  setStep: (step: RescheduleAppointmentFlowSteps) => void
  step: RescheduleAppointmentFlowSteps
  appointment: AppointmentFull | null
}> = ({setStep, step, appointment}) => {
  const {t} = useTranslate('appointments');
  const {mobile} = useMQuery();
  const defaultAppointmentType = appointment?.professional?.appointmentTypes.includes(AppointmentType.IN_PERSON) ? AppointmentType.IN_PERSON : AppointmentType.VIDEO;
  const [appointmentType, setAppointmentType] = useState<AppointmentType | null>(defaultAppointmentType);
  const [selectedSlot, setSelectedSlot] = useState<{
    from: string
    to: string
    date: string
  } | null>(null);
  const [address, setAddress] = useState<string | null>(getPlace(appointment?.address));
  const [dates, setDates] = useState<Pick<TFilter, 'start' | 'end'>>(initialDates);
  const {getProfessionalSlots, professionalSlots} = useProfessionalSlots();
  const {rescheduleAppointment} = useRescheduleAppointment();
  const addresses = appointment?.professional?.addresses.map((address) => {
    return {name: getPlace(address)};
  });

  const changeAddress = (event: SelectChangeEvent<string>) => {
    setAddress(event?.target?.value);
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setDates((prev) => ({...prev, [name]: date}));
  };

  const clearDate = () => {
    setDates(initialDates);
  };

  const onClose = () => {
    setStep('init');
    setDates(initialDates);
    setAppointmentType(null);
  };

  const handleClick = (
    item: {
      from?: string
      to?: string
      types?: string[]
    },
    date?: string,
  ) => {
    setSelectedSlot({from: item.from || '', to: item.to || '', date: date || ''});
  };

  const compareItems = ({
    selectedSlot,
    itemFrom,
    date,
  }: {
    selectedSlot: { from: string, date: string } | null
    itemFrom: string
    date: string
  }) => {
    if (!selectedSlot) return false;
    return selectedSlot.from === itemFrom && selectedSlot.date === date;
  };

  const handleChangeAppointmentType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) { setAppointmentType(AppointmentType[event.target.value as keyof typeof AppointmentType]); }
  };

  useEffect(() => {
    if (appointmentType && dates.end && dates.start && address) {
      const startDate = DateTime.fromJSDate(dates.start).toISODate();
      const endDate = DateTime.fromJSDate(dates.end).toISODate();

      const data = {
        addressId:
          appointment?.professional?.addresses.find((item) => getPlace(item) === address)?.id || '',
        period: {
          from: startDate,
          to: endDate,
        },
        types: [appointmentType],
      };
      getProfessionalSlots({id: appointment?.professional?.account.accountId, data});
    }
  }, [
    address,
    appointment?.professional?.account.accountId,
    appointment?.professional?.addresses,
    appointmentType,
    dates.end,
    dates.start,
    getProfessionalSlots,
  ]);

  useEffect(() => {
    if (appointmentType && dates.end && dates.start && appointment?.address?.id) {
      const startDate = DateTime.fromJSDate(dates.start).toISODate();
      const endDate = DateTime.fromJSDate(dates.end).toISODate();

      const data = {
        addressId: appointment.address.id,
        period: {
          from: startDate,
          to: endDate,
        },
        types: [appointmentType],
      };
      getProfessionalSlots({id: appointment?.professional?.account.accountId, data});
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const makeReschedule = ({
    date,
    from,
    to,
  }: {
    date: string | undefined
    from: string | undefined
    to: string | undefined
  }) => {
    if (appointmentType && from && to && appointment?.address?.id && date) {
      const data = {
        addressId:
          appointment?.professional?.addresses.find((item) => getPlace(item) === address)?.id || '',
        startDate: `${date}T${from}.000Z`,
        endDate: `${date}T${to}.000Z`,
        appointmentType,
      };
      rescheduleAppointment({id: appointment?.id, data});
    }
  };

  return (
    <Stack sx={sx.mainContainer}>
      <DialogTitle onClose={onClose}>{t('CHANGE_DATE_AND_TIME')}</DialogTitle>
      <DialogContent>
        <Stack sx={sx.dialogContentWrapper}>
          <Box sx={sx.dateRangeWrapper}>
            <DatePickerRange
              disabledPast={true}
              variant="outlined"
              label={t('DATE_RANGE')}
              startDate={dates.start}
              endDate={dates.end}
              setStartDate={changeDate('start')}
              setEndDate={changeDate('end')}
              clearDate={clearDate}
              isIconVisible={false}
            />
          </Box>
          <Stack gap={mobile ? 42 : 36}>
            <Stack sx={sx.selectSectionContainer}>
              {(appointment?.professional?.appointmentTypes.length ?? 0) >= 2 && (
                <>
                  <Box sx={{width: '35%'}}>
                    <RadioButtons
                      inColumn={mobile}
                      gapNumber={24}
                      value={appointmentType}
                      handleChange={handleChangeAppointmentType}
                      firstButtonData={{value: AppointmentType.VIDEO, label: t('VIDEO')}}
                      secondaryButtonData={{
                        value: AppointmentType.IN_PERSON,
                        label: t('IN_PERSON'),
                      }} />
                  </Box><Box sx={{width: '65%'}}>
                    <SelectLabels
                      disabled={(addresses?.length ?? 0) < 2}
                      placeholder={t('SELECT')}
                      value={address || t('SELECT')}
                      contentPaddingLeft="75px"
                      onChange={(event) => changeAddress(event)}
                      options={addresses || []} />
                  </Box>
                </>
              )}
              {(appointment?.professional?.appointmentTypes.length ?? 0) === 1 &&
                appointment?.professional?.appointmentTypes.includes(AppointmentType.IN_PERSON) &&
                (
                  <>
                    <Typography variant='14_18_500'>{t('IN_PERSON')}</Typography>
                    <Box sx={{width: '65%'}}>
                      <SelectLabels
                        disabled={(addresses?.length ?? 0) < 2}
                        placeholder={t('SELECT')}
                        value={address || t('SELECT')}
                        contentPaddingLeft="75px"
                        onChange={(event) => changeAddress(event)}
                        options={addresses || []} />
                    </Box>
                  </>
                )}
              {(appointment?.professional?.appointmentTypes.length ?? 0) === 1 &&
                appointment?.professional?.appointmentTypes.includes(AppointmentType.VIDEO) &&
                (
                  <>
                    <Box>
                      <Typography variant='14_18_500'>{t('VIDEO')}</Typography>
                    </Box>
                  </>
                )}
            </Stack>
            <Stack gap={24}>
              {professionalSlots?.map((slot) => (
                <Stack gap={24} key={slot.date}>
                  <Stack sx={sx.slotDateWrapper}>
                    <Typography variant={mobile ? '16_20_700' : '18_24_700'} textAlign="left">
                      {DateTime.fromISO(slot?.date || '').toFormat('cccc')}
                    </Typography>
                    <Typography variant={mobile ? '16_20_700' : '18_24_700'} textAlign="left">
                      {DateTime.fromISO(slot?.date || '').toFormat('LLL dd')}
                      {
                        hasSame(slot?.date) && (
                          <Typography
                            variant={mobile ? '16_20_500' : '18_24_500'}
                            sx={sx.slotToday}
                          >
                            {`(${t('TODAY')})`}
                          </Typography>
                        )
                      }
                    </Typography>
                  </Stack>
                  <Stack key={'side-menu'} sx={sx.slotsWrapper}>
                    {slot?.slots?.length
                      ? slot?.slots.map((item) => (
                        <Stack
                          key={item?.from}
                          sx={[...spreadSx(sx.slot), (t) => ({
                            gap: 2,
                            borderColor: compareItems({
                              selectedSlot,
                              itemFrom: item.from || '',
                              date: slot.date || '',
                            })
                              ? t.colors.secondary
                              : t.colors.all.grey[400],
                          })]}
                          onClick={() => handleClick(item, slot.date)}
                        >
                          {compareItems({selectedSlot, itemFrom: item.from || '', date: slot.date || ''}) && (
                            <AcceptIcon />
                          )}
                          <Typography
                            variant="14_18_700"
                            sx={(t) => ({
                              width: 68,
                              color: compareItems({
                                selectedSlot,
                                itemFrom: item.from || '',
                                date: slot.date || '',
                              })
                                ? t.colors.secondary
                                : t.colors.all.grey[400],
                            })}
                          >
                            {dateFormatted(item.from ?? NO_VALUE, DateFormat.TIME_SIMPLE)?.toLowerCase()}
                          </Typography>
                        </Stack>
                      ))
                      : (
                        <Typography variant="12_16_500">{t('NO_APPOINTMENTS')}</Typography>
                      )}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={sx.dialogActions}>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          {t('BACK')}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          disabled={!selectedSlot?.from || step === 'loading'}
          onClick={() =>
            makeReschedule({
              date: selectedSlot?.date,
              from: selectedSlot?.from,
              to: selectedSlot?.to,
            })
          }
        >
          {t('SAVE')}
        </Button>
      </DialogActions>
    </Stack>
  );
};
