/** @jsxImportSource @emotion/react */
import {FC, PropsWithChildren, useMemo} from 'react';
import {DateTime, Info} from 'luxon';
import {Cell, Circle, DatesContainer} from '../../styles';
import styled from '@emotion/styled';
import {DateRange} from '../CalendarPicker';
import {css as _} from '@mui/material';
import {alpha} from 'theme/utils';

interface DatesAreaProps {
  onChange: (d: DateTime) => void
  month: DateTime
  selected?: DateTime
  range?: DateRange
}

export const DatesArea: FC<DatesAreaProps> = ({
  month,
  selected,
  onChange,
  range,
}) => {
  const weekDays = Info.weekdays('short');
  const now = DateTime.now();
  const monthDates: DateTime[] = useMemo(() => {
    const startDay = month.startOf('month').startOf('week');
    const endDay = month.endOf('month').endOf('week');
    let cursor = startDay;
    const dates = [];
    while (cursor < endDay) {
      dates.push(cursor);
      cursor = cursor.plus({day: 1});
    }
    return dates;
  }, [month]);
  const startOfRange = (d: DateTime) => {
    return range?.[0] ? d.hasSame(range[0], 'day') : false;
  };
  const endOfRange = (d: DateTime) => {
    return range?.[1] ? d.hasSame(range[1], 'day') : false;
  };

  const withinRange = (d: DateTime) => {
    if (!range?.[0] || !range?.[1] || range?.[0]?.hasSame(range?.[1], 'day')) {
      return false;
    }
    return range?.[0] && range?.[1] && (range[0]?.startOf('day') <= d) && (d <= range[1]?.endOf('day'));
  };
  return (
    <>
      <WeekDaysRow>
        {weekDays.map((day, i) => (<div key={i}>{day}</div>))}
      </WeekDaysRow>
      <DatesContainer>
        {monthDates.map((v, i) => {
          const disableDay = v.toMillis() > now.toMillis();
          const rangeEnd = endOfRange(v);
          const rangeStart = startOfRange(v);
          return (
            <Cell key={i}>
              {withinRange(v) && (
                <RangeMark end={rangeEnd} start={rangeStart}/>
              )}
              <Circle
                onClick={() => onChange(v)}
                disabled={!v.hasSame(month, 'month') || disableDay}
                currentDay={v.hasSame(now, 'day')}
                selected={(selected && v.hasSame(selected, 'day')) || rangeEnd || rangeStart }
              >
                {v.day}
              </Circle>
            </Cell>
          );
        })}
      </DatesContainer>
    </>
  );
};
const WeekDaysRow = styled.div`
    display: flex;
    margin-bottom: 10px;
    div {
        width: calc(100% / 7);
        min-width: 0;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
    }
`;
const RangeMark = styled(({end: _, start: __, ...restProps}: PropsWithChildren<{end: boolean, start: boolean}>) => <div {...restProps}/>)<{end: boolean, start: boolean}>(({end, start, theme}) => _`
  position: absolute;
  width: 100%;
  height: 34px;
  background-color: ${alpha(theme.palette.secondary.main, 70)};
  border-radius: ${end ? '0 50% 50% 0' : start ? '50% 0 0 50%' : 'none'};
`);
