import {useEffect, useRef, useState} from 'react';
import {useGoogleLoader} from '@src/components/Google/GoogleLoaderProvider';

export const useGoogleAutocomplete = ({
  onChange,
}: {
  onChange?: (
    placeResult: google.maps.places.PlaceResult,
    autocomplete: google.maps.places.Autocomplete
  ) => void
} = {}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [autocomplete, setAutocomplete] = useState<
  google.maps.places.Autocomplete | undefined
  >(undefined);
  const loader = useGoogleLoader();

  // create autocomplete
  useEffect(() => {
    void loader?.importLibrary('places').then(({Autocomplete}) => {
      if (!ref.current) {
        return;
      }
      const autocomplete = new Autocomplete(ref.current, {
        strictBounds: false,
      });
      setAutocomplete(autocomplete);
    });
  }, [loader]);

  const onchangeRef = useRef(onChange);
  onchangeRef.current = onChange;
  // subscribe to events
  useEffect(() => {
    if (!autocomplete || !onchangeRef.current) {
      return;
    }
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      onchangeRef.current?.(place, autocomplete);
    });
    return () => {
      autocomplete.unbindAll();
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, [autocomplete]);

  return {ref, autocomplete};
};
