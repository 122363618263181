import {HealthcareInsurancePolicy} from '@src/api';
import {useTranslate} from '@src/i18n/useTranslate';
import {phoneNotRequiredUpdateValidator} from '@src/shared/utils/phoneNotRequiredUpdateValidator';
import {postalCodeValidator, ssnTailValidator} from '@src/shared/utils/validators';
import * as Yup from 'yup';

export const useValidationSchema = (insurance: HealthcareInsurancePolicy | undefined) => {
  const {t} = useTranslate('insurance');
  return Yup.object().shape({
    firstName: Yup.string().required(t('PLEASE_ENTER_FIRST_NAME')).nullable(),
    lastName: Yup.string().required(t('PLEASE_ENTER_LAST_NAME')).nullable(),
    birthDate: Yup.string().required(t('PLEASE_ENTER_A_BIRTH_DATE')).nullable(),
    birthSex: Yup.string().required(t('PLEASE_ENTER_BIRTH_SEX')).nullable(),
    state: Yup.string().required(t('PLEASE_ENTER_A_STATE')).nullable(),
    city: Yup.string().required(t('PLEASE_ENTER_A_CITY')),
    address1: Yup.string().required(t('PLEASE_ENTER_AN_ADDRESS')),
    phone: phoneNotRequiredUpdateValidator(t),
    postalCode: postalCodeValidator(t),
    endDate: Yup.string()
      .nullable()
      .test('is-endDate', t('PLEASE_ENTER_AN_END_DATE'), (value) => {
        return !(typeof insurance?.endDate === 'string' && !value);
      }),
    ssnTail: ssnTailValidator(t),
  });
};
