import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  container: {
    position: 'fixed',
    zIndex: 1031,
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    bgcolor: theme => theme.colors.background,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 14px 4px 8px',
    borderBottom: theme => `1px solid ${theme.colors.borderBlueLight}`,
    svg: {
      path: {
        stroke: theme => theme.palette.grey[700],
      },
    },
  },
};
