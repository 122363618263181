import {Box, Typography} from '@mui/material';
import {RowInformation} from '@src/components';
import {ListWrapper} from '@src/components/ListWrapper';
import {useTranslate} from '@src/i18n/useTranslate';
import {useTranslationMap} from '@src/shared/hooks';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC} from 'react';

import {listWrapperPadding} from '../styled';

import {sx} from './styles';
import {IRelationAboutWithAccessProps} from './types';

export const RelationAboutWithAccess: FC<IRelationAboutWithAccessProps> = ({relation}) => {
  const {t} = useTranslate('connections');
  const {mobile: isMobile} = useMQuery();

  const {mapValue} = useTranslationMap();

  const raceValue = mapValue(relation?.contact?.race ?? '', 'race');
  const genderValues = mapValue(relation?.contact?.gender ?? '', 'gender');
  const sexualOrientationValue = mapValue(relation?.contact?.sexualOrientation ?? '', 'sexualOrientation');

  return (
    <>
      <Box sx={sx.listContainer}>
        <Box display='flex' gap={10}>
          <Typography component='h4' sx={sx.title}>{t('PATIENT_INFORMATION')}</Typography>
        </Box>
        <ListWrapper padding={listWrapperPadding(isMobile)} noLast={{xs: 0}}>
          <RowInformation
            tooltip
            value={relation?.contact?.firstName}
            noMargin
            name={t('FIRST_NAME')} />
          <RowInformation
            tooltip
            value={relation?.contact?.lastName}
            noMargin
            name={t('LAST_NAME')} />
          <RowInformation
            tooltip
            value={relation?.contact?.middleName}
            noMargin
            name={t('MIDDLE_NAME')} />
          <RowInformation
            tooltip
            value={relation?.contact?.birthDateS}
            noMargin
            name={t('DATE_OF_BIRTH')} />
          <RowInformation
            tooltip
            value={t(relation?.contact?.birthSex)}
            noMargin
            name={t('BIRTH_SEX')} />
          <RowInformation
            tooltip
            value={raceValue}
            noMargin
            name={t('RACE')} />
          <RowInformation
            tooltip
            value={t(relation?.contact?.ethnicity)}
            noMargin
            name={t('ETHNICITY')} />
          <RowInformation
            tooltip
            value={genderValues}
            noMargin
            name={t('GENDER')} />
          <RowInformation
            tooltip
            value={sexualOrientationValue}
            noMargin
            name={t('SEXUAL_ORIENTATION')} />
          <RowInformation
            tooltip
            value={relation?.contact?.ssnTail}
            noMargin
            name={t('SSN_LAST_4')} />
        </ListWrapper>
      </Box>
    </>
  );
};
