import {MenuTypes} from '@api';
import {PageHeader as PageTitle} from '@components';
import {Box, Stack, Typography} from '@mui/material';
import {AnyAction} from '@reduxjs/toolkit';
import {PulseLoader} from '@src/components/PulseLoader';
import {ReactComponent as ShareIcon} from '@src/shared/assets/icons/18x18/share.svg';
import {ReactComponent as AddIcon} from '@src/shared/assets/icons/plus.svg';
import {useMQuery, useSidebarClassObserver} from '@src/shared/hooks';
import {fetchStorageFiles} from '@src/store/storage/slice';
import {useAppSelector} from '@store';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, IconButton} from 'ui-kit';

import {DocumentCard} from './components/DocumentCard';
import {Filters} from './components/Filters';
import {DEFAULT_PER_PAGE} from './constants';
import {useStorageTranslate} from './hooks';
import {MainContainer, MainWrapper, sx} from './styles';

export const Storage = () => {
  const {mobile} = useMQuery();
  const translate = useStorageTranslate();
  const {isSidebarOpen} = useSidebarClassObserver();
  const {files, isLoading} = useAppSelector((state) => state.storage);
  const dispatch = useDispatch();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScrollListener = (container: HTMLElement) => {
    const scrollTop = container.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  const handleFetch = (isNext: boolean) => {
    if (isNext && !files.hasNext) {
      return;
    }
    const {from, to, ...restFilters} = files.filters;
    dispatch(
      fetchStorageFiles({
        ...restFilters,
        from: from ? from.toISOString().split('T')[0] : undefined,
        to: to ? to.toISOString().split('T')[0] : undefined,
        startPage: isNext ? files.page : 0,
        perPage: DEFAULT_PER_PAGE,
        isNext,
      }) as unknown as AnyAction,
    );
  };

  useEffect(() => {
    handleFetch(false);
  }, [files.filters]);

  return (
    <>
      <PageTitle
        height={'auto'}
        itemAction={
          mobile
            ? (
              <IconButton variant={'text'} color={'secondary'} sx={sx.iconButton}>
                <ShareIcon />
              </IconButton>
            )
            : (
              <Stack gap={12} flexDirection={'row'}>
                <Button variant="contained" color="secondary" startIcon={<AddIcon />}>
                  {translate('ADD_DOCUMENT')}
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<ShareIcon />}>
                  {translate('SHARE_DOCUMENT')}
                </Button>
              </Stack>
            )
        }
      >
        {translate('STORAGE')}
      </PageTitle>
      <Stack sx={sx.generalContainer}>
        <Filters isSidebarOpen={isSidebarOpen} isScrolled={isScrolled} />
        <MainWrapper
          isScrolled={isScrolled}
          isSidebarOpen={isSidebarOpen}
          onScroll={handleScrollListener}
          onLoad={() => handleFetch(true)}
          threshold={500}
        >
          {(!isLoading || !!files.items.length) && (
            <MainContainer isSidebarOpen={isSidebarOpen}>
              {files.items.map((file, index) => (
                <DocumentCard key={index} file={file} />
              ))}
            </MainContainer>
          )}
          {!isLoading && !files.items.length && (
            <Typography variant={'14_18_500'} color={(theme) => theme.colors.alertColor}>
              {files.filters.menu === MenuTypes.FAVORITE
                ? (
                  <>
                    {translate('YOU_DONT_HAVE_FAVORITES')}
                    <br />
                    {translate('FAVORITE_WILL_SHOW_UP')}
                  </>
                )
                : (
                  translate('YOU_DONT_HAVE_DOCS')
                )}
            </Typography>
          )}
          {isLoading && (
            <Box display={'flex'} justifyContent={'center'} p={36}>
              <PulseLoader loading={true} />
            </Box>
          )}
        </MainWrapper>
      </Stack>
      {mobile && (
        <Box sx={sx.fixedButtonContainer}>
          <Button variant="contained" color="secondary" fullWidth>
            {translate('ADD_DOCUMENT')}
          </Button>
        </Box>
      )}
    </>
  );
};
