import {useMQuery} from '@src/shared/hooks';

import {MobileLayout} from '../../../RelationLayouts/MobileLayout';
import {TabletLayout} from '../../../RelationLayouts/TabletLayout';
import {RelationDesktopLayout} from '../RelationDesktopLayout';

export const RelationLayoutView = () => {
  const {mobile: isMobile, tablet: isTablet} = useMQuery();

  if (isTablet) {
    return <TabletLayout />;
  }

  if (isMobile) {
    return <MobileLayout />;
  }

  return <RelationDesktopLayout />;
};
