import {Avatar, Box, Stack, TableCell, Typography} from '@mui/material';
import {
  AccountStatus,
  RelationContactSearchResult,
  RequestRelationType,
} from '@src/api/relations';
import {RowInformation, WBox, ListWrapper} from '@src/components';
import {EllipsisTextTooltip} from '@src/components/EllipsisTextTooltip';
import {PulseLoader} from '@src/components/PulseLoader';
import {useObserver} from '@src/components/Table/useObserver';
import {useTranslate} from '@src/i18n/useTranslate';
import {ROUTERS_PATH} from '@src/routers';
import {ReactComponent as ArrowLeftIcon} from '@src/shared/assets/icons/arrow-left.svg';
import {ReactComponent as SearchIcon} from '@src/shared/assets/icons/icon-search.svg';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getUserInitials, useTrackIntersection} from '@src/shared/utils';
import {formatPhoneNumber} from '@src/shared/utils/formatPhoneNumber';
import {getName} from '@src/shared/utils/getName';
import {relationsActions} from '@src/store/relations/slice';
import {alpha} from '@src/theme/utils';
import {useState, useRef, FormEvent, SetStateAction, Dispatch} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Button, IconButton, InputControl} from 'ui-kit';

import {SendRequestSuccessModal} from '../../SendRequestSuccessModal';
import {AddRelationTableAction} from '../AddRelationTableAction';
import {MOBILE_COLORS} from '../constant';
import {EAccountStatusName} from '../types';

import {sx} from './styles';

const renderDesktopFlagCell = (flag: string) => (
  <TableCell sx={sx.flagCell}>
    {flag && (
      <Box
        sx={{
          ...MOBILE_COLORS[AccountStatus[flag as keyof typeof AccountStatus]],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 24,
          width: 'fit-content',
          padding: '0 8px',
        }}
      >
        <Typography variant="14_20_500">
          {EAccountStatusName[flag as keyof typeof EAccountStatusName]}
        </Typography>
      </Box>
    )}
  </TableCell>
);

export const AddRelationMobileLayout = ({
  searchBy,
  handleSubmit,
  setFieldValue,
  relationType,
  relationContacts,
  setHasSearched,
  fetching,
}: {
  searchBy: string | null
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  relationType: RequestRelationType | null
  relationContacts: RelationContactSearchResult[] | null
  setHasSearched: Dispatch<SetStateAction<boolean>>
  fetching: boolean
}) => {
  const {t} = useTranslate('connections');
  const {mobile} = useMQuery();
  const [isSearchAbsolute, setIsSearchAbsolute] = useState<boolean>(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const hasRelationContacts = relationContacts && relationContacts.length > 0;
  const hasMoreThanTwoRelationContacts = relationContacts && relationContacts.length > 2;
  const noRelationContacts = relationContacts && relationContacts.length < 1;

  const shouldRenderFixedSearch = mobile && !isSearchAbsolute;

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    handleSubmit();
  };
  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value || '';
    setFieldValue('searchBy', value);
  };

  const handleClickBreadcrumbs = () => {
    if (relationContacts) {
      dispatch(relationsActions.setRelationContacts({relationContacts: null}));
      setHasSearched(false);
    }
  };

  const handleClickCreatePatient = () => {
    return navigate(`${ROUTERS_PATH.CONNECTIONS_CREATE_A_NEW_PATIENT}?relationshipType=${relationType ?? ''}`);
  };

  const reference = useRef<HTMLDivElement | null>(null);
  useTrackIntersection({reference, callBack: setIsSearchAbsolute, threshold: 0});

  useObserver(reference);

  return (
    <WBox
      noPaddings
      sx={{
        height: hasRelationContacts ? 'inherit' : 'calc(100vh - 178px) !important',
        mb: hasMoreThanTwoRelationContacts ? '74px' : 24,
      }}
    >
      <>
        {shouldRenderFixedSearch && (
          <WBox sx={sx.searchContainer}>
            <Stack sx={sx.searchContent}>
              <InputControl
                name="searchBy"
                value={searchBy || ''}
                placeholder={t('SEARCH_RELATION_INPUT_PLACEHOLDER')}
                onChange={handleInputValue}
              />
              <IconButton
                sx={sx.bth}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </WBox>
        )}
        <Stack flex={1}>
          <Stack sx={sx.container} ref={reference}>
            <Stack sx={sx.flexContainer} onClick={handleClickBreadcrumbs}>
              <ArrowLeftIcon />
              <Typography variant="14_18_600">{t('SEARCH')}</Typography>
            </Stack>
            <Stack sx={sx.searchContainerDesk}>
              <InputControl
                name="searchBy"
                value={searchBy || ''}
                placeholder={t('SEARCH_BY_PLACEHOLDER_MOBILE')}
                onChange={handleInputValue}
                sx={{
                  svg: {
                    width: 23,
                    height: 23,
                  },
                }}
              />
              <Button
                sx={sx.bth}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >
                <SearchIcon />
              </Button>
            </Stack>
          </Stack>
          {fetching && (
            <Box sx={sx.loaderContainer}>
              <PulseLoader loading />
            </Box>
          )}
          {!fetching && noRelationContacts
            ? (
              <Typography variant="12_16_500" sx={sx.patientNotFound}>
                {t('NO_SUITABLE_RESULTS')}
              </Typography>
            )
            : (
              relationContacts?.map((row) => (
                <Stack
                  sx={(t) => ({
                    p: '18px 18px 30px 18px',
                    borderTop: `1px solid ${alpha(t.palette.secondary.main, 28)}`,
                  })}
                  key={row.id}
                >
                  <Stack flexDirection="row" justifyContent="space-between">
                    <Stack sx={sx.flexContainerInfo}>
                      <Avatar sx={sx.requestAvatar} src={row.accountAvatarUrl}>
                        <Typography variant="12_16_700">
                          {getUserInitials({
                            firstName: row.firstName,
                            lastName: row.lastName,
                            middleName: row.middleName,
                          })}
                        </Typography>
                      </Avatar>
                      <Stack sx={sx.widthLimitContainer}>
                        <EllipsisTextTooltip
                          variant="14_18_700"
                          ellipsisText={getName({
                            firstName: row.firstName,
                            lastName: row.lastName,
                          })}
                        />
                        <Typography variant="12_16_500">{row.birthDateS}</Typography>
                      </Stack>
                    </Stack>
                    <Box sx={sx.desktopFlagCellContainer}>
                      <Typography variant="14_18_500">
                        {renderDesktopFlagCell(row?.accountStatus)}
                      </Typography>
                    </Box>
                  </Stack>
                  <ListWrapper spaceBetween={8} padding="8px">
                    <RowInformation
                      variant="12_16_700"
                      valueTypographyVariant="12_16_500"
                      value={formatPhoneNumber(row.phone?.slice(1))}
                      noMargin
                      name={t('PHONE')}
                    />
                    <RowInformation
                      tooltip
                      variant="12_16_700"
                      valueTypographyVariant="12_16_500"
                      value={row.email}
                      noMargin
                      name={t('EMAIL')}
                    />
                    <RowInformation
                      variant="12_16_700"
                      valueTypographyVariant="12_16_500"
                      value={row.ssnTail}
                      noMargin
                      name={t('SSN')}
                    />
                    <RowInformation
                      sx={{mb: 28}}
                      variant="12_16_700"
                      valueTypographyVariant="12_16_500"
                      value={row.policyNumber}
                      noMargin
                      name={t('POLICY_ID')}
                    />
                  </ListWrapper>
                  <Box display="flex" justifyContent="start">
                    <AddRelationTableAction
                      id={row.id}
                      relationError={row.relationError}
                      relationshipType={relationType || null}
                      newContactBirthDate={row.birthDateS}
                    />
                  </Box>
                </Stack>
              ))
            )}
          <SendRequestSuccessModal />
        </Stack>
        {mobile && (
          <WBox sx={sx.wBoxBthContainer}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickCreatePatient}
            >
              {t('CREATE_A_NEW_PATIENT')}
            </Button>
          </WBox>
        )}
      </>
    </WBox>
  );
};
