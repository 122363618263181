import {Box, Typography, Theme} from '@mui/material';

import {getBackgroundColor, getBorderColor, getHoverBackgroundColor, getLabelColor} from './styled';
import {TabTetraryProps} from './types';

export const SidebarTabTetrary = ({icon, label, isActive, onClick, isDisabled, id}: TabTetraryProps) => {
  return (
    <Box
      id={id}
      onClick={onClick}
      sx={(t: Theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
        width: 102,
        height: 96,
        gap: 6,
        border: `1px solid ${getBorderColor(t, isActive, isDisabled) ?? ''}`,
        borderRight: isActive ? 'none' : 'auto',
        pointerEvents: isDisabled ? 'none' : 'auto',
        backgroundColor: getBackgroundColor(t, isActive, isDisabled),
        zIndex: !isActive ? 2 : 1,
        svg: {
          path: {
            fill: getLabelColor(t, isActive, isDisabled),
          },
        },
        '&:hover, &:active': {
          backgroundColor: getHoverBackgroundColor(t, isActive, isDisabled),
        },
        '&:not(first-child)': {
          mt: -1,
        },
      })}
    >
      <Box>
        {icon}
      </Box>
      <Typography
        variant='14_18_600'
        sx={(t: Theme) => ({
          color: getLabelColor(t, isActive, isDisabled),
        })}>
        {label}
      </Typography>
    </Box>
  );
};
