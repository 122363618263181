// eslint-disable-next-line no-comments/disallowComments
/** @jsxImportSource @emotion/react */
import {css as _, ButtonBase, Theme, SxProps, Box, SelectChangeEvent} from '@mui/material';
import Grid from '@src/components/Grid';
import {SelectLabels} from '@src/components/Select';
import {WhiteBox} from '@src/components/WhiteBox';
import {Switch} from '@src/components/form/SwitchControl';
import {SettingsButton} from '@src/components/fragments/SettingsButton';
import {FilterDialog} from '@src/pages/Results/components/FiltersDialog';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {useScrollToTop} from '@src/shared/hooks/useScrollToTop';
import {getFullName} from '@src/shared/utils';
import {Result} from 'api';
import {
  EmptySearch,
  FilterWrapper,
  Table,
  ITableScrollUp,
  DatePickerRange,
  PageHeader,
  Text,
} from 'components';
import {BoxBody, BoxPaddings} from 'components/WhiteBox/WhiteBox';
import {useTranslate} from 'i18n/useTranslate';
import {useEffect, useRef, useState} from 'react';
import {useResults} from 'store/results/hooks';

import {useHeader, initialFilter, initiialSort, IFilterResults} from './Results.constant';
import {dateInInterval, sortByDate} from './Results.helpers';
import {TableRow} from './TableRow';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: (theme) => theme.pageTitleHeight,
  },
};

export const Results = () => {
  const {t} = useTranslate('results');
  const [filter, setFilter] = useState(initialFilter);
  const [sort, setSort] = useState(initiialSort);
  const {data, isLoading} = useResults();
  const [result, setResult] = useState<Result[]>();
  const {mobile, tablet} = useMQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const refTable = useRef<ITableScrollUp>();
  const [allExpanded, setAllExpanded] = useState(false);
  const [selectItem, setSelectItem] = useState('');
  const [reportedPosition, setReportedPosition] = useState(true);
  const [collectedPosition, setCollectedPosition] = useState(true);
  useEffect(() => {
    onApply();
  }, [filter]);
  useEffect(() => {
    if (data && data.length > 0) setResult(data);
  }, [data]);

  useEffect(() => {
    if (result) {
      const arrayForSort = [...result];
      setResult(arrayForSort.sort((a, b) => sortByDate(a, b, sort)));
    }
  }, [sort]);

  const getPhysicians = [
    ...new Map(data?.map((item) => [item?.physician?.id, item.physician])).values(),
  ]
    .map((item) => ({value: item?.id || '', name: getFullName(item), key: item?.id || ''}))
    .filter((item) => item.name && item.value);

  const onApply = () => {
    const {physician, reportDateFrom, reportDateTo, collectionDateFrom, collectionDateTo} =
      filter;

    const doFiltration = (resultItem: Result) => {
      const filterByPhysician = physician ? resultItem.physician?.id === physician.value : true;

      const filterByRepoterDate =
        reportDateFrom || reportDateTo
          ? dateInInterval(reportDateFrom, reportDateTo, resultItem.reported)
          : true;

      const filterByCollectionDate =
        collectionDateFrom || collectionDateTo
          ? dateInInterval(collectionDateFrom, collectionDateTo, resultItem.collected)
          : true;
      return filterByPhysician && filterByRepoterDate && filterByCollectionDate;
    };
    if (data) setResult(data?.filter((item) => doFiltration(item)));
    refTable.current?.scrollUp();
  };

  const setNewFilters = (newFilters: IFilterResults) => {
    setFilter(newFilters);
  };

  const onClear = () => {
    setSelectItem('');
    setFilter(initialFilter);
    if (data) setResult(data);
  };

  const changeSelectPhysicians = (event: SelectChangeEvent<string>) => {
    setSelectItem(event.target.value);
    const data = getPhysicians.find((item) => item.name === event.target.value);
    if (data && event) {
      const item = event?.target?.value;
      setFilter((prev) => ({
        ...prev,
        physician: item ? {value: data?.value, name: data?.name} : null,
      }));
    }
  };

  const changeDate = (name: string) => (date: Date | null) => {
    setFilter((prev) => ({...prev, [name]: date}));
  };

  const filterResultByDate = (columnName: string) => () => {
    setSort((prev) => ({...prev, sortBy: columnName, sortDesc: !prev.sortDesc}));
    if (columnName === 'reported') setReportedPosition((prev) => !prev);
    if (columnName === 'collected') setCollectedPosition((prev) => !prev);
  };

  const {HEADER_LIST} = useHeader(filterResultByDate, reportedPosition, collectedPosition);

  const handleExpandAll = () => {
    setAllExpanded((prev) => {
      return !prev;
    });
  };

  const onClearReportDate = () => {
    setFilter((prev) => {
      return {
        ...prev,
        reportDateFrom: null,
        reportDateTo: null,
      };
    });
  };
  const onClearCollectionDate = () => {
    setFilter((prev) => {
      return {
        ...prev,
        collectionDateFrom: null,
        collectionDateTo: null,
      };
    });
  };

  const handleFilterDialogClose = () => {
    setFilterOpened(false);
  };

  useScrollToTop();

  return (
    <>
      <Box sx={sx.headerContainer}>
        <PageHeader height={'auto'}>{t('RESULTS')}</PageHeader>
        {(mobile || tablet) && <SettingsButton onClick={() => setFilterOpened(true)} />}
      </Box>
      <WhiteBox noPaddings>
        <BoxBody>
          {mobile && (
            <ButtonBase
              onClick={handleExpandAll}
              css={_`
                      width: 100%;
                      display: flex;
                      justify-content: space-between;
                      height: 76px;
                      align-items: center;
                      padding: 0 24px;
                      `}
            >
              <Text>{t('EXPAND_ALL')}</Text>
              <Switch css={_`margin-right: -12px;`} readOnly checked={allExpanded} />
            </ButtonBase>
          )}
          {!mobile && !tablet && (
            <BoxPaddings sx={{padding: '24px 36px'}}>
              <FilterWrapper
                onClear={onClear}
                css={_`label {flex: unset; width: 100%; max-width: unset} `}
              >
                <Grid container spacing={36} flexGrow={1}>
                  <Grid xs={4} lg={4} xl={4}>
                    <SelectLabels
                      placeholder={t('SELECT')}
                      value={selectItem}
                      onChange={changeSelectPhysicians}
                      options={getPhysicians || []}
                      label={t('PHYSICIAN')}
                      disabled={!getPhysicians.length}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} xl={4}>
                    <DatePickerRange
                      isIconVisible={false}
                      startAdornmentName="Report date"
                      variant="outlined"
                      label="Report Date"
                      startDate={filter.reportDateFrom}
                      setStartDate={changeDate('reportDateFrom')}
                      setEndDate={changeDate('reportDateTo')}
                      endDate={filter.reportDateTo}
                      clearDate={onClearReportDate}
                    />
                  </Grid>
                  <Grid xs={4} lg={4} xl={4}>
                    <DatePickerRange
                      isIconVisible={false}
                      startAdornmentName="Collection date"
                      variant="outlined"
                      label="Collection Date"
                      startDate={filter.collectionDateFrom}
                      setStartDate={changeDate('collectionDateFrom')}
                      setEndDate={changeDate('collectionDateTo')}
                      endDate={filter.collectionDateTo}
                      clearDate={onClearCollectionDate}
                    />
                  </Grid>
                </Grid>
              </FilterWrapper>
            </BoxPaddings>
          )}
          <Table
            css={_`margin-top: -1px`}
            classNames={{header: 'table-header'}}
            ref={refTable}
            gridTemplateColumns={mobile ? '4fr 3fr 1fr' : '1fr 1fr 1fr 0.5fr'}
            data={result || []}
            headerList={HEADER_LIST}
            loading={false}
            rowComponent={{component: TableRow, props: {allExpanded}}}
            emptyData={<EmptySearch isLoading={isLoading} />}
          />
        </BoxBody>
      </WhiteBox>
      <FilterDialog
        isFilterOpen={filterOpened}
        handleClose={handleFilterDialogClose}
        physicianOptions={getPhysicians}
        filter={filter}
        setNewFilters={setNewFilters}
      />
    </>
  );
};
