import {Typography, Stack} from '@mui/material';
import {ReactComponent as IconSVGWarning} from '@src/shared/assets/images/icons/icon_warning_yellow.svg';
import React from 'react';

import {PulseLoader} from '../PulseLoader';

export const EmptySearch: React.FC<{ isLoading?: boolean | null }> = ({isLoading}) => {
  return (
    <Stack
      direction="row"
      sx={{color: 'grey.400'}}
      padding={{xs: '18px 24px 8px', sm: '42px 0'}}
      justifyContent="center"
      alignItems="center"
      gap={{xs: '8px', sm: '4px'}}
    >
      {isLoading
        ? <PulseLoader loading={true} />
        : (
          <><IconSVGWarning /><Typography fontSize="14px" fontWeight={500}>
            There are no results matching your search. Try to change your search options
          </Typography></>
        )
      }
    </Stack>
  );
};
