import {Box, Stack} from '@mui/material';
import {Breadcrumbs, Link, Text} from '@src/components';
import {useTranslate} from '@src/i18n/useTranslate';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {getFullName} from '@src/shared/utils';
import {useContactRelations, useRelation} from '@src/store/relations/hooks';
import {useParams} from 'react-router-dom';
import {Button} from 'ui-kit';

import {breadCrumbsLink, isShowButtonEdit} from '../helpers';

import {RelationContactsWithAccess} from './RelationContactsWithAccess';
import {RelationContactsWithoutAccess} from './RelationContactsWithoutAccess';
import {useHandleClickButton} from './hooks';
import {sx} from './styles';

export const RelationContacts = () => {
  const {t} = useTranslate('connections');

  const {id} = useParams();
  const {mobile: isMobile} = useMQuery();
  const {relationId} = useContactRelations(id);
  const {relation} = useRelation(relationId);

  const handleClickButton = useHandleClickButton(id);

  const isAccesses = relation?.accesses?.length;

  return (
    <>
      {
        isMobile && (
          <Breadcrumbs>
            <Link to={breadCrumbsLink(id)}>{getFullName(relation?.contact)}</Link>
            <Text>{t('CONTACTS')}</Text>
          </Breadcrumbs>
        )
      }
      <Stack sx={sx.container}>
        {isAccesses
          ? <RelationContactsWithAccess relation={relation} />
          : <RelationContactsWithoutAccess relation={relation} />
        }
        {
          isShowButtonEdit({isMobile, relation}) && (
            <Box sx={sx.buttonsContainer}>
              <Button
                variant='outlined'
                color='primary'
                onClick={handleClickButton}>
                {
                  relation?.actualAddresses?.main
                    ? t('EDIT')
                    : t('ADD')
                }
              </Button>
            </Box>
          )}
      </Stack>
    </>
  );
};
