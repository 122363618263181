import {SxProps, Theme} from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  loverBlockContainer: (t) => ({
    borderTop: '1px solid',
    borderColor: t.palette.secondary._14,
    p: 24,
    [t.breakpoints.between('sm', 'lg')]: {
      width: 'auto',
      pb: 0,
    },
    [t.breakpoints.only('xs')]: {
      width: 'auto',
      backgroundColor: t.palette.common.white,
    },
  }),
  upperBlockContainer: (t) => ({
    flexGrow: 1,
    p: 24,
    [t.breakpoints.between('sm', 'lg')]: {
      pt: 0,
      pb: 36,
      width: 'auto',
    },
  }),
  listContainer: {
    mb: 36,
  },
  mainTitle: {
    typography: {
      xs: '22_26_500',
      sm: '24_34_500',
    },
  },
  boxInfoContainer: (t) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    color: t.palette.grey[600],
    svg: {
      flexShrink: 0,
      path: {
        fill: 'currentColor',
      },
    },
  }),
  notSpecifiedText: (t) => ({
    color: t.palette.grey[500],
    typography: '14_18_500',
  }),
  addressContainer: {
    flexDirection: 'initial',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
