import {SxProps, Theme, styled} from '@mui/material';
import {Form} from 'formik';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  mainContainer: {
    gap: {xs: 48, sm: 36, lg: 42},
  },
  whiteBox: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: {xs: 48, sm: 36, lg: 60},
    justifyContent: 'space-between',
    flexGrow: 1,
    p: {xs: '18px 16px', sm: 0, lg: 24},
    border: {
      sm: 'none',
      xs: `1px solid ${t.palette.secondary._14 ?? ''}`,
    },
  }),
  mainTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    flexDirection: 'row',
    justifyContent: {xs: 'space-between', sm: 'flex-end'},
    gap: 12,
  },
  button: (t) => ({
    [t.breakpoints.down('sm')]: {
      flex: 1,
    },
  }),
  insuranceTitle: {
    typography: {xs: '22_26_500', lg: '24_34_500'},
  },
  listItemText: {
    typography: '14_18_500',
  },
  plan: (t) => ({
    '& span:not(label span)': {
      color: t.colors.all.errorLegacy,
    },
  }),
};

export const StyledForm = styled(Form)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 36,
  flexGrow: 1,
  [theme.breakpoints.between('sm', 'lg')]: {
    gap: 36,
  },
  [theme.breakpoints.only('xs')]: {
    pb: 18,
    gap: 48,
  },
}));
