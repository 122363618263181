import {searchResults} from '@src/api/results';
import {useCountDown} from '@src/shared/hooks/useCountdown';
import {useTranslate} from '@src/i18n/useTranslate';
import {addSeconds} from 'date-fns';
import {useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';

export const useSearchData = () => {
  const {t} = useTranslate('results');
  const [searchError, setSearchError] = useState<string>('');
  const [result, setResult] = useState<any>(null);
  const [expiredSeconds, setExpiredSeconds] = useState<number | null>(0);
  const [expiredExtraError, setExpiredExtraError] = useState(false);
  const formatTime = useCountDown(expiredSeconds || 0);

  useEffect(() => {
    if (expiredSeconds) {
      setSearchError(t('EXPIRE_TIME_ERROR', {time: formatTime}));
    }
  }, [formatTime, t, expiredSeconds]);

  useEffect(() => {
    if (!formatTime) {
      setSearchError('');
    }
  }, [formatTime]);

  const searchResultHandler = useCallback(({lastName, key}: {lastName: string, key: string | null}) => {
    searchResults({lastName, key: key || ''}).then((response) => {
      setResult(response.data);
      setSearchError('');
      setExpiredExtraError(false);
    }).catch((error) => {
      setExpiredSeconds(addSeconds(Date.now(), 10).getTime());

      switch (error.status) {
        case 429: {
          setExpiredExtraError(false);
          setExpiredSeconds(0);
          return setSearchError(t('ATTEMPTS_ERROR_PLURAL', {count: error.data.attemptsLeft}));
        }
        case 403: {
          if (expiredSeconds !== 0) {
            setExpiredExtraError(true);
          }
          setExpiredSeconds(addSeconds(Date.now(), error.data.expiredSeconds).getTime());
        }
      }
    });
  }, [t, expiredSeconds]);

  return {searchError, result, searchResultHandler, expiredExtraError};
};

export const useValidationSchema = () => {
  const {t} = useTranslate('results');
  return Yup.object().shape({
    lastName: Yup.string()
      .required(t('REQUIRED_FIELD'))
      .nullable(),
  });
};
