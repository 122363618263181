import {Stack, Typography, Box} from '@mui/material';
import {RecaptchaNote} from '@src/components/RecaptchaNote';
import {WBox} from '@src/components/WhiteBox';
import {useTranslate} from '@src/i18n/useTranslate';
import {ReactComponent as ErrorIcon} from '@src/shared/assets/icons/error.svg';
import {FC} from 'react';
import {Button, InputControl} from 'ui-kit';

import {IResultFormProps} from './types';
import {sx} from './styled';

export const ResultForm: FC<IResultFormProps> = ({lastName, onSubmit, onChange, error, expiredExtraError}) => {
  const {t} = useTranslate('results');
  return (
    <WBox sx={sx.mainBlock}>
      <Stack sx={{flexGrow: 1}}>
        <Typography sx={sx.title} >{t('RESULT_VERIFICATION_SERVICE')}</Typography>
        <Typography sx={sx.subtitle}>{t('RESULT_VERIFICATION_SERVICE_SUBTITLE')}</Typography>
        <Typography sx={sx.text}>{t('RESULT_VERIFICATION_SERVICE_TEXT')}</Typography>
        <Stack >
          <InputControl
            sx={sx.input}
            name="lastName"
            label={t('LAST_NAME')}
            placeholder={t('LAST_NAME_PLACEHOLDER')}
            value={lastName}
            error={error}
            onChange={onChange}
            max={50}
          />
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            onClick={onSubmit}>{t('CONFIRM')}</Button>
          <Box sx={sx.errorWrapper}>
            {expiredExtraError && (
              <WBox sx={sx.errorBox}>
                <ErrorIcon />
                <Typography sx={sx.errMessage}>{t('THE_RESULTS_IS_NOT_AVAILABLE_')}</Typography>
              </WBox>
            )}
          </Box>
        </Stack>
        <Stack sx={sx.recaptchaWrapper}>
          <RecaptchaNote sx={sx.recaptcha} />
        </Stack>
      </Stack>
    </WBox>
  );
};
