import {GlobalStyles} from '@mui/material';
import {Main, PageBody, globalStyles} from '@src/features/layouts/MainLayout/styles';
import {useRecaptcha} from '@src/shared/hooks/useRecaptcha';
import {useFormik} from 'formik';
import {isEqual} from 'lodash-es';
import {useSearchParams} from 'react-router-dom';

import {PdfResultsView} from './PdfResultsView/PdfResultsView';
import {useSearchData, useValidationSchema} from './hooks';
import {ResultForm} from './ResultForm/ResultForm';

export const PdfResults = () => {
  useRecaptcha();
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const validationSchema = useValidationSchema();
  const {searchError, result, searchResultHandler, expiredExtraError} = useSearchData();

  const initialValue = {
    lastName: '',
    key,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: initialValue,
    validationSchema,
    onSubmit: async (values) => {
      if (!isEqual(initialValue, values)) {
        if (values.lastName) {
          searchResultHandler(values);
        };
      }
    },
  });

  return (
    <>
      <GlobalStyles styles={globalStyles} />
      <PageBody>
        <Main sx={{marginTop: 0}}>
          {!result && key
            ? (
              <ResultForm
                lastName={formik.values.lastName || ''}
                error={formik.touched?.lastName ? formik.errors.lastName || searchError : ''}
                expiredExtraError={expiredExtraError}
                onChange={formik.handleChange}
                onSubmit={formik.handleSubmit}
              />
            )
            : <PdfResultsView resultData={result} />
          }
        </Main>
      </PageBody>
    </>
  );
};
