import {Box, Button, Stack, SxProps, Theme} from '@mui/material';
import {DialogTitle} from '@src/components/Dialog';
import {useTranslate} from '@src/i18n/useTranslate';
import {modalSize, modalType} from '@src/pages/Connections/utils/functionHelpers';
import {useMQuery} from '@src/shared/hooks/useMQuery';
import {FC} from 'react';

import {StyledModal} from './styled';

const sx: Partial<Record<string, SxProps<Theme>>> = {
  modalButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    padding: 24,
  },
  modalButton: {
    padding: '11px 50px',
  },
};

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const AccessesDeleteModal: FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const {t} = useTranslate('accesses');
  const {mobile, tablet} = useMQuery();

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      size={modalSize(tablet, mobile)}
    >
      <DialogTitle onClose={onClose} noContent={false}>{t('DELETE')}</DialogTitle>
      <Stack sx={{alignItems: 'center'}}>
        {modalType(tablet, mobile)}
      </Stack>
      <Box sx={sx.modalButtonContainer}>
        <Button
          variant="contained"
          color="secondary"
          sx={{...sx.modalButton, width: {xs: '100%', sm: 'unset'}}}
          onClick={onClose}
        >
          {t('OK')}
        </Button>
      </Box>
    </StyledModal>
  );
};
