import {RelationType} from '@src/api';
import {Nullable} from '@src/types/NullableModel';

import {SaveDataHealthPolicies} from '../types';

export const saveData = ({
  relationship,
  birthDate,
  birthSex,
  firstName,
  lastName,
  middleName,
  ssnTail,
  state,
  city,
  postalCode,
  address1,
  address2,
  phone,
  endDate,
  insurancePlanId,
  policyId,
  edit,
  isPrimaryInsurance,
  relationId,
}: Nullable<SaveDataHealthPolicies>) => {
  const data = [
    {
      endDate: {
        value: endDate || '',
      },
      guarantor: {
        address1: address1 || '',
        address2: address2
          ? {
            value: address2 || null,
          }
          : null,
        birthDate: birthDate || '',
        birthSex: birthSex || null,
        city: city || '',
        firstName: firstName || '',
        lastName: lastName || '',
        middleName: middleName
          ? {
            value: middleName || null,
          }
          : null,
        phone: phone
          ? {
            value: phone || null,
          }
          : null,
        postalCode: postalCode || '',
        relationType: relationship === RelationType.SELF ? null : relationship,
        ssnTail: ssnTail
          ? {
            value: ssnTail || null,
          }
          : null,
        state: state || null,
      },
      insurancePlanId: insurancePlanId || '',
      number: policyId || '',
      primary: !isPrimaryInsurance,
    },
  ];
  if (edit && relationId) {
    edit({
      relationId,
      data: {
        deletedHealthcareInsurancePolicyIds: [],
        healthcareInsurancePoliciesCreate: data || undefined,
        healthcareInsurancePoliciesEdit: [],
      },
    });
  }
};
